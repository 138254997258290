import axios from 'axios';
import moment from 'moment';

export const API_URL =
  (process.env.REACT_APP_API_URL || 'https://backend.contextorevisor.com.br') +
  '/api/';
export const API_URL_MOTOR = 'https://contextoaberto.com.br/api/';
export const URL_VIEW_MOTOR = 'https://contextoaberto.com.br/#/';  
// export const URL_EDIT_MOTOR = (process.env.REACT_APP_URL_EDIT_MOTOR || 'http://localhost:8001/index.html#/');

export const TOKEN_KEY =
  process.env.REACT_APP_TOKEN_KEY || 'contexto_token_key';
export const TOKEN_MOTOR_KEY =
  process.env.REACT_APP_TOKEN_MOTOR_KEY || 'contexto_token_motor_key';
export const USER_KEY = process.env.REACT_APP_USER_KEY || 'contexto_user_key';
export const USER_ROLES_KEY =
  process.env.REACT_APP_USER_ROLES_KEY || 'contexto_user_roles_key';

class ApiService {
  static recoverPassword(data) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .post(`${API_URL}auth/password/email`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static resetPassword(data) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .post(`${API_URL}auth/password/reset`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static updatePassword(data) {
    let user = JSON.parse(window.localStorage.getItem(USER_KEY));
    let dataRequest = { ...data };
    dataRequest.email = user.email;
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .post(`${API_URL}user/${user.id}/password/reset`, dataRequest, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }
  static loginNew(data) {
    return axios
      .post(`${API_URL}login`, data, {})
      .then(
        response => {
          window.localStorage.setItem(TOKEN_KEY, response.data.success.token);
          return ApiService.user();
        }
        // err => {
        //
        //
        // }
      )
      .then(() => true);
  }

  static checkToken() {
    let token = window.localStorage.getItem(TOKEN_KEY);

    if (token) {
      return axios
        .get(`${API_URL}auth/token/refresh`, {
          params: { token },
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          window.localStorage.setItem(TOKEN_KEY, token);
          return true;
        });
    }

    return Promise.resolve(false);
  }

  /**
   * Verifica se já existe um apelido (nome do minisite) para um revisor
   *
   * @param {*} apelido
   */
  static verifyApelido(apelido) {
    return axios
      .get(`${API_URL}reviewers/verify/${apelido}`)
      .then(response => response.data);
  }

  static embeddedSigning(params) {
    const email = localStorage.getItem('email');
    const data = new FormData();
    data.append('doc', params);

    return axios
      .post(`${API_URL}embeddedSigning/${email}`, data)
      .then(response => {
        return response.data;
      })
      .catch(console.error);
  }

  static becomePartner(params) {
    const email = localStorage.getItem('email');

    return axios
      .put(`${API_URL}partner/${email}`, params)
      .then(response => {})
      .catch(console.error);
  }

  static user() {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .get(`${API_URL}user?include=roles`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        let { data, included } = response.data;
        let user = data.attributes;
        //console.log(user)
        user.id = data.id;

        if (included) {
          let includes = {};

          for (let entity of included) {
            if (!includes[entity.type]) {
              includes[entity.type] = [];
            }

            includes[entity.type].push(entity.attributes);
          }

          user = { ...user, ...includes };
        }

        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
        window.localStorage.setItem(
          USER_ROLES_KEY,
          JSON.stringify(ApiService.getUserRoles(user))
        );
        return ApiService.getUser();
      });
  }

  static getUser() {
    let user = JSON.parse(window.localStorage.getItem(USER_KEY));
    if (user) {
      user.bank = user.bank_code;
      let roles = JSON.parse(window.localStorage.getItem(USER_ROLES_KEY));
      return { user, roles };
    } else {
      return { user: null, roles: null };
    }
  }

  static login(params) {
    return axios
      .post(
        `${API_URL}auth/token?email=${params.email}&password=${params.password}`
      )
      .then(
        response => {
          let data = response.data;
          window.localStorage.setItem(TOKEN_KEY, data.access_token);

          return ApiService.user();
        },
        err => {}
      )
      .then(() => true);
  }

  static loginMotor(params) {
    return axios
      .post(
        `${API_URL_MOTOR}authenticate?email=${params.email}&password=${params.password}`
      )
      .then(response => {
        let data = response.data;
        window.localStorage.setItem(TOKEN_MOTOR_KEY, data.token);
      })
      .then(() => true);
  }

  static loginFacebook(params) {
    return axios
      .post(
        `${API_URL}auth/token/facebook?email=${params.email}&fb_id=${params.fb_id}&token=${params.token}&name=${params.name}`
      )
      .then(response => {
        let data = response.data.success;
        window.localStorage.setItem(TOKEN_KEY, data.token2);

        return ApiService.user();
      })
      .then(() => {
        return ApiService.loginMotorFacebook(params);
      });
  }

  static loginMotorFacebook(params) {
    return axios
      .post(
        `${API_URL_MOTOR}authenticate-facebook?email=${params.email}&fb_id=${params.fb_id}&token=${params.token}&name=${params.name}`
      )
      .then(response => {
        let data = response.data;
        window.localStorage.setItem(TOKEN_MOTOR_KEY, data.access_token);
      })
      .then(() => true);
  }

  static loginGoogle(params) {
    return axios
      .post(
        `${API_URL}auth/token/google?email=${params.email}&google_id=${params.google_id}&token=${params.token}&name=${params.name}`
      )
      .then(response => {
        let data = response.data.success;
        window.localStorage.setItem(TOKEN_KEY, data.token2);

        return ApiService.user();
      })
      .then(() => {
        return ApiService.loginMotorGoogle(params);
      });
  }

  static loginMotorGoogle(params) {
    return axios
      .post(
        `${API_URL_MOTOR}authenticate-google?email=${params.email}&google_id=${params.google_id}&token=${params.token}&name=${params.name}`
      )
      .then(response => {
        let data = response.data;
        window.localStorage.setItem(TOKEN_MOTOR_KEY, data.access_token);
      })
      .then(() => true);
  }

  static logoff() {
    let token = window.localStorage.getItem(TOKEN_KEY);
    window.localStorage.clear();
    return axios
      .get(`${API_URL}logout`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {});
  }

  static registerReviewer(attributes) {
    attributes = { ...attributes };
    console.log(attributes);
    attributes.dt_nascimento = moment(
      attributes.dt_nascimento,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');

    //console.log(attributes);
    return ApiService.uploadAttach(attributes.curriculum).then(result => {
      attributes.curriculum = result;
      console.log(result);
      let params = {
        data: { attributes }
      };

      return axios
        .post(`${API_URL}registerReviewer`, params.data.attributes)
        .then(response => {
          console.log(response);
        });
    });
  }

  static registerWriter(attributes) {
    attributes = { ...attributes };
    attributes.dt_nascimento = moment(
      attributes.dt_nascimento,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');

    let params = {
      data: { attributes }
    };

    return axios
      .post(`${API_URL}registerWriter`, params.data.attributes)
      .then(response => response.data);
  }

  //**- */
  static createPartnerInstituition(params) {
    return axios
      .post(`${API_URL}institution-partner`, params)
      .then(response => response.data);
  }

  static registerPromoter(attributes) {
    if (attributes.pf) {
      attributes.dt_nascimento = moment(
        attributes.dt_nascimento,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
    }

    return axios
      .post(`${API_URL}promoter-partner`, attributes)
      .then(response => response.data);
  }

  //**- */

  static states() {
    return axios.get(`${API_URL}states`).then(response => {
      let { data } = response.data;

      return data.map(item => {
        let { uf, descricao } = item.attributes;
        return {
          id: item.id,
          uf,
          descricao
        };
      });
    });
  }

  static trainings() {
    return axios.get(`${API_URL}trainings`).then(response => {
      let { data } = response.data;

      return data.map(item => {
        let { formacao } = item.attributes;
        return {
          id: item.id,
          formacao
        };
      });
    });
  }

  static divulgacao() {
    return axios
      .get(`${API_URL}meio-divulgacao`)
      .then(response => response.data);
  }

  static async reviewers(params = {}) {
    const {
      searchQuery,
      orderType,
      rating,
      pagePrice,
      numberRevisions,
      published,
      unpublished,
      approved,
      pending,
      unapproved,
      all,
      page
    } = params;

    const query = {
      page,
      name: searchQuery && { $like: `%${searchQuery}%` },
      sort: orderType && {
        page_price_cents: orderType === 'cheap' ? 'asc' : 'desc'
      },
      rating: rating && { $gte: rating },
      page_price_cents: pagePrice && { $lte: parseInt(pagePrice, 10) * 100 },
      qtd_redacao_revisada: numberRevisions && { $gte: numberRevisions },
      published: published || 0,
      unpublished: unpublished || 0,
      unapproved: unapproved || 0,
      pending: pending || 0,
      approved: approved || 0
    };

    console.log(query);

    const partners = await axios
      .get(`${API_URL}partners`, { params: query })
      .then(response => response.data);

    const reviewers = await axios
      .get(`${API_URL}reviewers${all ? '/all' : ''}`, {
        params: { ...query },
        headers: all
          ? {
              Authorization: `Bearer ${window.localStorage.getItem(TOKEN_KEY)}`
            }
          : undefined
      })
      .then(response => response.data);

    if (partners.data.length < 50) {
      return {
        ...reviewers,
        data: [...partners.data, ...reviewers.users.data],
        total: reviewers.total + partners.total
      };
    }

    return {
      ...partners,
      total: reviewers.total + partners.total
    };
  }

  static reviewer(apelido) {
    return axios.get(`${API_URL}reviewers/${apelido}`).then(response => {
      let { id, attributes } = response.data.data;

      return {
        id: id,
        name: attributes.name,
        apelido: attributes.apelido,
        job: attributes.ocupacao,
        role: attributes.roles[0].name,
        qtdRevisions: attributes.qtd_redacao_revisada,
        score: attributes.rating.toFixed(1),
        price: (attributes.page_price_cents || 0) / 100,
        attributes,
        ...attributes
      };
    });
  }

  static updateUser(user) {
    //console.log(user)
    if (user.curriculum && typeof user.curriculum !== 'string') {
      return ApiService.uploadAttach(user.curriculum.file).then(result1 => {
        //console.log(result1)
        user.curriculum = result1;
        if (user.attachments) {
          return ApiService.uploadFiles(user.attachments).then(result2 => {
            user.attachments = result2;
            //console.log(user)
            return ApiService.putUser(user);
          });
        } else {
          //console.log(user)
          return ApiService.putUser(user);
        }
      });
    } else {
      //console.log('salve2')
      if (user.attachments) {
        // console.log(user.attachments)
        return ApiService.uploadFiles(user.attachments).then(result => {
          user.attachments = result;
          // console.log(result)
          // console.log(user.attachments)
          return ApiService.putUser(user);
        });
      } else {
        return ApiService.putUser(user);
      }
    }
  }

  static contact(data) {
    return axios
      .post(`${API_URL}contact`, data)
      .then(response => response.data);
  }

  static publishProfile(data) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    let attributes = { ...data };
    let params = {
      data: { attributes }
    };

    return axios
      .post(`${API_URL}user/publish`, params, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => response.data);
  }

  static uploadFile(file) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    let formData = new FormData();
    formData.append('image', file);

    return axios
      .post(`${API_URL}static/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => response.data.path);
  }

  static uploadAttach(file) {
    let formData = new FormData();
    formData.append('file', file);

    return axios
      .post(`${API_URL}static/file`, formData)
      .then(response => response.data.path);
  }

  static updateUserAvatar(user, file) {
    return ApiService.uploadFile(file).then(path => {
      user.imagem = path;
      return ApiService.updateUser(user);
    });
  }

  static getUserRoles(user) {
    let isRevisor = false,
      isRedator = false,
      isParceiro = false,
      isRepresentante = false,
      isInstituicao = false,
      isDivulgador = false,
      isAdmin = false;

    if (user && user.role) {
      for (let role of user.role) {
        if (role.name === 'admin') {
          isAdmin = true;
        }

        if (role.name === 'revisor') {
          isRevisor = true;
        }

        if (role.name === 'redator') {
          isRedator = true;
        }

        if (role.name === 'parceiro') {
          isParceiro = true;
        }

        if (role.name === 'representante') {
          isRepresentante = true;
        }

        if (role.name === 'instituicao') {
          isInstituicao = true;
        }

        if (role.name === 'divulgador') {
          isDivulgador = true;
        }
      }
    }

    return {
      isRedator,
      isRevisor,
      isParceiro,
      isRepresentante,
      isInstituicao,
      isDivulgador,
      isAdmin
    };
  }

  static uploadFiles(attachments) {
    let promises = attachments
      .filter(item => item.upload)
      .map(item => {
        return ApiService.uploadAttach(item.file).then(path => {
          item.file = path;
          return item;
        });
      });

    if (promises.length === 0) {
      return Promise.resolve(attachments);
    }

    return Promise.all(promises).then(values => {
      let result = attachments.filter(item => !item.upload);
      result = result.concat(values);
      result = result.map(item => {
        delete item.upload;
        return item;
      });
      return result;
    });
  }

  static becomeReviewerPartner(user) {
    user = { ...user };
    delete user.role;
    delete user.id;
    let attributes = { ...user };
    return axios.put(`${API_URL}reviewer-partner/${user.email}`, attributes);
  }
  static checkReviewer(data) {
    return axios.get(`${API_URL}checkReviewer/${data.email}`);
  }

  static putUser(user) {
    user = { ...user };
    let token = window.localStorage.getItem(TOKEN_KEY);

    let user_id = parseInt(user.id, 10);

    delete user.role;
    delete user.id;

    let attributes = { ...user };

    let params = {
      data: { type: 'users', user_id, attributes }
    };
    return axios
      .put(`${API_URL}user/${user_id}`, params, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        return ApiService.user();
      });
  }
  static createCode() {
    let token = window.localStorage.getItem(TOKEN_KEY);
    return axios.get(`${API_URL}createCode`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }
  static sendCode(code) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    return axios.post(
      `${API_URL}sendcode`,
      { code: code },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
  }
  static existsEmail(data) {
    return axios.get(`${API_URL}users/exists-email?email=${data.email}`);
  }
  static verifyCode(params) {
    let code = params.code;
    return axios.get(`${API_URL}verifyCode/${code}`);
  }

  static createRepresentativePartner(attributes) {
    attributes = { ...attributes };
    attributes.dt_nascimento = moment(
      attributes.dt_nascimento,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');

    return axios
      .post(`${API_URL}representative-partner`, attributes)
      .then(response => response.data);
  }


  static uploadRedacaoFile(file, filename, revisorID, redatorID, wrpID) {
    let token = window.localStorage.getItem(TOKEN_MOTOR_KEY);
    return axios.post(
      `${API_URL_MOTOR}upload-arquivo/revisor/${revisorID}/redator/${redatorID}?token=${token}`,
      { arquivo: file, fileName: filename, wrpId: wrpID }
    );
  }

  static uploadRedacaoImagem(data) {
    let token = window.localStorage.getItem(TOKEN_MOTOR_KEY);
    return axios.post(`${API_URL_MOTOR}redacao?token=${token}`, {
      wrp_id: data.wrpID,
      redacao: data.filename,
      temaId: 1,
      redacao_imagem: data.arquivo,
      tipoRedacaoId: 8,
      modalidadeId: 2,
      tipoFormato: 1,
      versaoPaga: 1
    });
  }

  static async adminActionOnReviewer(params = {}) {
    const { reviewer, action } = params;

    if (reviewer) {
      const data = await axios.put(
        `${API_URL}reviewer/${reviewer.id}/${action}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(TOKEN_KEY)}`
          }
        }
      );
      return data;
    }

    return null;
  }

  static getClients() {
    let token = window.localStorage.getItem(TOKEN_KEY);
    return axios.get(`${API_URL}clients/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

export { ApiService };
