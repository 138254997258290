import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes';
import './ReceiverTextsSection.css';

const { buscarRevisor } = routes;

const ReceiveTextsSection = () => (
  <div className="section">
    <div className="header">
      <div className="redator-header">Cliente</div>
      <h1 className="section-header">
        Para qualquer tipo de texto:
        <br />
        aqui você encontra o revisor certo
      </h1>
    </div>
    <div className="flex-container-receiver process-content">
      <div className="flex-item-receiver">
        <div className="darkgray-text">Redações estudantis e para concurso</div>
        <p>
          Tenha suas redações corrigidas por um revisor especialista. Com
          ferramentas exclusivas, ele o ajudará a evoluir a expressão escrita e
          atingir seus objetivos.
        </p>
      </div>

      <div className="flex-item-receiver">
        <div className="darkgray-text">Trabalhos técnicos e acadêmicos</div>
        <p>
          Acabou o desespero de final de semestre e de curso! Com o Contexto
          Revisor você pode escolher o profissional ideal para revisar seu
          trabalho com rapidez, qualidade e preço justo.
        </p>
      </div>

      <div className="flex-item-receiver">
        <div className="darkgray-text">Textos em múltiplos formatos</div>
        <p>
          Trabalho para o colégio? Periódico? Livro? Blog? Site? Degravação de
          áudio? Legendas? Não importa o formato: há sempre um revisor
          especializado à sua espera.
        </p>
      </div>
      {/*<div className="halfbox">*/}
      {/*<img src={previaRevisao} alt="revision" />*/}
      {/*</div>*/}
      {/*<div className="halfbox left-space">*/}
      {/*<p>*/}
      {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse*/}
      {/*varius enim in eros elementum tristique. Duis cursus, mi quis viverra*/}
      {/*ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.*/}
      {/*</p>*/}
      {/*<div className="turquoise-text">Personalize seu perfil</div>*/}
      {/*<div className="turquoise-text">Receba os pagamentos na sua conta</div>*/}
      {/*<div className="giant-text">+3000 revisores</div>*/}
      {/*<a*/}
      {/*href="criar-conta-revisor.html"*/}
      {/*className="button turquoise w-button">*/}
      {/*Trabalhar como <strong>revisor</strong>*/}
      {/*</a>*/}
      {/*</div>*/}
    </div>
    <div className="header">
      <div className="giant-text">+3000 textos revisados</div>
      <Link
        to={buscarRevisor}
        className="button turquoise w-button btn-received"
      >
        Escolha o <strong>revisor</strong>
      </Link>
    </div>
  </div>
);
export default ReceiveTextsSection;
