import React, { Component } from 'react';
import {
  fetchProposal,
  fetchCounterProposal
} from '../../../../state-management/user/actions';
import ReactPaginate from 'react-paginate';
import imageAvatarFem from '../../../assetsHolder/images/avatar-1.svg';
import imageAvatarMasc from '../../../assetsHolder/images/avatar-2.svg';
import emptyProposal from '../../../assetsHolder/images/img-empty-proposal.png';
import moment from 'moment';
import { connect } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './Proposta.css';

const proposalTypes = {
  '1': 'Redação estudantil/concurso',
  '2': 'TCC/monografia/dissertação/tese',
  '3': 'Outros tipos de texto'
};

const statusLabel = {
  open: 'Em aberto',
  disapproved: 'Rejeitado',
  approved: 'Aprovado'
};

class PropostaComponent extends Component {
  state = {
    currentTab: 0,
    page: {
      size: 10,
      number: 1
    }
  };

  componentDidMount() {
    this.props.dispatch(fetchProposal({ page: this.state.page }));
    this.props.dispatch(fetchCounterProposal({ page: this.state.page }));
  }

  handlePageClick = data => {
    let state = { ...this.state };
    let selected = data.selected;
    state.page.number = selected + 1;
    this.setState(state);
    this.props.dispatch(fetchProposal({ page: state.page }));
  };

  changTab = currentTab => {
    this.setState({ currentTab });
  };

  handleDate = date => moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');

  handleDetalheProposta = proposal => {
    let { onDetalheProposta } = this.props;
    onDetalheProposta(proposal);
  };

  getStatus(proposal) {
    if (proposal.counter_proposal) {
      return { label: 'Rejeitada (Contraproposta)', className: 'disapproved' };
    }

    return { label: statusLabel[proposal.status], className: proposal.status };
  }

  renderAvatarImg(proposal) {
    let imgAvatarSrc = '';
    let { isRevisor, isRedator, isParceiro } = this.props.roles;

    if (isRedator) {
      if (proposal.reviewer.imagem) {
        imgAvatarSrc = proposal.reviewer.imagem;
      } else {
        imgAvatarSrc =
          proposal.reviewer.sexo === 'F' ? imageAvatarFem : imageAvatarMasc; //Imagens padrão
      }
    } else if (isRevisor || isParceiro) {
      if (proposal.writer.imagem) {
        imgAvatarSrc = proposal.writer.imagem;
      } else {
        imgAvatarSrc =
          proposal.writer.sexto === 'F' ? imageAvatarFem : imageAvatarMasc;
      }
    }

    return <img alt="Avatar" className="proposta-avatar" src={imgAvatarSrc} />;
  }

  renderTabContent() {
    if (this.state.currentTab === 0) {
      return this.renderTab1();
    }

    return this.renderTab2();
  }

  renderMessage(status) {
    let { isRevisor, isParceiro, isRedator } = this.props.roles;

    if (status === 'Aprovado' && (isRevisor || isParceiro)) {
      return 'Vá até Minhas Revisões e inicie o trabalho logo após o envio do texto pelo cliente.';
    } else if (status === 'Aprovado' && isRedator) {
      return 'Vá até Meus Pedidos para acompanhar a situação de revisão de seu texto.';
    } else {
      return null;
    }
  }

  renderTab1() {
    let { proposals, roles } = this.props;
    let propostaName = roles.isRevisor ? 'Proposta de' : 'Enviado para';
    let pagination = this.props.proposalsPagination;
    let loading = this.props.loading;
    if (pagination.total === undefined) {
      loading = true;
    }

    return (
      <div>
        {loading && (
          <SkeletonTheme>
            <ul className="perfil-list w-list-unstyled">
              <li className="perfil-item" key={2}>
                <div className="list-style" />
                <div className="revisao-item">
                  <Skeleton
                    circle={true}
                    height={40}
                    width={40}
                    style={{ marginBottom: '10px' }}
                  />
                  <Skeleton count={4} />
                </div>
              </li>
            </ul>
          </SkeletonTheme>
        )}

        {proposals.length === 0 && !loading && (
          <div>
            <img
              src={emptyProposal}
              alt="Lista Vazia"
              className="no-proposta"
            />
            <span className="no-proposta">Você ainda não possui propostas</span>
          </div>
        )}

        {proposals.length > 0 && !loading && (
          <ul className="perfil-list w-list-unstyled">
            {proposals
              .sort((a, b) => a['proposal_id'] - b['proposal_id'])
              .map((proposal, index) => {
                let status = this.getStatus(proposal);
                return (
                  <li className="perfil-item" key={index}>
                    <div className="list-style" />
                    <div className="revisao-item">
                      <div className="listonly perfil-especification proposta-header">
                        {this.renderAvatarImg(proposal)}
                        <div className="proposta-name">
                          <div>{propostaName}</div>
                          <span>
                            {roles.isRevisor
                              ? proposal.writer.name
                              : proposal.reviewer.name}
                          </span>
                        </div>
                        <span className={'proposta-status ' + status.className}>
                          Status: {status.label}
                        </span>
                      </div>
                      <div className="revisao-data">
                        {status.label === 'Aprovado'
                          ? this.handleDate(proposal.updated_at)
                          : proposal.created_at}
                      </div>
                      <div className="proposta-item">
                        Tipo de Texto:{' '}
                        <span>{proposalTypes[proposal.type]}</span>
                      </div>
                      <div className="proposta-item">
                        Num. de laudas:{' '}
                        <span>{proposal.total_pages} laudas</span>
                      </div>
                      <div className="proposta-item">
                        Prazo de entrega:{' '}
                        <span>{proposal.total_days} dias</span>
                      </div>

                      <div className="proposta-item">
                        <span>{this.renderMessage(status.label)}</span>
                        <span>
                          {status.label === 'Em aberto'
                            ? 'Aguardar resposta por e-mail.'
                            : ''}
                        </span>
                      </div>
                      <div className="visible">
                        <button
                          className="button w-button nomargin margin-left-auto"
                          onClick={this.handleDetalheProposta.bind(
                            this,
                            proposal
                          )}
                        >
                          Mostrar detalhes
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}

        {pagination.total_pages > 1 && (
          <ReactPaginate
            previousLabel={'← Anterior'}
            nextLabel={'Próxima →'}
            breakLabel={<span className="gap">...</span>}
            pageCount={Math.ceil(proposals.length / this.state.page.size)}
            forcePage={pagination.current_page - 1}
            containerClassName={'pagination'}
            previousLinkClassName={'previous_page'}
            nextLinkClassName={'next_page'}
            disabledClassName={'disabled'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }

  renderTab2() {
    let { counterProposals } = this.props;
    let { isRedator } = this.props.roles;
    let paginationCounterProposal = this.props.counterProposalsPagination;
    let loading = this.props.loading;
    if (paginationCounterProposal.total === undefined) {
      loading = true;
    }

    return (
      <div>
        {loading && (
          <SkeletonTheme>
            <ul className="perfil-list w-list-unstyled">
              <li className="perfil-item" key={2}>
                <div className="list-style" />
                <div className="revisao-item">
                  <Skeleton
                    circle={true}
                    height={40}
                    width={40}
                    style={{ marginBottom: '10px' }}
                  />
                  <Skeleton count={4} />
                </div>
              </li>
            </ul>
          </SkeletonTheme>
        )}

        {counterProposals.length === 0 && !loading && (
          <div>
            <img
              src={emptyProposal}
              alt="Lista Vazia"
              className="no-proposta"
            />
            <span className="no-proposta">
              Você ainda não possui contrapropostas
            </span>
          </div>
        )}

        {counterProposals.length > 0 && !loading && (
          <ul className="perfil-list w-list-unstyled">
            {counterProposals
              .sort((a, b) => (a['proposal_id'] < b['proposal_id'] ? 1 : -1))
              .map((proposal, index) => {
                let name = isRedator
                  ? proposal.reviewer.name
                  : proposal.writer.name;
                let label = isRedator ? 'Recebido de' : 'Enviado para';

                return (
                  <li className="perfil-item" key={index}>
                    <div className="list-style" />
                    <div className="revisao-item">
                      <div className="listonly perfil-especification proposta-header">
                        {this.renderAvatarImg(proposal)}
                        <div className="proposta-name">
                          <div>{label}:</div>
                          <span>{name}</span>
                        </div>
                        <span className={'proposta-status ' + proposal.status}>
                          Status: {statusLabel[proposal.status]}
                        </span>
                      </div>
                      <div className="revisao-data">{proposal.created_at}</div>
                      <div className="proposta-item">
                        Tipo de Texto:{' '}
                        <span>{proposalTypes[proposal.type]}</span>
                      </div>
                      <div className="proposta-item">
                        Num. de laudas:{' '}
                        <span>{proposal.total_pages} laudas</span>
                      </div>
                      <div className="proposta-item">
                        Prazo de entrega:{' '}
                        <span>{proposal.total_days} dias</span>
                      </div>

                      <div className="proposta-item">
                        <span>
                          {this.renderMessage(statusLabel[proposal.status])}
                        </span>
                        <span>
                          {statusLabel[proposal.status] === 'Em aberto'
                            ? 'Aguardar resposta por e-mail.'
                            : ''}
                        </span>
                      </div>
                      <div className="visible">
                        <button
                          className="button w-button nomargin margin-left-auto"
                          onClick={this.handleDetalheProposta.bind(
                            this,
                            proposal
                          )}
                        >
                          Mostrar detalhes
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}

        {paginationCounterProposal.total_pages > 1 && (
          <ReactPaginate
            previousLabel={'← Anterior'}
            nextLabel={'Próxima →'}
            breakLabel={<span className="gap">...</span>}
            pageCount={Math.ceil(
              counterProposals.length / this.state.page.size
            )}
            forcePage={paginationCounterProposal.current_page - 1}
            containerClassName={'pagination'}
            previousLinkClassName={'previous_page'}
            nextLinkClassName={'next_page'}
            disabledClassName={'disabled'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }

  renderContent() {
    let { isRedator } = this.props.roles;

    return (
      <div>
        <div>
          <p>
            A quantidade de reescrituras do texto e novas correções devem ser
            acordadas entre Cliente e Revisor.
          </p>
        </div>

        <div className="divider" />

        <div className="flex-row">
          <div
            className={'tab ' + (this.state.currentTab === 0 ? 'selected' : '')}
            onClick={this.changTab.bind(this, 0)}
          >
            {isRedator ? 'Propostas enviadas' : 'Propostas recebidas'}
          </div>
          <div
            className={'tab ' + (this.state.currentTab === 1 ? 'selected' : '')}
            onClick={this.changTab.bind(this, 1)}
          >
            {isRedator
              ? 'Contrapropostas recebidas'
              : 'Contrapropostas enviadas'}
          </div>
        </div>

        <div className="divider nomargin" />

        {this.renderTabContent()}
      </div>
    );
  }

  render() {
    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">Propostas de clientes</h4>
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => ({
  proposals: userStore.proposals,
  proposalsPagination: userStore.proposalsPagination
});

const ProposalsComponent = connect(mapStateToProps)(PropostaComponent);
export { ProposalsComponent };
