import React from 'react';
import routes from '../../../../../routes';

const { criarContaRevisor, criarContaRedator } = routes;

const CreateAccountSection = props =>
  !props.user ? (
    <div className="section">
      <div className="header">
        <h1 className="section-header">Crie sua conta agora mesmo!</h1>
        <p className="subtitle">
          E faça parte da maior e melhor plataforma de clientes e revisores do
          Brasil.
        </p>
      </div>
      <div className="all-center container">
        <button
          className="button nomargin w-button"
          onClick={() => props.history.push(criarContaRedator)}
        >
          Criar conta <strong>cliente</strong>
        </button>
        <p className="inbetween">ou</p>
        <button
          className="button nomargin turquoise w-button"
          onClick={() => props.history.push(criarContaRevisor)}
        >
          Criar conta <strong>revisor</strong>
        </button>
      </div>
    </div>
  ) : (
    ''
  );

export default CreateAccountSection;
