import React from 'react';
import ParceiroAvatar from './../ParceiroAvatar';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { array, number, string } from 'prop-types';

const { perfilRevisorSite } = routes;
const avatares = {
  feminino: '/static/media/avatar-1.b13492e1.svg',
  masculino: '/static/media/avatar-2.aeab4433.svg'
};

export const ReviewerCard = ({
  id,
  apelido,
  avatar,
  name,
  job,
  location,
  qtd_redacao_revisada,
  rating,
  current_plan,
  sexo,
  page_price_formatted,
  roles
}) => (
  <div className="card fullsize">
    <div className="card-top">
      {roles && roles[0].id === 4 ? (
        <div alt="avatar" className="card-avatar">
          <ParceiroAvatar
            id={id}
            src={avatar}
            onError={evt => {
              evt.target.src =
                sexo === 'M' ? avatares.masculino : avatares.feminino;
            }}
            alt="avatar"
            className="card-avatar"
          />
        </div>
      ) : (
        <img
          id={id}
          src={avatar}
          onError={evt => {
            evt.target.src =
              sexo === 'M' ? avatares.masculino : avatares.feminino;
          }}
          alt="avatar"
          className="card-avatar"
        />
      )}
    </div>
    <div className="card-content">
      <div className="w-row">
        <div className="w-col w-col-8 w-col-medium-6 w-col-small-6 w-col-tiny-6">
          <div className="card--name">{name}</div>
          <div className="card--funcao">{job}</div>
          <div className="card--local">{location}</div>
          <div className="card--preco">Revisões a partir de</div>
          <div className="card--valor">{page_price_formatted}</div>
        </div>
        <div className="w-col w-col-4 w-col-medium-6 w-col-small-6 w-col-tiny-6">
          <div className="card--quant">{qtd_redacao_revisada}</div>
          <div className="card--qualisupport">Revisões</div>
          <div className="card--score">
            <span className="score-icon"></span> {rating}
          </div>
          <div className="card--user">Usuário</div>
          <div className="card--package">{current_plan}</div>
        </div>
      </div>
    </div>
    <div className="w-row card-content-button">
      <Link
        to={perfilRevisorSite.replace(':apelido', apelido)}
        className="card-btn w-button"
      >
        ver perfil
      </Link>
    </div>
  </div>
);

ReviewerCard.propTypes = {
  id: number,
  apelido: string,
  avatar: string,
  name: string,
  job: string,
  location: string,
  qtd_redacao_revisada: number,
  rating: number,
  current_plan: string,
  sexo: string,
  page_price_formatted: string,
  roles: array
};

export default ReviewerCard;
