import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import routes from '../../../routes';
import { logoff } from '../../../state-management/user/actions';
import './Partnerships.css';
import { Box } from '../../siteComponents/Box';
import { Link } from 'react-router-dom';

const {
  parcerias,
  contratoRevisor,
  sejaRevisor,
  criarContaRevisor,
  criarContaInstituicaoParceira,
  criarContaParceiroDivulgador
} = routes;

class PartnershipsComponent extends Component {
  render() {
    return (
      <div>
        <GeneralMenu selected={parcerias} {...this.props} />
        <div>
          <div className="header">
            <h1 className="section-header">
              Parcerias Contexto Revisor:
              <br />
              somar esforços para multiplicar resultados!
            </h1>
          </div>
          <div className="box-container">
            <div className="hero-container">
              <Box
                className="box-flex"
                title="Revisor Parceiro"
                content={
                  <p>
                    Mediante a concessão de desconto promocional de 10% sobre o
                    valor médio que você atribuiu a sua lauda, nossas
                    instituições parceiras lhe encaminharão clientes com textos
                    para revisão.
                    <br />
                    Após a aplicação desse desconto e do percentual pela
                    utilização da plataforma, você receberá 72% do valor
                    contratado... e muitos, muitos clientes.
                  </p>
                }
                to={
                  this.props.authenticated
                    ? this.props.roles.isRevisor
                      ? contratoRevisor
                      : sejaRevisor
                    : criarContaRevisor
                }
                linkText={
                  this.props.authenticated ? (
                    this.props.roles.isRevisor ? (
                      <span style={{ color: '#FFFFFF' }}>
                        Quero ser <strong>parceiro!</strong>
                      </span>
                    ) : (
                      <span style={{ color: '#FFFFFF' }}>
                        Seja <strong>revisor!</strong>
                      </span>
                    )
                  ) : (
                    <Link to="/login/criar-conta-revisor">
                      <span style={{ color: '#FFFFFF' }}>
                        Entre para ser <strong>parceiro!</strong>
                      </span>
                    </Link>
                  )
                }
                boxType="revisor"
              />
              <Box
                className="box-flex"
                title="Representante Parceiro"
                content={
                  <p>
                    Você captará instituições com público que demande trabalho
                    de revisão de textos, tornando-as Instituições Parceiras.
                    <br />
                    2% de todos os valores pagos aos revisores pelos clientes
                    dessas instituições irão automaticamente para sua conta
                    bancária, durante todo o tempo em que perdurar a parceria do
                    Contexto com elas.
                  </p>
                }
                to={routes.parceiroRepresentante}
                linkText={
                  <span style={{ color: '#FFFFFF' }}>
                    Quero ser <strong>parceiro!</strong>
                  </span>
                }
                boxType="redator"
              />
            </div>
          </div>

          <div className="box-container">
            <div className="hero-container">
              <Box
                className="box-flex"
                title="Instituição Parceira"
                content={
                  <p>
                    Se sua instituição tem grande visibilidade e por ela
                    transitam potenciais clientes para revisão de textos, então
                    habilite-a para ser uma Instituição Parceira e divulgar o
                    Contexto Revisor.
                    <br />
                    8% de todo o valor arrecadado com o pagamento pelos seus
                    clientes aos nossos Revisores Parceiros serão transferidos
                    de imediato para a conta de sua instituição, enquanto
                    perdurar seu contrato com o Contexto.
                  </p>
                }
                to={criarContaInstituicaoParceira}
                linkText={
                  <Link to={routes.criarContaInstituicaoParceira}>
                    <span style={{ color: '#FFFFFF' }}>
                      Quero ser <strong>parceiro!</strong>
                    </span>
                  </Link>
                }
                boxType="redator"
              />
              <Box
                className="box-flex"
                title="Parceiro Divulgador"
                content={
                  <p>
                    Você tem um blog, um canal no Youtube, uma revista
                    eletrônica ou simplesmente é aquela pessoa com grande poder
                    de comunicação? Então se torne nosso Parceiro Divulgador e
                    mostre o Contexto Revisor a potenciais clientes para revisão
                    de textos.
                    <br />
                    Mediante um código promocional que lhe forneceremos, 8% de
                    todo o valor arrecadado com o pagamento pelos clientes aos
                    nossos Revisores Parceiros serão transferidos de imediato
                    para a sua conta bancária enquanto perdurar seu contrato com
                    o Contexto.
                  </p>
                }
                to={criarContaParceiroDivulgador}
                linkText={
                  <span style={{ color: '#FFFFFF' }}>
                    Quero ser <strong>parceiro!</strong>
                  </span>
                }
                boxType="revisor"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});

const mapStateToProps = ({ userStore, roles }) => ({
  authenticated: userStore.authenticated,
  roles: userStore.roles
});

const Partnerships = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipsComponent);
export { Partnerships };
