import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../../../siteComponents/Footer';
import GeneralMenu from '../../../../siteComponents/GeneralMenu';
import Modal from '../../../../siteComponents/modal';
import { logoff } from '../../../../../state-management/user/actions';
import AgreementPDF from './AgreementPDF.js';
import './ReviewerContract.css';
import cep from 'cep-promise';
import { ApiService } from '../../../../../state-management/service/api.js';
import {
  FormInput,
  FormButton,
  FormValidator,
  FormMask
} from '../../../../siteComponents/form';
import {
  userUpdate,
  userUpdateSuccess,
  showNotification,
  becomePartner
} from '../../../../../state-management';
// import { Link } from 'react-router-dom';
import { embeddedSigning } from '../../../../../state-management/user/actions';
import { pdf } from '@react-pdf/renderer';
import eversign from '../../eversign';

class ReviewerContractComponent extends Component {
  state = {
    showAccept: false,
    showRefuse: false,
    showDados: true,
    showButton: false,
    signingURL: '',
    user: {
      //email: '',
      document: '',
      postal_code: '',
      address: '',
      complement: '',
      number: '',
      district: '',
      city: '',
      uf_id: '',
      rg: '',
      rg_aux: '',
      nacionalidade: '',
      estado_civil: '',
      accept_partnership: false,
      nacionalidade_aux: '',
      estado_civil_aux: '',
      pf: false
    }
  };

  onCheck = e => {
    let { name, checked } = e.target;
    let state = { ...this.state };
    state.user[name] = checked;
    this.setState(state);
  };

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.user[name] = value;
    this.setState(newState);
  };

  check_email(value) {
    //importar um rota para checar se o email existe, se nao apresentar erro
    //continua a rota, se nao repita o processo, impede de prosseguir
  }

  verifica_cpf_cnpj(value) {
    if (value) {
      value = value.toString();
      value = value.replace(/[^0-9]/g, '');
      // Verifica CPF
      if (value.length === 11) {
        return 'CPF';
      }
      // Verifica CNPJ
      else if (value.length === 14) {
        return 'CNPJ';
      } else {
        return false;
      }
    } else {
      return (
        <Modal
          message="Antes de Continuar"
          okText="Okay"
          visible={this.state.showDados}
          onlyOkButton={true}
          onOkClick={this.handleDadosClose}
        ></Modal>
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      const { document } = nextProps.user;
      const { postal_code } = nextProps.user;
      const { city } = nextProps.user;
      const { address } = nextProps.user;
      const { number } = nextProps.user;
      const { complement } = nextProps.user;
      // const showDados = document;
      const isPJ = this.verifica_cpf_cnpj(document) === 'CNPJ';
      this.setState(({ user }) => ({
        user: {
          ...user,
          document,
          postal_code,
          city,
          number,
          complement,
          address
        },
        isPJ
      }));
    }

    if (nextProps.signingURL) {
      this.onScriptLoad(nextProps.signingURL);
    }
  }

  handleAccept = async () => {
    this.setState({ showButton: true }, async () => {
      const doc = await pdf(<AgreementPDF user={this.state.user} />).toBlob();
      const user = this.state.user;
      //this.setState({ showButton: false });
      this.props.dispatch(becomePartner(user));
      this.props.dispatch(embeddedSigning(doc));
      return;
    });
    return;
  };

  onScriptLoad = param => {
    const url = param;

    eversign.open({
      url: url,
      containerID: 'eversign',
      width: 600,
      height: 600,
      events: {
        loaded: () => {},
        signed: user => {
          this.setState({ showAccept: true });
          this.setState({ accept_partnership: true });
          //this.props.dispatch(userUpdate(user));
        },
        declined: function() {
          alert('Contrato recusado');
        },
        error: function() {
          alert('error callback');
        }
      }
    });
  };

  handleClose = () => {
    this.props.history.replace('/');
  };

  handleCep = event => {
    const cep_str = event.target.value.replace('-', '');
    if (cep_str.length !== 8) {
      return;
    }
    cep(cep_str)
      .then(data => {
        const { user } = this.state;

        user.address = data.street;
        user.district = data.neighborhood;
        user.city = data.city;
        user.state = data.state;

        this.props.states.forEach(item => {
          if (item.uf === data.state) {
            user.uf_id = item.id;
          }
        });

        this.setState({ user });
      })
      .catch(err => {
        this.props.dispatch(
          showNotification('error', `ATENÇÃO: CEP inválido.`, true)
        );
      });
  };
  /*
  handleEmail = event => {
    const { user } = this.state;
    ApiService.checkReviewer({ email: user.email }).then(response => {
      if (response && response.status) {
        if (!(response.status === 302)) {
          this.setState({
            user: {
              email: ''
            }
          });
          alert(
            'ATENÇÃO: Você está tentando criar um Revisor Parceiro com um email inexistente.'
          );
          return;
        }
      }
    });
  };
  */
  handleDadosClose = () => {
    const { user } = this.state;
    this.setState({ showDados: false });

    this.setState(prevState => ({
      user: {
        // object that we want to update
        ...prevState.user, // keep all other key-value pairs
        rg: this.state.user.rg_aux, // update the value of specific key
        nacionalidade: this.state.user.nacionalidade_aux,
        estado_civil: this.state.user.estado_civil_aux
      }
    }));

    this.props.dispatch(userUpdateSuccess({ ...this.props.user, user }));
  };

  showRefuseModal = () => {
    this.setState({ showRefuse: true });
  };

  handleRefuseClose = () => {
    this.props.history.replace('/');
  };

  handleDismiss = () => {
    this.setState({ showRefuse: false });
  };
  render() {
    let { user } = this.props;

    return (
      <div className="about">
        <Modal
          message="Antes de prosseguir precisamos dos seguintes dados."
          okText="Okay"
          visible={this.state.showDados}
          onlyOkButton={true}
          onOkClick={this.handleDadosClose}
        >
          <h6 className="section-header">
            Antes de prosseguir precisamos dos seguintes dados.
          </h6>
          <FormValidator id="email-form" name="email-form" className="form">
            <div className="revisor-step1">
              <FormInput
                type="text"
                className="accont-input w-input"
                name="document"
                placeholder="CPF ou CNPJ *"
                validators={['required', 'document']}
                onChange={this.handleChangeInputText}
                value={this.state.user.document}
              />
              <FormMask
                mask="99999-999"
                placeholder="CEP *"
                className="accont-input w-input form-margin"
                name="postal_code"
                validators={['required']}
                value={this.state.user.postal_code}
                onChange={this.handleChangeInputText}
                onBlur={this.handleCep}
              />
              <FormInput
                type="text"
                placeholder="Endereço *"
                className="accont-input w-input form-margin"
                name="address"
                validators={['required']}
                value={this.state.user.address}
                onChange={this.handleChangeInputText}
              />

              <div className="flex-row form-margin">
                <FormInput
                  type="text"
                  placeholder="Complemento"
                  className="accont-input w-input"
                  name="complement"
                  value={this.state.user.complement || ''}
                  onChange={this.handleChangeInputText}
                />

                <div className="space" />

                <FormInput
                  type="number"
                  validators={['required']}
                  placeholder="Número *"
                  className="accont-input w-input"
                  name="number"
                  validators={['required']}
                  value={this.state.user.number}
                  onChange={this.handleChangeInputText}
                />
              </div>

              <FormInput
                type="text"
                placeholder="Bairro *"
                className="accont-input w-input form-margin"
                name="district"
                validators={['required']}
                value={this.state.user.district || ''}
                onChange={this.handleChangeInputText}
              />

              <div className="flex-row form-margin">
                <select
                  className="accont-input w-select"
                  name="uf_id"
                  validators={['required']}
                  value={this.state.user.uf_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Estado *</option>

                  {this.props.states.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.uf} - {item.descricao}
                      </option>
                    );
                  })}
                </select>

                <div className="space" />

                <FormInput
                  type="text"
                  placeholder="Cidade *"
                  className="accont-input w-input"
                  name="city"
                  validators={['required']}
                  value={this.state.user.city}
                  onChange={this.handleChangeInputText}
                />
              </div>
              <label>O revisor é pessoa física</label>
              <FormInput
                type="checkbox"
                name="pf"
                id="pf"
                onClick={this.onCheck}
                checked={this.state.user.pf}
                className="w-checkbow-input"
              />
              {this.state.user.pf && (
                <div>
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    name="rg_aux"
                    placeholder="RG"
                    validators={['required']}
                    onChange={this.handleChangeInputText}
                    value={this.state.user.rg_aux}
                  />
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    name="nacionalidade_aux"
                    placeholder="Nacionalidade"
                    validators={['required']}
                    onChange={this.handleChangeInputText}
                    value={this.state.user.nacionalidade_aux}
                  />
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    name="estado_civil_aux"
                    placeholder="Estado Civil"
                    validators={['required']}
                    onChange={this.handleChangeInputText}
                    value={this.state.user.estado_civil_aux}
                  />
                </div>
              )}

              <FormButton
                type="button"
                className="accont-btn--revisor button w-button"
                onClick={this.handleDadosClose}
              >
                Pronto
              </FormButton>
            </div>
          </FormValidator>
        </Modal>
        <GeneralMenu {...this.props} />
        <div className="section-dark light-padding">
          <div className="flexcourners large-container">
            <div className="terms-container" style={{ padding: '3%' }}>
              <div className="w-form">
                <h1 className="turquoise-text bold">
                  CONTRATO DE PARCERIA COMERCIAL
                </h1>
                <p>
                  <strong>
                    <strong>PRECEPTOR EDUCAÇÃO A DISTÂNCIA LTDA ME,</strong>
                  </strong>
                  pessoa jurídica constituída na forma de Sociedade Limitada,
                  inscrita no CNPJ sob o nº 07.633.844/0001-19, com sede em St
                  SRTVS, Quadra 701, Bloco O, 110, Asa Sul, CEP 70.340-000,
                  Brasília-DF, representada na forma de seus atos constitutivos,
                  doravante denominada simplesmente{' '}
                  <strong>CONTEXTO REVISOR</strong>, do outro lado,
                </p>
                {this.state.user.pf ? (
                  <p>
                    <strong> {user && localStorage.getItem('nome')}</strong>,{' '}
                    {user && this.state.user.nacionalidade},
                    {user && this.state.user.estado_civil}, portador do RG nº{' '}
                    {user && this.state.user.rg}, inscrito no CPF sob o nº{' '}
                    {user && this.state.user.document}, residente e domiciliado
                    na {user && this.state.user.address}, nº{' '}
                    {user && this.state.user.number}{' '}
                    {(user && this.state.user.complement) || ''},
                    {user && this.state.user.city},{' '}
                    {user && this.state.user.state},{' '}
                    {user && this.state.user.postal_code} ,neste ato
                    representada na forma de seus atos constitutivos, doravante
                    denominado simplesmente como{' '}
                    <strong>REVISOR PARCEIRO</strong>.
                  </p>
                ) : (
                  <p>
                    {user && localStorage.getItem('nome')} pessoa jurídica
                    constituída na forma de (Sociedade Limitada/Empresário
                    Individual) no CNPJ sob o nº{' '}
                    {user && this.state.user.document}, com sede no{' '}
                    {user && this.state.user.address}, neste ato representada na
                    forma de seus atos constitutivos, doravante denominado
                    simplesmente como REVISOR PARCEIRO.
                  </p>
                )}

                <p className="name">
                  As partes ora identificadas comprometem-se a, respectivamente,
                  unir esforços, para firmar parceria comercial no intuito de
                  acelerar os resultados esperados na utilização do software da
                  seguinte forma:
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA PRIMEIRA - DO OBJETO E NATUREZA JURÍDICA
                </h5>

                <p>
                  1.1. O objeto contratual da parceria é firmar parceria
                  comercial entre CONTEXTO REVISOR e REVISOR PARCEIRO, de forma
                  que o REVISOR PARCEIRO permita descontos maiores em seus
                  serviços no intuito de aumentar o número de serviços
                  solicitados na plataforma da CONTEXTO REVISOR e fomentar o
                  crescimento geral de interesse pelos serviços do sistema
                  “Contexto Revisor”.
                </p>

                <p>
                  1.2. Para tal, o REVISOR PARCEIRO autorizará à CONTEXTO
                  REVISOR aplicar desconto fixo de 10% (dez por cento) em todos
                  os serviços de revisão feitos pelo REVISOR PARCEIRO a clientes
                  da plataforma da CONTEXTO REVISOR.
                </p>

                <p>
                  A presente parceria é firmada sem caráter de exclusividade,
                  podendo as Partes realizarem contratações com demais
                  parceiros, desde que não afete as questões de
                  confidencialidade previstas neste.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA SEGUNDA - REMUNERAÇÃO E PAGAMENTO
                </h5>

                <p>
                  2.1. Em razão desta parceria, a{' '}
                  <strong>CONTEXTO REVISOR</strong> pagará ao{' '}
                  <strong>REVISOR PARCEIRO</strong> os seguintes valores:
                </p>

                <p className="paragraph">
                  2.1.1. 2% (setenta e dois por cento) sobre o valor contratado
                  para cada revisão na plataforma, após descontados o valor
                  correspondente à tributação devida e o percentual de 10% (dez
                  por cento) para o cliente, conforme definido como objeto deste
                  Contrato. Estes valores serão repassados mensalmente, enquanto
                  perdurar este Contrato.
                </p>

                <p className="paragraph">
                  2.1.2. Os pagamentos serão realizados todo dia 5 (cinco) do
                  mês subsequente.
                </p>

                <p>
                  2.2. O pagamento será justificado por meio de relatório
                  específico disponibilizado pela CONTEXTO REVISOR, no qual
                  estarão descritos os valores recebidos e a data de finalização
                  de cada redação.
                </p>

                <p>
                  2.3. Todos os pagamentos feitos pela CONTEXTO REVISOR serão
                  realizados por meio da plataforma online de pagamentos, em
                  conta determinada pelo REVISOR PARCEIRO no momento da
                  assinatura do presente instrumento.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
                </h5>

                <p>
                  3.1. O REVISOR PARCEIRO se obriga a realizar as suas
                  atividades, cabendo-lhe total e exclusiva responsabilidade
                  pelo integral atendimento de toda a legislação que rege os
                  negócios jurídicos e que lhe atribua responsabilidades.
                  Igualmente, o CAPTADOR se obriga também a reembolsar a REVISOR
                  PARCEIRO por todas as despesas decorrentes de:
                </p>

                <p className="paragraph">
                  a) reconhecimento judicial de vínculo empregatício seu ou de
                  seus empregados com a REVISOR PARCEIRO;
                </p>

                <p className="paragraph">
                  b) reconhecimento judicial de solidariedade da CONTEXTO
                  REVISOR no cumprimento de suas obrigações trabalhistas e/ou
                  previdenciárias;
                </p>

                <p className="paragraph">
                  c) indenização, inclusive a terceiros, como consequência de
                  eventuais danos causados pelo REVISOR PARCEIRO, ou seus
                  prepostos, decorrentes de negligência ou imperícia, na
                  execução das suas atividades.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA QUARTA – OBRIGAÇÕES DA CONTEXTO REVISOR
                </h5>

                <p>
                  4.1. São obrigações da <strong>CONTEXTO REVISOR</strong>:
                </p>

                <p className="paragraph">
                  4.1.1. Dar assistência e orientação ao
                  <strong>REVISOR PARCEIRO</strong> para correta execução da
                  parceria aqui estabelecida.
                </p>

                <p className="paragraph">
                  4.1.2. Fornecer informações, desde que requisitadas, por meio
                  de planilhas e relatórios, a fim de auxiliar e esclarecer
                  dúvidas do REVISOR PARCEIRO a respeito da parceria exercida e
                  valores recebidos.
                </p>

                <p className="paragraph">
                  4.1.3. Realizar os repasses dos valores e das comissões,
                  conforme definido na Cláusula Segunda;
                </p>

                <p className="paragraph">
                  4.1.4. Esclarecer plenamente seus empregados, caso possua,
                  sobre as condições do contrato ora pactuado, cuidando para a
                  observância das obrigações assumidas e visando prevenir erros
                  de expectativas e interpretação
                </p>

                <p className="paragraph">
                  4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em
                  nome do <strong>REVISOR PARCEIRO</strong> sem expressa
                  autorização em documento específico.
                </p>

                <p className="paragraph">
                  4.1.6. Identificar no site o REVISOR PARCEIRO e sua oferta de
                  desconto de 10% (dez por cento) para os alunos de Instituições
                  Parceiras e outros clientes que por ventura utilizem a
                  plataforma da CONTEXTO REVISOR para revisões.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA QUINTA – OBRIGAÇÕES DO REVISOR PARCEIRO
                </h5>

                <p>
                  5.1. São obrigações do <strong>REVISOR PARCEIRO</strong>:
                </p>
                <p className="paragraph">
                  5.1.1. Realizar seus serviços de revisão com responsabilidade,
                  atenção, zelo e afinco, assumindo toda e qualquer
                  responsabilidade por frustrações, prejuízos e eventuais
                  dúvidas que clientes contratantes venham a ter após o serviço
                  prestado a partir da conexão feita pela CONTEXTO REVISOR.
                </p>
                <p className="paragraph">
                  5.1.2. Recolher correta e pontualmente todas as obrigações e
                  encargos trabalhistas, previdenciários, fiscais, parafiscais e
                  outros que sejam ou venham a ser devidos em razão do presente
                  contrato.
                </p>
                <p className="paragraph">
                  5.1.3. Responder integralmente pelos atos praticados por si,
                  seus prepostos ou funcionários, caso possua, que acarretarem
                  prejuízo a CONTEXTO REVISOR ou terceiro.
                </p>

                <p className="paragraph">
                  5.1.4.Esclarecer plenamente seus empregados, caso possua,
                  sobre as condições do contrato ora pactuado, cuidando para a
                  observância das obrigações assumidas e visando prevenir erros
                  de expectativas e interpretação.
                </p>

                <p className="paragraph">
                  5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em
                  nome da <strong>CONTEXTO REVISOR</strong> sem expressa
                  autorização, em documento específico.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
                </h5>

                <p>
                  6.1. Conforme determina o art. 4º da Lei 9.609/98, a
                  titularidade e todos os direitos relativos ao software
                  desenvolvido, como o código-fonte, especificações funcionais,
                  fluxogramas, logomarca, dentre outros dados técnicos,
                  pertencem exclusivamente à CONTEXTO REVISOR, podendo somente
                  esta utilizá-los, cedê-los e transferi-los a terceiros.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
                </h5>

                <p>
                  7.1. O REVISOR PARCEIRO compromete-se a manter o mais absoluto
                  sigilo de todas as informações de conteúdo confidencial, sejam
                  elas comerciais, técnicas, pessoais e outras, cujo
                  conhecimento tiver, em decorrência do exercício de suas
                  atividades; além de não exercer qualquer forma de concorrência
                  direta com a CONTEXTO REVISOR, podendo ser responsabilizado
                  civil ou criminalmente por eventuais danos materiais ou morais
                  decorrentes da não obediência a esta regra.
                </p>

                <p>
                  7.2. Por definição, “Informações Confidenciais” significam
                  todos as informações técnicas e gerais, know-how, documentos,
                  programas de computador e documentação, códigos fonte,
                  relatórios, dados financeiros ou outros dados, registros,
                  formulários, ferramentas, produtos, serviços, pesquisa
                  presente e futura, conhecimento técnico, planos de marketing,
                  segredos comerciais e outros materiais tangíveis ou
                  intangíveis, armazenados ou não, compilados ou reduzidos a
                  termo, seja física, eletrônica ou graficamente, por escrito,
                  ou por qualquer meio, que esteja em poder da CONTEXTO REVISOR,
                  e que seja revelado ao REVISOR PARCEIRO.
                </p>

                <p>
                  7.3. As Informações Confidenciais incluem, sem limitação,
                  materiais, arquivos e informações, bem como as informações de
                  propriedade da <strong>CONTEXTO REVISOR</strong> ou de
                  terceiros que:
                </p>

                <p className="paragraph">
                  a) contenham a especificação de propriedade exclusiva ou
                  confidencial;
                </p>

                <p className="paragraph">
                  b) cuja natureza confidencial tenha sido informada ao{' '}
                  <strong>REVISOR PARCEIRO</strong>;
                </p>

                <p className="paragraph">
                  c) consistam na solução, nas inovações e nos aperfeiçoamentos
                  que sejam de criação e de desenvolvimento individual ou em
                  conjunto entre as <strong>PARTES</strong>;
                </p>

                <p className="paragraph">
                  d) em virtude de suas características e de sua natureza seriam
                  consideradas confidenciais em circunstâncias semelhantes.
                </p>

                <p>
                  7.4. O <strong>REVISOR PARCEIRO</strong> concorda que a
                  violação desta Cláusula, pelo uso de qualquer informação
                  confidencial pertencente à <strong>CONTEXTO REVISOR</strong>,
                  sem sua expressa autorização, causar-lhe-á danos e prejuízos
                  irreparáveis, não podendo este ser inferior ao valor de pelo
                  menos, R$30.000,00 (trinta mil reais).
                </p>

                <p>
                  7.5. O REVISOR PARCEIRO concorda que as obrigações de
                  Confidencialidade e Sigilo se iniciam a partir da assinatura
                  do presente instrumento, perdurando na sua vigência e por 2
                  (dois) anos do término de qualquer que seja o vínculo
                  existente entre as Partes. Desta forma e na hipótese de
                  violação, a CONTEXTO REVISOR será imediatamente considerada
                  legítima detentora do direito a tomar todas as medidas
                  extrajudiciais e judiciais, nas esferas cíveis e criminais,
                  inclusive de caráter cautelar ou de antecipação de tutela
                  jurisdicional, que julgar cabíveis à defesa de seus direitos,
                  inclusive autorais, independentemente da tomada das medidas
                  judiciais tendentes a obter a reparação pelo dano causado.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
                </h5>

                <p>
                  8.1. Toda e qualquer despesa que o REVISOR PARCEIRO tiver em
                  virtude da execução desta parceria serão de exclusiva
                  responsabilidade deste, cabendo apenas à CONTEXTO REVISOR
                  realizar os repasses de valores e comissões, conforme acordado
                  na Cláusula Segunda.
                </p>

                <p>
                  8.2. Ainda, cada parte responderá, de forma individual e
                  exclusiva, pelos tributos e encargos que sejam de sua
                  titularidade passiva.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA
                </h5>

                <p>
                  9.1. O presente contrato de parceria comercial tem prazo de
                  vigência de 365 (trezentos e sessenta e cinco) dias, renovável
                  por iguais períodos caso as Partes assim decidam, a contar da
                  data de sua assinatura, sendo passível de rescisão por
                  qualquer das partes por meio de notificação expressa no prazo
                  de 30 (trinta) dias.
                </p>

                <p>
                  9.2. No caso de rescisão imotivada por qualquer das partes,
                  caso a CONTEXTO REVISOR tenha pagamentos pendentes, deverá
                  depositá-los em conta de titularidade do REVISOR PARCEIRO,
                  enviando o respectivo comprovante, no prazo de 30 (trinta)
                  dias da notificação da rescisão.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA DÉCIMA – RESCISÃO MOTIVADA
                </h5>

                <p>
                  10.1. O presente contrato reputar-se-á rescindido, de pleno
                  direito, independentemente de qualquer formalidade judicial ou
                  extrajudicial, se o REVISOR PARCEIRO:
                </p>

                <p className="paragraph">
                  10.1.1. ceder ou transferir o presente contrato, por qualquer
                  forma, a terceiros em quaisquer condições, sem o consentimento
                  da CONTEXTO REVISOR;
                </p>

                <p className="paragraph">
                  10.1.2. descumprir qualquer cláusula ou condição deste
                  contrato, especialmente no que diz respeito ao estabelecido na
                  Cláusula Primeira deste documento;
                </p>

                <p>
                  10.2. O presente contrato reputar-se-á rescindido, de pleno
                  direito, independentemente de qualquer formalidade judicial ou
                  extrajudicial, se a CONTEXTO REVISOR:
                </p>

                <p className="paragraph">
                  10.2.1. Descumprir qualquer cláusula ou condição deste
                  contrato especialmente no que tange a realizar os pagamentos
                  nas formas, prazos e condições pactuadas na Cláusula Segunda
                  deste documento.
                </p>

                <p>
                  10.3. Nos casos de rescisão motivada, não será necessária
                  qualquer notificação prévia para rescisão do presente
                  contrato, dando-se por encerrada a contratação no dia em que a
                  parte justificadamente se manifestar.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
                </h5>

                <p>
                  11.1. O descumprimento de qualquer das obrigações contratuais
                  acarretará, após prévia notificação ou interpelação, facultada
                  à parte inocente, de forma sucessiva:
                </p>

                <p className="paragraph">
                  a) A suspensão da execução do presente contrato, o que faculta
                  à CONTEXTO REVISOR a retenção de pagamentos e ao REVISOR
                  PARCEIRO a suspensão dos serviços de revisão imediatamente.
                </p>

                <p className="paragraph">
                  b) A exigibilidade direta e específica da obrigação
                  descumprida, acrescida de multa moratória à razão de 1% (um
                  por cento) sobre o total dos valores pagos até a data do
                  inadimplemento, e de juros moratórios no montante de 1% (um
                  por cento) ao mês.
                </p>

                <p className="paragraph">
                  c) Em última instância, a resolução do contrato.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA DÉCIMA SEGUNDA – TOLERÂNCIA
                </h5>

                <p>
                  12.1. A tolerância de uma das partes para com a outra,
                  relativamente ao descumprimento de qualquer uma das obrigações
                  assumidas neste contrato, será mera liberalidade, não
                  implicando novação ou renúncia a qualquer direito. A parte
                  tolerante poderá, a qualquer tempo, exigir da outra parte o
                  fiel e cabal cumprimento de suas obrigações.
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
                </h5>

                <p>
                  13.1. O REVISOR PARCEIRO declara ter lido e estar ciente e de
                  pleno acordo com todos os termos e condições deste Contrato, o
                  qual foi feito em estrita observância aos limites do
                  respectivo fim econômico ou social, pela boa-fé e/ou pelos
                  bons costumes, levando em consideração inclusive que não estão
                  em situação de premente necessidade e têm plenas condições de
                  cumprir todas as cláusulas e condições que constituem seus
                  direitos e obrigações constantes no presente instrumento.
                </p>

                <p>
                  13.2. As disposições deste instrumento somente poderão ser
                  alteradas mediante acordo escrito entre as partes, através de
                  termos aditivos que farão parte integrante do contrato,
                  ficando, desde já, consignado que atos de tolerância não
                  configurarão novação contratual.
                </p>

                <p>
                  13.3. Este Contrato não implica qualquer tipo de associação ou
                  sociedade entre as Partes, ou em qualquer vínculo empregatício
                  entre os sócios, diretores, empregados e prepostos de uma
                  Parte para com a outra.
                </p>

                <p>
                  13.4. As notificações de assuntos referentes a este
                  instrumento poderão ser realizadas por correspondência
                  destinada aos endereços físicos descritos na qualificação das
                  Partes e eletrônicos, abaixo indicados, com a devida
                  confirmação de recebimento:
                </p>

                <p className="paragraph">
                  a) E-mail da <strong>CONTEXTO REVISOR</strong>:
                  contato@contextorevisor.com.br
                </p>

                <p className="paragraph">
                  b) E-mail do <strong>REVISOR PARCEIRO</strong>:{' '}
                  {user && localStorage.getItem('email')}
                </p>

                <h5 className="turquoise-text bold">
                  CLÁUSULA DÉCIMA QUARTA – FORO
                </h5>

                <p>
                  14.1. As Partes elegem o foro da Comarca de Brasília, no
                  Distrito Federal, para dirimir quaisquer controvérsias que
                  surgirem do presente contrato, renunciando, expressamente, a
                  qualquer outro por mais privilegiado que seja.
                </p>

                <p>
                  E, por estarem, assim justos e contratados, assinam o presente
                  instrumento em duas vias de igual teor e forma, juntamente com
                  02 (duas) testemunhas.
                </p>

                <p>
                  Por estarem assim justos e contratados, firmam o presente
                  instrumento em duas vias de igual teor, juntamente com 02
                  (duas) testemunhas.
                </p>
              </div>
              <div id="eversign" align="center"></div>
              <div className="box-container">
                <Modal
                  message="Obrigado por participar! Sua proposta para tornar-se Revisor Parceiro já está sob análise. Para mais informações, acabamos de lhe enviar um e-mail especial."
                  okText="Okay"
                  visible={this.state.showAccept}
                  onlyOkButton={true}
                  onOkClick={this.handleClose}
                />

                <button
                  className="hero-btn turquesa w-button"
                  hidden={this.state.showButton}
                  onClick={this.handleAccept}
                >
                  Aceito os termos do contrato
                </button>
                <Modal
                  message="Você está recusando os termos do contrato, isso implica que você não se tornará Parceiro Revisor."
                  okText="Não quero ser parceiro"
                  visible={this.state.showRefuse}
                  onOkClick={this.handleRefuseClose}
                  onDismiss={this.handleDismiss}
                />

                <button
                  className="hero-btn turquesa w-button"
                  hidden={this.state.showButton}
                  onClick={this.showRefuseModal}
                >
                  {' '}
                  Não aceito os termos do contrato
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ reviewersStore, userStore }) => {
  let {
    states,
    trainings,
    reviewerCreateCounterProposalSuccess
  } = reviewersStore;
  return {
    states,
    trainings,
    ...userStore,
    reviewerCreateCounterProposalSuccess
  };
};

// const mapDispatchToProps = dispatch => ({
//   logoff: () => dispatch(logoff())
// });

const ReviewerContractPage = connect(
  mapStateToProps
  // mapDispatchToProps
)(ReviewerContractComponent);
export { ReviewerContractPage };
