import React from 'react';
import PriceBox from '../../../../siteComponents/PriceBox';
import routes from '../../../../../routes';

const { criarContaRevisor } = routes;

const PriceAndResourcesSection = () => (
  <div className="section">
    <div className="header">
      <h1 className="section-header">
        Você a um clique de se tornar <br />
        Revisor-Empreendedor
      </h1>
    </div>
    <div className="large-container price-content">
      <div style={{ width: '33%' }}>
        <div className="table-desc">
          <p className="bold pre-title" style={{ fontSize: '27px' }}>
            Escolha seu plano e já comece a revisar.
          </p>
          <p>
            Com ou sem assinatura paga, você ampliará o número de clientes e
            aumentará sua renda
          </p>
        </div>
      </div>
      <div>
        <PriceBox
          title="Flex"
          price="R$ 0,00"
          period="/MÊS"
          buttonText="COMECE AGORA"
          customClass="become-reviewer-price"
          to={criarContaRevisor}
        >
          <ul>
            <li>Número ilimitado de clientes simultâneos</li>
            <li>
              <i>Minissite</i> individualizado com os dados do revisor e com
              endereço próprio
            </li>
            <li>
              Participação em sistema de market place (onde os clientes poderão
              selecionar você para revisão)
            </li>
            <li>
              Sistema completo de e-commerce (os valores recebidos dos clientes
              já caem direto em sua conta)
            </li>
            <li>Ambiente CONTEXTO de redação e revisão on-line</li>
            <li>Hospedagem, backup e segurança do site e ambiente</li>
            <li>Suporte tecnológico</li>
            <li>Upload de arquivos próprios e de clientes</li>
            <li>
              Outdoor virtual (em sua própria página, para você captar
              anunciantes e ganhar com isso)
            </li>
            <li>
              Ferramenta de recebimento e revisão para redações manuscritas
            </li>
          </ul>

          <p>
            <strong>Você recebe do cliente e só paga…</strong> <br />
            R$ 0,00 de assinatura.
            <br />
            Pacote básico de ferramentas <br />e marketing, e 16%* sobre
            receita.
            <br />
            <br />
            * Acrescidos de 2,5% a serem destinados à Iugu para adiantamento, ao
            Revisor, de pagamentos efetuados por cartão.
            <br />
          </p>
        </PriceBox>
      </div>
      <div>
        <PriceBox
          title="Premium"
          price="R$ 39,90"
          period="/MÊS"
          customClass="become-reviewer-price"
          buttonText="ASSINAR AGORA"
          to={criarContaRevisor}
        >
          <ul>
            <li>Número ilimitado de clientes simultâneos</li>
            <li>
              <i>Minissite</i> individualizado com os dados do revisor e com
              endereço próprio
            </li>
            <li>
              Participação em sistema de market place (onde os clientes poderão
              selecionar você para revisão)
            </li>
            <li>
              Sistema completo de e-commerce (os valores recebidos dos clientes
              já caem direto em sua conta)
            </li>
            <li>Ambiente CONTEXTO de redação e revisão on-line</li>
            <li>
              Hospedagem, backup e segurança do <i>site</i> e ambiente
            </li>
            <li>Suporte tecnológico</li>
            <li>Upload de arquivos próprios e de clientes</li>
            <li>
              Outdoor virtual (em sua própria página, para você captar
              anunciantes e ganhar com isso)
            </li>
            <li>
              Ferramenta de recebimento e revisão para redações manuscritas
            </li>
            <li>
              Encaminhamento, pelo CONTEXTO, de clientes indicados por
              instituições parceiras
            </li>
          </ul>

          <p>
            <strong>Você recebe do cliente e só paga…</strong> <br />
            R$ 39,90 de assinatura. <br />
            Pacote completo de ferramentas <br />e marketing, e 16%* sobre
            receita.
            <br />
            <br />* Acrescidos de 2,5% a serem destinados à Iugu para
            adiantamento, ao Revisor, de pagamentos efetuados por cartão.
          </p>
        </PriceBox>
      </div>
    </div>
  </div>
);

export default PriceAndResourcesSection;
