import React from 'react';
import goldenStar from './images/golden-star.svg';
import fadedStar from './images/faded-star.svg';
import { func, number } from 'prop-types';

export const StarEvaluation = ({ rating, changeRating, starCallback }) => (
  <div>
    <div className="filter-title">Avaliação</div>
    <div className="stars-box">
      {[1, 2, 3, 4, 5].map(number => {
        return (
          <img
            key={number}
            src={number <= rating ? goldenStar : fadedStar}
            alt="star"
            className="star-medium"
            id={number}
            style={{ cursor: 'pointer' }}
            onClick={e => {
              changeRating(e.target.id);
              starCallback(number);
            }}
          />
        );
      })}
    </div>
    <div className="support-text">
      {rating && (
        <span>
          {rating} {rating > 1 ? 'estrelas' : 'estrela'}
        </span>
      )}
    </div>
  </div>
);

StarEvaluation.propTypes = {
  rating: number,
  changeRating: func,
  starCallback: func
};

export default StarEvaluation;
