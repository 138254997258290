import React from 'react';
import paulafernandes from './images/paula-fernanda.png';
import camilamendes from './images/camila-mendes.jpeg';
import rayanefelix from './images/rayane-feliz.png';
import vittorazevedo from './images/vittor-azevedo.png';

const TestimonialsSection = () => (
  <div className="section-dark">
    <div className="header">
      <h1 className="section-header">Depoimento de quem já usa!</h1>
    </div>
    <div className="container wrap-container">
      <div className="halfbox">
        <div className="white-box">
          <p className="testemonial-text">
            “O serviço de revisão é essencial à qualidade dos trabalhos
            acadêmicos e o Contexto Revisor é um <i>site</i> seguro que permite
            interação entre os usuários e o contato direto com profissionais
            qualificados. Nós escolhemos, interagimos e negociamos sem
            intermediários. O pagamento é feito on-line após a entrega do
            serviço. É uma proposta inovadora capaz de criar uma rede acadêmica
            segura, interativa e simples. O layout é totalmente interativo e
            intuitivo. Recomendo a todos os acadêmicos!”
          </p>
        </div>
        <div className="testemonial-person">
          <img
            src={paulafernandes}
            alt="avatar"
            className="testemonial-photo"
          />
          <p className="testemonial-name">Paula Fernanda</p>
          <div className="texto-redator">Professora universitária</div>
        </div>
      </div>
      <div className="halfbox">
        <div className="white-box">
          <p className="testemonial-text">
            "Agora, além de os clientes me encontrarem num <i>site</i> que
            investe em anúncios para a visibilidade dos revisores, posso também
            buscar novos clientes com o meu <i>minissite</i>, disponibilizado
            pelo Contexto Revisor. Além disso, eu mesmo defino o valor do meu
            trabalho e negocio diretamente com os interessados. O sistema é de
            fácil uso e seguro tanto para quem revisa quanto para quem tem os
            textos revisados. Enfim, uma excelente plataforma de valorização do
            revisor e do cliente."
          </p>
        </div>
        <div className="testemonial-person">
          <img src={vittorazevedo} alt="avatar" className="testemonial-photo" />
          <p className="testemonial-name">Vittor Azevedo</p>
          <div className="texto-revisor">Revisor</div>
        </div>
      </div>
      <div className="halfbox">
        <div className="white-box">
          <p className="testemonial-text">
            "O Contexto Revisor é uma saída prática e simples para solucionar os
            meus problemas de produção textual. Nele localizo, sem dificuldade,
            inúmeros revisores experientes para diversos tipos de texto. O{' '}
            <i>site</i>
            é seguro e amigável, e os detalhes do serviço são negociados com os
            revisores - posso acertar valor, data de entrega e diretriz da
            revisão. Todo o pagamento é feito on-line após a entrega do
            trabalho. Excelente!"
          </p>
        </div>
        <div className="testemonial-person">
          <img src={camilamendes} alt="avatar" className="testemonial-photo" />
          <p className="testemonial-name">Camilla Mendes</p>
          <div className="texto-redator">Advogada</div>
        </div>
      </div>
      <div className="halfbox">
        <div className="white-box">
          <p className="testemonial-text">
            “O sistema proporciona total liberdade e autonomia para o revisor.
            No Contexto o revisor tem uma expectativa de renda muito superior
            aos outros <i>sites</i> - neles, uma pequena fatia do valor do
            trabalho vai para o profissional; já no Contexto, o revisor recebe a
            maior parte e repassa uma parcela pequena e em troca recebe
            clientes, ferramentas e serviço. Recomendo muito! Vale a pena!”
          </p>
        </div>
        <div className="testemonial-person">
          <img src={rayanefelix} alt="avatar" className="testemonial-photo" />
          <p className="testemonial-name">Raiane Félix</p>
          <div className="texto-revisor">Revisora</div>
        </div>
      </div>
    </div>
  </div>
);

export default TestimonialsSection;
