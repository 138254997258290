import React from 'react';
import { withRouter } from 'react-router-dom';

class Logout extends React.Component {
  componentDidMount() {
    localStorage.clear();
    this.props.history.push('/');
  }

  render() {
    return null;
  }
}

export default withRouter(Logout);
