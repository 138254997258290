import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const Heading = styled.Text`
  margin: 12px;
  font-size: 12px;
  font-weight: bold;
`;

const Bold = styled.Text`
  font-weight: bold;
`;

const Section = styled.View`
  margin: 30px;
  font-size: 8px;
`;

const Paragraph = styled.Text`
  margin: 8px;
`;

const AgreementPDF = ({ user }) => (
  <Document>
    <Page>
      <Section>
        <Heading className="turquoise-text bold">
          CONTRATO DE PARCERIA COMERCIAL
        </Heading>

        <Paragraph>
          PRECEPTOR EDUCAÇÃO A DIST NCIA LTDA ME, pessoa jurídica constituída na
          forma de SociedadeLimitada,inscrita no CNPJ sob o nº
          07.633.844/0001-19, com sede na Rua Cel. Alves Teixeira, 1290, sala 5,
          bairro Dionísio Torres, CEP 60135-208, na cidade de Fortaleza, Estado
          do Ceará, representada na forma de seus atos constitutivos, doravante
          denominada simplesmente <Bold>CONTEXTO REVISOR</Bold>, e, do outro
          lado,
        </Paragraph>
        {user.pf ? (
          <Paragraph>
            {user.name},{user.nacionalidade}, {user.estado_civil}, portador do
            RG nº {user.rg}, inscrito no CPF sob o nº {user.document}, residente
            e domiciliado na {user.address}, {user.number}, {user.complement},{' '}
            {user.district}, {user.city}, {user.state_full} – {user.state},{' '}
            {user.postal_code}, neste ato representada na forma de seus atos
            constitutivos, doravante denominado simplesmente como{' '}
            <Bold>PARCEIRO DIVULGADOR</Bold>.
          </Paragraph>
        ) : (
          <Paragraph>
            {user.name}, pessoa jurídica constituída na forma de{' '}
            {user.tipo_empresa} no CNPJ sob o nº {user.document}, com sede no{' '}
            {user.address}, {user.number}, {user.complement}, {user.district},{' '}
            {user.city}, {user.state_full} – {user.state}, {user.postal_code},
            neste ato representada na forma de seus atos constitutivos,
            doravante denominado simplesmente como{' '}
            <Bold>PARCEIRO DIVULGADOR</Bold>.
          </Paragraph>
        )}
        <Paragraph>
          As partes ora identificadas comprometem-se a, respectivamente, unir
          esforços para garantir a consecução dos objetivos da parceria nos
          moldes das cláusulas abaixo:
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA PRIMEIRA - DO OBJETO
        </Heading>
        <Paragraph>
          1.1. O objeto contratual da parceria é a captação de clientes para os
          Revisores Parceiros da plataforma “Contexto Revisor”, de propriedade
          da <Bold>CONTEXTO REVISOR</Bold>, através da divulgação da plataforma
          por meio do <Bold>PARCEIRO DIVULGADOR</Bold>.
        </Paragraph>
        <Paragraph>
          1.2. O <Bold>PARCEIRO DIVULGADOR</Bold> realizará a divulgação da
          plataforma “Contexto Revisor” através de seus canais de comunicação,
          como site, blog e redes sociais, mediante código promocional fornecido
          pela <Bold>CONTEXTO REVISOR</Bold>.
        </Paragraph>
        <Paragraph>
          1.3. A presente parceria é firmada sem caráter de exclusividade,
          podendo a <Bold>CONTEXTO REVISOR</Bold> realizar contratações com
          demais parceiros com objeto semelhante ao do presente instrumento. No
          entanto, o <Bold>PARCEIRO DIVULGADOR</Bold> se compromete, no decorrer
          da presente parceria, a não firmar vínculos cujos objetos sejam
          conflitantes com o da presente parceria ou com empresas concorrentes
          da <Bold>CONTEXTO REVISOR</Bold>.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA SEGUNDA - REMUNERAÇÃO
        </Heading>
        <Paragraph>
          2.1. Em razão desta parceria, a <Bold>CONTEXTO REVISOR</Bold>{' '}
          repassará ao <Bold>PARCEIRO DIVULGADOR</Bold> os seguintes valores,
          exclusivamente nos casos em que o cliente utilizar o código
          promocional divulgado pela <Bold>PARCEIRO DIVULGADOR</Bold>:
        </Paragraph>
        <Paragraph>
          2.1.1. 8% (oito por cento) do valor total, retido o valor
          correspondente à tributação devida, proveniente da contratação de
          serviços oferecidos pela plataforma da <Bold>CONTEXTO REVISOR</Bold>
          pelos clientes que o <Bold>PARCEIRO DIVULGADOR</Bold> captou para os
          Revisores Parceiros. Os valores serão repassados mensalmente, enquanto
          perdurar este Contrato.
        </Paragraph>
        <Paragraph>
          2.1.2. Os pagamentos serão realizados todo dia 5 (cinco) do mês
          subsequente.
        </Paragraph>
        <Paragraph>
          2.1.3. A título de esclarecimento, os valores pagos pelos clientes
          captados serão distribuídos da seguinte forma: 8% destinado ao
          <Bold>PARCEIRO DIVULGADOR</Bold>; 20% destinado à{' '}
          <Bold>CONTEXTO REVISOR</Bold>; 72% destinado ao Revisor Parceiro.
        </Paragraph>
        <Paragraph>
          2.2. O repasse dos valores será justificado por meio de relatório
          específico disponibilizado pela <Bold>CONTEXTO REVISOR</Bold>, no qual
          estarão descritos os valores recebidos e a data de finalização de cada
          redação.
        </Paragraph>
        <Paragraph>
          2.3. Todos os repasses feitos pela <Bold>CONTEXTO REVISOR</Bold> serão
          realizados por meio da plataforma online de pagamentos, em conta
          determinada pelo <Bold>PARCEIRO DIVULGADOR</Bold> no momento da
          assinatura do presente instrumento.
        </Paragraph>
        <Paragraph>
          2.4. A simples divulgação não gera direito ao repasse dos valores
          descritos nessa cláusula, sendo necessário que o cliente utilize o
          código promocional divulgado.
        </Paragraph>

        <Heading className="turquoise-text bold">
          CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
        </Heading>
        <Paragraph>
          3.1. O <Bold>PARCEIRO DIVULGADOR</Bold> se obriga a realizar as suas
          atividades, cabendo-lhe total e exclusiva responsabilidade pelo
          integral atendimento de toda a legislação que rege os negócios
          jurídicos e que lhe atribua responsabilidades. Igualmente, o
          <Bold>PARCEIRO DIVULGADOR</Bold> se obriga também a reembolsar o{' '}
          <Bold>CONTEXTO REVISOR</Bold> todas as despesas decorrentes de:
        </Paragraph>
        <Paragraph>
          a) reconhecimento judicial de vínculo empregatício seu ou de seus
          empregados com a <Bold>CONTEXTO REVISOR</Bold>;
        </Paragraph>
        <Paragraph>
          b) reconhecimento judicial de solidariedade da{' '}
          <Bold>CONTEXTO REVISOR</Bold> no cumprimento de suas obrigações
          trabalhistas e/ou previdenciárias;
        </Paragraph>
        <Paragraph>
          c) indenização, inclusive a terceiros, como consequência de eventuais
          danos causados pelo <Bold>PARCEIRO DIVULGADOR</Bold>, ou seus
          prepostos, decorrentes de negligência ou imperícia, na execução das
          suas atividades.
        </Paragraph>
        <Paragraph>
          3.2. Os serviços de divulgação realizados pelo{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold>
          serão feitos dentro dos limites da ética e legalidade, zelando pela
          boa reputação da <Bold>CONTEXTO REVISOR</Bold>.
        </Paragraph>

        <Heading className="turquoise-text bold">
          CLÁUSULA QUARTA – OBRIGAÇÕES DA <Bold>CONTEXTO REVISOR</Bold>
        </Heading>

        <Paragraph>
          4.1. São obrigações da <Bold>CONTEXTO REVISOR</Bold>:
        </Paragraph>
        <Paragraph>
          4.1.1. Dar assistência e orientação ao{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold> para correta execução da parceria
          aqui estabelecida.
        </Paragraph>
        <Paragraph>
          4.1.2. Fornecer o código promocional e demais informações necessárias.
        </Paragraph>
        <Paragraph>
          4.1.3. Realizar os repasses dos valores e das comissões, conforme
          definido na Cláusula Segunda;
        </Paragraph>
        <Paragraph>
          4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, cuidando para a observância das
          obrigações assumidas e visando prevenir erros de expectativas e
          interpretação.
        </Paragraph>
        <Paragraph>
          4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome do{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold> sem expressa autorização em documento
          específico.
        </Paragraph>
        <Paragraph>
          4.1.6. Identificar, no site, os revisores que se dispõem a ser
          parceiros e conceder 10% (dez por cento) de desconto sobre o valor da
          lauda.
        </Paragraph>
        <Paragraph>
          4.1.7. Fornecer arquivos com imagens de cartaz e folder/flyer,
          contendo código promocional específico, para impressão de material de
          divulgação por parte do <Bold>PARCEIRO DIVULGADOR</Bold>, no objetivo
          de promover a parceria aqui estabelecida, quando necessário.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA QUINTA – OBRIGAÇÕES DO <Bold>PARCEIRO DIVULGADOR</Bold>
        </Heading>
        <Paragraph>
          5.1. São obrigações da <Bold>PARCEIRO DIVULGADOR</Bold>:
        </Paragraph>
        <Paragraph>
          5.1.1. Utilizar seus meios de comunicação para divulgar e prospectar
          clientes para os Revisores Parceiros da plataforma “Contexto Revisor”,
          mediante o código promocional fornecido pela
          <Bold>CONTEXTO REVISOR</Bold>.
        </Paragraph>
        <Paragraph>
          5.1.2. Recolher correta e pontualmente todas as obrigações e encargos
          trabalhistas, previdenciários, fiscais, parafiscais e outros que sejam
          ou venham a ser devidos em razão do presente contrato.
        </Paragraph>
        <Paragraph>
          5.1.3. Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, cuidando para a observância das
          obrigações assumidas e visando prevenir erros de expectativas e
          interpretação.
        </Paragraph>
        <Paragraph>
          5.1.4. Responder integralmente pelos atos que vier a praticar
          pessoalmente ou através de prepostos ou funcionários, caso possua, que
          acarretem prejuízo à <Bold>CONTEXTO REVISOR</Bold> ou terceiro.
        </Paragraph>
        <Paragraph>
          5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome da{' '}
          <Bold>CONTEXTO REVISOR</Bold> sem expressa autorização em documento
          específico.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
        </Heading>

        <Paragraph>
          6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade e
          todos os direitos relativos ao software desenvolvido, como o
          código-fonte, especificações funcionais, fluxogramas, logomarca,
          dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
          REVISOR, podendo somente esta utilizá-los, cedê-los e transferi-los a
          terceiros.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
        </Heading>
        <Paragraph>
          7.1. O <Bold>PARCEIRO DIVULGADOR</Bold> compromete-se a manter o mais
          absoluto sigilo de todas as informações de conteúdo confidencial,
          sejam elas comerciais, técnicas, pessoais e outras, cujo conhecimento
          tiver, em decorrência do exercício de suas atividades; além de não
          exercer qualquer forma de concorrência direta com a{' '}
          <Bold>CONTEXTO REVISOR</Bold>, podendo ser responsabilizado civil ou
          criminalmente por eventuais danos materiais ou morais decorrentes da
          não obediência a esta regra.
        </Paragraph>
        <Paragraph>
          7.2. Por definição, “Informações Confidenciais” significam todos as
          informações técnicas e gerais, know-how, documentos, programas de
          computador e documentação, códigos fonte, relatórios, dados
          financeiros ou outros dados, registros, formulários, ferramentas,
          produtos, serviços, pesquisa presente e futura, conhecimento técnico,
          planos de marketing, segredos comerciais e outros materiais tangíveis
          ou intangíveis, armazenados ou não, compilados ou reduzidos a termo,
          seja física, eletrônica ou graficamente, por escrito, ou por qualquer
          meio, que esteja em poder da <Bold>CONTEXTO REVISOR</Bold>, e que seja
          revelado ao <Bold>PARCEIRO DIVULGADOR</Bold>.
        </Paragraph>
        <Paragraph>
          7.3. As Informações Confidenciais incluem, sem limitação, materiais,
          arquivos e informações, bem como as informações de propriedade da{' '}
          <Bold>CONTEXTO REVISOR</Bold> ou de terceiros que: a) contenham a
          especificação de propriedade exclusiva ou confidencial; b) cuja
          natureza confidencial tenha sido informada ao{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold>; c) consistam na solução, nas
          inovações e aperfeiçoamentos que sejam de criação e desenvolvimento
          individual ou em conjunto entre as PARTES; e d) em virtude de suas
          características e natureza seriam consideradas confidenciais em
          circunstâncias semelhantes.
        </Paragraph>
        <Paragraph>
          7.4. O <Bold>PARCEIRO DIVULGADOR</Bold> concorda que a violação desta
          Cláusula, pelo uso de qualquer informação confidencial pertencente à
          <Bold>CONTEXTO REVISOR</Bold>, sem sua expressa autorização,
          causar-lhe-á danos e prejuízos irreparáveis, não podendo este ser
          inferior ao valor de R$30.000,00 (trinta mil reais).
        </Paragraph>
        <Paragraph>
          7.5. O <Bold>PARCEIRO DIVULGADOR</Bold> concorda que as obrigações de
          Confidencialidade e Sigilo se iniciam a partir da assinatura do
          presente instrumento, perdurando na sua vigência e por 2 (dois) anos
          do término de qualquer que seja o vínculo existente entre as Partes.
          Desta forma e na hipótese de violação, a <Bold>CONTEXTO REVISOR</Bold>
          será imediatamente considerada legítima detentora do direito a tomar
          todas as medidas extrajudiciais e judiciais, nas esferas cíveis e
          criminais, inclusive de caráter cautelar ou de antecipação de tutela
          jurisdicional, que julgar cabíveis à defesa de seus direitos,
          inclusive autorais, independentemente da tomada das medidas judiciais
          tendentes a obter a reparação pelo dano causado.
        </Paragraph>

        <Heading className="turquoise-text bold">
          CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
        </Heading>
        <Paragraph>
          8.1. Toda e qualquer despesa que o <Bold>PARCEIRO DIVULGADOR</Bold>{' '}
          tiver em virtude da execução desta parceria serão de exclusiva
          responsabilidade deste, cabendo apenas à <Bold>CONTEXTO REVISOR</Bold>{' '}
          realizar os repasses de valores e comissões, conforme acordado na
          Cláusula Segunda.
        </Paragraph>
        <Paragraph>
          8.2. Ainda, cada parte responderá, de forma individual e exclusiva,
          pelos tributos e encargos que sejam de sua titularidade passiva.
        </Paragraph>

        <Heading className="turquoise-text bold">
          CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA
        </Heading>
        <Paragraph>
          9.1. O presente contrato de parceria comercial tem prazo de vigência
          de 365 (trezentos e sessenta e cinco) dias, renovável por iguais
          períodos caso as Partes assim decidam, a contar da data de sua
          assinatura, sendo passível de rescisão por qualquer das partes por
          meio de notificação expressa no prazo de 30 (trinta) dias.
        </Paragraph>
        <Paragraph>
          9.2. No caso de rescisão imotivada por qualquer das partes, caso a
          <Bold>CONTEXTO REVISOR</Bold> tenha pagamentos pendentes, deverá
          depositá-los em conta de titularidade do{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold>, enviando o respectivo comprovante,
          no prazo de 30 (trinta) dias da notificação da rescisão.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA NONA – RESCISÃO MOTIVADA
        </Heading>
        <Paragraph>
          10.1. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se o <Bold>PARCEIRO DIVULGADOR</Bold>:
        </Paragraph>
        <Paragraph>
          10.1.1. ceder ou transferir o presente contrato, por qualquer forma, a
          terceiros em quaisquer condições, sem o consentimento da
          <Bold>CONTEXTO REVISOR</Bold>;
        </Paragraph>
        <Paragraph>
          10.1.2. descumprir qualquer cláusula ou condição deste contrato,
          especialmente no que diz respeito ao estabelecido na Cláusula Primeira
          deste documento;
        </Paragraph>
        <Paragraph>
          10.2. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se a <Bold>CONTEXTO REVISOR</Bold>:
        </Paragraph>
        <Paragraph>
          10.2.1. descumprir qualquer cláusula ou condição deste contrato
          especialmente no que tange a realizar os pagamentos nas formas, prazos
          e condições pactuadas na Cláusula Segunda deste documento.
        </Paragraph>
        <Paragraph>
          10.3. Nos casos de rescisão motivada, não será necessária qualquer
          notificação prévia para rescisão do presente contrato, dando-se por
          encerrada a contratação no dia em que a parte justificadamente se
          manifestar.
        </Paragraph>

        <Heading className="turquoise-text bold">
          CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
        </Heading>
        <Paragraph>
          11.1. O descumprimento de qualquer das obrigações contratuais
          acarretará, após prévia notificação ou interpelação, facultada à parte
          inocente, de forma sucessiva:
        </Paragraph>
        <Paragraph>
          a) A suspensão da execução do presente contrato, o que faculta à
          <Bold>CONTEXTO REVISOR</Bold> a retenção de pagamentos e ao{' '}
          <Bold>PARCEIRO DIVULGADOR</Bold>a suspensão da captação de clientes e
          dos materiais de divulgação da plataforma “Contexto Revisor”.
        </Paragraph>
        <Paragraph>
          b) A exigibilidade direta e específica da obrigação descumprida,
          acrescida de multa moratória à razão de 1% (um por cento) sobre o
          total dos valores pagos até a data do inadimplemento, e de juros
          moratórios no montante de 1% (um por cento) ao mês.
        </Paragraph>
        <Paragraph>c) Em última instância, a resolução do contrato.</Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA DÉCIMA SEGUNDA – TOLER NCIA
        </Heading>
        <Paragraph>
          12.1. A tolerância de uma das partes para com a outra, relativamente
          ao descumprimento de qualquer uma das obrigações assumidas neste
          contrato, será mera liberalidade, não implicando novação ou renúncia a
          qualquer direito. A parte tolerante poderá, a qualquer tempo, exigir
          da outra parte o fiel e cabal cumprimento de suas obrigações.
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
        </Heading>
        <Paragraph>
          13.1. O <Bold>PARCEIRO DIVULGADOR</Bold> declara ter lido e estar
          ciente e de pleno acordo com todos os termos e condições deste
          Contrato, o qual foi feito em estrita observância aos limites do
          respectivo fim econômico ou social, pela boa-fé e/ou pelos bons
          costumes, levando em consideração inclusive que não estão em situação
          de premente necessidade e têm plenas condições de cumprir todas as
          cláusulas e condições que constituem seus direitos e obrigações
          constantes no presente instrumento.
        </Paragraph>
        <Paragraph>
          13.2. As disposições deste instrumento somente poderão ser alteradas
          mediante acordo escrito entre as partes, através de termos aditivos
          que farão parte integrante do contrato, ficando, desde já, consignado
          que atos de tolerância não configurarão novação contratual.
        </Paragraph>
        <Paragraph>
          13.3. Este Contrato não implica qualquer tipo de associação ou
          sociedade entre as Partes, ou em qualquer vínculo empregatício entre
          os sócios, diretores, empregados e prepostos de uma Parte para com a
          outra.
        </Paragraph>
        <Paragraph>
          13.4. As notificações de assuntos referentes a este instrumento
          poderão ser realizadas por correspondência destinada aos endereços
          físicos descritos na qualificação das Partes e eletrônicos, abaixo
          indicados, com a devida confirmação de recebimento:
        </Paragraph>
        <Paragraph>
          a) E-mail da <Bold>CONTEXTO REVISOR</Bold>:
          contato@contextorevisor.com.br
        </Paragraph>
        <Paragraph>
          b) E-mail do <Bold>PARCEIRO DIVULGADOR</Bold>: {user.email}
        </Paragraph>
        <Heading className="turquoise-text bold">
          CLÁUSULA DÉCIMA QUARTA – FORO
        </Heading>
        <Paragraph>
          14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
          Federal, para dirimir quaisquer controvérsias que surgirem do presente
          contrato, renunciando, expressamente, a qualquer outro por mais
          privilegiado que seja.
        </Paragraph>
      </Section>
    </Page>
  </Document>
);

export default AgreementPDF;
