import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REVIEWER_CREATE_PROPOSAL,
  REVIEWER_CREATE_COUNTER_PROPOSAL,
  REVIEWER_DISAPPROVE_PROPOSAL,
  REVIEWER_APPROVE_PROPOSAL,
  FETCH_STATES,
  FETCH_TRAININGS,
  FETCH_DIVULGACAO,
  FETCH_REVIEWERS,
  FETCH_RANDOM_REVIEWERS,
  APPLY_QUERY_FILTER,
  FETCH_REVIEWER,
  fetchedStates,
  fetchedTrainings,
  fetchedDivulgacao,
  fetchedReviewers,
  fetchedRandomReviewers,
  fetchedReviewer,
  reviewerCreateProposalSuccess,
  reviewerCreateCounterProposalSuccess,
  reviewerDisapproveProposalSuccess,
  reviewerApproveProposalSuccess,
  reviewerCreateProposalError
} from './actions';
import { ApiService, PropostaService } from '../service';

function* requestStates() {
  try {
    const data = yield call(ApiService.states);
    yield put(fetchedStates(data));
  } catch (e) {}
}

function* requestTrainnings() {
  try {
    const data = yield call(ApiService.trainings);
    yield put(fetchedTrainings(data));
  } catch (e) {}
}

function* requestMeiosDivulgacao() {
  try {
    const data = yield call(ApiService.divulgacao);
    yield put(fetchedDivulgacao(data));
  } catch (e) {}
}

function* requestReviewers(action) {
  let params = action.data || {};
  params.query = JSON.stringify({ published_at: { $exists: true } });

  try {
    const data = yield call(ApiService.reviewers, params);
    yield put(fetchedReviewers(data));
  } catch (e) {}
}

function* requestFilterReviewers(action) {
  try {
    const data = yield call(ApiService.reviewers, action.data);
    yield put(fetchedReviewers(data, { reset: true }));
  } catch (e) {}
}

function* requestRandomReviewers() {
  try {
    const data = yield call(ApiService.reviewers, {
      random: true,
      page: { size: 2 }
    });
    yield put(fetchedRandomReviewers(data));
  } catch (e) {}
}

function* requestReviewer(action) {
  try {
    const data = yield call(ApiService.reviewer, action.apelido);

    yield put(fetchedReviewer(data));
  } catch (e) {}
}

function* createProposal(action) {
  try {
    const data = yield call(PropostaService.createProposal, action.data);
    yield put(reviewerCreateProposalSuccess(data));
  } catch (e) {
    yield put(reviewerCreateProposalError(e));
  }
}

function* createCounterProposal(action) {
  try {
    const data = yield call(
      PropostaService.createCounterProposal,
      action.data,
      action.id
    );
    yield put(reviewerCreateCounterProposalSuccess(data));
  } catch (e) {}
}

function* disapproveProposal(action) {
  try {
    const data = yield call(PropostaService.disapprove, action.id);
    yield put(reviewerDisapproveProposalSuccess(data));
  } catch (e) {}
}

function* approveProposal(action) {
  try {
    const data = yield call(PropostaService.approve, action.id);
    yield put(reviewerApproveProposalSuccess(data));
  } catch (e) {}
}

export function* requestsReviewer() {
  yield takeLatest(FETCH_STATES, requestStates);
  yield takeLatest(FETCH_TRAININGS, requestTrainnings);
  yield takeLatest(FETCH_DIVULGACAO, requestMeiosDivulgacao);
  yield takeLatest(FETCH_REVIEWERS, requestReviewers);
  yield takeLatest(APPLY_QUERY_FILTER, requestFilterReviewers);
  yield takeLatest(FETCH_RANDOM_REVIEWERS, requestRandomReviewers);
  yield takeLatest(FETCH_REVIEWER, requestReviewer);
  yield takeLatest(REVIEWER_CREATE_PROPOSAL, createProposal);
  yield takeLatest(REVIEWER_CREATE_COUNTER_PROPOSAL, createCounterProposal);
  yield takeLatest(REVIEWER_DISAPPROVE_PROPOSAL, disapproveProposal);
  yield takeLatest(REVIEWER_APPROVE_PROPOSAL, approveProposal);
}
