import { connect } from 'react-redux';
import MainMenu from './MainMenu';
import { logoff } from '../../../state-management/user/actions';
const mapStateToProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});
const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
