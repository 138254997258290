import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showNotification,
  reviewerCreateCounterProposal,
  reviewerDisapproveProposal,
  reviewerApproveProposal,
  fetchOneProposal
} from '../../../../state-management';
import Modal from '../../../siteComponents/modal';
import {
  FormValidator,
  FormInput,
  FormButton,
  FormCurrency
} from '../../../siteComponents/form';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import imageAvatarFem from '../../../assetsHolder/images/avatar-1.svg';
import imageAvatarMasc from '../../../assetsHolder/images/avatar-2.svg';
import modalContraproposta from '../../../assetsHolder/images/ic-dialog-contraproposta.png';

const proposalTypes = {
  '1': 'Redação estudantil/concurso',
  '2': 'TCC/monografia/dissertação/tese',
  '3': 'Outros tipos de texto'
};

const statusLabel = {
  open: 'Em aberto',
  disapproved: 'Rejeitado',
  approved: 'Aprovado'
};

const revisorMessageApprove = () => {
  return (
    <div>
      <div>
        Ao clicar em "Aprovar proposta", você está concordando com os termos
        descritos no "Detalhe da proposta", inclusive o valor por lauda e total.
      </div>
      <div>
        Após confirmada, a proposta aceita aparecerá no item de menu
        <strong> Minhas Revisões</strong>
        <span style={{ fontWeight: 500 }}>
          {' '}
          aguardando o depósito do valor pelo cliente e a confirmação bancária -
          quando então você, revisor, será notificado por e-mail.
        </span>
      </div>
    </div>
  );
};

const message = {
  approve: {
    cliente:
      'Ao clicar em "Aprovar Proposta" você está concordando com o termos negociados e será encaminhado para pagamento de sua revisão. \n' +
      'Após o pagamento poderá acessar o painel de envio do seu material',
    revisor: revisorMessageApprove()
  },
  finish: 'Tem certeza que deseja finalizar essa negociação?'
};

const modalOkText = {
  approve: 'Concordar',
  finish: 'Estou certo'
};

class DetalheProposta extends Component {
  state = {
    visible: false,
    modalCounterProposal: false,
    modal: 'approve',
    form: {
      total_days: '',
      total_proposal_cents: '',
      description: ''
    },
    disableButtons: false
  };

  componentDidMount() {
    if (this.props.proposal.proposal_id) {
      this.props.dispatch(fetchOneProposal(this.props.proposal.id));
    }
  }

  componentWillReceiveProps(nextProps) {
    let { isRevisor, isParceiro } = this.props.roles;

    if (nextProps.reviewerCreateCounterProposalSuccess) {
      this.props.dispatch(
        showNotification('success', 'Contraproposta enviada com sucesso!', true)
      );
    }

    if (nextProps.reviewerDisapproveProposalSuccess) {
      this.props.dispatch(
        showNotification('success', 'Encerrado com sucesso!', true)
      );

      this.props.onBackProposta();
    }

    if (nextProps.reviewerApproveProposalSuccess) {
      let title = nextProps.proposal.proposal_id
        ? 'Contraproposta'
        : 'Proposta';

      this.props.dispatch(
        showNotification('success', `${title} aceita!`, true)
      );

      let timer = setTimeout(() => {
        if (isRevisor || isParceiro) {
          this.props.onBackRevisoes();
        } else {
          this.props.onBackMinhasCompras();
        }
        clearTimeout(timer);
      }, 2000);
    }
  }

  handleApprove = () => {
    this.setState({ visible: true, modal: 'approve' });
  };

  handleFinish = () => {
    this.setState({ visible: true, modal: 'finish' });
  };

  handleCounterProposal = show => {
    this.setState({ modalCounterProposal: show });
  };

  handleCounterProposalDetail = proposal => {
    this.props.onDetalheProposta(proposal);
  };

  handleContinue = () => {
    if (this.state.modal === 'finish') {
      this.props.dispatch(reviewerDisapproveProposal(this.props.proposal.id));
    } else if (this.state.modal === 'approve') {
      this.props.dispatch(reviewerApproveProposal(this.props.proposal.id));
    }
  };

  handleDismiss = () => {
    this.setState({ visible: false });
  };

  handleCreateCounterProposal = e => {
    e.preventDefault();

    let form = { ...this.state.form };
    form.total_days = parseInt(form.total_days, 10);

    let state = { ...this.state };
    state.disableButtons = true;
    state.modalCounterProposal = false;

    this.setState(state);

    this.props.dispatch(
      reviewerCreateCounterProposal(form, this.props.proposal.id)
    );
  };

  handleChangeObservacoesInput = e => {
    let { name, value } = e.target;
    let newState = { ...this.state };

    let valueWithoutEmail = value.replace(/\b(\w)+[@](\w)+\.(\w)+\b/g, '');
    let valueWithoutPhone = valueWithoutEmail.replace(/\d{6,}/, '');

    newState.form[name] = valueWithoutPhone;
    this.setState(newState);
  };

  handleChangeInput = e => {
    let { name, value } = e.target;
    let newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  handleCentsChange = values => {
    let { form } = this.state;
    form.total_proposal_cents = values.floatValue * 100;
    this.setState({ form });
  };

  getName(proposal) {
    let { isRevisor, isParceiro } = this.props.roles;

    if (isRevisor || isParceiro) {
      return proposal.writer.name;
    }

    return proposal.reviewer.name;
  }

  getStatus(status) {
    if (this.props.proposal.counter_proposal) {
      return { label: 'Rejeitada (Contraproposta)', className: 'disapproved' };
    }

    return { label: statusLabel[status], className: status };
  }

  getModalMessage() {
    let { isRevisor, isParceiro } = this.props.roles;

    if (this.state.modal === 'approve') {
      return message[this.state.modal][
        isRevisor || isParceiro ? 'revisor' : 'cliente'
      ];
    }

    return message[this.state.modal];
  }

  renderAvatarImg(proposal) {
    let imgAvatarSrc = '';
    let { isRevisor, isParceiro, isRedator } = this.props.roles;

    if (isRedator) {
      if (proposal.reviewer.imagem) {
        imgAvatarSrc = proposal.reviewer.imagem;
      } else {
        imgAvatarSrc =
          proposal.reviewer.sexo === 'F' ? imageAvatarFem : imageAvatarMasc; //Imagens padrão
      }
    } else if (isRevisor || isParceiro) {
      if (proposal.writer.imagem) {
        imgAvatarSrc = proposal.writer.imagem;
      } else {
        imgAvatarSrc =
          proposal.writer.sexto === 'F' ? imageAvatarFem : imageAvatarMasc;
      }
    }

    return <img alt="Avatar" className="proposta-avatar" src={imgAvatarSrc} />;
  }

  renderProposalPrice() {
    let { proposal } = this.props;

    if (proposal.total_proposal_cents) {
      return (
        <div className="proposta-total">
          Valor proposto:{' '}
          <span>
            {' '}
            {proposal.total_proposal_formatted.toLocaleString('pt-BR')}
          </span>
        </div>
      );
    }

    return null;
  }

  renderInfo() {
    let { proposal } = this.props;
    let propostaTitle = proposal.proposal_id ? 'contraproposta' : 'proposta';

    if (proposal.status === 'approved') {
      return (
        <p className="proposta-info">
          <b>Importante:</b> Essa {propostaTitle} tem caráter de negociação,
          somente se tornando obrigatória para as partes, nos termos do art. 427
          do Código Civil, após o pagamento ser autorizado pelo cliente.
        </p>
      );
    }
  }

  renderButtons() {
    let { isRedator, isRevisor, isParceiro } = this.props.roles;
    let { proposal } = this.props;

    if (isRedator) {
      if (
        proposal.counter_proposal &&
        proposal.counter_proposal.status === 'open'
      ) {
        return (
          <div>
            <ul className="proposta-status-msg margintop">
              <li>Aguardando resposta da contraproposta</li>
            </ul>
            <div className="flex-row space-between margintop">
              <button
                className="button turquoise w-button nomargin margin-left-auto"
                onClick={this.handleCounterProposalDetail.bind(
                  this,
                  proposal.counter_proposal
                )}
              >
                Link para <strong>contraproposta</strong>
              </button>
            </div>
          </div>
        );
      }

      if (proposal.invoice && proposal.invoice.status === 'pending') {
        return (
          <div>
            {!proposal.proposal_id && (
              <ul className="proposta-status-msg margintop">
                <li>Proposta aprovada pelo Revisor</li>
              </ul>
            )}
            <div className="flex-row space-between margintop">
              <a
                className="button turquoise w-button nomargin margin-left-auto"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.proposal.invoice.provider_url}
              >
                Link para <strong>pagamento</strong>
              </a>
            </div>
          </div>
        );
      }

      if (proposal.status === 'open') {
        return (
          <div>
            {!proposal.proposal_id && !proposal.counter_proposal && (
              <ul className="proposta-status-msg margintop">
                <li>Aguardando resposta do revisor</li>
              </ul>
            )}

            {proposal.proposal_id && (
              <div className="flex-row space-between margintop">
                <button
                  className="button inverse-btn tomato nomargin"
                  onClick={this.handleFinish}
                >
                  Encerrar <strong>negociação</strong>
                </button>

                <button
                  className="button turquoise w-button nomargin"
                  onClick={this.handleApprove}
                >
                  Aprovar <strong>contraproposta</strong>
                </button>
              </div>
            )}
          </div>
        );
      }
    }

    if (isRevisor || isParceiro) {
      if (proposal.invoice && proposal.invoice.status === 'pending') {
        return (
          <div>
            <ul className="proposta-status-msg margintop">
              <li>Aguardando pagamento.</li>
            </ul>
          </div>
        );
      }

      if (proposal.counter_proposal) {
        if (proposal.counter_proposal.status === 'open') {
          return (
            <div>
              <ul className="proposta-status-msg margintop">
                <li>Aguardando resposta da contraproposta</li>
              </ul>
              <div className="flex-row space-between margintop">
                <button
                  className="button turquoise w-button nomargin margin-left-auto"
                  onClick={this.handleCounterProposalDetail.bind(
                    this,
                    proposal.counter_proposal
                  )}
                >
                  Link para <strong>contraproposta</strong>
                </button>
              </div>
            </div>
          );
        }

        if (proposal.counter_proposal.status === 'disapproved') {
          return (
            <div>
              <ul className="proposta-status-msg margintop">
                <li>Contraproposta rejeitada</li>
              </ul>
            </div>
          );
        }

        return (
          <div>
            <ul className="proposta-status-msg margintop">
              <li>Contraproposta aprovada</li>
            </ul>
          </div>
        );
      }

      if (proposal.status === 'open' && !this.state.disableButtons) {
        return (
          <div>
            {proposal.proposal_id && (
              <ul className="proposta-status-msg margintop">
                <li>Aguardando resposta do Cliente</li>
              </ul>
            )}

            <div className="flex-row space-between margintop">
              {!proposal.proposal_id && (
                <button
                  className="button inverse-btn tomato nomargin"
                  onClick={this.handleFinish}
                >
                  Encerrar <strong>negociação</strong>
                </button>
              )}

              {!proposal.proposal_id && !proposal.counter_proposal && (
                <button
                  className="button inverse-btn nomargin"
                  onClick={this.handleCounterProposal.bind(this, true)}
                >
                  Fazer <strong>contraproposta</strong>
                </button>
              )}

              {!proposal.proposal_id && !proposal.counter_proposal && (
                <button
                  className="button turquoise w-button nomargin"
                  onClick={this.handleApprove}
                >
                  Aprovar <strong>proposta</strong>
                </button>
              )}
            </div>
          </div>
        );
      }
    }

    return null;
  }

  render() {
    let { onBackProposta, roles, proposal } = this.props;
    let propostaName =
      roles.isRevisor || roles.isParceiro ? 'Proposta de' : 'Enviado para';
    let propostaTitle = proposal.proposal_id ? 'contraproposta' : 'proposta';
    let status = this.getStatus(proposal.status);

    return (
      <div className="hero-box img-box proposta-detalhe">
        <h4 className="turquoise-text bold">
          <span className="back-button" onClick={onBackProposta}>
            <FontAwesomeIcon icon="arrow-left" />
          </span>
          Detalhes da {propostaTitle}
        </h4>

        <div className="flex-row">
          {this.renderAvatarImg(proposal)}
          <div className="proposta-name">
            <div>{propostaName}</div>
            <span>{this.getName(proposal)}</span>
          </div>
          <span className={'proposta-status ' + status.className}>
            Status: {status.label}
          </span>
        </div>

        <div className="divider" />

        {this.renderInfo()}

        <div className="revisao-data">{proposal.created_at}</div>
        <div className="proposta-item">
          Tipo de Texto: <span>{proposalTypes[proposal.type]}</span>
        </div>
        <div className="proposta-item">
          Nº. de laudas: <span>{proposal.total_pages} laudas</span>
        </div>
        <div className="proposta-item">
          Prazo de entrega: <span>{proposal.total_days} dias</span>
        </div>

        <h6 className="proposta-subtitle">Descrição da proposta</h6>

        <p className="proposta-descricao">{proposal.description}</p>

        <div className="proposta-total">
          Valor total original: <span>{proposal.total_original_formatted}</span>
        </div>

        {this.renderProposalPrice()}
        {this.renderButtons()}

        <Modal
          message={this.getModalMessage()}
          okText={modalOkText[this.state.modal]}
          onOkClick={this.handleContinue}
          visible={this.state.visible}
          onDismiss={this.handleDismiss}
        />

        <Modal
          visible={this.state.modalCounterProposal}
          onDismiss={this.handleCounterProposal.bind(this, false)}
        >
          <img
            className="modal-icon"
            src={modalContraproposta}
            alt="Contraproposta"
          />
          <h5 className="modal-title turquoise-text">CONTRAPROPOSTA</h5>
          <div className="divider" />

          <p className="modal-text">
            Descreva abaixo detalhes de sua contraproposta
          </p>

          <p className="modal-text">
            (valores, prazo de entrega e observações)
          </p>

          <FormValidator
            className="w-form"
            onSubmit={this.handleCreateCounterProposal}
          >
            <FormInput
              className="accont-input w-input"
              name="total_days"
              type="number"
              placeholder="Contraproposta de prazo"
              onChange={this.handleChangeInput}
              validators={['required']}
              value={this.state.form.total_days}
            />

            <FormCurrency
              className="accont-input w-input"
              name="total_proposal_cents"
              placeholder="Contraproposta de valor"
              validators={['required']}
              onValueChange={this.handleCentsChange}
            />

            <textarea
              rows="2"
              className="accont-input w-input"
              name="description"
              placeholder="Observações"
              onChange={this.handleChangeObservacoesInput}
              value={this.state.form.description}
            />

            <div className="modal-counter-proposal-buttons">
              <FormButton className="button turquoise w-button">
                Enviar <strong>contraproposta</strong>
              </FormButton>
            </div>
          </FormValidator>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ reviewersStore, userStore }, props) => {
  let {
    reviewerDisapproveProposalSuccess,
    reviewerApproveProposalSuccess
  } = reviewersStore;
  let proposal = userStore.proposal || props.proposal;
  return {
    reviewerDisapproveProposalSuccess,
    reviewerApproveProposalSuccess,
    proposal
  };
};

const DetalhePropostaComponent = connect(mapStateToProps)(DetalheProposta);
export { DetalhePropostaComponent };
