import React from 'react';
import arrowDown from './images/arrow-down.svg';
import marcaContextoBranca from './images/marca-contexto-branca.svg';
import './Jumbo.css';

export const Jumbotron = () => (
  <div className="flex-container-jumbo">
    <div>
      <img src={marcaContextoBranca} alt="marca" className="logo" />
    </div>
    <div>
      <h1 className="hero-title">
        Plataforma de conexão entre clientes e revisores de texto
      </h1>
    </div>
    <div>
      <p className="hero-subtitle" id="subtitle">
        Cliente escolhe o profissional de revisão. Revisor realiza o trabalho
        com qualidade. O cliente paga e o revisor recebe. Tudo on-line,
        eficiente, seguro, simples e rápido.
      </p>
    </div>
    <div>
      <img src={arrowDown} alt="marca" className="arrow-down" id="seta" />
    </div>
  </div>
);
export default Jumbotron;
