import React, { useState, useEffect } from 'react';
import GeneralMenu from '../../../../siteComponents/GeneralMenu/GeneralMenu';
import Modal from '../../../../siteComponents/modal';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../../../routes';
import { userCreateRepresentative } from '../../../../../state-management';
import AgreementPDF from './AgreementPDF.js';
import { embeddedSigning } from '../../../../../state-management/user/actions';
import { pdf } from '@react-pdf/renderer';
import eversign from '../../eversign';

const { parcerias, parceiroRepresentante } = routes;

const RepresentativeAgreement = ({ user, dispatch, history, signingURL }) => {
  const [showAccept, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (signingURL) {
      onScriptLoad(signingURL);
    }
  }, [signingURL]);

  const handleAccept = async () => {
    dispatch(userCreateRepresentative(user));
    const doc = await pdf(<AgreementPDF user={user} />).toBlob();
    localStorage.setItem('email', user.email);
    dispatch(embeddedSigning(doc));
  };

  const onScriptLoad = signingURL => {
    eversign.open({
      url: signingURL,
      containerID: 'eversign',
      width: 600,
      height: 600,
      events: {
        loaded: () => {
          setShowButton(true);
        },
        signed: () => {
          setShow(true);
        },
        declined: function() {
          alert('Contrato recusado');
        },
        error: function() {
          alert('error callback');
        }
      }
    });
  };

  const handleClose = () => {
    history.push('/');
  };
  return user ? (
    <div className="about">
      <Modal
        message="Obrigado por participar! Sua proposta para tornar-se Parceiro Divulgador já está sob análise. Para mais informações, acabamos de lhe enviar um e-mail especial."
        okText="Okay"
        visible={showAccept}
        onlyOkButton={true}
        onOkClick={handleClose}
      />
      <GeneralMenu />
      <div className="section-dark light-padding">
        <div className="flexcourners large-container">
          <div className="terms-container" style={{ padding: '3%' }}>
            <h1 className="blue-text bold">CONTRATO DE PARCERIA COMERCIAL</h1>
            <p>
              PRECEPTOR EDUCAÇÃO A DIST NCIA LTDA ME, pessoa jurídica
              constituída na forma de Sociedade Limitada, inscrita no CNPJ sob o
              nº 07.633.844/0001-19, na Rua Cel. Alves Teixeira, 1290, sala 5,
              bairro Dionísio Torres, CEP 60135-208, na cidade de Fortaleza,
              Estado do Ceará, representada na forma de seus atos constitutivos,
              doravante denominada simplesmente <b>CONTEXTO REVISOR</b>, do
              outro lado,
            </p>
            {user.pf ? (
              <p>
                {user.name}, {user.nacionalidade}, {user.estado_civil}, portador
                do RG nº {user.rg}, inscrito no CPF sob o nº {user.document},
                residente e domiciliado em endereço completo: {user.address},{' '}
                {user.number}
                {user.complement && `, ${user.complement}`}, {user.district},{' '}
                {user.city},{user.state} – UF, {user.postal_code}, neste ato
                representada na forma de seus atos constitutivos, doravante
                denominado simplesmente como <b>PARCEIRO CAPTADOR</b>.
              </p>
            ) : (
              <p>
                {user.name}, pessoa jurídica constituída na forma de
                {user.company} no CNPJ sob o nº {user.document}, com sede em{' '}
                {user.address}, {user.number}
                {user.complement && `, ${user.complement}`}, {user.district},{' '}
                {user.postal_code}, na cidade de {user.city} - {user.state}{' '}
                neste ato representada na forma de seus atos constitutivos,
                doravante denominado simplesmente como <b>PARCEIRO CAPTADOR</b>.
              </p>
            )}
            <p>
              As partes ora identificadas comprometem-se a, respectivamente,
              unir esforços para firmar parceria comercial no intuito de
              acelerar os resultados esperados na utilização do software da
              seguinte forma:
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA PRIMEIRA - DO OBJETO E NATUREZA JURÍDICA
            </h1>
            <p>
              O objeto contratual da parceria é firmar parceria comercial entre
              <b>CONTEXTO REVISOR</b> e <b>PARCEIRO CAPTADOR</b>, de forma que o
              PARCEIRO CAPTADOR ajude a acelerar o crescimento do número de
              demandantes de revisão de textos para a plataforma de revisão da
              CONTEXTO REVISOR denominada “Contexto Revisor”, encontrando e
              auxiliando na captação de Instituições Parceiras com razoável base
              de possíveis clientes.
            </p>
            <p>
              A presente parceria é firmada sem caráter de exclusividade,
              podendo a <b>CONTEXTO REVISOR</b> realizar contratações com demais
              parceiros com objeto semelhante ao do presente instrumento. No
              entanto, o <b>PARCEIRO CAPTADOR</b> se compromete, no decorrer da
              presente parceria, a não firmar vínculos cujos objetos sejam
              especificamente conflitantes com o da presente parceria ou com
              empresas concorrentes da <b>CONTEXTO REVISOR</b>.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA SEGUNDA - REMUNERAÇÃO E PAGAMENTO
            </h1>
            <p>
              2.1. Em razão desta parceria, a <b>CONTEXTO REVISOR</b> pagará ao
              <b>PARCEIRO CAPTADOR</b> os seguintes valores:
            </p>
            <p>
              2.1.1. 2% (dois por cento) sobre o valor do pagamento total,
              retido o valor correspondente à tributação devida, realizado
              utilizando o código da Instituição Parceira que tiver sido captada
              com auxílio específico do <b>PARCEIRO CAPTADOR</b>, sendo estes
              repassados mensalmente, enquanto perdurar este Contrato.
            </p>
            <p>
              2.1.2. Os pagamentos serão realizados todo dia [Campo Preenchível]
              5 (cinco) do mês subsequente.
            </p>
            <p>
              2.2. O pagamento será justificado por meio de relatório específico
              disponibilizado pela <b>CONTEXTO REVISOR</b>, no qual estarão
              descritos os valores recebidos e a data de finalização de cada
              serviço de revisão textual.
            </p>
            <p>
              2.3. Todos os pagamentos feitos pela <b>CONTEXTO REVISOR</b> serão
              realizados por meio da plataforma on-line de pagamentos, em conta
              determinada pelo <b>PARCEIRO CAPTADOR</b> no momento da assinatura
              do presente instrumento.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
            </h1>
            <p>
              3.1. O <b>PARCEIRO CAPTADOR</b> se obriga a realizar as suas
              atividades, cabendo-lhe total e exclusiva responsabilidade pelo
              integral atendimento de toda a legislação que rege os negócios
              jurídicos e que lhe atribua responsabilidades. Igualmente, o{' '}
              <b>PARCEIRO CAPTADOR</b>
              se obriga também a reembolsar a <b>CONTEXTO REVISOR</b> por todas
              as despesas decorrentes de:
            </p>
            <p>
              a) reconhecimento judicial de vínculo empregatício seu ou de seus
              empregados com a <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              b) reconhecimento judicial de solidariedade da{' '}
              <b>CONTEXTO REVISOR</b> no cumprimento de suas obrigações
              trabalhistas e/ou previdenciárias;
            </p>
            <p>
              c) indenização, inclusive a terceiros, como consequência de
              eventuais danos causados pelo <b>PARCEIRO CAPTADOR</b>, ou seus
              prepostos, decorrentes de negligência ou imperícia, na execução
              das suas atividades.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA QUARTA – OBRIGAÇÕES DA <b>CONTEXTO REVISOR</b>
            </h1>
            <p>
              4.1. São obrigações da <b>CONTEXTO REVISOR</b>:
            </p>
            <p>
              4.1.1. Dar assistência e orientação ao <b>PARCEIRO CAPTADOR</b>{' '}
              para correta execução da parceria aqui estabelecida.
            </p>
            <p>
              4.1.2. Fornecer informações, desde que requisitadas, por meio de
              planilhas e relatórios, a fim de auxiliar e esclarecer dúvidas do
              <b>PARCEIRO CAPTADOR</b> a respeito da execução da parceria e da
              percepção e partilha de valores recebidos.
            </p>
            <p>
              4.1.3. Realizar os repasses dos valores e das comissões, conforme
              definido na Cláusula Segunda;
            </p>
            <p>
              4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, com vistas a assegurar a
              observância das obrigações assumidas e a prevenir expectativas
              equivocadas e erros de interpretação.
            </p>
            <p>
              4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              do <b>PARCEIRO CAPTADOR</b> sem expressa autorização em documento
              específico.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA QUINTA – OBRIGAÇÕES DO <b>PARCEIRO CAPTADOR</b>
            </h1>
            <p>
              5.1. São obrigações do <b>PARCEIRO CAPTADOR</b>:
            </p>
            <p>
              5.1.1. Identificar, abordar e oferecer parceria da CONTEXTO
              REVISOR a instituições com potencial para serem parceiras na
              captação de clientes, no intuito de acelerar o crescimento do uso
              do sistema “Contexto Revisor”.
            </p>
            <p>
              5.1.2. Recolher correta e pontualmente todas as obrigações e
              encargos trabalhistas, previdenciários, fiscais, parafiscais e
              outros que sejam ou venham a ser devidos em razão do presente
              contrato.
            </p>
            <p>
              5.1.3. Responder integralmente pelos atos praticados por seus
              prepostos ou funcionários, caso possua, que acarretarem prejuízo à
              <b>CONTEXTO REVISOR</b> ou terceiro.
            </p>
            <p>
              5.1.4. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, com vistas a assegurar a
              observância das obrigações assumidas e a prevenir expectativas
              equivocadas e erros de interpretação.
            </p>
            <p>
              5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              da <b>CONTEXTO REVISOR</b> sem expressa autorização, em documento
              específico.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
            </h1>
            <p>
              6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade
              e todos os direitos relativos ao software desenvolvido, como o
              código-fonte, especificações funcionais, fluxogramas, logomarca,
              dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
              REVISOR, podendo somente esta utilizá-los, cedê-los e
              transferi-los a terceiros.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
            </h1>
            <p>
              7.1. O <b>PARCEIRO CAPTADOR</b> compromete-se a manter o mais
              absoluto sigilo de todas as informações de conteúdo confidencial,
              sejam elas comerciais, técnicas, pessoais e outras, cujo
              conhecimento tiver, em decorrência do exercício de suas
              atividades; além de não exercer qualquer forma de concorrência
              direta com a CONTEXTO REVISOR, podendo ser responsabilizado civil
              ou criminalmente por eventuais danos materiais ou morais
              decorrentes da não obediência a esta regra.
            </p>
            <p>
              7.2. Por definição, “Informações Confidenciais” significam todos
              as informações técnicas e gerais, know-how, documentos, programas
              de computador e documentação, códigos fonte, relatórios, dados
              financeiros ou outros dados, registros, formulários, ferramentas,
              produtos, serviços, pesquisa presente e futura, conhecimento
              técnico, planos de marketing, segredos comerciais e outros
              materiais tangíveis ou intangíveis, armazenados ou não, compilados
              ou reduzidos a termo, seja física, eletrônica ou graficamente, por
              escrito, ou por qualquer meio, que esteja em poder da CONTEXTO
              REVISOR, e que seja revelado ao <b>PARCEIRO CAPTADOR</b>.
            </p>
            <p>
              7.3. As Informações Confidenciais incluem, sem limitação,
              materiais, arquivos e informações, bem como as informações de
              propriedade da <b>CONTEXTO REVISOR</b> ou de terceiros que: a)
              contenham a especificação de propriedade exclusiva ou
              confidencial; b) cuja natureza confidencial tenha sido informada
              ao <b>PARCEIRO CAPTADOR</b>; c) consistam na solução, nas
              inovações e nos aperfeiçoamentos que sejam de criação e de
              desenvolvimento individual ou em conjunto entre as PARTES; e d) em
              virtude de suas características e de sua natureza seriam
              consideradas confidenciais em circunstâncias semelhantes.
            </p>
            <p>
              7.4. O <b>PARCEIRO CAPTADOR</b> concorda que a violação desta
              Cláusula, pelo uso de qualquer informação confidencial pertencente
              à<b>CONTEXTO REVISOR</b>, sem sua expressa autorização,
              causar-lhe-á danos e prejuízos irreparáveis, não podendo este ser
              inferior ao valor de R$30.000,00 (trinta mil reais).
            </p>
            <p>
              7.5. O <b>PARCEIRO CAPTADOR</b> concorda que as obrigações de
              Confidencialidade e Sigilo se iniciam a partir da assinatura do
              presente instrumento, perdurando na sua vigência e por 2 (dois)
              anos do término de qualquer que seja o vínculo existente entre as
              Partes. Desta forma, na hipótese de violação, a{' '}
              <b>CONTEXTO REVISOR</b>
              será imediatamente considerada legítima detentora do direito a
              tomar todas as medidas extrajudiciais e judiciais, nas esferas
              cíveis e criminais, inclusive de caráter cautelar ou de
              antecipação de tutela jurisdicional, que julgar cabíveis à defesa
              de seus direitos, inclusive autorais, independentemente da tomada
              das medidas judiciais tendentes a obter a reparação pelo dano
              causado.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
            </h1>
            <p>
              8.1. Toda e qualquer despesa que o <b>PARCEIRO CAPTADOR</b> tiver
              em virtude da execução desta parceria serão de exclusiva
              responsabilidade deste, cabendo apenas à <b>CONTEXTO REVISOR</b>{' '}
              realizar os repasses de valores e comissões, conforme acordado na
              Cláusula Segunda.
            </p>
            <p>
              8.2. Ainda, cada parte responderá, de forma individual e
              exclusiva, pelos tributos e encargos que sejam de sua titularidade
              passiva.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA
            </h1>
            <p>
              9.1. O presente contrato de parceria comercial tem prazo de
              vigência de 365 (trezentos e sessenta e cinco) dias, renovável por
              iguais períodos, caso as Partes assim decidam, a contar da data de
              sua assinatura, sendo passível de rescisão por qualquer das partes
              por meio de notificação expressa no prazo de 30 (trinta) dias.
            </p>
            <p>
              9.2. No caso de rescisão imotivada por qualquer das partes, caso a
              <b>CONTEXTO REVISOR</b> tenha pagamentos pendentes, deverá
              depositá-los em conta de titularidade do <b>PARCEIRO CAPTADOR</b>,
              enviando o respectivo comprovante, no prazo de 30 (trinta) dias da
              notificação da rescisão.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA DÉCIMA – RESCISÃO MOTIVADA
            </h1>
            <p>
              10.1. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se o <b>PARCEIRO CAPTADOR</b>:
            </p>
            <p>
              10.1.1. ceder ou transferir o presente contrato, por qualquer
              forma, a terceiros em quaisquer condições, sem o consentimento da
              <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              10.1.2. descumprir qualquer cláusula ou condição deste contrato,
              especialmente no que diz respeito ao estabelecido na Cláusula
              Primeira deste documento;
            </p>
            <p>
              10.2. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se a <b>CONTEXTO REVISOR</b>:
            </p>
            <p>
              10.2.1. descumprir qualquer cláusula ou condição deste contrato,
              especialmente no que tange a realizar os pagamentos nas formas,
              prazos e condições pactuadas na Cláusula Segunda deste documento.
            </p>
            <p>
              10.3. Nos casos de rescisão motivada, não será necessária qualquer
              notificação prévia para rescisão do presente contrato, dando-se
              por encerrada a contratação no dia em que a parte justificadamente
              se manifestar.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
            </h1>
            <p>
              11.1. O descumprimento de qualquer das obrigações contratuais
              acarretará, após prévia notificação ou interpelação, facultada à
              parte inocente, de forma sucessiva:
            </p>
            <p>
              a) A suspensão da execução do presente contrato, o que faculta à
              <b>CONTEXTO REVISOR</b> a retenção de pagamentos e ao{' '}
              <b>PARCEIRO CAPTADOR</b> a suspensão dos serviços de captação
              imediatamente.
            </p>
            <p>
              b) A exigibilidade direta e específica da obrigação descumprida,
              acrescida de multa moratória à razão de 1% (um por cento) sobre o
              total dos valores pagos até a data do inadimplemento, e de juros
              moratórios no montante de 1% (um por cento) ao mês.
            </p>
            <p>c) Em última instância, a resolução do contrato.</p>
            <h1 className="blue-text bold">
              CLÁUSULA DÉCIMA SEGUNDA – TOLER NCIA
            </h1>
            <p>
              12.1. A tolerância de uma das partes para com a outra,
              relativamente ao descumprimento de qualquer uma das obrigações
              assumidas neste contrato, será mera liberalidade, não implicando
              novação ou renúncia a qualquer direito. A parte tolerante poderá,
              a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento
              de suas obrigações.
            </p>
            <h1 className="blue-text bold">
              CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
            </h1>
            <p>
              13.1. O <b>PARCEIRO CAPTADOR</b> declara ter lido e estar ciente e
              de pleno acordo com todos os termos e condições deste Contrato, o
              qual foi feito em estrita observância dos limites do respectivo
              fim econômico ou social, da boa-fé e/ou dos bons costumes, levando
              em consideração inclusive que não estão em situação de premente
              necessidade e têm plenas condições de cumprir todas as cláusulas e
              condições que constituem seus direitos e obrigações constantes no
              presente instrumento.
            </p>
            <p>
              13.2. As disposições deste instrumento somente poderão ser
              alteradas mediante acordo escrito entre as partes, por meio de
              termos aditivos que farão parte integrante do contrato, ficando,
              desde já, consignado que eventuais atos de tolerância não
              configurarão novação contratual.
            </p>
            <p>
              13.3. Este Contrato não implica qualquer tipo de associação ou de
              sociedade entre as Partes, ou em qualquer vínculo empregatício
              entre sócios, diretores, empregados e prepostos de uma Parte para
              com a outra.
            </p>
            <p>
              13.4. As notificações de assuntos referentes a este instrumento
              poderão ser realizadas por correspondência destinada aos endereços
              físicos descritos na qualificação das Partes e eletrônicos, abaixo
              indicados, com a devida confirmação de recebimento:
            </p>
            <p>
              a) E-mail da <b>CONTEXTO REVISOR</b>:
              contato@contextorevisor.com.br
            </p>
            <p>
              b) E-mail do <b>PARCEIRO CAPTADOR</b>: {user.email}{' '}
            </p>
            <h1 className="blue-text bold">CLÁUSULA DÉCIMA QUARTA – FORO</h1>
            <p>
              14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
              Federal, para dirimir quaisquer controvérsias que surgirem do
              presente contrato, renunciando, expressamente, a qualquer outro
              por mais privilegiado que seja.
            </p>
            <div id="eversign" align="center"></div>
            <div className="flex-container">
              <button
                className="hero-btn turquesa w-button"
                hidden={showButton}
                onClick={handleAccept}
              >
                Aceito os termos do contrato
              </button>

              <Link
                className="hero-btn turquesa w-button"
                to={parceiroRepresentante}
              >
                Voltar e Alterar os Dados
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={parcerias} />
  );
};

const mapStateToProps = ({ userStore }) => {
  const { user, signingURL } = userStore;
  return { user, signingURL };
};

export default connect(mapStateToProps)(RepresentativeAgreement);
