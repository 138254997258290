import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'rc-slider/lib/Slider';
import {
  FormValidator,
  FormCurrency,
  FormSelect,
  FormInput
} from '../../../../siteComponents/form';
import {
  reviewerCreateProposal,
  showNotification
} from '../../../../../state-management';
import 'rc-slider/assets/index.css';
import './OrderSection.css';

const dotStyle = {
  backgroundColor: '#BDF1ED',
  width: 11,
  height: 11,
  border: 'none',
  bottom: -3
};
const activeDotStyle = {
  backgroundColor: '#55ddd2',
  width: 11,
  height: 11,
  border: 'none',
  bottom: -3
};
const railStyle = { backgroundColor: '#BDF1ED' };
const handleStyle = {
  backgroundColor: '#55ddd2',
  width: 15,
  height: 15,
  border: 'none'
};
const trackStyle = { backgroundColor: '#55ddd2' };

class OrderSection extends Component {
  state = {
    reviewer: null,
    form: {
      type: '',
      description: '',
      page_price_cents: '0.00',
      total_days: 1,
      total_pages: 1,
      total_original_cents: `0.00`,
      total_proposal_cents: '',
      page_price_formatted: '',
      reviewer_id: ''
    }
  };

  constructor(props) {
    super(props);

    if (!this.state.reviewer && props.reviewer.id) {
      let newState = { ...this.state };
      newState.reviewer = props.reviewer;
      newState.form.page_price_cents = props.reviewer.page_price_cents;
      newState.form.total_original_cents = props.reviewer.page_price_cents;
      this.state = newState;
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log('zapzapzap');
    if (nextProps.reviewer && nextProps.reviewer.id) {
      let newState = { ...this.state };

      newState.form.reviewer_id = nextProps.reviewer.id;

      newState.reviewer = nextProps.reviewer;
      newState.form.page_price_cents = nextProps.reviewer.page_price_cents;
      newState.form.total_original_cents =
        newState.form.page_price_cents * this.state.form.total_pages;
      this.setState(newState);
    }

    if (nextProps.reviewerCreateProposalSuccess) {
      this.props.dispatch(
        showNotification('success', 'Proposta enviada com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.push('/profile');
        window.location.reload();
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.reviewerCreateProposalError) {
      this.props.dispatch(
        showNotification(
          'error',
          'ATENÇÃO: Sua proposta não foi enviada, tente novamente.',
          true
        )
      );
    }
  }

  handleChangeSlider = (key, value) => {
    // console.log(value);
    let newState = { ...this.state };

    newState.form[key] = value;

    if (key === 'total_pages') {
      newState.form.total_original_cents =
        newState.form.page_price_cents * this.state.form.total_pages;
    }

    this.setState(newState);
  };

  getDiasLabel() {
    if (this.state.form.total_days > 1) {
      return `${this.state.form.total_days} dias`;
    }

    return `${this.state.form.total_days} dia`;
  }

  replaceAll(str, de, para) {
    var pos = str.indexOf(de);
    while (pos > -1) {
      str = str.replace(de, para);
      pos = str.indexOf(de);
    }
    return str;
  }

  formatarValorLauda(valor) {
    var valorStr = valor.replace('R$ ', '');
    if (valorStr.indexOf('.') !== -1) {
      var valorSemPonto = this.replaceAll(valorStr.split(',')[0], '.', '');
      valorSemPonto = valorSemPonto + '.' + valorStr.split(',')[1];
      return parseFloat(valorSemPonto);
    } else {
      return parseFloat(valorStr.replace(',', '.'));
    }
  }

  getPricePerPage() {
    if (
      this.state.reviewer !== null &&
      this.state.reviewer.page_price_formatted !== null &&
      this.state.reviewer.page_price !== null
    ) {
      var page_price_tmp = this.formatarValorLauda(
        this.state.reviewer.page_price_formatted
      );

      if (this.state.reviewer.page_price !== page_price_tmp) {
        let pagePrice = `R$ ${parseFloat(
          this.state.reviewer.page_price
        ).toFixed(2)}`;

        return this.state.reviewer && pagePrice.replace('.', ',');
      } else {
        return this.state.reviewer && this.state.reviewer.page_price_formatted;
      }
    } else {
      return this.state.reviewer && this.state.reviewer.page_price_formatted;
    }
  }

  handleFormChange = e => {
    let { name, value } = e.target;
    let newState = { ...this.state };
    newState.form[name] = value;
    newState.form.reviewer_id = newState.reviewer.id;
    this.setState(newState);
  };

  handleCentsChange = values => {
    let { form } = this.state;
    form.total_proposal_cents = values.floatValue * 100;
    this.setState({ form });
  };

  handleCreateProposal = () => {
    let form = { ...this.state.form };

    delete form.page_price_formatted;

    if (!form.type || !form.total_pages || !form.description) {
      let erros = [];
      if (!form.type) {
        erros.push('Tipo de Texto');
      }

      if (!form.total_pages) {
        erros.push('Nº de Laudas');
      }

      if (!form.description) {
        erros.push('Descrição do Trabalho');
      }

      erros = erros.join(' e ');
      this.props.dispatch(
        showNotification(
          'error',
          `Preencha os campos obrigatórios ${erros}`,
          true
        )
      );
    } else {
      this.props.dispatch(reviewerCreateProposal(form));
    }
  };

  renderButtons() {
    let { authenticated, roles } = this.props.userStore;
    let nextUrl = this.props.match
      ? `/login?next=${this.props.match.url}`
      : '/';

    if (this.props.disabled) {
      return null;
    }

    return (
      <div>
        {!authenticated && (
          <Link to={nextUrl} className="button w-button">
            Entrar para enviar proposta
          </Link>
        )}

        {authenticated &&
          ((roles.isRedator && (
            <button
              type="button"
              className="button w-button"
              style={{ maxWidth: '90%' }}
              onClick={this.handleCreateProposal}
            >
              Enviar <strong>proposta</strong>
            </button>
          )) || (
            <p className="msg-subtitle-revisor">
              *Você não pode enviar proposta usando um perfil de revisor
            </p>
          ))}
      </div>
    );
  }

  render() {
    return (
      <div className="perfil-sidebar">
        <div className="price">
          <h4>Preço por lauda</h4>
          <p>{this.getPricePerPage()}</p>
        </div>

        {!this.props.disabled && (
          <FormValidator>
            <div className="divider-gray" />

            <div className="side-section">
              <p className="perfil-titles">Proposta do cliente</p>
              <div
                style={{
                  color: '#9599A4',
                  fontSize: '1.1em',
                  marginBottom: '7px'
                }}
              >
                Qual tipo de texto? <span style={{ color: 'red' }}>*</span>
              </div>

              <FormSelect
                className="accont-input w-select"
                name="type"
                style={{ width: '100%' }}
                value={this.state.form.type}
                validators={['required']}
                onChange={this.handleFormChange}
              >
                <option value="">Informe o tipo de texto</option>
                <option value="1">Redação estudantil/concurso</option>
                <option value="2">TCC/monografia/dissertação/tese</option>
                <option value="3">Outros tipos de texto</option>
              </FormSelect>

              <div className="slider-label">
                <div style={{ color: '#9599A4', fontSize: '1.1em' }}>
                  Nº de laudas <span style={{ color: 'red' }}>*</span>
                </div>
                <FormInput
                  id="total_pages_input"
                  className="w-input"
                  type="number"
                  value={this.state.form.total_pages}
                  min={1}
                  max={500}
                  style={{ maxWidth: '90px' }}
                  onChange={() => {
                    this.handleChangeSlider(
                      'total_pages',
                      document.getElementById('total_pages_input').value
                    );
                  }}
                />
              </div>

              <Slider
                min={1}
                max={500}
                marks={{ 0: '1', 500: '500' }}
                value={this.state.form.total_pages}
                onChange={this.handleChangeSlider.bind(this, 'total_pages')}
                style={{ marginTop: 10 }}
                trackStyle={[trackStyle]}
                handleStyle={[handleStyle]}
                dotStyle={dotStyle}
                activeDotStyle={activeDotStyle}
                railStyle={railStyle}
              />

              <div className="slider-label">
                <div className="label-left">Prazo de Entrega</div>
                <FormInput
                  id="dias_input"
                  className="w-input"
                  type="number"
                  value={this.state.form.total_days}
                  min={1}
                  max={180}
                  style={{ maxWidth: '90px' }}
                  onChange={() => {
                    this.handleChangeSlider(
                      'total_days',
                      document.getElementById('dias_input').value
                    );
                  }}
                />
              </div>

              <Slider
                min={1}
                max={180}
                marks={{ 0: '1', 180: '180' }}
                value={this.state.form.total_days}
                onChange={this.handleChangeSlider.bind(this, 'total_days')}
                style={{ marginTop: 10, marginBottom: 50 }}
                trackStyle={[trackStyle]}
                handleStyle={[handleStyle]}
                dotStyle={dotStyle}
                activeDotStyle={activeDotStyle}
                railStyle={railStyle}
              />
            </div>

            <div className="sidebar-price">
              <span>* Valor total aproximado</span>
              <div className="giant-text">
                R${' '}
                {(this.state.form.total_original_cents / 100).toLocaleString(
                  'pt-BR',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                )}
              </div>
            </div>

            <ul className="no-border perfil-list w-list-unstyled">
              <li className="aligner-item perfil-item">
                <div className="list-style" />
                <div>
                  <p className="perfil-especification">
                    {this.state.form.total_pages} unidades / laudas
                  </p>
                  <p className="small-paragraph">
                    * 1 lauda possui até 70 linhas ou 1400 caracteres, incluindo
                    espaços em branco.
                  </p>
                </div>
              </li>
              <li className="aligner-item perfil-item">
                <div className="list-style" />
                <div>
                  <p className="perfil-especification">
                    Prazo <strong>{this.getDiasLabel()}</strong>
                  </p>
                </div>
              </li>
              <li className="aligner-item perfil-item">
                <div className="list-style" />
                <div>
                  <p className="small-paragraph">
                    * Na proposta ou contraproposta, o valor do serviço de
                    revisão pode variar de acordo com o prazo de entrega
                    requerido pelo cliente.
                  </p>
                </div>
              </li>
              <li className="aligner-item perfil-item">
                <div className="list-style" />
                <div>
                  <p className="small-paragraph">
                    * Quantidade de reescritas e recorreções a ser combinada
                    entre redator e revisor no campo abaixo.
                  </p>
                </div>
              </li>
            </ul>

            <div className="divider-gray" />

            <div className="proposta-descricao">
              <p className="proposta-subtitle">
                Descreva o trabalho que pretende entregar para revisão{' '}
                <span style={{ color: 'red' }}>*</span>
              </p>
              <textarea
                style={{ width: '85%', maxWidth: '85%', minWidth: '85%' }}
                className="accont-input w-input"
                rows="4"
                placeholder="Descreva aqui"
                name="description"
                value={this.state.form.description}
                onChange={this.handleFormChange}
              />

              <p className="proposta-subtitle">
                Quer propor ao revisor um preço total diferente?
              </p>

              <FormCurrency
                style={{ width: '85%', maxWidth: '85%', minWidth: '85%' }}
                placeholder="Propor valor"
                className="accont-input w-input"
                name="total_proposal_cents"
                onValueChange={this.handleCentsChange}
              />
            </div>

            <div
              style={{ textAlign: 'center', maxWidth: '90%', margin: '10px' }}
            >
              <small>
                Os campos marcados com <span style={{ color: 'red' }}>*</span>{' '}
                são obrigatórios
              </small>
            </div>

            {this.renderButtons()}
          </FormValidator>
        )}
      </div>
    );
  }
}

export default OrderSection;
