import React from 'react';
import ViralContainer from '../../siteComponents/ViralContainer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import SearchBar from '../../siteComponents/SearchBar';
import { ListReviewers } from '../../siteComponents/ListReviewers';
import Footer from '../../siteComponents/Footer/index';
import routes from '../../../routes';

const { buscarRevisor } = routes;

const SearchReviewer = props => (
  <div>
    <GeneralMenu selected={buscarRevisor} {...props} />
    <div className="light-padding section-dark">
      <ViralContainer
        color="blue-box"
        text="Encontre os melhores revisores para seus textos"
      />
      <SearchBar />
    </div>
    <ListReviewers />
    <Footer />
  </div>
);
export default SearchReviewer;
