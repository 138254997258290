import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../siteComponents/modal';
import { userPreviewState } from '../../../../state-management';

class CurriculumComponent extends Component {
  status = 'clean';
  state = {
    formInvalid: true,
    formData: {
      title: '',
      file: ''
    },
    curriculumAntigo: '',
    curriculum: {
      link: '',
      file: '',
      title: ''
    },
    modalSuccess: false,
    sendModal: false
  };
  fileRef = null;

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    if (props.value) {
      this.state.curriculum.link = props.value;
    }
  }

  componentWillReceiveProps(props) {
    if (props.value) {
      let state = { ...this.state };
      state.curriculum.link = props.value ? props.value : '';
      if (state.sendModal) {
        state.modalSuccess = true;
        state.sendModal = false;
        this.status = 'received';
      }
      if (
        this.status === 'sended' &&
        state.curriculumAntigo !== state.curriculum.link
      ) {
        state.sendModal = true;
      }
      this.setState(state);
    }
  }

  addCurriculum = () => {
    let state = { ...this.state };
    state.curriculumAntigo = state.curriculum.link;
    state.curriculum.title = this.state.formData.title;
    state.curriculum.file = { ...this.state.formData };

    state.formData = { title: '', file: '' };
    state.formInvalid = true;

    this.fileRef.current.value = '';

    this.setState(state);
    this.props.onChange({ name: 'curriculum', value: state.curriculum.file });
    this.status = 'sended';
  };

  handleCurriculumChange = e => {
    let state = { ...this.state };
    let aux = e.target.files[0];
    var extension = aux.type.split('/').pop();
    if (aux) {
      if (/^(pdf)$/.test(extension)) {
        if (aux.size <= 66060288) {
          state.formData.file = aux;
          state.formData.title = aux.name;
          state.formInvalid = false;
          state.curriculumAntigo = state.curriculum.link;
          this.status = 'selected';
        } else {
          state.formData.file = '';
          state.formInvalid = true;
          this.status = 'size_error';
        }
      } else {
        state.formData.file = '';
        state.formInvalid = true;
        this.status = 'format_error';
      }
    } else {
      state.formData.file = '';
      state.formInvalid = true;
      this.status = 'error';
    }
    this.setState(state);
  };

  render() {
    return (
      <div className="flex-column add-item-inverse-container">
        <h5 className="config-subtitle">Currículo</h5>
        <div className="anexo-container">
          <div className="item-exp">
            <div style={{ flex: 4, display: 'flex' }}>
              {!this.state.curriculum.link && (
                <h5 style={{ alignSelf: 'center' }}>Nenhum anexo</h5>
              )}
              {this.status === 'clean' && (
                <h5 style={{ alignSelf: 'center' }}>Meu currículo</h5>
              )}
              {this.status === 'selected' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Arquivo <strong>{this.state.formData.title}</strong>{' '}
                  selecionado com sucesso. Agora é só <strong>enviar</strong>!
                </h5>
              )}
              {this.status === 'sended' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Enviando o arquivo{' '}
                  <strong>{this.state.curriculum.title}</strong> ...
                </h5>
              )}
              {this.status === 'received' && (
                <h5 style={{ alignSelf: 'center' }}>
                  O arquivo <strong>{this.state.curriculum.title}</strong> foi
                  enviado com sucesso!
                </h5>
              )}
              {this.status === 'size_error' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Por favor, envie um arquivo com tamanho menor que
                  <strong> 64MB</strong>.
                </h5>
              )}
              {this.status === 'format_error' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Atenção! Nós só aceitamos arquivos do formato
                  <strong> PDF</strong>. Por favor, envie outro arquivo.
                </h5>
              )}
              {this.status === 'error' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Ocorreu um <strong>erro</strong>. Por favor, envie novamente o
                  arquivo ou tente enviar um outro arquivo.
                </h5>
              )}
            </div>
          </div>
        </div>

        <input
          id="curriculumfile"
          type="file"
          placeholder="Currículo"
          name="file"
          accept=".pdf"
          style={{ visibility: 'hidden', height: '0' }}
          onChange={this.handleCurriculumChange}
          ref={this.fileRef}
        />

        <div class="flexbox">
          <label
            htmlFor="curriculumfile"
            className="btn button w-button link-btn"
          >
            Selecionar currículo
          </label>
          <button
            className="btn button w-button small-btn inverse-btn add-btn"
            onClick={this.addCurriculum}
            disabled={this.state.formInvalid}
          >
            Enviar currículo
          </button>
        </div>

        <Modal
          title="TUDO CERTO!"
          message={
            'Seu currículo foi atualizado com sucesso! Pedimos que conferira outras informações do seu minissite. Aproveite para adicionar mais informações como anexos de diplomas, certificados e experiências. Marque ou adicione também suas habilidades de revisão. Lembre-se que um bom minissite pode ser o diferencial na hora de conquistar seus clientes!'
          }
          okText={'Ok, entendi'}
          onDismiss={() => this.setState({ modalSuccess: false })}
          onOkClick={() => this.setState({ modalSuccess: false })}
          onlyOkButton={true}
          visible={this.state.modalSuccess}
        />
      </div>
    );
  }
}

const mapToStateProps = ({ userStore }) => {
  let { previewState } = userStore;
  return previewState;
};

const mapDispatchToProps = dispatch => ({
  userPreviewState: data => dispatch(userPreviewState(data))
});

const Curriculos = connect(
  mapToStateProps,
  mapDispatchToProps
)(CurriculumComponent);

export { Curriculos };
