import React, { Component } from 'react';

import { FormInput, FormValidator } from '../../siteComponents/form';
import { showNotification, updatePassword } from '../../../state-management';

class AlterarSenhaComponent extends Component {
  state = {
    form: {
      password: '',
      password_confirmation: ''
    }
  };

  handleChangeInput = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.form[name] = value;
    this.setState(state);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.updatePasswordSuccess) {
      this.props.dispatch(
        showNotification('success', 'Senha alterada com sucesso', true)
      );
      let state = { ...this.state };
      state.form.password = '';
      state.form.password_confirmation = '';
      this.setState(state);
    }

    if (nextProps.updatePasswordError) {
      this.props.dispatch(
        showNotification(
          'error',
          'ATENÇÃO: Ocorreu um erro. Tente novamente mais tarde.',
          true
        )
      );
    }
  }

  handleSubmit = () => {
    if (this.state.form.password && this.state.form.password_confirmation) {
      if (
        this.state.form.password.length >= 6 &&
        this.state.form.password_confirmation.length >= 6
      ) {
        if (
          this.state.form.password === this.state.form.password_confirmation
        ) {
          this.props.dispatch(updatePassword(this.state.form));
        } else {
          this.props.dispatch(
            showNotification('error', 'Senhas devem ser iguais', true)
          );
        }
      } else {
        this.props.dispatch(
          showNotification(
            'error',
            'Senha deve ter no mínimo 6 caracteres',
            true
          )
        );
      }
    } else {
      this.props.dispatch(
        showNotification('error', 'Campos obrigatórios não preenchidos', true)
      );
    }
  };

  render() {
    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">Alterar Senha</h4>

        <FormValidator className="flex-column w-form">
          <FormInput
            type="password"
            placeholder="Nova Senha *"
            className="accont-input w-input"
            name="password"
            value={this.state.form.password}
            onChange={this.handleChangeInput}
            error={this.state.formError}
          />

          <FormInput
            type="password"
            placeholder="Confirmar Nova Senha *"
            className="accont-input w-input"
            name="password_confirmation"
            value={this.state.form.password_confirmation}
            onChange={this.handleChangeInput}
            error={this.state.formError}
          />

          <button
            type="button"
            className="button turquoise w-button submit-profile"
            onClick={this.handleSubmit}
          >
            Alterar Senha
          </button>
        </FormValidator>
      </div>
    );
  }
}

export { AlterarSenhaComponent };
