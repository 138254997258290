import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PriceBox from '../../../../siteComponents/PriceBox';
import {
  FormInput,
  FormButton,
  FormValidator
} from '../../../../siteComponents/form';
import routes from '../../../../../routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  userCreatePartnerInstituition,
  showNotification
} from '../../../../../state-management';
import './PartnerInstituitionRegisterSection.css';
import { ApiService } from '../../../../../state-management/service/api';

const { criarConta } = routes;

class PartnerInstituitionRegisterComponent extends Component {
  sectionOne = null;
  sectionTwo = null;
  sectionThree = null;

  sectionButtons = {};

  state = {
    paymentStepEnable: false,
    activeStep: 1,
    form: {
      denominacao_social: '',
      nome_fantasia: '',
      cnpj_cpf: '',
      nome_responsavel_instituicao: '',
      cpf_responsavel: '',
      email: '',
      password: '',
      terms: false
    },
    error: {
      denominacao_social: false,
      email: false,
      password: false,
      cnpj_cpf: false,
      nome_responsavel_instituicao: false,
      cpf_responsavel: false
    }
  };

  timeOut = null;

  constructor(props) {
    super(props);
    this.sectionOne = React.createRef();
    this.sectionTwo = React.createRef();
    this.sectionThree = React.createRef();

    this.sectionButtons = {
      1: this.sectionOne,
      2: this.sectionTwo,
      3: this.sectionThree
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated && !this.loggedHere) {
      this.props.history.replace('/profile');
    }

    if (nextProps.loginSuccess) {
      this.props.dispatch(
        showNotification('success', 'Usuário logado com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.replace(this.state.nextUrl);
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.loginFailed) {
      this.props.dispatch(
        showNotification(
          'error',
          'Acesso não autorizado, confira seu email e senha!',
          true
        )
      );
    }

    if (nextProps.accountCreated) {
      const newState = { ...this.state };

      this.setState(newState);

      this.props.dispatch(
        showNotification(
          'success',
          'Pronto! Você já preencheu o cadastro e pode logar no sistema. Mas lembre-se: você só poderá publicar seu perfil (minissite) após a autorização bancária, que ocorrerá em no máximo 48h.!',
          true
        )
      );

      this.timeOut = setTimeout(() => {
        this.props.history.replace('/login');
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 4000);
    }

    if (nextProps.accountError) {
      if (nextProps.accountErrorMsg && nextProps.accountErrorMsg.message) {
        let message;

        if (
          nextProps.accountErrorMsg.message ===
          'The email has already been taken.'
        ) {
          message =
            'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.';
        } else {
          message = 'Error ao criar conta parceiro instituição.';
        }

        this.props.dispatch(showNotification('error', message, true));

        const newState = { ...this.state };
        this.setState(newState);
      } else {
        this.props.dispatch(
          showNotification('error', 'Verique os dados e tente novamente', true)
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  changeStep = (number, ref) => {
    if (number === 2) {
      ApiService.existsEmail({ email: this.state.form.email })
        .then(() => {
          this._changeStep(number, ref);
        })
        .catch(e => {
          if (e && e.response && e.response.status) {
            if (e.response.status === 302) {
              this.props.dispatch(
                showNotification(
                  'error',
                  'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.',
                  true
                )
              );
              return;
            }
          }
          throw e;
        });
    } else {
      this._changeStep(number, ref);
    }
  };

  _changeStep = (number, ref) => {
    if (ref && ReactDOM.findDOMNode(ref.current).disabled) {
      return;
    }

    const newState = { ...this.state };
    newState.activeStep = number;
    this.setState(newState);
  };

  handleChangeDate = (date, inputName) => {
    const newState = { ...this.state };
    newState.form[inputName] = date;
    this.setState(newState);
  };

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  onCheck = e => {
    let { name, checked } = e.target;
    let state = { ...this.state };
    state.form[name] = checked;
    this.setState(state);
  };

  lastName = str => {
    var arr = str.split(' ');
    return arr[arr.length - 1];
  };

  firstName = str => {
    var arr = str.split(' ');
    return arr.slice(0, arr.length - 1).join(' ');
  };

  handleSubmit = event => {
    if (!this.state.form.terms) {
      this.props.dispatch(
        showNotification(
          'error',
          'Para efetivar o cadastro, você precisa concordar com os Termos e Condições de Uso e Política de Privacidade do Contexto Revisor.',
          true
        )
      );
      return;
    }
    event.target.disabled = true;

    const newState = { ...this.state };
    this.setState(newState);

    this.props.dispatch(userCreatePartnerInstituition(this.state.form));
  };

  renderStepIndicators(stepActive) {
    let sectionButtonRef = this.sectionButtons[stepActive];

    return (
      <div className="turquoise-list w-col w-col-5 w-col-small-5 w-col-tiny-5">
        <ul className="unordered-list w-list-unstyled">
          <li
            className={'step-revisor ' + (stepActive === 1 ? 'active' : '')}
            onClick={() => this.changeStep(1, sectionButtonRef)}
          >
            <div>1</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 2 ? 'active' : '')}
            onClick={() => this.changeStep(2, sectionButtonRef)}
          >
            <div className="text-block">2</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 3 ? 'active' : '')}
            onClick={() => this.changeStep(3, sectionButtonRef)}
          >
            <div className="text-block">3</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 4 ? 'active' : '')}
            onClick={() => this.changeStep(4, sectionButtonRef)}
          >
            <div className="text-block">4</div>
          </li>

          {this.state.paymentStepEnable && (
            <li
              className={'step-revisor ' + (stepActive === 5 ? 'active' : '')}
              onClick={() => this.changeStep(5, sectionButtonRef)}
            >
              <div className="text-block">5</div>
            </li>
          )}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="accont-box halfbox">
        <div className="w-form">
          {this.state.activeStep === 1 && (
            <FormValidator id="email-form" name="email-form" className="form">
              <div className="revisor-step1">
                <div className="row w-row">
                  <div className="turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1">
                    <Link className="no-style -turquoise" to={criarConta}>
                      
                    </Link>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Instituição parceira</div>
                  </div>
                  {this.renderStepIndicators(1)}
                </div>
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="email"
                  placeholder="E-mail"
                  validators={['email', 'required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.email}
                />
                <FormInput
                  type="password"
                  className="accont-input w-input"
                  name="password"
                  placeholder="Senha"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.password}
                />
                <div></div>
                <FormButton
                  type="button"
                  ref={this.sectionOne}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(2)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 2 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back1"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor2-1"
                    onClick={() => this.changeStep(1)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Dados da instituição...</div>
                  </div>
                  {this.renderStepIndicators(2)}
                </div>
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  maxLength="256"
                  name="denominacao_social"
                  placeholder="Denomicação Social"
                  id="denominacao_social"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.denominacao_social}
                />
                {console.log(this.state.form.denominacao_social)}
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="nome_fantasia"
                  placeholder="Nome fantasia"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.nome_fantasia}
                />
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="cnpj_cpf"
                  placeholder="CNPJ ou CPF"
                  id="cnpj_cpf"
                  validators={['cnpj_cpf', 'required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.cnpj_cpf}
                />
                <FormButton
                  type="button"
                  ref={this.sectionTwo}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(3)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 3 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back2"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor-3-2"
                    onClick={() => this.changeStep(2)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Dados do responsável...</div>
                  </div>
                  {this.renderStepIndicators(3)}
                </div>
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  maxLength="256"
                  name="nome_responsavel_instituicao"
                  placeholder="Nome do responsável"
                  id="nome_responsavel_instituicao"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.nome_responsavel_instituicao}
                />
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="cpf_responsavel"
                  id="cpf_responsavel"
                  placeholder="CPF do responsavel"
                  validators={['cpf', 'required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.cpf_responsavel}
                />
                <FormButton
                  type="button"
                  ref={this.sectionThree}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(4)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 4 && (
            <div>
              <div className="row w-row">
                <div
                  id="revisor-back2"
                  className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                  data-ix="revisor-3-2"
                  onClick={() => this.changeStep(3)}
                >
                  <div></div>
                </div>
                <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                  <div>Escolha o plano</div>
                </div>
                {this.renderStepIndicators(4)}
              </div>

              {!this.state.paymentStepEnable && (
                <FormValidator className="form">
                  <div className="w-row">
                    <div className="w-col w-col-12">
                      <div className="gender-checkbox w-checkbox terms-margin">
                        <FormInput
                          type="checkbox"
                          name="terms"
                          id="terms"
                          onClick={this.onCheck}
                          checked={this.state.form.terms ? true : false}
                          className="w-checkbox-input"
                        />
                        <label
                          htmlFor="terms"
                          className="w-form-label label-gray"
                        >
                          <span>Concordo com os </span>
                          <Link to="/termos" target="_blank">
                            Termos e Condições de Uso e Política de Privacidade
                            do Contexto Revisor.
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>
                </FormValidator>
              )}

              {!this.state.paymentStepEnable && (
                <button
                  type="button"
                  className="accont-btn--revisor button w-button"
                  onClick={this.handleSubmit}
                >
                  Finalizar criação de <strong>conta</strong>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapPropsToState = ({ reviewersStore, userStore }) => {
  let { states, trainings } = reviewersStore;
  let { accountCreated, accountError, accountErrorMsg } = userStore;
  let { loginSuccess, loginFailed, authenticated } = userStore;

  return {
    states,
    trainings,
    accountCreated,
    accountError,
    accountErrorMsg,
    loginSuccess,
    loginFailed,
    authenticated
  };
};

const PartnerInstituitionRegisterSection = connect(mapPropsToState)(
  PartnerInstituitionRegisterComponent
);
export default PartnerInstituitionRegisterSection;
