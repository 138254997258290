import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const Heading = styled.Text`
  margin: 12px;
  font-size: 12px;
  font-weight: bold;
`;

const Bold = styled.Text`
  font-weight: bold;
`;

const Section = styled.View`
  margin: 30px;
  font-size: 8px;
`;

const Paragraph = styled.Text`
  margin: 8px;
`;

const AgreementPDF = ({ user }) => (
  <Document>
    <Page>
      <Section>
        <Heading className="blue-text bold">
          CONTRATO DE PARCERIA COMERCIAL
        </Heading>
        <Paragraph>
          PRECEPTOR EDUCAÇÃO A DIST NCIA LTDA ME, pessoa jurídica constituída na
          forma de Sociedade Limitada, inscrita no CNPJ sob o nº
          07.633.844/0001-19, na Rua Cel. Alves Teixeira, 1290, sala 5, bairro
          Dionísio Torres, CEP 60135-208, na cidade de Fortaleza, Estado do
          Ceará, representada na forma de seus atos constitutivos, doravante
          denominada simplesmente <Bold>CONTEXTO REVISOR</Bold>, do outro lado,
        </Paragraph>
        {user.pf ? (
          <Paragraph>
            {user.name}, {user.nacionalidade}, {user.estado_civil}, portador do
            RG nº {user.rg}, inscrito no CPF sob o nº {user.document}, residente
            e domiciliado em endereço completo: {user.address}, {user.number}
            {user.complement && `, ${user.complement}`}, {user.district},{' '}
            {user.city},{user.state} – UF, {user.postal_code}, neste ato
            representada na forma de seus atos constitutivos, doravante
            denominado simplesmente como <Bold>PARCEIRO CAPTADOR</Bold>.
          </Paragraph>
        ) : (
          <Paragraph>
            {user.name}, pessoa jurídica constituída na forma de
            {user.company} no CNPJ sob o nº {user.document}, com sede em{' '}
            {user.address}, {user.number}
            {user.complement && `, ${user.complement}`}, {user.district},{' '}
            {user.postal_code}, na cidade de {user.city} - {user.state} neste
            ato representada na forma de seus atos constitutivos, doravante
            denominado simplesmente como <Bold>PARCEIRO CAPTADOR</Bold>.
          </Paragraph>
        )}
        <Paragraph>
          As partes ora identificadas comprometem-se a, respectivamente, unir
          esforços para firmar parceria comercial no intuito de acelerar os
          resultados esperados na utilização do software da seguinte forma:
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA PRIMEIRA - DO OBJETO E NATUREZA JURÍDICA
        </Heading>
        <Paragraph>
          O objeto contratual da parceria é firmar parceria comercial entre
          <Bold>CONTEXTO REVISOR</Bold> e <Bold>PARCEIRO CAPTADOR</Bold>, de
          forma que o PARCEIRO CAPTADOR ajude a acelerar o crescimento do número
          de demandantes de revisão de textos para a plataforma de revisão da
          CONTEXTO REVISOR denominada “Contexto Revisor”, encontrando e
          auxiliando na captação de Instituições Parceiras com razoável base de
          possíveis clientes.
        </Paragraph>
        <Paragraph>
          A presente parceria é firmada sem caráter de exclusividade, podendo a{' '}
          <Bold>CONTEXTO REVISOR</Bold> realizar contratações com demais
          parceiros com objeto semelhante ao do presente instrumento. No
          entanto, o <Bold>PARCEIRO CAPTADOR</Bold> se compromete, no decorrer
          da presente parceria, a não firmar vínculos cujos objetos sejam
          especificamente conflitantes com o da presente parceria ou com
          empresas concorrentes da <Bold>CONTEXTO REVISOR</Bold>.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA SEGUNDA - REMUNERAÇÃO E PAGAMENTO
        </Heading>
        <Paragraph>
          2.1. Em razão desta parceria, a <Bold>CONTEXTO REVISOR</Bold> pagará
          ao
          <Bold>PARCEIRO CAPTADOR</Bold> os seguintes valores:
        </Paragraph>
        <Paragraph>
          2.1.1. 2% (dois por cento) sobre o valor do pagamento total, retido o
          valor correspondente à tributação devida, realizado utilizando o
          código da Instituição Parceira que tiver sido captada com auxílio
          específico do <Bold>PARCEIRO CAPTADOR</Bold>, sendo estes repassados
          mensalmente, enquanto perdurar este Contrato.
        </Paragraph>
        <Paragraph>
          2.1.2. Os pagamentos serão realizados todo dia [Campo Preenchível] 5
          (cinco) do mês subsequente.
        </Paragraph>
        <Paragraph>
          2.2. O pagamento será justificado por meio de relatório específico
          disponibilizado pela <Bold>CONTEXTO REVISOR</Bold>, no qual estarão
          descritos os valores recebidos e a data de finalização de cada serviço
          de revisão textual.
        </Paragraph>
        <Paragraph>
          2.3. Todos os pagamentos feitos pela <Bold>CONTEXTO REVISOR</Bold>{' '}
          serão realizados por meio da plataforma on-line de pagamentos, em
          conta determinada pelo <Bold>PARCEIRO CAPTADOR</Bold> no momento da
          assinatura do presente instrumento.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
        </Heading>
        <Paragraph>
          3.1. O <Bold>PARCEIRO CAPTADOR</Bold> se obriga a realizar as suas
          atividades, cabendo-lhe total e exclusiva responsabilidade pelo
          integral atendimento de toda a legislação que rege os negócios
          jurídicos e que lhe atribua responsabilidades. Igualmente, o{' '}
          <Bold>PARCEIRO CAPTADOR</Bold>
          se obriga também a reembolsar a <Bold>CONTEXTO REVISOR</Bold> por
          todas as despesas decorrentes de:
        </Paragraph>
        <Paragraph>
          a) reconhecimento judicial de vínculo empregatício seu ou de seus
          empregados com a <Bold>CONTEXTO REVISOR</Bold>;
        </Paragraph>
        <Paragraph>
          b) reconhecimento judicial de solidariedade da{' '}
          <Bold>CONTEXTO REVISOR</Bold> no cumprimento de suas obrigações
          trabalhistas e/ou previdenciárias;
        </Paragraph>
        <Paragraph>
          c) indenização, inclusive a terceiros, como consequência de eventuais
          danos causados pelo <Bold>PARCEIRO CAPTADOR</Bold>, ou seus prepostos,
          decorrentes de negligência ou imperícia, na execução das suas
          atividades.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA QUARTA – OBRIGAÇÕES DA <Bold>CONTEXTO REVISOR</Bold>
        </Heading>
        <Paragraph>
          4.1. São obrigações da <Bold>CONTEXTO REVISOR</Bold>:
        </Paragraph>
        <Paragraph>
          4.1.1. Dar assistência e orientação ao <Bold>PARCEIRO CAPTADOR</Bold>{' '}
          para correta execução da parceria aqui estabelecida.
        </Paragraph>
        <Paragraph>
          4.1.2. Fornecer informações, desde que requisitadas, por meio de
          planilhas e relatórios, a fim de auxiliar e esclarecer dúvidas do
          <Bold>PARCEIRO CAPTADOR</Bold> a respeito da execução da parceria e da
          percepção e partilha de valores recebidos.
        </Paragraph>
        <Paragraph>
          4.1.3. Realizar os repasses dos valores e das comissões, conforme
          definido na Cláusula Segunda;
        </Paragraph>
        <Paragraph>
          4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, com vistas a assegurar a
          observância das obrigações assumidas e a prevenir expectativas
          equivocadas e erros de interpretação.
        </Paragraph>
        <Paragraph>
          4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome do{' '}
          <Bold>PARCEIRO CAPTADOR</Bold> sem expressa autorização em documento
          específico.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA QUINTA – OBRIGAÇÕES DO <Bold>PARCEIRO CAPTADOR</Bold>
        </Heading>
        <Paragraph>
          5.1. São obrigações do <Bold>PARCEIRO CAPTADOR</Bold>:
        </Paragraph>
        <Paragraph>
          5.1.1. Identificar, abordar e oferecer parceria da CONTEXTO REVISOR a
          instituições com potencial para serem parceiras na captação de
          clientes, no intuito de acelerar o crescimento do uso do sistema
          “Contexto Revisor”.
        </Paragraph>
        <Paragraph>
          5.1.2. Recolher correta e pontualmente todas as obrigações e encargos
          trabalhistas, previdenciários, fiscais, parafiscais e outros que sejam
          ou venham a ser devidos em razão do presente contrato.
        </Paragraph>
        <Paragraph>
          5.1.3. Responder integralmente pelos atos praticados por seus
          prepostos ou funcionários, caso possua, que acarretarem prejuízo à
          <Bold>CONTEXTO REVISOR</Bold> ou terceiro.
        </Paragraph>
        <Paragraph>
          5.1.4. Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, com vistas a assegurar a
          observância das obrigações assumidas e a prevenir expectativas
          equivocadas e erros de interpretação.
        </Paragraph>
        <Paragraph>
          5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome da{' '}
          <Bold>CONTEXTO REVISOR</Bold> sem expressa autorização, em documento
          específico.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
        </Heading>
        <Paragraph>
          6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade e
          todos os direitos relativos ao software desenvolvido, como o
          código-fonte, especificações funcionais, fluxogramas, logomarca,
          dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
          REVISOR, podendo somente esta utilizá-los, cedê-los e transferi-los a
          terceiros.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
        </Heading>
        <Paragraph>
          7.1. O <Bold>PARCEIRO CAPTADOR</Bold> compromete-se a manter o mais
          absoluto sigilo de todas as informações de conteúdo confidencial,
          sejam elas comerciais, técnicas, pessoais e outras, cujo conhecimento
          tiver, em decorrência do exercício de suas atividades; além de não
          exercer qualquer forma de concorrência direta com a CONTEXTO REVISOR,
          podendo ser responsabilizado civil ou criminalmente por eventuais
          danos materiais ou morais decorrentes da não obediência a esta regra.
        </Paragraph>
        <Paragraph>
          7.2. Por definição, “Informações Confidenciais” significam todos as
          informações técnicas e gerais, know-how, documentos, programas de
          computador e documentação, códigos fonte, relatórios, dados
          financeiros ou outros dados, registros, formulários, ferramentas,
          produtos, serviços, pesquisa presente e futura, conhecimento técnico,
          planos de marketing, segredos comerciais e outros materiais tangíveis
          ou intangíveis, armazenados ou não, compilados ou reduzidos a termo,
          seja física, eletrônica ou graficamente, por escrito, ou por qualquer
          meio, que esteja em poder da CONTEXTO REVISOR, e que seja revelado ao{' '}
          <Bold>PARCEIRO CAPTADOR</Bold>.
        </Paragraph>
        <Paragraph>
          7.3. As Informações Confidenciais incluem, sem limitação, materiais,
          arquivos e informações, bem como as informações de propriedade da{' '}
          <Bold>CONTEXTO REVISOR</Bold> ou de terceiros que: a) contenham a
          especificação de propriedade exclusiva ou confidencial; b) cuja
          natureza confidencial tenha sido informada ao{' '}
          <Bold>PARCEIRO CAPTADOR</Bold>; c) consistam na solução, nas inovações
          e nos aperfeiçoamentos que sejam de criação e de desenvolvimento
          individual ou em conjunto entre as PARTES; e d) em virtude de suas
          características e de sua natureza seriam consideradas confidenciais em
          circunstâncias semelhantes.
        </Paragraph>
        <Paragraph>
          7.4. O <Bold>PARCEIRO CAPTADOR</Bold> concorda que a violação desta
          Cláusula, pelo uso de qualquer informação confidencial pertencente à
          <Bold>CONTEXTO REVISOR</Bold>, sem sua expressa autorização,
          causar-lhe-á danos e prejuízos irreparáveis, não podendo este ser
          inferior ao valor de R$30.000,00 (trinta mil reais).
        </Paragraph>
        <Paragraph>
          7.5. O <Bold>PARCEIRO CAPTADOR</Bold> concorda que as obrigações de
          Confidencialidade e Sigilo se iniciam a partir da assinatura do
          presente instrumento, perdurando na sua vigência e por 2 (dois) anos
          do término de qualquer que seja o vínculo existente entre as Partes.
          Desta forma, na hipótese de violação, a <Bold>CONTEXTO REVISOR</Bold>
          será imediatamente considerada legítima detentora do direito a tomar
          todas as medidas extrajudiciais e judiciais, nas esferas cíveis e
          criminais, inclusive de caráter cautelar ou de antecipação de tutela
          jurisdicional, que julgar cabíveis à defesa de seus direitos,
          inclusive autorais, independentemente da tomada das medidas judiciais
          tendentes a obter a reparação pelo dano causado.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
        </Heading>
        <Paragraph>
          8.1. Toda e qualquer despesa que o <Bold>PARCEIRO CAPTADOR</Bold>{' '}
          tiver em virtude da execução desta parceria serão de exclusiva
          responsabilidade deste, cabendo apenas à <Bold>CONTEXTO REVISOR</Bold>{' '}
          realizar os repasses de valores e comissões, conforme acordado na
          Cláusula Segunda.
        </Paragraph>
        <Paragraph>
          8.2. Ainda, cada parte responderá, de forma individual e exclusiva,
          pelos tributos e encargos que sejam de sua titularidade passiva.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA
        </Heading>
        <Paragraph>
          9.1. O presente contrato de parceria comercial tem prazo de vigência
          de 365 (trezentos e sessenta e cinco) dias, renovável por iguais
          períodos, caso as Partes assim decidam, a contar da data de sua
          assinatura, sendo passível de rescisão por qualquer das partes por
          meio de notificação expressa no prazo de 30 (trinta) dias.
        </Paragraph>
        <Paragraph>
          9.2. No caso de rescisão imotivada por qualquer das partes, caso a
          <Bold>CONTEXTO REVISOR</Bold> tenha pagamentos pendentes, deverá
          depositá-los em conta de titularidade do{' '}
          <Bold>PARCEIRO CAPTADOR</Bold>, enviando o respectivo comprovante, no
          prazo de 30 (trinta) dias da notificação da rescisão.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA DÉCIMA – RESCISÃO MOTIVADA
        </Heading>
        <Paragraph>
          10.1. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se o <Bold>PARCEIRO CAPTADOR</Bold>:
        </Paragraph>
        <Paragraph>
          10.1.1. ceder ou transferir o presente contrato, por qualquer forma, a
          terceiros em quaisquer condições, sem o consentimento da
          <Bold>CONTEXTO REVISOR</Bold>;
        </Paragraph>
        <Paragraph>
          10.1.2. descumprir qualquer cláusula ou condição deste contrato,
          especialmente no que diz respeito ao estabelecido na Cláusula Primeira
          deste documento;
        </Paragraph>
        <Paragraph>
          10.2. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se a <Bold>CONTEXTO REVISOR</Bold>:
        </Paragraph>
        <Paragraph>
          10.2.1. descumprir qualquer cláusula ou condição deste contrato,
          especialmente no que tange a realizar os pagamentos nas formas, prazos
          e condições pactuadas na Cláusula Segunda deste documento.
        </Paragraph>
        <Paragraph>
          10.3. Nos casos de rescisão motivada, não será necessária qualquer
          notificação prévia para rescisão do presente contrato, dando-se por
          encerrada a contratação no dia em que a parte justificadamente se
          manifestar.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
        </Heading>
        <Paragraph>
          11.1. O descumprimento de qualquer das obrigações contratuais
          acarretará, após prévia notificação ou interpelação, facultada à parte
          inocente, de forma sucessiva:
        </Paragraph>
        <Paragraph>
          a) A suspensão da execução do presente contrato, o que faculta à
          <Bold>CONTEXTO REVISOR</Bold> a retenção de pagamentos e ao{' '}
          <Bold>PARCEIRO CAPTADOR</Bold> a suspensão dos serviços de captação
          imediatamente.
        </Paragraph>
        <Paragraph>
          b) A exigibilidade direta e específica da obrigação descumprida,
          acrescida de multa moratória à razão de 1% (um por cento) sobre o
          total dos valores pagos até a data do inadimplemento, e de juros
          moratórios no montante de 1% (um por cento) ao mês.
        </Paragraph>
        <Paragraph>c) Em última instância, a resolução do contrato.</Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA DÉCIMA SEGUNDA – TOLER NCIA
        </Heading>
        <Paragraph>
          12.1. A tolerância de uma das partes para com a outra, relativamente
          ao descumprimento de qualquer uma das obrigações assumidas neste
          contrato, será mera liberalidade, não implicando novação ou renúncia a
          qualquer direito. A parte tolerante poderá, a qualquer tempo, exigir
          da outra parte o fiel e cabal cumprimento de suas obrigações.
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
        </Heading>
        <Paragraph>
          13.1. O <Bold>PARCEIRO CAPTADOR</Bold> declara ter lido e estar ciente
          e de pleno acordo com todos os termos e condições deste Contrato, o
          qual foi feito em estrita observância dos limites do respectivo fim
          econômico ou social, da boa-fé e/ou dos bons costumes, levando em
          consideração inclusive que não estão em situação de premente
          necessidade e têm plenas condições de cumprir todas as cláusulas e
          condições que constituem seus direitos e obrigações constantes no
          presente instrumento.
        </Paragraph>
        <Paragraph>
          13.2. As disposições deste instrumento somente poderão ser alteradas
          mediante acordo escrito entre as partes, por meio de termos aditivos
          que farão parte integrante do contrato, ficando, desde já, consignado
          que eventuais atos de tolerância não configurarão novação contratual.
        </Paragraph>
        <Paragraph>
          13.3. Este Contrato não implica qualquer tipo de associação ou de
          sociedade entre as Partes, ou em qualquer vínculo empregatício entre
          sócios, diretores, empregados e prepostos de uma Parte para com a
          outra.
        </Paragraph>
        <Paragraph>
          13.4. As notificações de assuntos referentes a este instrumento
          poderão ser realizadas por correspondência destinada aos endereços
          físicos descritos na qualificação das Partes e eletrônicos, abaixo
          indicados, com a devida confirmação de recebimento:
        </Paragraph>
        <Paragraph>
          a) E-mail da <Bold>CONTEXTO REVISOR</Bold>:
          contato@contextorevisor.com.br
        </Paragraph>
        <Paragraph>
          b) E-mail do <Bold>PARCEIRO CAPTADOR</Bold>: {user.email}{' '}
        </Paragraph>
        <Heading className="blue-text bold">
          CLÁUSULA DÉCIMA QUARTA – FORO
        </Heading>
        <Paragraph>
          14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
          Federal, para dirimir quaisquer controvérsias que surgirem do presente
          contrato, renunciando, expressamente, a qualquer outro por mais
          privilegiado que seja.
        </Paragraph>
      </Section>
    </Page>
    <Page />
  </Document>
);

export default AgreementPDF;
