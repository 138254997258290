import React from 'react';
import GeneralMenu from '../../../../siteComponents/GeneralMenu/GeneralMenu';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import routes from '../../../../../routes';
import Footer from '../../../../siteComponents/Footer';
import Modal from '../../../../siteComponents/modal';
import { userCreatePromoter } from '../../../../../state-management';
import { useState, useEffect } from 'react';
import AgreementPDF from './AgreementPDF.js';
import { embeddedSigning } from '../../../../../state-management/user/actions';
import { pdf } from '@react-pdf/renderer';
import eversign from '../../eversign';

const { parcerias, criarContaParceiroDivulgador } = routes;

const PromoterAgreement = ({
  user,
  dispatch,
  showNotification,
  history,
  signingURL
}) => {
  const [showAccept, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (signingURL) {
      onScriptLoad(signingURL);
    }
  }, [signingURL]);

  const handleAccept = async () => {
    dispatch(userCreatePromoter(user));
    const doc = await pdf(<AgreementPDF user={user} />).toBlob();
    localStorage.setItem('email', user.email);
    dispatch(embeddedSigning(doc));
  };

  const onScriptLoad = signingURL => {
    eversign.open({
      url: signingURL,
      containerID: 'eversign',
      width: 600,
      height: 600,
      events: {
        loaded: () => {
          setShowButton(true);
        },
        signed: () => {
          setShow(true);
        },
        declined: function() {
          alert('Contrato recusado');
        },
        error: function() {
          alert('error callback');
        }
      }
    });
  };

  const handleClose = () => {
    history.push('/');
  };

  return user ? (
    <div className="about">
      <Modal
        message="Obrigado por participar! Sua proposta para tornar-se Parceiro Divulgador já está sob análise. Para mais informações, acabamos de lhe enviar um e-mail especial."
        okText="Okay"
        visible={showAccept}
        onlyOkButton={true}
        onOkClick={handleClose}
      />
      <GeneralMenu />
      <div className="section-dark light-padding">
        <div className="flexcourners large-container">
          <div className="terms-container" style={{ padding: '3%' }}>
            <h1 className="turquoise-text bold">
              CONTRATO DE PARCERIA COMERCIAL
            </h1>

            <p>
              PRECEPTOR EDUCAÇÃO A DIST NCIA LTDA ME, pessoa jurídica
              constituída na forma de SociedadeLimitada,inscrita no CNPJ sob o
              nº 07.633.844/0001-19, com sede na Rua Cel. Alves Teixeira, 1290,
              sala 5, bairro Dionísio Torres, CEP 60135-208, na cidade de
              Fortaleza, Estado do Ceará, representada na forma de seus atos
              constitutivos, doravante denominada simplesmente{' '}
              <b>CONTEXTO REVISOR</b>, e, do outro lado,
            </p>
            {user.pf ? (
              <p>
                {user.name},{user.nacionalidade}, {user.estado_civil}, portador
                do RG nº {user.rg}, inscrito no CPF sob o nº {user.document},
                residente e domiciliado na {user.address}, {user.number},{' '}
                {user.complement}, {user.district}, {user.city},{' '}
                {user.state_full} – {user.state}, {user.postal_code}, neste ato
                representada na forma de seus atos constitutivos, doravante
                denominado simplesmente como <b>PARCEIRO DIVULGADOR</b>.
              </p>
            ) : (
              <p>
                {user.name}, pessoa jurídica constituída na forma de{' '}
                {user.tipo_empresa} no CNPJ sob o nº {user.document}, com sede
                no {user.address}, {user.number}, {user.complement},{' '}
                {user.district}, {user.city}, {user.state_full} – {user.state},{' '}
                {user.postal_code}, neste ato representada na forma de seus atos
                constitutivos, doravante denominado simplesmente como{' '}
                <b>PARCEIRO DIVULGADOR</b>.
              </p>
            )}
            <p>
              As partes ora identificadas comprometem-se a, respectivamente,
              unir esforços para garantir a consecução dos objetivos da parceria
              nos moldes das cláusulas abaixo:
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA PRIMEIRA - DO OBJETO
            </h1>
            <p>
              1.1. O objeto contratual da parceria é a captação de clientes para
              os Revisores Parceiros da plataforma “Contexto Revisor”, de
              propriedade da <b>CONTEXTO REVISOR</b>, através da divulgação da
              plataforma por meio do <b>PARCEIRO DIVULGADOR</b>.
            </p>
            <p>
              1.2. O <b>PARCEIRO DIVULGADOR</b> realizará a divulgação da
              plataforma “Contexto Revisor” através de seus canais de
              comunicação, como site, blog e redes sociais, mediante código
              promocional fornecido pela <b>CONTEXTO REVISOR</b>.
            </p>
            <p>
              1.3. A presente parceria é firmada sem caráter de exclusividade,
              podendo a <b>CONTEXTO REVISOR</b> realizar contratações com demais
              parceiros com objeto semelhante ao do presente instrumento. No
              entanto, o <b>PARCEIRO DIVULGADOR</b> se compromete, no decorrer
              da presente parceria, a não firmar vínculos cujos objetos sejam
              conflitantes com o da presente parceria ou com empresas
              concorrentes da <b>CONTEXTO REVISOR</b>.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SEGUNDA - REMUNERAÇÃO
            </h1>
            <p>
              2.1. Em razão desta parceria, a <b>CONTEXTO REVISOR</b> repassará
              ao <b>PARCEIRO DIVULGADOR</b> os seguintes valores, exclusivamente
              nos casos em que o cliente utilizar o código promocional divulgado
              pela <b>PARCEIRO DIVULGADOR</b>:
            </p>
            <p>
              2.1.1. 8% (oito por cento) do valor total, retido o valor
              correspondente à tributação devida, proveniente da contratação de
              serviços oferecidos pela plataforma da <b>CONTEXTO REVISOR</b>
              pelos clientes que o <b>PARCEIRO DIVULGADOR</b> captou para os
              Revisores Parceiros. Os valores serão repassados mensalmente,
              enquanto perdurar este Contrato.
            </p>
            <p>
              2.1.2. Os pagamentos serão realizados todo dia 5 (cinco) do mês
              subsequente.
            </p>
            <p>
              2.1.3. A título de esclarecimento, os valores pagos pelos clientes
              captados serão distribuídos da seguinte forma: 8% destinado ao
              <b>PARCEIRO DIVULGADOR</b>; 20% destinado à{' '}
              <b>CONTEXTO REVISOR</b>; 72% destinado ao Revisor Parceiro.
            </p>
            <p>
              2.2. O repasse dos valores será justificado por meio de relatório
              específico disponibilizado pela <b>CONTEXTO REVISOR</b>, no qual
              estarão descritos os valores recebidos e a data de finalização de
              cada redação.
            </p>
            <p>
              2.3. Todos os repasses feitos pela <b>CONTEXTO REVISOR</b> serão
              realizados por meio da plataforma online de pagamentos, em conta
              determinada pelo <b>PARCEIRO DIVULGADOR</b> no momento da
              assinatura do presente instrumento.
            </p>
            <p>
              2.4. A simples divulgação não gera direito ao repasse dos valores
              descritos nessa cláusula, sendo necessário que o cliente utilize o
              código promocional divulgado.
            </p>

            <h1 className="turquoise-text bold">
              CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
            </h1>
            <p>
              3.1. O <b>PARCEIRO DIVULGADOR</b> se obriga a realizar as suas
              atividades, cabendo-lhe total e exclusiva responsabilidade pelo
              integral atendimento de toda a legislação que rege os negócios
              jurídicos e que lhe atribua responsabilidades. Igualmente, o
              <b>PARCEIRO DIVULGADOR</b> se obriga também a reembolsar o{' '}
              <b>CONTEXTO REVISOR</b> todas as despesas decorrentes de:
            </p>
            <p>
              a) reconhecimento judicial de vínculo empregatício seu ou de seus
              empregados com a <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              b) reconhecimento judicial de solidariedade da{' '}
              <b>CONTEXTO REVISOR</b> no cumprimento de suas obrigações
              trabalhistas e/ou previdenciárias;
            </p>
            <p>
              c) indenização, inclusive a terceiros, como consequência de
              eventuais danos causados pelo <b>PARCEIRO DIVULGADOR</b>, ou seus
              prepostos, decorrentes de negligência ou imperícia, na execução
              das suas atividades.
            </p>
            <p>
              3.2. Os serviços de divulgação realizados pelo{' '}
              <b>PARCEIRO DIVULGADOR</b>
              serão feitos dentro dos limites da ética e legalidade, zelando
              pela boa reputação da <b>CONTEXTO REVISOR</b>.
            </p>

            <h1 className="turquoise-text bold">
              CLÁUSULA QUARTA – OBRIGAÇÕES DA <b>CONTEXTO REVISOR</b>
            </h1>

            <p>
              4.1. São obrigações da <b>CONTEXTO REVISOR</b>:
            </p>
            <p>
              4.1.1. Dar assistência e orientação ao <b>PARCEIRO DIVULGADOR</b>{' '}
              para correta execução da parceria aqui estabelecida.
            </p>
            <p>
              4.1.2. Fornecer o código promocional e demais informações
              necessárias.
            </p>
            <p>
              4.1.3. Realizar os repasses dos valores e das comissões, conforme
              definido na Cláusula Segunda;
            </p>
            <p>
              4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, cuidando para a observância
              das obrigações assumidas e visando prevenir erros de expectativas
              e interpretação.
            </p>
            <p>
              4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              do <b>PARCEIRO DIVULGADOR</b> sem expressa autorização em
              documento específico.
            </p>
            <p>
              4.1.6. Identificar, no site, os revisores que se dispõem a ser
              parceiros e conceder 10% (dez por cento) de desconto sobre o valor
              da lauda.
            </p>
            <p>
              4.1.7. Fornecer arquivos com imagens de cartaz e folder/flyer,
              contendo código promocional específico, para impressão de material
              de divulgação por parte do <b>PARCEIRO DIVULGADOR</b>, no objetivo
              de promover a parceria aqui estabelecida, quando necessário.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA QUINTA – OBRIGAÇÕES DO <b>PARCEIRO DIVULGADOR</b>
            </h1>
            <p>
              5.1. São obrigações da <b>PARCEIRO DIVULGADOR</b>:
            </p>
            <p>
              5.1.1. Utilizar seus meios de comunicação para divulgar e
              prospectar clientes para os Revisores Parceiros da plataforma
              “Contexto Revisor”, mediante o código promocional fornecido pela
              <b>CONTEXTO REVISOR</b>.
            </p>
            <p>
              5.1.2. Recolher correta e pontualmente todas as obrigações e
              encargos trabalhistas, previdenciários, fiscais, parafiscais e
              outros que sejam ou venham a ser devidos em razão do presente
              contrato.
            </p>
            <p>
              5.1.3. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, cuidando para a observância
              das obrigações assumidas e visando prevenir erros de expectativas
              e interpretação.
            </p>
            <p>
              5.1.4. Responder integralmente pelos atos que vier a praticar
              pessoalmente ou através de prepostos ou funcionários, caso possua,
              que acarretem prejuízo à <b>CONTEXTO REVISOR</b> ou terceiro.
            </p>
            <p>
              5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              da <b>CONTEXTO REVISOR</b> sem expressa autorização em documento
              específico.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
            </h1>

            <p>
              6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade
              e todos os direitos relativos ao software desenvolvido, como o
              código-fonte, especificações funcionais, fluxogramas, logomarca,
              dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
              REVISOR, podendo somente esta utilizá-los, cedê-los e
              transferi-los a terceiros.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
            </h1>
            <p>
              7.1. O <b>PARCEIRO DIVULGADOR</b> compromete-se a manter o mais
              absoluto sigilo de todas as informações de conteúdo confidencial,
              sejam elas comerciais, técnicas, pessoais e outras, cujo
              conhecimento tiver, em decorrência do exercício de suas
              atividades; além de não exercer qualquer forma de concorrência
              direta com a <b>CONTEXTO REVISOR</b>, podendo ser responsabilizado
              civil ou criminalmente por eventuais danos materiais ou morais
              decorrentes da não obediência a esta regra.
            </p>
            <p>
              7.2. Por definição, “Informações Confidenciais” significam todos
              as informações técnicas e gerais, know-how, documentos, programas
              de computador e documentação, códigos fonte, relatórios, dados
              financeiros ou outros dados, registros, formulários, ferramentas,
              produtos, serviços, pesquisa presente e futura, conhecimento
              técnico, planos de marketing, segredos comerciais e outros
              materiais tangíveis ou intangíveis, armazenados ou não, compilados
              ou reduzidos a termo, seja física, eletrônica ou graficamente, por
              escrito, ou por qualquer meio, que esteja em poder da{' '}
              <b>CONTEXTO REVISOR</b>, e que seja revelado ao{' '}
              <b>PARCEIRO DIVULGADOR</b>.
            </p>
            <p>
              7.3. As Informações Confidenciais incluem, sem limitação,
              materiais, arquivos e informações, bem como as informações de
              propriedade da <b>CONTEXTO REVISOR</b> ou de terceiros que: a)
              contenham a especificação de propriedade exclusiva ou
              confidencial; b) cuja natureza confidencial tenha sido informada
              ao <b>PARCEIRO DIVULGADOR</b>; c) consistam na solução, nas
              inovações e aperfeiçoamentos que sejam de criação e
              desenvolvimento individual ou em conjunto entre as PARTES; e d) em
              virtude de suas características e natureza seriam consideradas
              confidenciais em circunstâncias semelhantes.
            </p>
            <p>
              7.4. O <b>PARCEIRO DIVULGADOR</b> concorda que a violação desta
              Cláusula, pelo uso de qualquer informação confidencial pertencente
              à<b>CONTEXTO REVISOR</b>, sem sua expressa autorização,
              causar-lhe-á danos e prejuízos irreparáveis, não podendo este ser
              inferior ao valor de R$30.000,00 (trinta mil reais).
            </p>
            <p>
              7.5. O <b>PARCEIRO DIVULGADOR</b> concorda que as obrigações de
              Confidencialidade e Sigilo se iniciam a partir da assinatura do
              presente instrumento, perdurando na sua vigência e por 2 (dois)
              anos do término de qualquer que seja o vínculo existente entre as
              Partes. Desta forma e na hipótese de violação, a{' '}
              <b>CONTEXTO REVISOR</b>
              será imediatamente considerada legítima detentora do direito a
              tomar todas as medidas extrajudiciais e judiciais, nas esferas
              cíveis e criminais, inclusive de caráter cautelar ou de
              antecipação de tutela jurisdicional, que julgar cabíveis à defesa
              de seus direitos, inclusive autorais, independentemente da tomada
              das medidas judiciais tendentes a obter a reparação pelo dano
              causado.
            </p>

            <h1 className="turquoise-text bold">
              CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
            </h1>
            <p>
              8.1. Toda e qualquer despesa que o <b>PARCEIRO DIVULGADOR</b>{' '}
              tiver em virtude da execução desta parceria serão de exclusiva
              responsabilidade deste, cabendo apenas à <b>CONTEXTO REVISOR</b>{' '}
              realizar os repasses de valores e comissões, conforme acordado na
              Cláusula Segunda.
            </p>
            <p>
              8.2. Ainda, cada parte responderá, de forma individual e
              exclusiva, pelos tributos e encargos que sejam de sua titularidade
              passiva.
            </p>

            <h1 className="turquoise-text bold">
              CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA
            </h1>
            <p>
              9.1. O presente contrato de parceria comercial tem prazo de
              vigência de 365 (trezentos e sessenta e cinco) dias, renovável por
              iguais períodos caso as Partes assim decidam, a contar da data de
              sua assinatura, sendo passível de rescisão por qualquer das partes
              por meio de notificação expressa no prazo de 30 (trinta) dias.
            </p>
            <p>
              9.2. No caso de rescisão imotivada por qualquer das partes, caso a
              <b>CONTEXTO REVISOR</b> tenha pagamentos pendentes, deverá
              depositá-los em conta de titularidade do{' '}
              <b>PARCEIRO DIVULGADOR</b>, enviando o respectivo comprovante, no
              prazo de 30 (trinta) dias da notificação da rescisão.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA NONA – RESCISÃO MOTIVADA
            </h1>
            <p>
              10.1. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se o <b>PARCEIRO DIVULGADOR</b>:
            </p>
            <p>
              10.1.1. ceder ou transferir o presente contrato, por qualquer
              forma, a terceiros em quaisquer condições, sem o consentimento da
              <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              10.1.2. descumprir qualquer cláusula ou condição deste contrato,
              especialmente no que diz respeito ao estabelecido na Cláusula
              Primeira deste documento;
            </p>
            <p>
              10.2. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se a <b>CONTEXTO REVISOR</b>:
            </p>
            <p>
              10.2.1. descumprir qualquer cláusula ou condição deste contrato
              especialmente no que tange a realizar os pagamentos nas formas,
              prazos e condições pactuadas na Cláusula Segunda deste documento.
            </p>
            <p>
              10.3. Nos casos de rescisão motivada, não será necessária qualquer
              notificação prévia para rescisão do presente contrato, dando-se
              por encerrada a contratação no dia em que a parte justificadamente
              se manifestar.
            </p>

            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
            </h1>
            <p>
              11.1. O descumprimento de qualquer das obrigações contratuais
              acarretará, após prévia notificação ou interpelação, facultada à
              parte inocente, de forma sucessiva:
            </p>
            <p>
              a) A suspensão da execução do presente contrato, o que faculta à
              <b>CONTEXTO REVISOR</b> a retenção de pagamentos e ao{' '}
              <b>PARCEIRO DIVULGADOR</b>a suspensão da captação de clientes e
              dos materiais de divulgação da plataforma “Contexto Revisor”.
            </p>
            <p>
              b) A exigibilidade direta e específica da obrigação descumprida,
              acrescida de multa moratória à razão de 1% (um por cento) sobre o
              total dos valores pagos até a data do inadimplemento, e de juros
              moratórios no montante de 1% (um por cento) ao mês.
            </p>
            <p>c) Em última instância, a resolução do contrato.</p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA SEGUNDA – TOLER NCIA
            </h1>
            <p>
              12.1. A tolerância de uma das partes para com a outra,
              relativamente ao descumprimento de qualquer uma das obrigações
              assumidas neste contrato, será mera liberalidade, não implicando
              novação ou renúncia a qualquer direito. A parte tolerante poderá,
              a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento
              de suas obrigações.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
            </h1>
            <p>
              13.1. O <b>PARCEIRO DIVULGADOR</b> declara ter lido e estar ciente
              e de pleno acordo com todos os termos e condições deste Contrato,
              o qual foi feito em estrita observância aos limites do respectivo
              fim econômico ou social, pela boa-fé e/ou pelos bons costumes,
              levando em consideração inclusive que não estão em situação de
              premente necessidade e têm plenas condições de cumprir todas as
              cláusulas e condições que constituem seus direitos e obrigações
              constantes no presente instrumento.
            </p>
            <p>
              13.2. As disposições deste instrumento somente poderão ser
              alteradas mediante acordo escrito entre as partes, através de
              termos aditivos que farão parte integrante do contrato, ficando,
              desde já, consignado que atos de tolerância não configurarão
              novação contratual.
            </p>
            <p>
              13.3. Este Contrato não implica qualquer tipo de associação ou
              sociedade entre as Partes, ou em qualquer vínculo empregatício
              entre os sócios, diretores, empregados e prepostos de uma Parte
              para com a outra.
            </p>
            <p>
              13.4. As notificações de assuntos referentes a este instrumento
              poderão ser realizadas por correspondência destinada aos endereços
              físicos descritos na qualificação das Partes e eletrônicos, abaixo
              indicados, com a devida confirmação de recebimento:
            </p>
            <p>
              a) E-mail da <b>CONTEXTO REVISOR</b>:
              contato@contextorevisor.com.br
            </p>
            <p>
              b) E-mail do <b>PARCEIRO DIVULGADOR</b>: {user.email}
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA QUARTA – FORO
            </h1>
            <p>
              14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
              Federal, para dirimir quaisquer controvérsias que surgirem do
              presente contrato, renunciando, expressamente, a qualquer outro
              por mais privilegiado que seja.
            </p>
            <div id="eversign" align="center"></div>
            <div className="flex-container">
              <button
                className="hero-btn turquesa w-button"
                hidden={showButton}
                onClick={handleAccept}
              >
                Aceito os termos do contrato
              </button>

              <Link
                className="hero-btn turquesa w-button"
                to={criarContaParceiroDivulgador}
              >
                Voltar e Alterar os Dados
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <Redirect to={parcerias} />
  );
};

const mapStateToProps = ({ userStore }) => {
  const { user, signingURL } = userStore;
  return { user, signingURL };
};

export default connect(mapStateToProps)(PromoterAgreement);
