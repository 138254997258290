import React, { Component } from 'react';
import { Blurb } from '../../../../siteComponents/blurb/index';

class HowItWorksSection extends Component {
  sectionRef = null;

  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollTo && nextProps.scrollTo !== this.props.scrollTo) {
      this.sectionRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  render() {
    return (
      <div className="img-bg section-dark" ref={this.sectionRef}>
        <div className="header">
          <div className="redator-header">Cliente</div>
          <h1 className="section-header">
            6 razões para usar o Contexto Revisor
          </h1>
        </div>
        <div className="process-content">
          <Blurb
            content="1"
            title="Busque por revisor"
            description="Temos centenas de revisores experientes e qualificados aguardando o seu contato."
          />
          <Blurb
            content="2"
            title="A escolha é sua"
            description="Você analisa currículo, preço e prazo e escolhe o revisor certo para o seu texto."
          />
          <Blurb
            content="3"
            title="Trato feito"
            description="Você e o revisor combinam e confirmam prazo de entrega e pagamento, e a revisão pode se iniciar."
          />
          <Blurb
            content="4"
            title="Paga e pega"
            description="O revisor só recebe o $ após a conclusão das etapas ou do trabalho conforme o combinado."
          />
          <Blurb
            content="5"
            title="Pagamento seguro"
            description="Utilizamos o sistema Iugu de transações bancárias on-line, um dos mais confiáveis do País."
          />
          <Blurb
            content="6"
            title="Tudo aqui"
            description="Você busca, contrata, envia, recebe, pega e paga em um só lugar. Contexto Revisor: simples e prático!"
          />
        </div>
      </div>
    );
  }
}

export default HowItWorksSection;
