import React, { Component } from 'react';
import Select from 'react-validation/build/select';
import PropTypes from 'prop-types';
import { required } from './validators';

const validators = {
  required: required
};

class FormSelect extends Component {
  render() {
    let validations = [];

    if (this.props.validators) {
      for (let validator of this.props.validators) {
        validations.push(validators[validator]);
      }
    }

    return (
      <Select {...this.props} validations={validations}>
        {this.props.children}
      </Select>
    );
  }
}

FormSelect.propTypes = {
  validators: PropTypes.array
};

export { FormSelect };
