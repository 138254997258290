import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  showNotification,
  userRecoverPassword
} from '../../../state-management';
import { FormValidator, FormInput } from '../../siteComponents/form';
import './RecoverPassword.css';
import marcaContextoAzul from '../../assetsHolder/images/marca-contexto-azul.svg';

class RecoverComponent extends Component {
  state = {
    email: ''
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.recoverPasswordSuccess) {
      this.props.dispatch(
        showNotification(
          'success',
          'Enviamos o link de redefinição para seu email',
          true
        )
      );
      this.props.history.goBack();
    }

    if (nextProps.recoverPasswordError) {
      this.props.dispatch(
        showNotification(
          'error',
          'ATENÇÃO: Dados inválidos tente novamente.',
          true
        )
      );
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.email) {
      this.props.dispatch(userRecoverPassword(this.state));
    } else {
      this.props.dispatch(
        showNotification('error', 'ATENÇÃO: Informe o email', true)
      );
    }
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    return (
      <div className="recover-container">
        <img src={marcaContextoAzul} alt="Contexto" className="marca" />

        <div className="box">
          <div className="title-padding title-container">
            <div className="turquoise-list">
              <Link className="no-style " to="/login">
                
              </Link>
            </div>
            <div className="title">Esqueci minha senha</div>
          </div>

          <p className="title-padding">
            Informe seu email para receber um link de recuperação de senha.
          </p>

          <FormValidator className="form">
            <FormInput
              type="text"
              placeholder="E-mail"
              className="accont-input w-input"
              value={this.state.email}
              validators={['email']}
              name="email"
              onChange={this.handleChange}
            />

            <button
              type="submit"
              className="button w-button"
              onClick={this.handleSubmit}
            >
              Enviar
            </button>
          </FormValidator>

          <div className="divider" />

          <button
            type="button"
            className="button inverse-btn nomargin button-cancel"
            onClick={this.handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  let { recoverPasswordSuccess, recoverPasswordError } = userStore;
  return { recoverPasswordSuccess, recoverPasswordError };
};

const RecoverPage = connect(mapStateToProps)(RecoverComponent);
export { RecoverPage };
