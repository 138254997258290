import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FormInput,
  FormButton,
  FormValidator,
  FormDate,
  FormSelect
} from '../../../../siteComponents/form';
import { userCreateWriter } from '../../../../../state-management/user/actions';
import { showNotification } from '../../../../../state-management';
import routes from '../../../../../routes';
import { ApiService } from '../../../../../state-management/service/api';

import './WriterRegisterSection.css';
import LoginSocialSection from '../LoginSocialSection/LoginSocialSection';

const { criarConta } = routes;

class WriterRegisterSectionComponent extends Component {
  sectionOne = null;

  state = {
    activeStep: 1,
    form: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      uf_id: '',
      dt_nascimento: '',
      tipo_formacao_id: '',
      terms: false,
      outro_sexo: '',
      code: ''
    }
  };

  timeOut = null;

  constructor(props) {
    super(props);
    this.sectionOne = React.createRef();
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated && !this.loggedHere) {
      this.props.history.replace('/profile');
    }

    if (nextProps.loginSuccess) {
      this.props.dispatch(
        showNotification('success', 'Usuário logado com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.replace(this.state.nextUrl);
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.loginFailed) {
      this.props.dispatch(
        showNotification(
          'error',
          'Acesso não autorizado, confira seu email e senha!',
          true
        )
      );
    }

    if (nextProps.accountCreated) {
      this.props.dispatch(
        showNotification('success', 'Conta criada com sucesso!', true)
      );

      this.timeOut = setTimeout(() => {
        this.props.history.replace('/login');
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.accountError) {
      if (
        nextProps.accountErrorMsg &&
        nextProps.accountErrorMsg.message &&
        nextProps.accountErrorMsg.message ===
          'The email has already been taken.'
      ) {
        this.props.dispatch(
          showNotification(
            'error',
            'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.',
            true
          )
        );
      } else {
        this.props.dispatch(
          showNotification('error', 'Verique os dados e tente novamente', true)
        );
      }
    }
  }

  changeStep = (number, ref) => {
    if (number === 2) {
      if (this.state.form.password !== this.state.form.password_confirmation) {
        this.props.dispatch(
          showNotification(
            'error',
            'ATENÇÃO: As senhas devem ser iguais.',
            true
          )
        );
        return;
      }
      ApiService.existsEmail({ email: this.state.form.email })
        .then(() => {
          this._changeStep(number, ref);
        })
        .catch(e => {
          if (e && e.response && e.response.status) {
            if (e.response.status === 302) {
              this.props.dispatch(
                showNotification(
                  'error',
                  'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.',
                  true
                )
              );
              return;
            }
          }
          throw e;
        });
    } else {
      this._changeStep(number, ref);
    }
  };

  _changeStep = (number, ref) => {
    if (ref && ReactDOM.findDOMNode(ref.current).disabled) {
      return;
    }

    const newState = { ...this.state };
    newState.activeStep = number;
    this.setState(newState);
  };

  handleChangeDate = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  onCheck = e => {
    let { name, checked } = e.target;
    let state = { ...this.state };
    state.form[name] = checked;
    this.setState(state);
  };

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  handleChangeOther = e => {
    const { value } = e.target;
    this.setState({
      form: { ...this.state.form, sexo: 'O', outro_sexo: value }
    });
  };

  handleCode = event => {
    ApiService.verifyCode(this.state.form)
      .then(response => {
        this.props.dispatch(
          showNotification('success', 'Código Válido!', true)
        );
      })
      .catch(err => {
        this.props.dispatch(
          showNotification('error', 'Código Inválido.', true)
        );
        this.setState({
          form: { ...this.state.form, code: '' }
        });
        return;
      });
  };

  handleSubmit = event => {
    let { form } = this.state;
    const { dispatch } = this.props;

    if (!form.sexo) {
      dispatch(showNotification('error', 'Selecione um gênero.', true));
      return;
    }
    if (!form.terms) {
      dispatch(
        showNotification(
          'error',
          'Para efetivar o cadastro,você precisa concordar com os Termos e Condições de Uso e Política de Privacidade do Contexto Revisor.',
          true
        )
      );
      return;
    }

    if (form.sexo !== 'O') {
      form.outro_sexo = '';
    }
    dispatch(userCreateWriter(form));
  };

  render() {
    return (
      <div className="accont-box halfbox">
        <div className="w-form">
          {this.state.activeStep === 1 && (
            <Fragment>
              <FormValidator className="form">
                <div className="redator-step1">
                  <div className="row w-row">
                    <div className="blue-list w-col w-col-1 w-col-small-1 w-col-tiny-1">
                      <Link className="no-style -blue" to={criarConta}>
                        
                      </Link>
                    </div>
                    <div className="blue-list w-col w-col-7 w-col-small-7 w-col-tiny-7">
                      <div>Criar conta de cliente</div>
                    </div>
                    <div className="blue-list w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <ul className="unordered-list w-list-unstyled">
                        <li className="active step-redator">
                          <div>1</div>
                        </li>
                        <li
                          className="step-redator"
                          onClick={() => this.changeStep(2, this.sectionOne)}
                        >
                          <div>2</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    maxLength="256"
                    name="name"
                    placeholder="Nome"
                    id="name"
                    value={this.state.form.name}
                    onChange={this.handleChangeInputText}
                    validators={['required']}
                  />
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    placeholder="E-mail"
                    id="email"
                    value={this.state.form.email}
                    onChange={this.handleChangeInputText}
                    validators={['required', 'email']}
                  />
                  <FormInput
                    type="password"
                    className="accont-input w-input"
                    maxLength="256"
                    name="password"
                    placeholder="Senha"
                    id="password"
                    value={this.state.form.password}
                    onChange={this.handleChangeInputText}
                    validators={['required']}
                  />

                  <FormInput
                    type="password"
                    className="accont-input w-input"
                    maxLength="256"
                    name="password_confirmation"
                    placeholder="Confirmação de senha"
                    id="password"
                    value={this.state.form.password_confirmation}
                    onChange={this.handleChangeInputText}
                    validators={['required']}
                  />

                  <FormButton
                    ref={this.sectionOne}
                    type="button"
                    className="accont-btn--redator button w-button"
                    onClick={() => this.changeStep(2)}
                  >
                    Próximo
                  </FormButton>
                  <div className="separator">
                    <div className="account-stripe" />
                    <div>ou entrar com</div>
                    <div className="account-stripe" />
                  </div>
                </div>
              </FormValidator>
              <LoginSocialSection />
            </Fragment>
          )}

          {this.state.activeStep === 2 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    className="back-button blue-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="redator2-1"
                    onClick={() => this.changeStep(1)}
                  >
                    <div></div>
                  </div>
                  <div className="blue-list w-col w-col-7 w-col-small-7 w-col-tiny-7">
                    <div>Criar conta de cliente</div>
                  </div>
                  <div className="blue-list w-col w-col-4 w-col-small-4 w-col-tiny-4">
                    <ul className="unordered-list w-list-unstyled">
                      <li
                        className="step-redator"
                        onClick={() => this.changeStep(1)}
                      >
                        <div>1</div>
                      </li>
                      <li className="active step-redator">
                        <div>2</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <FormSelect
                  id="estado-2"
                  name="uf_id"
                  className="accont-input w-select"
                  validators={['required']}
                  value={this.state.form.uf_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Estado</option>

                  {this.props.states.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.uf} - {item.descricao}
                      </option>
                    );
                  })}
                </FormSelect>
                <FormSelect
                  id="formacao"
                  name="tipo_formacao_id"
                  className="accont-input w-select"
                  validators={['required']}
                  value={this.state.form.tipo_formacao_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Nível de formação</option>

                  {this.props.trainings.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.formacao}
                      </option>
                    );
                  })}
                </FormSelect>

                <div className="gender">
                  <div className="gender-box">
                    <label>Data de Nascimento</label>
                    <FormDate
                      alwaysShowMask={true}
                      className="w-input accont-input"
                      name="dt_nascimento"
                      minDate={new Date('01/01/1900')}
                      maxDate={new Date()}
                      validators={['date', 'required']}
                      value={this.state.form.dt_nascimento}
                      onChange={this.handleChangeDate}
                    />
                  </div>
                </div>

                <div className="gender">
                  <div className="gender-box">
                    <label>Gênero *</label>
                    <div className="gender-radio w-radio">
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios1"
                          value="M"
                          checked={this.state.form.sexo === 'M'}
                          onChange={this.handleChangeInputText}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Masculino
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios2"
                          value="F"
                          checked={this.state.form.sexo === 'F'}
                          onChange={this.handleChangeInputText}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Feminino
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gender radio-input">
                  <div className="gender-box">
                    <div className="gender-radio w-radio">
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios3"
                          value="O"
                          checked={this.state.form.sexo === 'O'}
                          onChange={this.handleChangeInputText}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios3"
                        >
                          Outro
                        </label>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="accont-input w-input"
                      maxLength="256"
                      name="outro"
                      data-name="outro"
                      placeholder="Outro"
                      id="outro"
                      value={this.state.form.outro_sexo || ''}
                      onChange={this.handleChangeOther}
                    />
                  </div>
                </div>

                <div className="gender-box">
                  <div className="gender-radio w-radio">
                    {/*<p className="paragraph-3">
                        Quero pagar menos! Insira seu código promocional e ganhe
                        desconto de 10% sobre o valor total do trabalho.
                      </p>
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="code"
                        placeholder="Código Promocional"
                        // validators={['required']}
                        onChange={this.handleChangeInputText}
                        onBlur={this.handleCode}
                        value={this.state.form.code}
                />*/}
                    <FormInput
                      type="checkbox"
                      name="terms"
                      id="terms"
                      onClick={this.onCheck}
                      checked={this.state.form.terms ? true : false}
                      className="w-checkbox-input"
                    />
                    <label
                      style={{ marginTop: '5px' }}
                      htmlFor="terms"
                      className="w-form-label label-gray"
                    >
                      <span>Concordo com os </span>
                      <Link to="/termos" target="_blank">
                        Termos e Condições de Uso e Política de Privacidade do
                        Contexto Revisor.
                      </Link>
                    </label>
                  </div>
                </div>

                <FormButton
                  type="button"
                  className="accont-btn--redator button w-button"
                  onClick={this.handleSubmit}
                >
                  Criar conta <strong>cliente</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}
        </div>
      </div>
    );
  }
}

const mapPropsToState = ({ reviewersStore, userStore }) => {
  let { states, trainings } = reviewersStore;
  let { accountCreated, accountError, accountErrorMsg } = userStore;
  let { loginSuccess, loginFailed, authenticated } = userStore;

  return {
    accountCreated,
    accountError,
    states,
    trainings,
    accountErrorMsg,
    loginSuccess,
    loginFailed,
    authenticated
  };
};

const WriterRegisterSection = connect(mapPropsToState)(
  WriterRegisterSectionComponent
);
export default WriterRegisterSection;
