import { string } from 'prop-types';
import React from 'react';
import { FaHandshake } from 'react-icons/fa';
import './ParceiroAvatar.css';

export const ParceiroAvatar = ({ src }) => {
  return (
    <div className="foto-container" style={{ backgroundImage: ` url(${src})` }}>
      <FaHandshake className="icone"></FaHandshake>
      <span className="span-parceiro">
        <strong>Revisor Parceiro:</strong>
        <br />O Revisor parceiro oferece uma queda no preço de{' '}
        <strong>10%</strong> para clientes com cupons de desconto.
      </span>
    </div>
  );
};

ParceiroAvatar.propTypes = {
  src: string
};

export default ParceiroAvatar;
