import React from 'react';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import { LoginSection } from './sections/LoginSection';
import ChooseRegisterProfileSection from './sections/ChooseRegisterProfileSection';
import ReviewerRegisterSection from './sections/ReviewerRegisterSection/index';
import WriterRegisterSection from './sections/WriterRegisterSection';
import { Route, Redirect } from 'react-router-dom';
import PartnerInstituition from './sections/PartnerInstituitionRegisterSection/index';
import routes from '../../../routes';
import Logo from '../../siteComponents/Logo';
import { RepresentativePartnershipRegister } from '../Partnerships/sections/RepresentativePartnership';
import PromoterPartnerSection from './sections/PromoterPartnerSection/index';
import { connect } from 'react-redux';

const {
  criarConta,
  criarContaRevisor,
  criarContaRedator,
  parceiroRepresentante,
  criarContaInstituicaoParceira,
  criarContaParceiroDivulgador
} = routes;
const Login = props => {
  if (props.authenticated) {
    const search = new URLSearchParams(props.location.search);
    const next = search.get('next');
    const id = search.get('id');

    return (
      <Redirect
        to={next || (id ? '/profile-proposta-detalhe/' + id : 'profile')}
      />
    );
  }

  return (
    <div>
      <GeneralMenu />
      <div className="section-dark light-padding">
        <div className="flexcourners large-container">
          <div
            className={
              'halfbox ' +
              (props.location.pathname === criarConta ||
              props.location.pathname === '/login'
                ? 'overlay-login'
                : '') +
              (props.location.pathname === criarContaRevisor
                ? 'overlay-revisor'
                : '') +
              (props.location.pathname === criarContaRedator
                ? 'overlay-redator'
                : '') +
              (props.location.pathname === criarContaInstituicaoParceira
                ? 'overlay-revisor'
                : '') +
              (props.location.pathname === parceiroRepresentante
                ? 'overlay-redator'
                : '') +
              (props.location.pathname === criarContaParceiroDivulgador
                ? 'overlay-revisor'
                : '')
            }
          >
            <Logo />
            <h4 className="overlay-text">
              Plataforma de conexão entre clientes e revisores
            </h4>
          </div>
          <Route exact path={props.match.url} component={LoginSection} />
          <Route path={criarConta} component={ChooseRegisterProfileSection} />
          <Route path={criarContaRedator} component={WriterRegisterSection} />
          <Route path={criarContaRevisor} component={ReviewerRegisterSection} />
          <Route
            path={criarContaInstituicaoParceira}
            component={PartnerInstituition}
          />
          <Route
            path={parceiroRepresentante}
            component={RepresentativePartnershipRegister}
          />
          <Route
            path={criarContaParceiroDivulgador}
            component={PromoterPartnerSection}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});

export default connect(mapStateToProps)(Login);
