import React, { Component } from 'react';
import { connect, useStore } from 'react-redux';
import ProfileHeaderSection from './section/ProfileHeaderSection';
import OrderSection from './section/OrderSection';
import './ProfilePreview.css';

import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ProfilePreviewComponent extends Component {
  state = {
    seeMore: false
  };

  scrollTop = () => {
    document.querySelector('.nav-container').scrollIntoView({
      behavior: 'smooth'
    });
  };

  handleToggleSeeMore = () => {
    this.setState({ seeMore: !this.state.seeMore });
  };

  getAbout() {
    let { reviewer } = this.props;
    let more = (reviewer.attributes && reviewer.attributes.sobre) || '';
    let less = more.substring(0, 100);
    let enableMore = more.length > 100;
    return { less, more, enableMore };
  }

  getTraining(id) {
    const training = this.props.trainings.filter(
      training => training.id === id
    )[0];
    return training ? training.formacao : '';
  }

  getTags(tagSkill) {
    if (tagSkill && typeof tagSkill === 'string') {
      return tagSkill.split(',').map(tag => {
        return { id: tag, text: tag };
      });
    }
    return [];
  }

  maxTextRender = (n, str) => {
    if (str.length > n) return str.substring(0, n) + '...';
    else return str;
  };

  render() {
    if (typeof this.props.user === 'undefined' || !this.props.visible) {
      return null;
    }

    let { statesMap, roles } = this.props;
    let reviewer =
      Object.keys(this.props.previewState).length === 0
        ? this.props.user
        : this.props.previewState;
    if (reviewer) {
      reviewer.qtdRevisions = reviewer.qtd_redacao_revisada;
      reviewer.score = reviewer.rating.toFixed(1);
      reviewer.price = reviewer.page_price_cents || 0;
      reviewer.page_price = reviewer.page_price_cents / 100;
    }

    let experiences =
      reviewer && reviewer.experiences ? reviewer.experiences : [];
    let attachments = reviewer.attachments ? reviewer.attachments : [];
    let curriculum = reviewer.curriculum ? reviewer.curriculum : null;

    // console.log(reviewer)
    // console.log(reviewer.curriculum)
    // console.log(curriculum)

    let newProps = { ...this.props };
    newProps.reviewer = reviewer;
    let about = this.getAbout();
    return (
      <div className="profile-preview">
        <div className="light-padding section-dark">
          <ProfileHeaderSection
            reviewer={reviewer}
            states={statesMap}
            roles={roles}
          />

          <div className="info-boxes">
            <div className="two-thirds">
              <div className="perfil-box load-more">
                <p className="perfil-titles">Sobre</p>
                <p className="turquoise-text">{reviewer.sobre}</p>

                <div style={{ marginTop: 20 }}>
                  <div style={{ marginTop: 20 }}>
                    <p className="perfil-titles">Ocupação</p>
                    <p className="turquoise-text">{reviewer.ocupacao}</p>
                  </div>

                  {/* <div style={{ marginTop: 20 }}>
                    <p className="perfil-titles">Titulação</p>
                    <p className="turquoise-text">
                      {this.getTraining(reviewer.tipo_formacao_id)}
                    </p>
                  </div> */}
                </div>

                {reviewer.video_url && (
                  <iframe
                    type="text/html"
                    width="320"
                    height="180"
                    src={reviewer.video_url}
                    frameBorder="0"
                    title="Video"
                  />
                )}

                {about.enableMore && !this.state.seeMore && (
                  <div style={{ marginTop: 20 }}>
                    <p className="show-more">{about.less}</p>
                  </div>
                )}

                {this.state.seeMore && (
                  <div style={{ marginTop: 20 }}>
                    <p>{about.more}</p>
                  </div>
                )}

                {about.enableMore && (
                  <div className="loadmore">
                    <button
                      className="button small-btn w-button"
                      onClick={this.handleToggleSeeMore}
                    >
                      {this.state.seeMore ? 'VER MENOS' : 'VER MAIS'}
                    </button>
                  </div>
                )}
              </div>

              <div className="perfil-box">
                <p className="perfil-titles">Experiências</p>

                {experiences.length === 0 && (
                  <p className="turquoise-text">Nenhuma experiência</p>
                )}

                {experiences.length > 0 && (
                  <ul className="perfil-list w-list-unstyled">
                    {experiences.map((exp, index) => (
                      <li className="perfil-item" key={index}>
                        <div className="list-style" />
                        <div>
                          <p className="perfil-especification">
                            {this.maxTextRender(50, exp.title)}
                          </p>
                          <p>{this.maxTextRender(200, exp.description)}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="perfil-box">
                <p className="perfil-titles bold">Currículo</p>

                {!curriculum && <p className="turquoise-text">Nenhum anexo</p>}

                {curriculum && (
                  <ul className="perfil-list w-list-unstyled">
                    <li className="perfil-item">
                      <div className="list-style" />
                      <div>
                        <a
                          className="listonly perfil-especification"
                          href={curriculum}
                          target="blank"
                        >
                          Meu currículo
                        </a>
                      </div>
                    </li>
                  </ul>
                )}
              </div>

              <div className="perfil-box">
                <p className="perfil-titles">Anexos</p>

                {attachments.length === 0 && (
                  <p className="turquoise-text">Nenhum anexo</p>
                )}

                {attachments.length > 0 && (
                  <ul className="perfil-list w-list-unstyled">
                    {attachments.map((attch, index) => (
                      <li className="perfil-item" key={index}>
                        <div className="list-style" />
                        <div>
                          <a
                            className="listonly perfil-especification"
                            href={attch.file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.maxTextRender(50, attch.title)}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* skills */}
              <div className="perfil-box">
                <p className="perfil-titles">Skills/Habilidades</p>
                {reviewer.redacao_estudantil === 0 &&
                  reviewer.redacao_concursos === 0 &&
                  reviewer.tcc === 0 &&
                  reviewer.monografia === 0 &&
                  reviewer.dissertacao === 0 &&
                  reviewer.tese === 0 &&
                  reviewer.trabalhos_tecnicos === 0 &&
                  reviewer.livros === 0 &&
                  reviewer.periodicos === 0 &&
                  reviewer.blogs === 0 &&
                  reviewer.sites_aplicativos === 0 &&
                  reviewer.textos_em_imagens === 0 &&
                  reviewer.transcricoes_audio_video === 0 &&
                  reviewer.recursos_concurso === 0 &&
                  reviewer.traducoes === 0 && (
                    <p className="turquoise-text">Nenhuma Skill/Habilidade</p>
                  )}
                <ul className="turquoise-text">
                  <div className="w-row">
                    <div className="w-col w-col-4">
                      {reviewer.redacao_estudantil === 1 && (
                        <li>Redação estudantil</li>
                      )}
                      {reviewer.redacao_concursos === 1 && (
                        <li>Redação para concursos</li>
                      )}
                      {reviewer.tcc === 1 && <li>TCC</li>}
                      {reviewer.monografia === 1 && <li>Monografia</li>}
                      {reviewer.dissertacao === 1 && <li>Dissertação</li>}
                    </div>
                    <div className="w-col w-col-4">
                      {reviewer.tese === 1 && <li>Tese</li>}
                      {reviewer.trabalhos_tecnicos === 1 && (
                        <li>Trabalhos técnicos</li>
                      )}
                      {reviewer.livros === 1 && <li>Livros</li>}
                      {reviewer.periodicos === 1 && <li>Periódicos</li>}
                      {reviewer.blogs === 1 && <li>Blogs</li>}
                    </div>
                    <div className="w-col w-col-4">
                      {reviewer.sites_aplicativos === 1 && (
                        <li>Sites e aplicativos</li>
                      )}
                      {reviewer.textos_em_imagens === 1 && (
                        <li>Textos em imagens</li>
                      )}
                      {reviewer.transcricoes_audio_video === 1 && (
                        <li>Transcrições de áudio/vídeo</li>
                      )}
                      {reviewer.recursos_concurso === 1 && (
                        <li>Recursos para concurso</li>
                      )}
                      {reviewer.traducoes === 1 && <li>Traduções</li>}
                    </div>
                  </div>
                </ul>

                {reviewer.tag_skill && (
                  <label className="w-form-label">
                    <p>Outros</p>
                  </label>
                )}
                <div>
                  <ReactTags
                    tags={this.getTags(reviewer.tag_skill)}
                    delimiters={delimiters}
                    readOnly={true}
                  />
                </div>
              </div>
              {/* skills */}

              {
                // Essa parte, apesar de ter conteúdo no front, não está integrada e não possui
                // função empregada.
                /* <div className="perfil-box load-more">
                <p className="perfil-titles">Avaliações</p>

                <div className="loadmore">
                  <button className="button small-btn w-button">
                    VER MAIS
                  </button>
                </div>
              </div>
 */
              }
              <button
                onClick={this.scrollTop}
                className="button w-button white-btn"
              >
                Voltar ao topo
              </button>
            </div>
            <div className="one-third">
              <div>
                <OrderSection {...newProps} disabled={true} />

                <div className="publicidade">Publicidade</div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-footer">
          <button
            style={{ opacity: 1 }}
            className="button inverse-btn close"
            onClick={this.props.onDismiss}
          >
            Fechar pré-visualização
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reviewersStore, userStore }, props) => {
  let {
    reviewer,
    loading,
    reviewerCreateProposalSuccess,
    statesMap,
    trainings
  } = reviewersStore;

  let user = props.user || userStore.user;

  return {
    user,
    reviewer,
    loading,
    userStore,
    ...userStore,
    reviewerCreateProposalSuccess,
    statesMap,
    trainings
  };
};
const ProfilePreview = connect(mapStateToProps)(ProfilePreviewComponent);
export { ProfilePreview };
