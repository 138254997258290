import React from 'react';
import validator from 'validator';
import moment from 'moment';

export const required = value => {
  if (!value || !value.toString().trim().length) {
    return <div className="error">Campo não pode ser vazio</div>;
  }
};

export const email = value => {
  if (!validator.isEmail(value)) {
    return <div className="error">{`${value} não é um email válido.`}</div>;
  }
};

export const date = (value, props) => {
  let minDate = moment(props.minDate);
  value = value ? value.replace(/_/g, '').replace(/[a-zA-z]/g, '') : '';
  let momentDate = moment(value, props.format || 'DD/MM/YYYY');
  let length = (props.format || 'DD/MM/YYYY').length;

  if (value.length !== length) {
    return;
  }

  let invalid = !momentDate.isValid() || momentDate.isBefore(minDate);

  if (props.maxDate) {
    let maxDate = moment(props.maxDate);
    invalid = invalid || momentDate.isAfter(maxDate);
  }

  if (invalid) {
    return <div className="error">Data inválida</div>;
  }
};

export const max = (value, props) => {
  if (value && value.length && value.length > parseInt(props.maxLength, 10)) {
    return <div className="error">Campo inválido</div>;
  }
};

export const cnpj = value => {
  if (valida_cnpj(value) === false) {
    return <div className="error">CNPJ inválido</div>;
  }
};

export const cpf = value => {
  if (valida_cpf(value) === false) {
    return <div className="error">CPF inválido</div>;
  }
};

export const document = value => {
  if (valida_cpf_cnpj(value) === false) {
    return <div className="error">CPF ou CNPJ inválido</div>;
  }

  function verifica_cpf_cnpj(value) {
    value = value.toString();
    value = value.replace(/[^0-9]/g, '');

    // Verifica CPF
    if (value.length === 11) {
      return 'CPF';
    }

    // Verifica CNPJ
    else if (value.length === 14) {
      return 'CNPJ';
    } else {
      return false;
    }
  }

  /*
 valida_cpf_cnpj

 Valida o CPF ou CNPJ

 @access public
 @return bool true para válido, false para inválido
*/
  function valida_cpf_cnpj(value) {
    // Verifica se é CPF ou CNPJ
    var valida = verifica_cpf_cnpj(value);

    // Garante que o value é uma string
    value = value.toString();

    // Remove caracteres inválidos do value
    value = value.replace(/[^0-9]/g, '');

    // Valida CPF
    if (valida === 'CPF') {
      // Retorna true para cpf válido
      return valida_cpf(value);
    }

    // Valida CNPJ
    else if (valida === 'CNPJ') {
      // Retorna true para CNPJ válido
      return valida_cnpj(value);
    }

    // Não retorna nada
    else {
      return false;
    }
  } // valida_cpf_cnpj
};

/*
 Valida CPF

 Valida se for CPF

 @param  string cpf O CPF com ou sem pontos e traço
 @return bool True para CPF correto - False para CPF incorreto
*/
function valida_cpf(value) {
  if (value) {
    // Garante que o value é uma string
    value = value.toString();

    // Remove caracteres inválidos do value
    value = value.replace(/[^0-9]/g, '');

    // Captura os 9 primeiros dígitos do CPF
    // Ex.: 02546288423 = 025462884
    var digitos = value.substr(0, 9);

    // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
    var novo_cpf = calc_digitos_posicoes(digitos);

    // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
    var final_cpf = calc_digitos_posicoes(novo_cpf, 11);

    // Verifica se o novo CPF gerado é idêntico ao CPF enviado
    if (final_cpf === value) {
      // CPF válido
      return true;
    } else {
      // CPF inválido
      return false;
    }
  }
  return false;
} // valida_cpf
/*
 valida_cnpj

 Valida se for um CNPJ

 @param string cnpj
 @return bool true para CNPJ correto
*/
function valida_cnpj(value) {
  // Garante que o value é uma string
  value = value.toString();

  // Remove caracteres inválidos do value
  value = value.replace(/[^0-9]/g, '');

  // O value original
  var cnpj_original = value;

  // Captura os primeiros 12 números do CNPJ
  var primeiros_numeros_cnpj = value.substr(0, 12);

  // Faz o primeiro cálculo
  var primeiro_calculo = calc_digitos_posicoes(primeiros_numeros_cnpj, 5);

  // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
  var segundo_calculo = calc_digitos_posicoes(primeiro_calculo, 6);

  // Concatena o segundo dígito ao CNPJ
  var cnpj = segundo_calculo;

  // Verifica se o CNPJ gerado é idêntico ao enviado
  if (cnpj === cnpj_original) {
    return true;
  }

  // Retorna falso por padrão
  return false;
} // valida_cnpj

function calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
  // Garante que o value é uma string
  digitos = digitos.toString();

  // Faz a soma dos dígitos com a posição
  // Ex. para 10 posições:
  //   0    2    5    4    6    2    8    8   4
  // x10   x9   x8   x7   x6   x5   x4   x3  x2
  //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
  for (var i = 0; i < digitos.length; i++) {
    // Preenche a soma com o dígito vezes a posição
    soma_digitos = soma_digitos + digitos[i] * posicoes;

    // Subtrai 1 da posição
    posicoes--;

    // Parte específica para CNPJ
    // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
    if (posicoes < 2) {
      // Retorno a posição para 9
      posicoes = 9;
    }
  }

  // Captura o resto da divisão entre soma_digitos dividido por 11
  // Ex.: 196 % 11 = 9
  soma_digitos = soma_digitos % 11;

  // Verifica se soma_digitos é menor que 2
  if (soma_digitos < 2) {
    // soma_digitos agora será zero
    soma_digitos = 0;
  } else {
    // Se for maior que 2, o resultado é 11 menos soma_digitos
    // Ex.: 11 - 9 = 2
    // Nosso dígito procurado é 2
    soma_digitos = 11 - soma_digitos;
  }

  // Concatena mais um dígito aos primeiro nove dígitos
  // Ex.: 025462884 + 2 = 0254628842
  var cpf = digitos + soma_digitos;

  // Retorna
  return cpf;
} // calc_digitos_posicoes
