import React from 'react';
import marcaContextoBranca from './images/marca-contexto-branca.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool } from 'prop-types';

const version = process.env.REACT_APP_BUILD_VERSION || '0.0.1';
const env = process.env.REACT_APP_BUILD_ENV || 'dev';

export const Footer = ({ authenticated }) => (
  <footer className="footer">
    <div className="large-container w-row">
      <div className="w-col w-col-3 w-col-small-3 w-col-tiny-tiny-stack">
        <img src={marcaContextoBranca} alt="marca" className="logo-footer" />
      </div>
      <div className="w-col w-col-3 w-col-small-3 w-col-tiny-tiny-stack">
        {!authenticated && (
          <React.Fragment>
            <Link to="/login" className="footer-link">
              Fazer login
            </Link>
            <Link to="/login/criar-conta-revisor" className="footer-link">
              Criar conta revisor
            </Link>
            <Link to="/login/criar-conta-redator" className="footer-link">
              Criar Conta cliente
            </Link>
          </React.Fragment>
        )}
        <Link to="/termos" className="footer-link last-link">
          Política de Uso de Dados
        </Link>
      </div>
      <div className="w-col w-col-3 w-col-small-3 w-col-tiny-tiny-stack">
        <Link to="/buscar-revisor" className="footer-link">
          Buscar Revisor
        </Link>
        <Link to="/seja-revisor" className="footer-link">
          Seja Revisor
        </Link>
        {/*<Link to="/parcerias" className="footer-link">
          Seja Parceiro
        </Link>
        */}
        <Link to="/sobre" className="footer-link">
          Sobre Nós
        </Link>
        <Link to="/contato" className="footer-link">
          Contato
        </Link>
      </div>
      <div className="w-col w-col-3 w-col-small-3 w-col-tiny-tiny-stack">
        <div className="footer-text">© 2018 Contexto</div>
        <div className="footer-text">Parceiros desenvolvedores</div>
        <div className="footer-text">Devsquad e Xpbox</div>
        <div className="footer-text">
          versão {version}-{env}
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  authenticated: bool
};

Footer.defaultProps = {
  authenticated: false
};

const mapStateToProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});

export default connect(mapStateToProps)(Footer);
