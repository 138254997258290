import React, { Component } from 'react';
import PropTypes, { bool, func, string } from 'prop-types';
import modalAtencao from '../../assetsHolder/images/ic-dialog-atencao.png';
import './Modal.css';

export class Modal extends Component {
  state = {
    visible: false
  };
  containerRef = null;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  handleDismiss = () => {
    let { onDismiss } = this.props;
    onDismiss && onDismiss();
  };

  handleOk = () => {
    let { onOkClick } = this.props;
    this.handleDismiss();
    onOkClick && onOkClick();
  };

  handleDanger = () => {
    let { onDangerClick } = this.props;
    onDangerClick();
    this.handleDismiss();
  };

  renderContent() {
    let { message, okText, onlyOkButton, dangerText } = this.props;

    if (this.props.children) {
      return this.props.children;
    }

    return (
      <div className="box" ref={this.containerRef}>
        <img className="modal-icon" src={modalAtencao} alt="Atenção" />
        <h5 className="modal-title turquoise-text">
          {this.props.title || 'ATENÇÃO'}
        </h5>
        <div className="divider" />

        <div className="modal-text">{message}</div>

        {!onlyOkButton && (
          <div className="flex-row space-between">
            <button className="button inverse-btn" onClick={this.handleDismiss}>
              Cancelar
            </button>
            {okText && (
              <button
                className="button turquoise w-button"
                onClick={this.handleOk}
              >
                {okText}
              </button>
            )}
            {dangerText && (
              <div className="flex-row center">
                <button
                  className="button w-button btn-danger"
                  onClick={this.handleDanger}
                >
                  {dangerText}
                </button>
              </div>
            )}
          </div>
        )}
        {onlyOkButton && (
          <div className="flex-row" style={{ alignItems: 'center' }}>
            <button
              className="button turquoise w-button"
              onClick={this.handleOk}
            >
              {okText}
            </button>
          </div>
        )}
      </div>
    );
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <div className="modal-container" onClick={this.handleDismiss}>
        <div
          className="box"
          ref={this.containerRef}
          onClick={event => event.stopPropagation()}
        >
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  visible: bool.isRequired,
  onlyOkButton: bool,
  title: string,
  message: string,
  okText: string,
  onDismiss: func,
  onOkClick: func,
  onDangerClick: func
};

export default Modal;
