import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Logo from '../../siteComponents/Logo';
import routes from '../../../routes';
import { logoff } from '../../../state-management/user/actions';
import './About.css';

const { sobre } = routes;

class AboutComponent extends Component {
  render() {
    return (
      <div className="about">
        <GeneralMenu selected={sobre} {...this.props} />
        <div className="section-dark light-padding">
          <div className="flexcourners large-container">
            <div className="halfbox overlay-login">
              <Logo />
              <h4 className="overlay-text">
                Plataforma de conexão entre clientes <br />e revisores.
              </h4>
            </div>

            <div className="halfbox login-box" style={{ padding: '3%' }}>
              <div className="w-form">
                <h1 className="turquoise-text bold">Somos</h1>

                <p>
                  ... um time de revisores que resolveu virar o jogo e colocar o
                  revisor no centro do negócio de revisão de textos.
                </p>
                <p>
                  … um grupo de professores, especialistas, mestres, doutores e
                  desenvolvedores que se uniu para pensar, planejar e executar
                  uma nova forma de munir o revisor de textos com todo o
                  instrumental para realizar o seu trabalho a distância.
                </p>
                <p>
                  … uma equipe de profissionais que acredita no empreendedorismo
                  e em soluções criativas para os antigos e novos problemas da
                  construção e da correção de textos.
                </p>
                <p>
                  Enfim, somos um grupo de cidadãos que quer contribuir para a
                  melhoria do nível de leitura, de escrita e de cultura da
                  população brasileira.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});

const About = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutComponent);
export { About };
