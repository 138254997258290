import React from 'react';
import MainMenu from '../../../../siteComponents/MainMenu';
import Jumbotron from '../../../../siteComponents/Jumbotron';
import { Box } from '../../../../siteComponents/Box';
import routes from '../../../../../routes';
import './MainSection.css';

const { buscarRevisor, criarContaRevisor, profile } = routes;

const clienteTitle = () => {
  return (
    <div>
      O revisor ideal para seu <br />
      texto você encontra aqui
    </div>
  );
};

const revisorTitle = () => {
  return (
    <div>
      Seja Revisor-Empreendedor: <br />
      um ambiente feito para você
    </div>
  );
};

const MainSection = props => (
  <div className="hero">
    <div className="menu-container">
      <div
        data-collapse="small"
        data-animation="over-left"
        data-duration="400"
        className="hero-navbar w-nav"
      >
        <MainMenu {...props} />
      </div>
    </div>
    <Jumbotron />
    <div className="hero-container">
      <Box
        className="box-flex"
        superTitle="Cliente"
        title={clienteTitle()}
        content="Se você é estudante, redator ou produtor de conteúdo, o Contexto Revisor permite sua escolha
        com base em critérios objetivos, como currículo, avaliação do profissional e valor médio cobrado. A busca é
          simples e rápida."
        linkText={
          <span>
            Quero escolher o <strong>revisor!</strong>
          </span>
        }
        to={buscarRevisor}
        footer="Saiba mais"
        onFooterClick={props.onFooterClienteClick}
        boxType="redator"
      />
      <Box
        className="box-flex"
        superTitle="Revisor"
        title={revisorTitle()}
        content="Trabalhe de imediato em seu próprio ambiente virtual, com endereço exclusivo para divulgar aqui e nas redes sociais,
        trocar textos e documentos com seus clientes e receber deles por sistema seguro de pagamento on-line.
"
        linkText={
          <span>
            Quero começar <strong>agora!</strong>
          </span>
        }
        to={props.user ? profile : criarContaRevisor}
        footer="Saiba mais"
        onFooterClick={props.onFooterRevisorClick}
        boxType="revisor"
      />
    </div>
  </div>
);

export default MainSection;
