import React, { Component } from 'react';
import { FormInput } from '../../../siteComponents/form';
import { connect } from 'react-redux';

import { WithContext as ReactTags } from 'react-tag-input';
import { userPreviewState } from '../../../../state-management';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class SkillsComponent extends Component {
  state = {
    formInvalid: true,
    formData: {
      title: '',
      file: ''
    },
    user: {
      tag_skill: ''
    },
    tags: []
  };

  fileRef = null;

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    if (props.value) {
      this.state.user = props;
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  handleDelete(index) {
    const tag_skill = this.removeTags(this.state.user.tag_skill, index);
    const state = { ...this.state };
    state.user.tag_skill = tag_skill;
    state.tags = state.tags.filter((tag, i) => i !== index);
    this.setState(state);
  }

  handleAddition(tag) {
    const state = { ...this.state };
    state.tags.push({ id: tag, text: tag });
    this.setState(state => ({ tags: [...state.tags] }));
    let tag_skill = state.tags.map(element => element.text);
    state.user.tag_skill = tag_skill.toString();
    this.setState(state);
    this.props.userPreviewState({
      ...this.props.value,
      tag_skill: this.state.user.tag_skill
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      const state = { ...this.state };
      state.tags = this.getRequestTags(nextProps.value.tag_skill);
      state.user = nextProps.value;
      this.setState(state);
    }
  }

  getRequestTags(tagSkill) {
    if (tagSkill && typeof tagSkill === 'string') {
      return tagSkill.split(',').map(tag => {
        return { id: tag, text: tag };
      });
    } else {
      return [];
    }
  }

  removeTags(tagSkill, index) {
    if (
      tagSkill &&
      typeof tagSkill === 'string' &&
      tagSkill.indexOf(',') > -1
    ) {
      return tagSkill
        .split(',')
        .filter((tag, i) => i !== index)
        .reduce((a, b) => a + ',' + b);
    }
    return '';
  }

  onCheck = event => {
    this.props.onChange({
      name: event.target.name,
      value: event.target.checked ? 1 : 0
    });
    this.props.userPreviewState({ ...this.props.value });
  };

  render() {
    return (
      <div className="">
        <h5 className="config-subtitle">Habilidades</h5>

        <div className="w-row">
          <div className="w-col w-col-4">
            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="redacao_estudantil"
                id="redacao_estudantil"
                onClick={this.onCheck}
                checked={this.state.user.redacao_estudantil ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="redacao_estudantil"
                className="w-form-label"
              >
                Redação estudantil
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="redacao_concursos"
                id="redacao_concursos"
                onClick={this.onCheck}
                checked={this.state.user.redacao_concursos ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="redacao_concursos"
                className="w-form-label"
              >
                Redação para concursos
              </label>
            </div>

            <div className="gender-radio w-radio">
              <FormInput
                type="checkbox"
                name="tcc"
                id="tcc"
                onClick={this.onCheck}
                checked={this.state.user.tcc ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="tcc"
                className="w-form-label"
              >
                TCC
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="monografia"
                id="monografia"
                onClick={this.onCheck}
                checked={this.state.user.monografia ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="monografia"
                className="w-form-label"
              >
                Monografia
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="dissertacao"
                id="dissertacao"
                onClick={this.onCheck}
                checked={this.state.user.dissertacao ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="dissertacao"
                className="w-form-label"
              >
                Dissertação
              </label>
            </div>
          </div>

          <div className="w-col w-col-4">
            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="tese"
                id="tese"
                onClick={this.onCheck}
                checked={this.state.user.tese ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="tese"
                className="w-form-label"
              >
                Tese
              </label>
            </div>

            <div className="gender-radio w-radio">
              <FormInput
                type="checkbox"
                name="trabalhos_tecnicos"
                id="trabalhos_tecnicos"
                onClick={this.onCheck}
                checked={this.state.user.trabalhos_tecnicos ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="trabalhos_tecnicos"
                className="w-form-label"
              >
                Trabalhos técnicos
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="livros"
                id="livros"
                onClick={this.onCheck}
                checked={this.state.user.livros ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="livros"
                className="w-form-label"
              >
                Livros
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="periodicos"
                id="periodicos"
                onClick={this.onCheck}
                checked={this.state.user.periodicos ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="periodicos"
                className="w-form-label"
              >
                Periódicos
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="blogs"
                id="blogs"
                onClick={this.onCheck}
                checked={this.state.user.blogs ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="blogs"
                className="w-form-label"
              >
                Blogs
              </label>
            </div>
          </div>

          <div className="w-col w-col-4">
            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="sites_aplicativos"
                id="sites_aplicativos"
                onClick={this.onCheck}
                checked={this.state.user.sites_aplicativos ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="sites_aplicativos"
                className="w-form-label"
              >
                Sites e aplicativos
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="textos_em_imagens"
                id="textos_em_imagens"
                onClick={this.onCheck}
                checked={this.state.user.textos_em_imagens ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="textos_em_imagens"
                className="w-form-label"
              >
                Textos em imagens
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="transcricoes_audio_video"
                id="transcricoes_audio_video"
                onClick={this.onCheck}
                checked={
                  this.state.user.transcricoes_audio_video ? true : false
                }
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="transcricoes_audio_video"
                className="w-form-label"
              >
                Transcrições de áudio/vídeo
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="recursos_concurso"
                id="recursos_concurso"
                onClick={this.onCheck}
                checked={this.state.user.recursos_concurso ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="recursos_concurso"
                className="w-form-label"
              >
                Recursos para concurso
              </label>
            </div>

            <div className="gender-radio w-checkbox">
              <FormInput
                type="checkbox"
                name="traducoes"
                id="traducoes"
                onClick={this.onCheck}
                checked={this.state.user.traducoes ? true : false}
                className="w-checkbox-input"
              />
              <label
                style={{ marginTop: '5px' }}
                htmlFor="traducoes"
                className="w-form-label"
              >
                Traduções
              </label>
            </div>
          </div>
        </div>

        <label className="w-form-label">
          <p>Outros</p>
        </label>
        <div>
          <ReactTags
            tags={this.state.tags}
            delimiters={delimiters}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            placeholder="Adicione uma habilidade"
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userPreviewState: data => dispatch(userPreviewState(data))
});

const mapStateToProps = ({ userStore }) => {
  let { previewState } = userStore;
  return previewState;
};

const Skills = connect(mapStateToProps, mapDispatchToProps)(SkillsComponent);

export { Skills };
