import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from '../../../../../routes';
import './styles.css';
import image from '../../../../assetsHolder/images/img-reviewer-preview.png';

const { criarContaRedator, profile } = routes;

class FindReviewerSectionComponent extends Component {
  render() {
    let props = this.props;
    console.log(props);

    return (
      <div>
        <div className="header">
          <div className="redator-header">Cliente</div>
          <h1 className="section-header">
            Os melhores revisores num só lugar.
            <br />A escolha é sua!
          </h1>
        </div>
        <div className="container">
          <div className="halfbox right-space">
            <p className="paragraph-2">
              Procure seu revisor ideal filtrando por critérios objetivos e
              analisando currículos e perfis.
              <br />
              E, de imediato, pode escolhê-lo e submeter seu trabalho à revisão.
            </p>
            <div className="blue-text">Ganhe em tempo, preço e qualidade.</div>
            <div className="blue-text">Ganhe com o Contexto Revisor.</div>
            {/* <div className="giant-text">+1000 revisores</div> */}
            {!props.user ? (
              <button
                className="button w-button"
                onClick={() => props.history.push(criarContaRedator)}
              >
                Quero criar<strong> minha conta</strong>
              </button>
            ) : (
              ''
            )}
          </div>
          <div
            style={{ display: 'block', alignSelf: 'center' }}
            className="halfbox left-space"
          >
            <img src={image} />
          </div>
        </div>
      </div>
    );
  }
}

const mapsStateToProps = ({ reviewersStore }) => {
  return {
    reviewers: reviewersStore.randomReviewers
  };
};

const FindReviewerSection = connect(mapsStateToProps)(
  FindReviewerSectionComponent
);
export default FindReviewerSection;
