import React from 'react';
import StarEvaluation from '../StarEvaluation';
import Range from '../Range';
import OrderByReviewer from '../OrderByReviewer';
import { func, number, string } from 'prop-types';

export class SearchBar extends React.Component {
  state = {
    showSearchOptions: true
  };

  onOptionsClicked = () => {
    this.setState({ showSearchOptions: !this.state.showSearchOptions });
  };

  handleChange = e => this.props.changeSearchQuery(e.target.value);

  handleApply = event => {
    event.preventDefault();

    const {
      searchQuery,
      orderType,
      rating,
      pagePrice,
      numberRevisions
    } = this.props;

    this.props.applyFilter({
      searchQuery,
      orderType,
      rating,
      pagePrice,
      numberRevisions,
      page: 1
    });
  };

  handleClear = () => {
    this.props.clearQueryFilter();
  };

  render() {
    return (
      <div className="filter-form w-form">
        <form onSubmit={this.handleApply}>
          <div>
            <div className="filter-top">
              <input
                type="text"
                className="filter-input w-input"
                maxLength="256"
                name="filtro-nome"
                data-name="filtro-nome"
                placeholder="Digite sua palavra chave"
                id="filtro-nome"
                value={this.props.searchQuery}
                onChange={this.handleChange}
              />
              {this.props.searchQuery && (
                <button
                  className="filter-btn close-btn w-button"
                  type="button"
                  onClick={this.handleClear}
                >
                  x
                </button>
              )}
              <button
                className="filter-btn w-button"
                onClick={this.handleApply}
              />
              <button
                onClick={this.onOptionsClicked}
                type="button"
                className="open-filter w-button"
              />
            </div>
            {this.state.showSearchOptions && (
              <ul className="filter-dropdown-app w-list-unstyled">
                <li className="filter-item">
                  <StarEvaluation />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <Range
                    title="Valor da lauda"
                    infText="R$5"
                    infValue={5}
                    supText="R$100"
                    supValue={100}
                    value={this.props.pagePrice}
                    change={this.props.changePagePrice}
                  />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <Range
                    title="Nº de Revisões"
                    infText="0"
                    infValue={0}
                    supText="300"
                    supValue={300}
                    value={this.props.numberRevisions}
                    change={this.props.changeNumberRevisions}
                  />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <OrderByReviewer />
                </li>
                <li className="filter-sep" />
                <li className="filter-item last-item apply-btn">
                  <button
                    className="button w-button filter-button"
                    type="submit"
                  >
                    APLICAR
                  </button>
                  <button
                    className="button w-button filter-button"
                    onClick={this.handleClear}
                  >
                    LIMPAR
                  </button>
                </li>
              </ul>
            )}
          </div>
        </form>
      </div>
    );
  }
}

SearchBar.propTypes = {
  searchQuery: string,
  orderType: string,
  rating: number,
  pagePrice: number,
  numberRevisions: number,
  changeSearchQuery: func,
  applyFilters: func,
  changePagePrice: func,
  changeNumberRevisions: func
};

export default SearchBar;
