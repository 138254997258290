import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Sidebar from '../MainMenu/SidebarMenu';
import marcaContexto from '../../assetsHolder/images/logo-horizontal-blue.png';
import { Link } from 'react-router-dom';
import ProfileMenu from '../ProfileMenu';
import routes from '../../../routes';
import { ApiService } from '../../../state-management/service';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { bool, func, object } from 'prop-types';

const {
  login,
  buscarRevisor,
  sejaRevisor,
  contato,
  sobre,
  profile,
  parcerias,
  painel,
  painelClients
} = routes;

export const GeneralMenu = ({
  authenticated,
  logoff,
  selected,
  history,
  onProfileMenuClick
}) => {
  const [show, setShow] = useState(false);

  let viewer = ApiService.getUser()?.user;
  const [isAdmin] = useState(
    viewer?.role && viewer.role.map(role => role.name)?.indexOf('admin') != -1
  );

  function handleState() {
    setShow(!show);
  }

  return (
    <div
      data-collapse="medium"
      data-animation="over-left"
      data-duration="400"
      className="navbar w-nav"
    >
      <div className="nav-container w-clearfix">
        <nav className="nav-menu w-nav-menu">
          {authenticated && isAdmin && (
            <DropdownButton
              className="hero-navlink w-nav-link"
              id="dropdown-painel-admin"
              title="Painel de Admin"
            >
              <Dropdown.Item href={painel}>Visualizar Revisores</Dropdown.Item>
              <Dropdown.Item href={painelClients}>
                Visualizar Clientes
              </Dropdown.Item>
            </DropdownButton>
          )}

          <Link to="/" className="navlink w-nav-link">
            Home
          </Link>

          <Link
            to={buscarRevisor}
            className={
              'navlink w-nav-link ' +
              (buscarRevisor === selected ? 'w--current' : '')
            }
          >
            Buscar Revisor
          </Link>

          {!authenticated && (
            <Link
              to={sejaRevisor}
              className={
                'navlink w-nav-link ' +
                (sejaRevisor === selected ? 'w--current' : '')
              }
            >
              Seja Revisor
            </Link>
          )}

          {/*<Link
            to={parcerias}
            className={
              'navlink w-nav-link ' +
              (parcerias === selected ? 'w--current' : '')
            }
          >
            Seja Parceiro
          </Link>
          */}
          <Link
            to={sobre}
            className={
              'navlink w-nav-link ' + (sobre === selected ? 'w--current' : '')
            }
          >
            Sobre Nós
          </Link>

          <Link
            to={contato}
            className={
              'navlink w-nav-link ' + (contato === selected ? 'w--current' : '')
            }
          >
            Contato
          </Link>

          {authenticated ? (
            <ProfileMenu
              history={history}
              logoff={logoff}
              profileRoute={profile}
              onProfileMenuClick={onProfileMenuClick}
            />
          ) : (
            <Link to={login} className="blue hero-navbtn w-nav-link">
              Entrar
            </Link>
          )}
        </nav>
        <Link to="/" className="brand w-nav-brand">
          <img src={marcaContexto} alt="marca" />
        </Link>
        <div className="menu-mobile w-nav-button">
          <div onClick={handleState} className="w-icon-nav-menu" />
          {ReactDOM.createPortal(<Sidebar show={show} />, document.body)}
        </div>
      </div>
    </div>
  );
};

GeneralMenu.propTypes = {
  authenticated: bool,
  logoff: func,
  selected: bool,
  history: object,
  onProfileMenuClick: func
};

export default GeneralMenu;
