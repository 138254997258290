import React, { Component } from 'react';
import { connect } from 'react-redux';

import avatar1 from '../../assetsHolder/images/avatar-1.svg';
import avatar2 from '../../assetsHolder/images/avatar-2.svg';
import './ProfileMenu.css';

export class ProfileMenuComponent extends Component {
  state = {
    visible: false
  };
  containerRef = null;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  toggleMenu = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleOutsideClick = event => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.setState({ visible: !this.state.visible });
    }
  };

  handleLogout = () => {
    this.setState({ visible: false });
    this.props.history.replace('/');
    this.props.logoff();
    window.location.reload();
  };

  handleProfile = () => {
    this.setState({ visible: false });

    if (this.props.onProfileMenuClick) {
      this.props.onProfileMenuClick('dados');
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    this.props.history !== undefined
      ? this.props.history.push(this.props.profileRoute)
      : '';
  };

  handleProfileScreen = screen => {
    this.setState({ visible: false });

    if (this.props.onProfileMenuClick) {
      this.props.onProfileMenuClick(screen);
      return;
    }
    this.props.history !== undefined
      ? this.props.history.push('/profile', { screen })
      : (window.location = '/profile');
    //window.location.reload(); //Linha somente para evitar
  };

  renderMenu() {
    let { roles, user } = this.props;
    let socialUser = false;
    if (user) {
      if (user.fb_id !== null) {
        socialUser = true;
      }
      if (user.google_id !== null) {
        socialUser = true;
      }
    }

    return (
      this.state.visible && (
        <div className="hero-box" ref={this.containerRef}>
          {((roles && roles.isRevisor) ||
            (roles && roles.isParceiro) ||
            (roles && roles.isRedator)) && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'propostas')}
              className="item"
            >
              Propostas e Contrapropostas
            </div>
          )}
          {roles && roles.isRedator && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'compras')}
              className="item"
            >
              Meus pedidos
            </div>
          )}

          {((roles && roles.isRevisor) || (roles && roles.isParceiro)) && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'revisoes')}
              className="item"
            >
              Minhas revisões
            </div>
          )}

          {((roles && roles.isRevisor) || (roles && roles.isParceiro)) && (
            <div>
              <div
                onClick={this.handleProfileScreen.bind(this, 'configuracao')}
                className="item"
              >
                Meu perfil (minissite)
              </div>
            </div>
          )}

          <div onClick={this.handleProfile} className="item">
            Dados conta Contexto
          </div>

          {((roles && roles.isInstituicao) ||
            (roles && roles.isRepresentante) ||
            (roles && roles.isDivulgador)) && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'promocional')}
              className="item"
            >
              Código Promocional
            </div>
          )}

          {!(roles && roles.isRedator) && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'financeiro')}
              className="item"
            >
              Dados financeiros
            </div>
          )}

          {!socialUser && (
            <div
              onClick={this.handleProfileScreen.bind(this, 'alterarSenha')}
              className="item"
            >
              Alterar Senha
            </div>
          )}

          <div onClick={this.handleLogout} className="item">
            Sair
          </div>
        </div>
      )
    );
  }

  render() {
    let { user } = this.props;

    return (
      <span className="profile-menu">
        <img
          className="avatar"
          src={
            (user && user.imagem) ||
            (user && user.sexo === 'F' ? avatar1 : avatar2)
          }
          alt="Avatar"
          onClick={this.toggleMenu}
        />
        {this.renderMenu()}
      </span>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  return { ...userStore };
};

const ProfileMenu = connect(mapStateToProps)(ProfileMenuComponent);
export default ProfileMenu;
