import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loading from '../../assetsHolder/images/loading.svg';
import './Loading.css';

export class Loading extends Component {
  render() {
    if (!this.props.visible) {
      return null;
    }

    return (
      <div className="loading-container">
        <img src={loading} alt="Loading" />
        <div>Carregando... Aguarde!</div>
      </div>
    );
  }
}

Loading.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default Loading;
