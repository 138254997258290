import { SHOW_NOTIFICATION } from './actions';

const initialState = {
  notificationType: '',
  notificationMsg: ''
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      //DO NOT REMOVE RANDOM, FORCE UPDATE WHEN IS THE SAME MSG
      return {
        ...action,
        random: Math.random()
      };

    default:
      return state;
  }
};

export default notifications;
