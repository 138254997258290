import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import { logoff } from '../../../state-management/user/actions';
import './Terms.css';

class TermsComponent extends Component {
  renderNomenclaturas() {
    return (
      <p>
        <span className="italic bold">Login</span>: É o processo que permite o
        acesso a um sistema informático, controlado por meio de identificação e
        autenticação do usuário pelas credenciais fornecidas por esse mesmo
        internauta.
        <br />
        <span className="italic">On-line</span>: Termo da língua inglesa cujo
        significado literal é “em linha”. É habitualmente usado para designar
        que um determinado usuário da internet ou de outra rede de computadores
        está conectado à rede.
        <br />
        <span className="italic">Usuário Revisor</span>: Revisor que prestará o
        serviço de revisão on-line de redações e textos em geral.
        <br />
        <span className="italic">Usuário Revisado/Cliente</span>
        : Qualquer pessoa que tenha acesso ao site/plataforma/aplicativo com
        intuito de obter a revisão on-line de redações e textos em geral.
        <br />
        <span className="italic">Vírus</span>: Software malicioso que infecta
        sistemas e computadores, com objetivo de causar algum dano, faz cópias
        de si mesmo e tenta se espalhar para outros computadores, utilizando-se
        de diversos meios.
      </p>
    );
  }

  render() {
    let href = window.location.origin;

    return (
      <div className="about">
        <GeneralMenu {...this.props} />
        <div className="section-dark light-padding">
          <div className="flexcourners large-container">
            <div className="terms-container" style={{ padding: '3%' }}>
              <div className="w-form">
                <h1 className="turquoise-text bold">
                  PARA VOCÊ ENTENDER MELHOR COMO FUNCIONA ESTA PLATAFORMA
                </h1>

                <p>
                  Fizemos o máximo para reduzir e simplificar as regras da nossa
                  Plataforma. Por isso, separamos abaixo os pontos mais
                  importantes para você, que também podem ser lidos de forma bem
                  completa e detalhada nos Termos de Uso a seguir​.
                </p>
                <p>
                  Além disso, estamos sempre disponíveis para tirar qualquer
                  dúvida que você tenha pelo “Chat On-line” disponível na
                  plataforma, pelo telefone/WhatsApp 55 61 99949-4321, de
                  segunda a sexta, das 9h às 19h, exceto feriados, e pelo e-mail
                  para o endereço eletrônico ​contato@contextorevisor.com.br​
                </p>
                <span>
                  <p className="sub-item">
                    <strong>
                      1) Nosso negócio é oferecer um ambiente de marketplace que
                      objetiva facilitar o contato entre o Usuário
                      Revisado/Cliente e o Usuário Revisor, oferecendo um
                      domínio específico para que o Usuário Revisor possa
                      prospectar clientes por meio de divulgação direta e
                      independente, e também disponibilizando hospedagem,
                      suporte técnico, ferramentas de revisão, e sistema de
                      pagamento/recebimento de valores on-line.
                    </strong>
                  </p>
                  <p className="sub-item">
                    <strong>2) </strong>Os Usuários Revisores devem pagar
                    valores para utilizar os serviços da{' '}
                    <strong>CONTEXTO REVISOR</strong>​. Esses valores estarão
                    sempre expostos antes de você fazer a contratação. Além
                    disso, os Usuários Clientes devem pagar pelos serviços dos
                    Usuários Revisores contratados por meio da plataforma.
                  </p>
                  <p className="sub-item">
                    <strong>3) </strong>Nos esforçamos bastante para que a nossa
                    plataforma seja segura, mas ainda assim recomendamos que,
                    antes de baixar qualquer conteúdo, você instale antivírus e
                    programas de proteção.
                  </p>
                  <p className="sub-item">
                    <strong>4) </strong>Nossos Termos de Uso poderão mudar, mas
                    você sempre poderá acessar a versão mais atualizada na nossa
                    plataforma. Além disso, se formos realizar alguma ação que a
                    lei exija sua autorização, você receberá um aviso antes para
                    poder aceitar ou recusar.
                  </p>
                  <p className="sub-item">
                    <strong>5) </strong>Temos uma Política de Privacidade que
                    trata sobre o que fazemos com os seus dados pessoais. É
                    muito importante que você leia e entenda esse documento
                    também!
                  </p>
                  <p className="sub-item">
                    <strong>6) </strong>Os Termos de Uso a seguir estão
                    divididos da seguinte forma para facilitar o seu acesso à
                    informação:
                  </p>
                  <p className="sub-item2">
                    a) Data de Disponibilização do Texto;
                  </p>
                  <p className="sub-item2">
                    b) Explicação dos Termos Técnicos ou em Língua Estrangeira;
                  </p>
                  <p className="sub-item2">c) Serviços;</p>
                  <p className="sub-item2">d) Cadastro dos Usuários;</p>
                  <p className="sub-item2">
                    e) Pagamento pelo Uso da Plataforma;
                  </p>
                  <p className="sub-item2">f) Responsabilidades das Partes;</p>
                  <p className="sub-item2">
                    g) Isenção de Responsabilidade da CONTEXTO REVISOR;
                  </p>
                  <p className="sub-item2">
                    h) Regras de Conduta e Proibições;
                  </p>
                  <p className="sub-item2">i) Avaliações dos Usuários;</p>
                  <p className="sub-item2">j) Propriedade Intelectual;</p>
                  <p className="sub-item2">
                    k) Tratamento de Dados Pessoais, Privacidade e Segurança;
                  </p>
                  <p className="sub-item2">
                    l) Desdobramentos do acesso à Plataforma;
                  </p>
                  <p className="sub-item2">
                    m) Alterações dos Termos e Condições de Uso;
                  </p>
                  <p className="sub-item2">
                    n) Definição da Lei Aplicável e do Foro de eleição;
                  </p>
                  <p className="sub-item2">o) Canal de Contato;</p>
                </span>

                <h1 className="turquoise-text bold">TERMOS DE USO</h1>

                <p>
                  Esta plataforma, cujo nome é{' '}
                  <strong>
                    <strong>CONTEXTO REVISOR</strong>
                  </strong>
                  , é de propriedade, mantida e controlada por{' '}
                  <strong>PRECEPTOR EDUCAÇÃO A DISTÂNCIA LTDA.ME</strong>,
                  inscrita no CNPJ sob o nº 07.633.844/0001-19, com sede na Rua
                  Cel. Alves Teixeira, 1290, sala 5, bairro Dionísio Torres, CEP
                  60135-208, na cidade de Fortaleza, Estado do Ceará
                </p>

                <p>
                  Este documento visa prestar informações sobre o modo de
                  utilização da plataforma pelos usuários e suas ferramentas,
                  condições, nomenclaturas, direitos e deveres, além de
                  preservar a privacidade dos usuários. Alertamos que todo o
                  texto deve ser lido com atenção e, caso você não concorde com
                  o conteúdo de nossos termos e/ou política de privacidade, não
                  dê prosseguimento à navegação ou à utilização de nossos
                  serviços. Recomendamos, ainda, que caso seja aceito, que você
                  armazene ou imprima uma cópia deste contrato, incluindo todas
                  as políticas.
                </p>

                <h5 className="turquoise-text bold">
                  1. DATA DE DISPONIBILIZAÇÃO DO TEXTO
                </h5>

                <p>
                  O presente documento foi redigido e disponibilizado em
                  15/07/2020.
                </p>

                <h5 className="turquoise-text bold">
                  2. EXPLICAÇÃO DOS TERMOS TÉCNICOS OU EM LÍNGUA ESTRANGEIRA
                </h5>
                <p>
                  Abaixo estão dispostos os significados das nomenclaturas
                  técnicas e termos na língua inglesa.
                </p>

                {this.renderNomenclaturas()}

                <h5 className="turquoise-text bold">3. SERVIÇOS</h5>

                <p>
                  3.1. <strong>O que somos: </strong>A{' '}
                  <strong>CONTEXTO REVISOR</strong> consiste, mas não se limita,
                  a um marketplace especializado com o objetivo de possibilitar
                  ao Usuário Revisado/Cliente a revisão on-line de redações por
                  meio da prestação de serviço do Usuário Revisor.
                </p>
                <p className="sub-item">
                  3.1.1. A{' '}
                  <strong>
                    CONTEXTO REVISOR não é prestadora de serviço de revisão de
                    textos, em nenhuma circunstância.
                  </strong>
                </p>
                <p className="sub-item">
                  3.1.2. A função da plataforma é oferecer um ambiente de
                  marketplace que objetiva facilitar o contato entre o Usuário
                  Revisado/Cliente e o Usuário Revisor, oferecendo um domínio
                  específico para que o Usuário Revisor possa prospectar
                  clientes por meio de divulgação direta e independente, também
                  disponibilizando hospedagem, suporte técnico, ferramentas de
                  revisão e sistema de pagamento/recebimento de valores on-line
                  ( este, por meio da empresa{' '}
                  <a href="https://iugu.com">Iugu</a>).
                </p>
                <p>
                  3.2. Ao utilizar a <strong>CONTEXTO REVISOR</strong> o Usuário
                  deve compreender e aceitar que todas as suas ações e escolhas
                  são livres e não possuem nenhum tipo de influência ou
                  ingerência da <strong>CONTEXTO REVISOR</strong>.
                </p>
                <p className="sub-item">
                  3.2.1. Os Usuários Revisores relacionados nas pesquisas
                  realizadas por meio da plataforma são aqueles constantes em
                  nosso banco de dados e são escolhidos pelos Usuários Clientes
                  conforme perfil.
                </p>
                <p className="sub-item">
                  3.2.2. Na busca de proporcionar aos Usuários Clientes a
                  escolha de Usuários Revisores minimamente capacitados para as
                  tarefas de revisão, a <strong>CONTEXTO REVISOR</strong> poderá
                  exercer Curadoria sobre estes, por meio de apresentação de
                  currículo, testes e entrevistas, entre outros meios.
                </p>
                <p>
                  3.3. Fica esclarecido que o presente serviço não estabelece
                  entre as partes qualquer vínculo empregatício, societário ou
                  associativo, permanecendo cada parte como única responsável
                  por todas as suas respectivas despesas e encargos, sejam de
                  natureza trabalhista, previdenciária, fiscal, securitária,
                  civil, penal ou de qualquer outra natureza ou espécie.
                </p>
                <p>
                  3.4. O site da plataforma é acessado via navegador e o
                  aplicativo será posteriormente disponibilizado para iOS e
                  Android por meio da <i>AppStore</i> e da <i>PlayStore</i>.
                </p>

                <h5 className="turquoise-text bold">
                  4. CADASTRO DOS USUÁRIOS
                </h5>

                <p>
                  4.1. Para o cadastro e utilização dos serviços da{' '}
                  <strong>CONTEXTO REVISOR</strong> serão requeridas as
                  seguintes informações:
                </p>
                <p className="sub-item">
                  4.1.1. Usuário Revisado/Cliente: nome completo, data de
                  nascimento, e-mail de melhor contato, foto, gênero, nível de
                  escolaridade, unidade da Federação onde reside e dados
                  bancários no sistema de pagamento on-line da{' '}
                  <a href="https://iugu.com">Iugu</a>.
                </p>
                <p className="sub-item">
                  4.1.2. Usuário Revisor: nome completo, data de nascimento,
                  e-mail de melhor contato, foto, gênero, nível de escolaridade,
                  unidade da Federação onde reside, currículo atualizado e dados
                  bancários no sistema de pagamento on-line da{' '}
                  <a href="https://iugu.com">Iugu</a>.
                </p>
                <p>
                  4.2. Cada Usuário determinará o seu <i>login</i> e senha de
                  acesso, sendo de sua exclusiva responsabilidade a manutenção
                  do sigilo dessas informações. A{' '}
                  <strong>CONTEXTO REVISOR</strong> não se responsabiliza pelas
                  ações e danos que poderão ser causados pelo acesso irregular
                  por terceiros.
                </p>
                <p>
                  4.3. É permitida a realização de apenas 1 (uma) conta por
                  Usuário.
                </p>
                <p>
                  4.4. O cadastro na plataforma é permitido{' '}
                  <strong>somente a pessoas acima de 13 (treze) anos</strong> e
                  a <strong>CONTEXTO REVISOR</strong>
                  fará esforços razoáveis para confirmar esse dado, mas não se
                  responsabiliza caso a pessoa cadastrada não possua a idade
                  informada no formulário.
                </p>
                <p>
                  4.5. Os Usuários são responsáveis por fornecer informações
                  verdadeiras, precisas e atualizadas.
                </p>
                <p>
                  4.6. A <strong>CONTEXTO REVISOR</strong> poderá recusar,
                  suspender ou cancelar, sem notificação prévia, a conta de
                  acesso de um Usuário sempre que suspeitar que as informações
                  fornecidas são falsas, incompletas, desatualizadas ou
                  imprecisas, ou, ainda, nos casos indicados nas leis e
                  regulamentos aplicáveis, nestes Termos de Uso ou em qualquer
                  Política.
                </p>

                <h5 className="turquoise-text bold">
                  5. PAGAMENTO PELO USO DA PLATAFORMA
                </h5>

                <p>
                  5.1. O Usuário Revisor deverá pagar valores para utilizar os
                  serviços da plataforma, conforme o plano escolhido:
                </p>
                <p className="sub-item">
                  5.1.1. <strong>Plano Flex</strong>: neste plano, o Usuário
                  Revisor não pagará assinatura mensal à{' '}
                  <strong>CONTEXTO REVISOR</strong> mas pagará um percentual à{' '}
                  <strong>CONTEXTO REVISOR</strong> sobre o valor total das
                  transações realizadas com os Usuários Clientes.
                </p>
                <p className="sub-item2">
                  5.1.1.1. Por meio do <strong>Plano Flex</strong>, a{' '}
                  <strong>CONTEXTO REVISOR</strong> disponibiliza 2 (dois)
                  espaços para a realização de anúncios no “domínio” do Usuário
                  Revisor e o faturamento será da{' '}
                  <strong>CONTEXTO REVISOR</strong>.
                </p>
                <p className="sub-item">
                  5.1.2. <strong>Plano Premium</strong>: neste plano, o Usuário
                  Revisor pagará à <strong>CONTEXTO REVISOR</strong> um valor
                  referente a assinatura mensal do Plano; e mais um percentual
                  sobre o valor total das transações realizadas com os Usuários
                  Revisados/Clientes.
                </p>
                <p className="sub-item2">
                  5.1.2.1. Por meio do <strong>Plano Premium</strong>, a{' '}
                  <strong>CONTEXTO REVISOR</strong> disponibiliza 2 (dois)
                  espaços para a realização de anúncios no “domínio” do Usuário
                  Revisor, e o faturamento será do próprio Usuário Revisor.
                </p>
                <p>
                  5.2. Respeitamos todos os seus direitos, inclusive o direito
                  de arrependimento e cancelamento do serviço com reembolso no
                  total dos valores pagos, no prazo de até 07 (sete) dias,
                  contados a partir da data da compra.
                </p>
                <p className="sub-item">
                  5.2.1. Após o prazo de 07 (sete) dias, caso o Usuário queira
                  cancelar os serviços da <strong>CONTEXTO REVISOR</strong>, não
                  será feito reembolso, contudo a plataforma está liberada até o
                  próximo vencimento da mensalidade.
                </p>
                <p>
                  5.3. O repasse da comissão da{' '}
                  <strong>CONTEXTO REVISOR</strong>é feito automaticamente pela
                  plataforma, com base no valor total dos serviços contratados,
                  após a conclusão de cada transação realizada com sucesso,
                  sendo separados os percentuais devidos ao Usuário Revisor e à{' '}
                  <strong>CONTEXTO REVISOR</strong> de acordo com o que é
                  pré-definido em cada Plano (Plano Flex e Plano Premium), nas
                  formas e condições ofertadas no anúncio, nos orçamentos
                  disponibilizados por meio da plataforma e no presente Termo.
                </p>
                <p>
                  5.4. A proteção das transações eletrônicas não é feita pela{' '}
                  <strong>CONTEXTO REVISOR</strong>, mas pela{' '}
                  <a href="https://iugu.com">Iugu</a>, empresa especializada em
                  pagamentos eletrônicos e que pode garantir toda a segurança
                  necessária para cada transação e movimentação financeira.
                </p>
                <p>
                  5.5. Os pagamentos serão realizados pelos Usuários Clientes no
                  momento da contratação. Apenas após a finalização com sucesso
                  da prestação de serviço, o Usuário Revisado/Cliente liberará
                  os valores ao Usuário Revisor.
                </p>
                <p className="sub-item">
                  5.5.1. Caso o Usuário Revisado/Cliente não fique totalmente
                  satisfeito com o serviço é dever do Usuário Revisor buscar
                  entender o que o cliente espera e refazer o serviço para então
                  ter o pagamento do pedido liberado.
                </p>
                <p>
                  5.6. Após a liberação do pagamento, ambos devem
                  obrigatoriamente se avaliar pela Plataforma, buscando assim
                  expressar os detalhes do serviço prestado e/ou contratado com
                  o intuito também de informar os demais Usuários da Plataforma.
                </p>
                <p>
                  5.7. O Usuário Revisor compreende e aceita que a liberação do
                  pagamento de qualquer pedido finalizado com sucesso se dará
                  após 30 (trinta) dias corridos, contados a partir de o Usuário
                  Revisado/Cliente ter liberado o dinheiro por meio da
                  plataforma, respeitando assim o prazo estabelecido pela{' '}
                  <a href="https://iugu.com">Iugu</a>, ou arcará com valor
                  percentual para antecipação do pagamento, nas formas e
                  condições ofertadas no anúncio, nos orçamentos
                  disponibilizados por meio da plataforma e no presente Termo.
                </p>
                <p>
                  5.8. Ao Usuário Revisado/Cliente não será cobrado nenhum valor
                  para realizar o cadastro na <strong>CONTEXTO REVISOR</strong>,
                  devendo efetuar o pagamento nos casos de contratação dos
                  serviços de revisão textual, nas formas e condições ofertadas
                  no anúncio, nos orçamentos disponibilizados por meio da
                  plataforma e no presente Termo.
                </p>
                <p>
                  5.9. A <strong>CONTEXTO REVISOR</strong> indica que antes de
                  efetuar qualquer transação financeira, o Usuário leia
                  atentamente os Termos de Uso e Políticas de Privacidade da{' '}
                  <a href="https://iugu.com">Iugu</a>, que não possuem relação
                  com a <strong>CONTEXTO REVISOR</strong> e com este documento.
                </p>
                <p>
                  5.10. Todos os Usuário Revisores deverão, obrigatoriamente,
                  acatar e aceitar a disponibilização de pagamentos que a{' '}
                  <strong>CONTEXTO REVISOR</strong> fornece por meio da
                  Plataforma, ficando proibido de negociar com o Usuário
                  Revisado outras formas de pagamentos que não seja pela
                  Plataforma.
                </p>
                <p>
                  5.11. A <strong>CONTEXTO REVISOR</strong> não se
                  responsabiliza por qualquer inadimplência por parte do Usuário
                  Revisado/Cliente ao Usuário Revisor, devendo a parte lesada
                  buscar às suas custas o recebimento dos valores devidos.{' '}
                </p>
                <p>
                  5.12. Devoluções e estornos ao Usuário Revisado/Cliente
                  somente serão realizados nas hipóteses de comprovados erros na
                  cobrança e cancelamentos com antecedência mínima de 8 (oito)
                  horas.{' '}
                </p>
                <p>
                  5.13. Aceitando este termo, você concorda com todas as nossas
                  condições e se compromete a não abrir disputas fraudulentas de
                  pagamento. Se algo estiver errado com algum pagamento ou
                  cobrança, antes entre imediatamente em contato conosco pelo
                  e-mail <a>contato@contextorevisor.com.br</a>. A abertura de
                  disputa fraudulenta ocasionará automaticamente o bloqueio de
                  sua conta de acesso.
                </p>

                <h5 className="turquoise-text bold">
                  6. RESPONSABILIDADE DAS PARTES
                </h5>
                <p>
                  6.1. <strong>Responsabilidade da CONTEXTO REVISOR:</strong>
                </p>
                <p className="sub-item">
                  6.1.1. Realizar os serviços conforme o descrito nestes termos
                  de uso;
                </p>
                <p className="sub-item">
                  6.1.2. Responsalizar-se pelo funcionamento da plataforma e
                  pelas correções que eventualmente sejam necessárias;
                </p>
                <p className="sub-item">
                  6.1.3. Informar qualquer alteração dos serviços aos Usuários,
                  por meio de comunicados simples na plataforma;
                </p>
                <p>
                  6.2. <strong>Responsabilidade dos Usuários:</strong>
                </p>
                <p className="sub-item">
                  6.2.1. Utilizar a plataforma conforme os critérios de
                  utilização definidos pela <strong>CONTEXTO REVISOR</strong>,
                  sem alterar a sua programação, quebrar senhas ou realizar
                  procedimentos que venham causar prejuízos à{' '}
                  <strong>CONTEXTO REVISOR</strong> e aos demais Usuários;
                </p>
                <p className="sub-item">
                  6.2.2. Responsabilizar-se para todos os efeitos, inclusive
                  jurídicos, pelo teor das informações que introduzir e pelos
                  compromissos que assumir na plataforma;
                </p>
                <p className="sub-item">
                  6.2.3. Respeitar integralmente estes Termos de Uso, Políticas
                  de Privacidade, legislação vigente e contratos entre as
                  partes;
                </p>
                <p className="sub-item">
                  6.2.4. O Usuário Revisor deve ter mínima capacidade técnica
                  para prestar o serviço anunciado, sendo que todo conteúdo e
                  informação inseridos na <strong>CONTEXTO REVISOR</strong> são
                  de responsabilidade exclusiva do Usuário que o produz e o
                  expõe, de modo que a <strong>CONTEXTO REVISOR</strong> jamais
                  responderá por essas ações.
                </p>

                <h5 className="turquoise-text bold">
                  7. ISENÇÃO DE RESPONSABILIDADE DA CONTEXTO REVISOR
                </h5>
                <p>
                  7.1. A{' '}
                  <strong>CONTEXTO REVISOR não se responsabiliza por:</strong>
                </p>
                <p className="sub-item">
                  7.1.1. Eventual indisponibilidade da plataforma, à qual não
                  tenha dado causa.
                </p>
                <p className="sub-item">
                  7.1.2. Condutas dos Usuários e pelo descumprimento de suas
                  obrigações.
                </p>
                <p className="sub-item">
                  7.1.3. Inserção de informações falsas pelos Usuários.
                </p>
                <p className="sub-item">
                  7.1.4. Danos que o Usuário possa experimentar por ações
                  exclusivas de terceiros, bem como falhas na conexão de rede e
                  interações maliciosas, como vírus.
                </p>
                <p className="sub-item">
                  7.1.5. Danos que o Usuário possa ter em decorrência do mau uso
                  da plataforma em desconformidade com estes Termos, com a
                  Política de Privacidade, com a lei ou ordens judiciais.
                </p>
                <p className="sub-item">
                  7.1.6. Casos fortuitos ou de força maior.
                </p>
                <p className="sub-item">
                  7.1.7. Qualidade das revisões realizadas pelos Usuários
                  Revisores aos Usuários Clientes. Todos os procedimentos e
                  serviços anunciados pela plataforma são prestados por
                  Profissionais Revisores de Texto, sendo esses os únicos
                  responsáveis por suas revisões/correções, pelas informações e
                  orientações prestadas. A <strong>CONTEXTO REVISOR</strong> não
                  oferece qualquer garantia sobre os serviços, nem mesmo que,
                  após a correção, o texto não contenha erros ou equívocos
                  textuais gerados pelo Usuário Revisor.
                </p>
                <p className="sub-item">
                  7.1.8.Qualquer forma de descumprimento dos serviços oferecidos
                  pelos Usuários Revisores, obrigações por eles assumidas ou
                  quaisquer prejuízos ou danos resultantes dos descumprimentos,
                  ainda que a intermediação tenha sido realizada por meio da
                  plataforma.
                </p>
                <p className="sub-item">
                  7.1.9. Inadimplência do Usuário Revisado/Cliente
                </p>

                <h5 className="turquoise-text bold">
                  8. REGRAS DE CONDUTA E PROIBIÇÕES
                </h5>
                <p>
                  8.1. Os Usuários <strong>não</strong> podem:
                </p>
                <p className="sub-item">
                  8.1.1. Lesar direitos da <strong>CONTEXTO REVISOR​</strong>,
                  dos operadores da plataforma, de outros Usuários, de terceiros
                  ou agir sob qualquer meio ou forma que possa contribuir com
                  tal violação;
                </p>
                <p className="sub-item">
                  8.1.2. Executar atos que limitem ou impeçam a utilização da
                  plataforma ou acessar ilicitamente a ​
                  <strong>CONTEXTO REVISOR​</strong>;
                </p>
                <p className="sub-item">
                  8.1.3. Utilizar a ferramenta para praticar ações ilícitas e
                  difundir mensagens não relacionadas com a plataforma ou com
                  suas finalidades, incluindo mensagens com conteúdo impróprio;
                </p>
                <p className="sub-item">
                  8.1.4. Inserir dados que sejam falsos, desatualizados ou
                  incompletos;
                </p>
                <p className="sub-item">
                  8.1.5. Responsabilizar a <strong>CONTEXTO REVISOR</strong> por
                  condutas de Usuários que estejam cadastrados na plataforma.
                </p>
                <p className="sub-item">
                  8.1.6. Disponibilizar seu perfil para intermediar trabalhos de
                  outros profissionais;
                </p>
                <p className="sub-item">
                  8.1.7. Utilizar a plataforma para prestar serviços de Revisão
                  de Redação sem possuir competência técnica mínima para tanto;
                </p>
                <p className="sub-item">
                  8.1.8. Divulgar, sem a devida autorização do autor, os textos
                  enviados para revisão no site;
                </p>
                <p className="sub-item">
                  8.1.9. Plagiar, copiar, ou reproduzir, ainda que parcialmente,
                  os textos enviados para revisão no site.
                </p>
                <p>
                  8.2. Ao sinal de qualquer descumprimento de conduta, o Usuário
                  poderá ser bloqueado ou excluído da Plataforma, sem
                  necessidade de aviso prévio.
                </p>

                <h5 className="turquoise-text bold">
                  9. AVALIAÇÕES DOS USUÁRIOS
                </h5>
                <p>
                  9.1. Com o intuito de propiciar ao Usuário Revisado/Cliente a
                  escolha dos melhores prestadores de serviço de Revisão de
                  Redação, após o serviço o Usuário Revisado/Cliente poderá
                  fazer uma recomendação, além de apresentar comentários,
                  mostrando a experiência com o Usuário Revisor, sendo vedado o
                  anonimato.
                </p>
                <p>
                  9.2. A cada avaliação feita pelo Usuário Revisado/Cliente,
                  será permitido ao Usuário Revisor o direito de responder
                  apenas uma vez, expondo seu ponto de vista sobre o comentário,
                  caso queira.
                </p>
                <p>
                  9.3. Os Usuários Revisores cujas avaliações negativas
                  corresponderem a 50% (cinquenta por cento) do total das
                  Avaliações dos Usuários Revisados/Clientes poderão ser
                  excluídos da <strong>CONTEXTO REVISOR</strong>​, a critério
                  único e exclusivo desta.
                </p>
                <p>
                  9.4. A <strong>CONTEXTO REVISOR</strong> poderá realizar uma
                  curadoria a respeito das avaliações lançadas na plataforma, de
                  modo a filtrar aquelas que não auxiliam o Usuário
                  Revisado/Cliente na escolha do melhor profissional. O Usuário
                  Revisor tem ciência de que a curadoria não representa
                  indicação ou exercício de influência.
                </p>
                <p>
                  9.5. A <strong>CONTEXTO REVISOR</strong> se reserva o direito
                  de publicar qualquer comentário no momento que desejar e a seu
                  único e exclusivo critério, não havendo, entretanto, qualquer
                  obrigação de publicação.
                </p>
                <p>
                  9.6. Os Usuários aceitam e concordam com a possibilidade de
                  compartilhamento, pela <strong>CONTEXTO REVISOR​</strong>, das
                  Avaliações dos Usuários Clientes sobre os serviços recebidos.
                </p>
                <p>
                  9.7. Avaliações e depoimentos com palavras ofensivas serão
                  desconsiderados e ocultados.
                </p>
                <p>
                  9.8. Mesmo diante das pontuações e avaliações feitas dentro da
                  plataforma, é de inteira responsabilidade dos Usuários
                  Clientes a escolha do prestador Revisor que atenderá às suas
                  necessidades. A <strong>CONTEXTO REVISOR</strong> não tem
                  nenhuma influência ou responsabilidade nessa escolha.
                </p>

                <h5 className="turquoise-text bold">
                  10. PROPRIEDADE INTELECTUAL
                </h5>
                <p>
                  10.1. A titularidade e os direitos relativos à plataforma
                  pertencem exclusivamente à <strong>CONTEXTO REVISOR</strong>​.
                  O acesso à plataforma e a sua regular utilização pelo Usuário
                  não lhe conferem qualquer direito ou prerrogativa sobre
                  propriedade intelectual ou outro conteúdo nela inserido.
                </p>
                <p>
                  10.2. Todo o conteúdo da plataforma, incluindo nome, marca,
                  domínio, programas, bases de dados, arquivos, textos,
                  desenhos, fotos, layouts, cabeçalhos e demais elementos, foi
                  criado, desenvolvido ou cedido à{' '}
                  <strong>CONTEXTO REVISOR​</strong>, sendo, portanto, de
                  propriedade exclusiva da <strong>CONTEXTO REVISOR</strong> ou
                  a ela licenciado e encontra-se protegido pelas leis
                  brasileiras e tratados internacionais que versam sobre
                  direitos de propriedade intelectual.
                </p>
                <p>
                  10.3. São proibidas: a exploração, cessão, imitação, cópia,
                  plágio, aplicação de engenharia reversa, tentativa de invasão
                  (hackear), armazenamento, alteração, modificação de
                  características, ampliação, venda, locação, doação, alienação,
                  transferência, reprodução, integral ou parcial, de qualquer
                  conteúdo da plataforma da ​<strong>CONTEXTO REVISOR</strong>​.
                </p>
                <p>
                  10.4. Ao enviar conteúdo para a{' '}
                  <strong>CONTEXTO REVISOR​</strong>, o Usuário retém a
                  titularidade de seus direitos sobre dito conteúdo (textos,
                  vídeos, imagens, áudio, entre outros), cedendo ao{' '}
                  <strong>CONTEXTO REVISOR</strong> uma licença de caráter
                  gratuito, mundial e não exclusivo para a reprodução, tradução
                  e exibição, sob qualquer meio ou forma, inclusive no
                  site/plataforma/aplicativo, declarando, ainda, o Usuário ser
                  titular de todos os direitos relacionados ao referido
                  conteúdo.
                </p>
                <p>
                  10.5. A pessoa que violar as proibições contidas na legislação
                  sobre propriedade intelectual e nestes Termos será
                  responsabilizada, civil e criminalmente, pelas infrações
                  cometidas, além de poder ser penalizada na plataforma.
                </p>

                <h5 className="turquoise-text bold">
                  11. TRATAMENTO DE DADOS PESSOAIS, PRIVACIDADE E SEGURANÇA
                </h5>
                <p>
                  11.1. A <strong>CONTEXTO REVISOR</strong> dispõe de uma
                  política específica para regular a coleta, guarda e tratamento
                  de dados pessoais, bem como a sua segurança: Política de
                  Privacidade. Essa política específica integra inseparavelmente
                  estes Termos, ressaltando-se que os dados de utilização da
                  plataforma serão arquivados nos termos da legislação em vigor.
                </p>

                <h5 className="turquoise-text bold">
                  12. DESDOBRAMENTOS DO ACESSO À PLATAFORMA
                </h5>
                <p>
                  12.1. Apesar dos melhores esforços da{' '}
                  <strong>CONTEXTO REVISOR </strong>para fornecer as melhores
                  tecnologias para manter a conexão e sincronização on-line e
                  acesso seguro aos Usuários, em virtude de dificuldades
                  técnicas, aplicações de internet ou problemas de transmissão,
                  é possível ocorrer cópias inexatas ou incompletas das
                  informações contidas na plataforma. Além disso, vírus de
                  computador ou outros programas danosos também poderão ser
                  baixados inadvertidamente da plataforma.
                </p>
                <p className="sub-item">
                  12.1.1. A <strong>CONTEXTO REVISOR</strong> recomenda a
                  instalação de antivírus ou protetores adequados.
                </p>
                <p>
                  12.2. A <strong>CONTEXTO REVISOR</strong> se reserva o direito
                  de unilateralmente modificar a plataforma, bem como a
                  configuração, a apresentação, o desenho, o conteúdo, as
                  funcionalidades, as ferramentas ou qualquer outro elemento,
                  inclusive o seu cancelamento.
                </p>

                <h5 className="turquoise-text bold">
                  13. ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO
                </h5>
                <p>
                  13.1. A <strong>CONTEXTO REVISOR</strong> poderá
                  unilateralmente adicionar e/ou modificar qualquer cláusula
                  contida nestes Termos de Uso. A versão atualizada valerá para
                  o uso da plataforma realizado a partir de sua publicação. A
                  continuidade de acesso ou utilização da plataforma, depois da
                  divulgação, confirmará a vigência dos novos Termos de Uso
                  pelos Usuários.
                </p>
                <p>
                  13.2. Caso a mudança efetuada necessite de consentimento do
                  Usuário, será apresentada a opção de aceitar de forma livre,
                  inequívoca e informada o novo texto ou de recusá-lo.
                </p>
                <p>
                  13.3. Caso o Usuário não esteja de acordo com a alteração,
                  poderá não fornecer consentimento para atos específicos ou
                  poderá rescindir totalmente seu vínculo com a{' '}
                  <strong>CONTEXTO REVISOR​</strong>. Essa rescisão não eximirá,
                  no entanto, o Usuário de cumprir com todas as obrigações
                  assumidas sob as versões precedentes dos Termos de Uso.
                </p>

                <h5 className="turquoise-text bold">
                  14. LEI APLICÁVEL E FORO DE ELEIÇÃO
                </h5>
                <p>
                  14.1. A Plataforma é controlada, operada e administrada na
                  cidade de Brasília, Distrito Federal, Brasil, podendo ser
                  acessada por qualquer dispositivo conectado à Internet,
                  independentemente de sua localização geográfica.
                </p>
                <p className="sub-item">
                  14.1.1. A <strong>CONTEXTO REVISOR</strong> não garante que a
                  plataforma seja apropriada ou esteja disponível para uso em
                  outros locais. As pessoas que acessam ou usam a plataforma da{' '}
                  <strong>CONTEXTO REVISOR</strong> a partir de outras
                  jurisdições o fazem por conta própria e são responsáveis pelo
                  cumprimento das leis regionais/nacionais.
                </p>
                <p>
                  14.2. O Usuário concorda que a legislação aplicável para fins
                  destes Termos e Condições de Uso e das Políticas de
                  Privacidade será a vigente na República Federativa do Brasil.
                </p>
                <p>
                  14.3. A <strong>CONTEXTO REVISOR</strong> e o Usuário
                  concordam que o Foro de Brasília, Distrito Federal, Brasil,
                  será o único competente para dirimir qualquer questão ou
                  controvérsia oriunda ou resultante do uso da plataforma,
                  renunciando expressamente a qualquer outro, por mais
                  privilegiado que seja, ou venha a ser.
                </p>

                <h5 className="turquoise-text bold">15. CONTATO</h5>
                <p>
                  15.1. A <strong>CONTEXTO REVISOR</strong> disponibiliza os
                  seguintes canais para receber todas as comunicações que os
                  Usuários desejarem fazer:
                </p>
                <p className="sub-item">
                  15.1.1. Pelo “Chat On-line” disponível na plataforma.
                </p>
                <p className="sub-item">
                  15.1.2. Pelo telefone/WhatsApp 55 61 99949-4321, de segunda a
                  sexta, das 9h às 19h, exceto feriados.
                </p>
                <p className="sub-item">
                  15.1.3. Por e-mail para o endereço eletrônico{' '}
                  <a>contato@contextorevisor.com.br</a>
                </p>
                <p>
                  15.2. Todas as solicitações devem sempre estar acompanhadas
                  das informações de contato do usuário, para que a{' '}
                  <strong>CONTEXTO REVISOR</strong> possa efetivar o
                  atendimento.
                </p>
                <p>
                  15.3. Os clientes receberão a confirmação de recebimento do
                  contato, e a <strong>CONTEXTO REVISOR​</strong> terá até o
                  máximo de 05 (cinco) dias para retorná-los.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});

const TermsPage = connect(mapStateToProps, mapDispatchToProps)(TermsComponent);
export { TermsPage };
