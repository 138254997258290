import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { reducers, sagas } from './state-management';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { Notification } from './components/pages/Notification/Notification';
import MainPage from './components/pages/MainPage';
import SearchReviewer from './components/pages/SearchReviewer';
import { ProfileReviewer } from './components/pages/ProfileReviewer';
import BecomeReviewer from './components/pages/BecomeReviewer';
import { About } from './components/pages/About';
import { TermsPage } from './components/pages/Terms';
import { Contact } from './components/pages/Contact';
import { Partnerships } from './components/pages/Partnerships';
import { ReviewerContractPage } from './components/pages/Partnerships/sections/ReviewerPartnerships';
import Blog from './components/pages/Blog';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import { ProfilePage } from './components/pages/Profile/ProfilePage';
import { RecoverPage } from './components/pages/RecoverPassword';
import { ResetPasswordPage } from './components/pages/ResetPassword';
import routes from './routes';
import {
  checkToken,
  fetchTrainings,
  fetchStates,
  fetchDivulgacao
} from './state-management';
import fontawesome from '@fortawesome/fontawesome';
import brands from '@fortawesome/fontawesome-free-brands';
import solids from '@fortawesome/fontawesome-free-solid';
import { loadProgressBar } from 'axios-progress-bar';
import withTracker from './withTracker';
import ReactPixel from 'react-facebook-pixel';
import RepresentativeAgreement from './components/pages/Partnerships/sections/RepresentativeAgreement';
import PromoterAgreement from './components/pages/Partnerships/sections/PromoterAgreement';
import InstitutionAgreement from './components/pages/Partnerships/sections/InstitutionAgreement';
import AdminPanel from './components/pages/AdminPanel/AdminPanel';
import AdminPanelClients from './components/pages/AdminPanelClients/AdminPanelClients';

fontawesome.library.add(brands, solids);

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(routeMiddleware, sagaMiddleware)
);
const {
  buscarRevisor,
  sejaRevisor,
  contato,
  sobre,
  blog,
  login,
  logout,
  perfilRevisor,
  parcerias,
  contratoRevisor,
  contratoRepresentante,
  contratoDivulgador,
  contratoInstituicao,
  profile,
  termos,
  recuperarSenha,
  redefinirSenha,
  perfilPropostaDetalhe,
  perfilRevisorSite,
  painel,
  painelClients
} = routes;

// TODO: Remove hardcoded email
const advancedMatching = { em: 'carloshrbezerra@email.com' };
const options = {
  autoConfig: true,
  debug: false
};

sagaMiddleware.run(sagas);

class App extends Component {
  componentDidMount() {
    ReactPixel.init('2178197005768334', advancedMatching, options);
    ReactPixel.pageView();

    store.dispatch(checkToken());
    store.dispatch(fetchStates());
    store.dispatch(fetchTrainings());
    store.dispatch(fetchDivulgacao());
  }

  render() {
    loadProgressBar();
    return (
      <Provider store={store}>
        <Router>
          <Route path="/">
            <Notification />
          </Route>
          <Switch>
            <Route exact={true} path="/" component={withTracker(MainPage)} />
            <Route path={painel} component={AdminPanel} />
            <Route path={painelClients} component={AdminPanelClients} />
            <Route
              path={buscarRevisor}
              component={withTracker(SearchReviewer)}
            />
            <Route path={sejaRevisor} component={withTracker(BecomeReviewer)} />
            <Route path={sobre} component={withTracker(About)} />
            <Route path={contato} component={withTracker(Contact)} />
            <Route path={blog} component={withTracker(Blog)} />
            <Route path={login} component={withTracker(Login)} />
            <Route path={logout} component={withTracker(Logout)} />
            <Route exact path={profile} component={withTracker(ProfilePage)} />
            <Route
              path={perfilPropostaDetalhe}
              component={withTracker(ProfilePage)}
            />
            {/*<Route path={parcerias} component={withTracker(Partnerships)} />
            <Route
              path={contratoRevisor}
              component={withTracker(ReviewerContractPage)}
            />
            <Route
              path={contratoRepresentante}
              component={withTracker(RepresentativeAgreement)}
            />
            <Route
              path={contratoDivulgador}
              component={withTracker(PromoterAgreement)}
            />
            <Route
              path={contratoInstituicao}
              component={withTracker(InstitutionAgreement)}
            />
          */}
            <Route path={termos} component={withTracker(TermsPage)} />
            <Route path={recuperarSenha} component={withTracker(RecoverPage)} />
            <Route
              path={redefinirSenha}
              component={withTracker(ResetPasswordPage)}
            />

            <Route
              path={perfilRevisorSite}
              component={withTracker(ProfileReviewer)}
            />
            <Route
              path={perfilRevisor}
              component={withTracker(ProfileReviewer)}
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
