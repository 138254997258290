import React, { Component } from 'react';
import { control } from 'react-validation';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { required, date } from './validators';

const validators = {
  required: required,
  date: date
};

class FormDateComponent extends Component {
  state = {
    error: null
  };

  handleChange = e => {
    this.props.onChange && this.props.onChange(e);
  };

  handleValidate = e => {
    let error;
    for (let key of this.props.validators) {
      error = validators[key](e.target.value, this.props);
      if (error) {
        this.setState({ error });
        break;
      }
    }

    if (!error) {
      this.setState({ error: null });
    }
  };

  render() {
    let newProps = { ...this.props };
    newProps.mask = newProps.format
      ? newProps.format.replace(/[a-zA-Z]/g, '9')
      : '99/99/9999';

    delete newProps.onChange;
    delete newProps.minDate;
    delete newProps.maxDate;
    delete newProps.className;
    delete newProps.isChanged;

    let { className } = this.props;
    className = className || '';
    className += this.state.error ? ' is-invalid-input' : '';

    return (
      <div>
        <InputMask
          {...newProps}
          className={className}
          onChange={this.handleChange}
          onBlur={this.handleValidate}
        />
        {this.state.error}
      </div>
    );
  }
}

const FormDateHOC = control(FormDateComponent);

const FormDate = props => {
  let validations = [];
  if (props.validators) {
    for (let validator of props.validators) {
      validations.push(validators[validator]);
    }
  }

  return <FormDateHOC {...props} validations={validations} />;
};

FormDate.propTypes = {
  validators: PropTypes.array,
  minDate: PropTypes.any.isRequired,
  maxDate: PropTypes.any,
  format: PropTypes.string
};

export { FormDate };
