import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainSection from './Sections/MainSection';
import FindReviewerSection from './Sections/FindReviewerSection';
import HowItWorksSection from './Sections/HowItWorksSection';
import TenReasonsToBeReviewr from './Sections/TenReasonsToBeReviewr';
import ReceiveTextsSection from './Sections/ReceiveTextsSection';
import TestimonialsSection from './Sections/TestimonialsSection';
import CreateAccountSection from './Sections/CreateAccountSection';
import Footer from '../../siteComponents/Footer/index';
import '../../assetsHolder';

class MainPageComponent extends Component {
  state = {
    clienteScrollTo: false,
    revisorScrollTo: false
  };

  onFooterClienteClick = () => {
    this.setState({ clienteScrollTo: true });
    this.clearScrollTo();
  };

  onFooterRevisorClick = () => {
    this.setState({ revisorScrollTo: true });
    this.clearScrollTo();
  };

  clearScrollTo = () => {
    let timer = setTimeout(() => {
      this.setState({ clienteScrollTo: false, revisorScrollTo: false });
      clearTimeout(timer);
    }, 300);
  };

  render() {
    let props = { ...this.props };

    return (
      <div id="main-page" style={{ backgroundColor: 'white' }}>
        <MainSection
          {...props}
          onFooterClienteClick={this.onFooterClienteClick}
          onFooterRevisorClick={this.onFooterRevisorClick}
        />
        <FindReviewerSection {...props} />
        <HowItWorksSection scrollTo={this.state.clienteScrollTo} />
        <TenReasonsToBeReviewr scrollTo={this.state.revisorScrollTo} />
        <ReceiveTextsSection />
        <TestimonialsSection />
        <CreateAccountSection {...props} />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  return { ...userStore };
};

const MainPage = connect(mapStateToProps)(MainPageComponent);

export default MainPage;
