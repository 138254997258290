import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  FormMask,
  FormInput,
  FormButton,
  FormValidator,
  FormSelect,
  FormDate
} from '../../../../siteComponents/form';
import routes from '../../../../../routes';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  userUpdateSuccess,
  showNotification
} from '../../../../../state-management';
import './PromoterPartnerSection.css';
import { ApiService } from '../../../../../state-management/service/api';
import cep from 'cep-promise';

const { parcerias, contratoDivulgador } = routes;

class PromoterPartnerComponent extends Component {
  sectionOne = null;
  sectionTwo = null;
  sectionThree = null;

  sectionButtons = {};

  state = {
    paymentStepEnable: false,
    activeStep: 1,
    form: {
      name: '',
      email: '',
      password: '',
      sexo: '',
      outro_sexo: '',
      uf_id: '',
      dt_nascimento: '',
      tipo_formacao_id: '',
      meio_divulgacao_id: '',
      rg: '',
      cpf: '',
      estado_civil: '',
      nacionalidade: '',
      contract: '',
      terms: false,
      pf: true,
      document: '',
      postal_code: '',
      address: '',
      complement: '',
      number: '',
      district: '',
      city: '',
      tipo_empresa: '',
      nome_fantasia: ''
    },
    error: {
      name: false,
      email: false,
      password: false,
      uf_id: false,
      dt_nascimento: false,
      tipo_formacao_id: false,
      document: false
    }
  };

  timeOut = null;

  constructor(props) {
    super(props);
    this.sectionOne = React.createRef();
    this.sectionTwo = React.createRef();
    this.sectionThree = React.createRef();

    this.sectionButtons = {
      1: this.sectionOne,
      2: this.sectionTwo,
      3: this.sectionThree
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated && !this.loggedHere) {
      this.props.history.replace('/profile');
    }

    if (nextProps.loginSuccess) {
      this.props.dispatch(
        showNotification('success', 'Usuário logado com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.replace(this.state.nextUrl);
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.loginFailed) {
      this.props.dispatch(
        showNotification(
          'error',
          'Acesso não autorizado, confira seu email e senha!',
          true
        )
      );
    }

    if (nextProps.accountCreated) {
      const newState = { ...this.state };
      // newState.form.card['number'] = '';
      this.setState(newState);

      this.props.dispatch(
        showNotification(
          'success',
          'Pronto! Você já preencheu o cadastro e pode logar no sistema. Mas lembre-se: você só poderá publicar seu perfil (minissite) após a autorização bancária, que ocorrerá em no máximo 48h.!',
          true
        )
      );

      this.timeOut = setTimeout(() => {
        this.props.history.replace('/login');
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 4000);
    }

    if (nextProps.accountError) {
      if (nextProps.accountErrorMsg && nextProps.accountErrorMsg.message) {
        let message;

        if (
          nextProps.accountErrorMsg.message ===
          'The email has already been taken.'
        ) {
          message =
            'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.';
        } else {
          message = 'Error ao criar parceiro divulgador.';
        }

        this.props.dispatch(showNotification('error', message, true));

        const newState = { ...this.state };
        this.setState(newState);
      } else {
        this.props.dispatch(
          showNotification('error', 'Verique os dados e tente novamente', true)
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  changeStep = (number, ref) => {
    if (number === 2) {
      ApiService.existsEmail({ email: this.state.form.email })
        .then(() => {
          this._changeStep(number, ref);
        })
        .catch(e => {
          if (e && e.response && e.response.status) {
            if (e.response.status === 302) {
              this.props.dispatch(
                showNotification(
                  'error',
                  'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.',
                  true
                )
              );
              return;
            }
          }
          throw e;
        });
    } else {
      this._changeStep(number, ref);
    }
  };

  _changeStep = (number, ref) => {
    if (ref && ReactDOM.findDOMNode(ref.current).disabled) {
      return;
    }

    const newState = { ...this.state };
    newState.activeStep = number;
    this.setState(newState);
  };

  handleChangeDate = (date, inputName) => {
    const newState = { ...this.state };
    newState.form[inputName] = date;
    this.setState(newState);
  };

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  handleChangeOther = e => {
    const { value } = e.target;
    this.setState({
      form: { ...this.state.form, sexo: 'O', outro_sexo: value }
    });
  };

  handleAccountChange = accountType => {
    const newState = { ...this.state };
    this.setState(newState);
  };

  onCheck = e => {
    let { name, checked } = e.target;
    let state = { ...this.state };
    state.form[name] = checked;
    this.setState(state);
  };

  handleChangeCardInputText = e => {
    // TODO: descobrir o que essa função deveria fazer 🤔
    const newState = { ...this.state };
    this.setState(newState);
  };

  handleFinish = () => {
    this.props.dispatch(userUpdateSuccess({ user: this.state.form }));
    this.props.history.push(contratoDivulgador);
  };

  handleCep = event => {
    const cep_str = event.target.value.replace('-', '');
    if (cep_str.length !== 8) {
      return;
    }
    cep(cep_str)
      .then(data => {
        const { form } = this.state;

        form.address = data.street;
        form.district = data.neighborhood;
        form.city = data.city;
        form.state = data.state;

        this.props.states.forEach(item => {
          if (item.uf === data.state) {
            form.uf_id = item.id;
          }
        });

        this.setState({ form });
      })
      .catch(err => {
        this.props.dispatch(
          showNotification('error', `ATENÇÃO: CEP inválido.`, true)
        );
      });
  };

  switchPF = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, pf: !form.pf } });
  };

  renderStepIndicators(stepActive) {
    let sectionButtonRef = this.sectionButtons[stepActive];

    return (
      <div className="turquoise-list w-col w-col-5 w-col-small-5 w-col-tiny-5">
        <ul className="unordered-list w-list-unstyled">
          <li
            className={'step-revisor ' + (stepActive === 1 ? 'active' : '')}
            onClick={() => this.changeStep(1, sectionButtonRef)}
          >
            <div>1</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 2 ? 'active' : '')}
            onClick={() => this.changeStep(2, sectionButtonRef)}
          >
            <div className="text-block">2</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 3 ? 'active' : '')}
            onClick={() => this.changeStep(3, sectionButtonRef)}
          >
            <div className="text-block">3</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 4 ? 'active' : '')}
            onClick={() => this.changeStep(4, sectionButtonRef)}
          >
            <div className="text-block">4</div>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="accont-box halfbox">
        <div className="w-form">
          {this.state.activeStep === 1 && (
            <FormValidator id="email-form" name="email-form" className="form">
              <div className="revisor-step1">
                <div className="row w-row">
                  <div className="turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1">
                    <Link className="no-style -turquoise" to={parcerias}>
                      
                    </Link>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Criar conta parceiro divulgador</div>
                  </div>
                  {this.renderStepIndicators(1)}
                </div>
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  maxLength="256"
                  name="name"
                  placeholder="Nome *"
                  id="name"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.name}
                />
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="email"
                  placeholder="E-mail *"
                  validators={['email', 'required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.email}
                />
                <FormInput
                  type="password"
                  className="accont-input w-input"
                  name="password"
                  placeholder="Senha *"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.password}
                />
                <FormSelect
                  name="meio_divulgacao_id"
                  className="accont-input w-select"
                  validators={['required']}
                  value={this.state.form.meio_divulgacao_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Meio de divulgação *</option>
                  {this.props.divulgacao.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.meio_divulgacao}
                      </option>
                    );
                  })}
                </FormSelect>
                <div className="gender">
                  <div className="gender-box">
                    <div className="gender-radio w-radio">
                      <input
                        type="radio"
                        id="radio"
                        name="pf"
                        className="w-radio-input"
                        checked={this.state.form.pf}
                        onChange={this.switchPF}
                      />
                      <label htmlFor="radio" className="w-form-label">
                        Pessoa Física
                      </label>
                    </div>
                  </div>

                  <div className="gender-box">
                    <div className="gender-box w-radio">
                      <input
                        type="radio"
                        id="radio-2"
                        name="pj"
                        className="w-radio-input"
                        checked={!this.state.form.pf}
                        onChange={this.switchPF}
                      />
                      <label htmlFor="radio-2" className="w-form-label">
                        Pessoa Jurídica
                      </label>
                    </div>
                  </div>
                </div>
                <FormButton
                  type="button"
                  ref={this.sectionOne}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(2)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}
          {this.state.activeStep === 2 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back2"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor-3-2"
                    onClick={() => this.changeStep(1)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Complete seu perfil</div>
                  </div>
                  {this.renderStepIndicators(2)}
                </div>

                <div>
                  {!this.state.form.pf ? (
                    <div>
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="nome_fantasia"
                        placeholder="Nome fantasia"
                        validators={['required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.nome_fantasia}
                      />
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="document"
                        placeholder="CNPJ *"
                        validators={['cnpj', 'required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.document}
                      />
                      <FormSelect
                        name="tipo_empresa"
                        validators={['required']}
                        className="accont-input w-select"
                        value={this.state.form.tipo_empresa}
                        onChange={this.handleChangeInputText}
                      >
                        <option value="">Tipo de Empresa *</option>
                        <option value="Sociedade Limitada">
                          Sociedade Limitada
                        </option>
                        <option value="Empresa Individual">
                          Empresa Individual
                        </option>
                      </FormSelect>
                    </div>
                  ) : (
                    <div>
                      <div className="gender">
                        <div className="gender-box">
                          <label>Data de Nascimento *</label>
                          <FormDate
                            alwaysShowMask={true}
                            className="w-input accont-input"
                            name="dt_nascimento"
                            minDate={new Date('01/01/1900')}
                            maxDate={new Date()}
                            validators={['date', 'required']}
                            value={this.state.form.dt_nascimento}
                            onChange={this.handleChangeInputText}
                          />
                        </div>
                      </div>
                      <div className="gender">
                        <div className="gender-box">
                          <label>Gênero *</label>
                          <div className="gender-radio w-radio">
                            <input
                              type="radio"
                              id="radio"
                              name="sexo"
                              className="w-radio-input"
                              value="M"
                              checked={this.state.form.sexo === 'M'}
                              onChange={this.handleChangeInputText}
                            />
                            <label htmlFor="radio" className="w-form-label">
                              Masculino
                            </label>
                          </div>
                        </div>

                        <div className="gender-box">
                          <div className="gender-box w-radio">
                            <input
                              type="radio"
                              id="radio-2"
                              name="sexo"
                              value="F"
                              className="w-radio-input"
                              checked={this.state.form.sexo === 'F'}
                              onChange={this.handleChangeInputText}
                            />
                            <label htmlFor="radio-2" className="w-form-label">
                              Feminino
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="gender radio-input">
                        <div className="gender-box">
                          <div className="gender-radio w-radio">
                            <input
                              type="radio"
                              id="radio-3"
                              name="sexo"
                              className="w-radio-input"
                              value="O"
                              checked={this.state.form.sexo === 'O'}
                              onChange={this.handleChangeInputText}
                            />
                            <label htmlFor="radio-3" className="w-form-label">
                              Outro
                            </label>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="accont-input w-input"
                          maxLength="256"
                          name="outro"
                          data-name="outro"
                          placeholder="Outro"
                          id="outro"
                          value={this.state.form.outro_sexo}
                          onChange={this.handleChangeOther}
                        />
                      </div>
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="document"
                        placeholder="CPF *"
                        validators={['cpf', 'required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.document}
                      />
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="rg"
                        placeholder="RG *"
                        validators={['required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.rg}
                      />
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="estado_civil"
                        placeholder="Estado civil *"
                        validators={['required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.estado_civil}
                      />
                      <FormInput
                        type="text"
                        className="accont-input w-input"
                        name="nacionalidade"
                        placeholder="Nacionalidade *"
                        validators={['required']}
                        onChange={this.handleChangeInputText}
                        value={this.state.form.nacionalidade}
                      />
                      <FormSelect
                        name="tipo_formacao_id"
                        validators={['required']}
                        className="accont-input w-select"
                        value={this.state.form.tipo_formacao_id}
                        onChange={this.handleChangeInputText}
                      >
                        <option value="">Nível de formação *</option>

                        {this.props.trainings.map(item => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.formacao}
                            </option>
                          );
                        })}
                      </FormSelect>
                    </div>
                  )}
                </div>

                <FormButton
                  type="button"
                  ref={this.sectionThree}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(3)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}
          {this.state.activeStep === 3 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back1"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor2-1"
                    onClick={() => this.changeStep(2)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Só mais alguns dados...</div>
                  </div>
                  {this.renderStepIndicators(3)}
                </div>
                <FormMask
                  mask="99999-999"
                  placeholder="CEP *"
                  className="accont-input w-input form-margin"
                  name="postal_code"
                  validators={['required']}
                  value={this.state.form.postal_code}
                  onChange={this.handleChangeInputText}
                  onBlur={this.handleCep}
                />
                <FormSelect
                  name="uf_id"
                  validators={['required']}
                  className="accont-input w-select"
                  value={this.state.form.uf_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Estado</option>

                  {this.props.states.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.uf} - {item.descricao}
                      </option>
                    );
                  })}
                </FormSelect>
                <FormInput
                  type="text"
                  placeholder="Endereço *"
                  className="accont-input w-input form-margin"
                  name="address"
                  validators={['required']}
                  value={this.state.form.address}
                  onChange={this.handleChangeInputText}
                />

                <div className="flex-row form-margin">
                  <input
                    type="text"
                    placeholder="Complemento"
                    className="accont-input w-input"
                    name="complement"
                    value={this.state.form.complement || ''}
                    onChange={this.handleChangeInputText}
                  />
                  <div className="space" />
                  <FormInput
                    type="number"
                    validators={['required']}
                    placeholder="Número *"
                    className="accont-input w-input"
                    name="number"
                    value={this.state.form.number}
                    onChange={this.handleChangeInputText}
                  />
                </div>
                <input
                  type="text"
                  placeholder="Bairro *"
                  className="accont-input w-input form-margin"
                  name="district"
                  value={this.state.form.district || ''}
                  onChange={this.handleChangeInputText}
                />
                <FormButton
                  type="button"
                  ref={this.sectionTwo}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(4)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}
          {this.state.activeStep === 4 && (
            <div>
              <div className="row w-row">
                <div
                  id="revisor-back2"
                  className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6"
                  data-ix="revisor-3-2"
                  onClick={() => this.changeStep(3)}
                >
                  <div></div>
                </div>
                <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                  <div>Quase lá...</div>
                </div>
                {this.renderStepIndicators(4)}
              </div>

              <FormValidator className="form">
                <div></div>
                <div className="w-row">
                  <div className="w-col w-col-12">
                    <div className="gender-checkbox w-checkbox terms-margin">
                      <h1 className="section-header">Quase lá...</h1>
                      <p className="paragraph-3">
                        Você já é quase um parceiro divulgador, leia e aceite os
                        nossos termos do contrato para se tornar nosso parceiro!
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />

                      <FormInput
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onClick={this.onCheck}
                        checked={this.state.form.terms ? true : false}
                        className="w-checkbox-input"
                      />
                      <label
                        htmlFor="terms"
                        className="w-form-label label-gray"
                      >
                        <span>Concordo com os </span>
                        <Link to="/termos" target="_blank">
                          Termos e Condições de Uso e Política de Privacidade do
                          Contexto Revisor.
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  disabled={!this.state.form.terms}
                  type="button"
                  className="hero-btn turquesa w-button"
                  onClick={this.handleFinish}
                >
                  Visualizar o <strong>contrato</strong> de parceria
                </button>
              </FormValidator>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapPropsToState = ({ reviewersStore, userStore }) => {
  let { states, trainings, divulgacao } = reviewersStore;
  let { accountCreated, accountError, accountErrorMsg } = userStore;
  let { loginSuccess, loginFailed, authenticated } = userStore;

  return {
    states,
    trainings,
    divulgacao,
    accountCreated,
    accountError,
    accountErrorMsg,
    loginSuccess,
    loginFailed,
    authenticated
  };
};

const PromoterPartnerSection = connect(mapPropsToState)(
  PromoterPartnerComponent
);
export default withRouter(PromoterPartnerSection);
