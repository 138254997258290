import React from 'react';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Footer from '../../siteComponents/Footer/index';
import routes from '../../../routes';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { default as ModalContexto } from '../../siteComponents/modal';

import { ApiService } from '../../../state-management/service/api';
import ReactPaginate from 'react-paginate';

import StarEvaluation from '../../siteComponents/StarEvaluation';
import Range from '../../siteComponents/Range';
import OrderByReviewer from '../../siteComponents/OrderByReviewer';

import ParceiroAvatar from '../../siteComponents/ParceiroAvatar';
import Modal from 'react-bootstrap/Modal';
import avatar1 from './images/avatar-1.svg';
import avatar2 from './images/avatar-2.svg';

import './AdminPanel.css';

const { painel, perfilRevisorSite } = routes;
const avatares = {
  feminino: avatar1,
  masculino: avatar2
};

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalProfile: false,
      loading: false,
      totalPages: 1,
      currentPage: 1,
      modalIntention: null,
      modalVisible: false,
      clickedReviewer: null,
      approvedReviewers: null,
      pendingReviewers: null,
      inactiveReviewers: null,
      publishedReviewers: null,
      unpublishedReviewers: null,
      unapprovedReviewers: null,
      totalReviewers: null,
      profiles: [],
      filteredProfiles: [],
      form: {
        searchBar: '',
        showFilters: true,
        filters: {
          rating: 0,
          pagePrice: null,
          revisions: 0,
          orderBy: '',
          checkbox: {
            approved: true,
            pending: true,
            published: true,
            unpublished: true,
            unapproved: true
          }
        }
      }
    };

    this.findReviewers(1);

    this.handleApply = this.handleApply.bind(this);
    this.handleButtonActivate = this.handleButtonActivate.bind(this);
    this.handleButtonDeactivate = this.handleButtonDeactivate.bind(this);
    this.handleButtonPublish = this.handleButtonPublish.bind(this);
    this.handleButtonUnpublish = this.handleButtonUnpublish.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handlePagePrice = this.handlePagePrice.bind(this);
    this.handleRating = this.handleRating.bind(this);
    this.handleRevisions = this.handleRevisions.bind(this);
    this.handleSearchbarChange = this.handleSearchbarChange.bind(this);
    this.handleShowFilters = this.handleShowFilters.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  findReviewers(page) {
    let form = this.state.form;

    ApiService.reviewers({
      searchQuery: form.searchBar,
      rating: form.filters.rating,
      pagePrice: form.filters.pagePrice,
      numberRevisions: form.filters.revisions,
      published: +form.filters.checkbox.published,
      unpublished: +form.filters.checkbox.unpublished,
      approved: +form.filters.checkbox.approved,
      pending: +form.filters.checkbox.pending,
      unapproved: +form.filters.checkbox.unapproved,
      all: true,
      page: page
    }).then(
      res => {
        console.log(res);
        this.setState(
          {
            profiles: res.users.data,
            currentPage: res.users.current_page,
            totalPages: res.users.last_page,
            totalReviewers: res.users.total,
            filteredProfiles: res.users.data,
            activeReviewers: res.active,
            publishedReviewers: res.published,
            unpublishedReviewers: res.unpublished,
            approvedReviewers: res.approved,
            pendingReviewers: res.pending,
            unapprovedReviewers: res.unapproved
          },
          () => {
            console.log('Atualizado!');
            //console.log(this.state.currentPage)
          }
        );
      },
      err => {
        console.error(err);
      }
    );
  }

  alterReviewer(reviewer, action, callback) {
    ApiService.adminActionOnReviewer({ reviewer, action }).then(
      res => {
        if (res.status == 200 || res.status == 204) callback();
      },
      err => {
        console.error('Deu erro', err);
      }
    );
  }

  handleApply(event) {
    this.findReviewers();
  }

  handleSeeProfile(reviewer) {
    this.setState({
      modalProfile: true,
      clickedReviewer: reviewer
    });
  }

  handlePageChange = e => {
    let page = e.selected + 1;
    this.findReviewers(page);
  };

  handleButtonActivate(reviewer) {
    this.setState(
      { modalIntention: 'ativar', clickedReviewer: reviewer },
      () => {
        this.modalShow();
      }
    );
  }

  handleButtonDeactivate(reviewer) {
    this.setState(
      { modalIntention: 'desativar', clickedReviewer: reviewer },
      () => {
        this.modalShow();
      }
    );
  }

  handleButtonPublish(reviewer) {
    this.setState(
      { modalIntention: 'publicar', clickedReviewer: reviewer },
      () => {
        this.modalShow();
      }
    );
  }

  handleButtonUnpublish(reviewer) {
    this.setState(
      { modalIntention: 'despublicar', clickedReviewer: reviewer },
      () => {
        this.modalShow();
      }
    );
  }

  handleCheck(val) {
    let newState = { ...this.state };
    newState.form.filters.checkbox[val] = !newState.form.filters.checkbox[val];
    this.setState(newState);
    this.findReviewers(1);
  }

  handleClear() {
    let newState = { ...this.state };
    newState.form.searchBar = '';
    newState.form.filters.pagePrice = null;
    this.setState(newState);
    this.findReviewers(1);
  }

  handleOrder(value) {
    let newState = { ...this.state };
    newState.form.filters.orderBy = value;
    this.setState(newState);
  }

  handlePagePrice(value) {
    let newState = { ...this.state };
    newState.form.filters.pagePrice = value;
    this.setState(newState);
  }

  handleRating(value) {
    let newState = { ...this.state };
    newState.form.filters.rating =
      value == newState.form.filters.rating ? 0 : value;
    this.setState(newState);
  }

  //handleAdminRating(value) {
  //
  //}

  handleRevisions(value) {
    let newState = { ...this.state };
    newState.form.filters.revisions = value;
    this.setState(newState);
  }

  handleSearchbarChange(event) {
    let newState = { ...this.state };
    newState.form.searchBar = event.target.value;
    this.setState(newState);
  }

  handleShowFilters() {
    let newState = { ...this.state };
    newState.form.showFilters = !newState.form.showFilters;
    this.setState(newState);
  }

  filterProfiles() {
    let newState = { ...this.state };
    let filters = newState.form.filters.checkbox;
    newState.filteredProfiles = newState.profiles.slice();

    if (!filters.published)
      newState.filteredProfiles = newState.filteredProfiles.filter(el => {
        return !el.published_at;
      });

    if (!filters.unpublished)
      newState.filteredProfiles = newState.filteredProfiles.filter(el => {
        return el.published_at;
      });

    if (!filters.approved)
      newState.filteredProfiles = newState.filteredProfiles.filter(el => {
        return el.published_at && !el.ativo;
      });

    if (!filters.pending)
      newState.filteredProfiles = newState.filteredProfiles.filter(el => {
        return el.ativo;
      });

    if (!filters.deactivated)
      newState.filteredProfiles = newState.filteredProfiles.filter(el => {
        return el.ativo;
      });

    //newState.totalPages = Math.rof(newState.filteredProfiles.length / 100) + 1;
    this.setState(newState);
    console.log(this.state.filteredProfiles);
  }

  modalShow = () => {
    this.setState({ modalVisible: true });
  };

  modalDismiss = () => {
    this.setState({ modalVisible: false });
  };

  modalConfirmActivate = revisor => {
    //console.log('ativou ' + revisor);

    this.alterReviewer(revisor, 'activate', () => {
      let profiles = this.state.profiles.slice();
      let i = profiles.map(value => value.id).indexOf(revisor.id);
      profiles[i].ativo = true;
      this.setState({ profiles: profiles });
    });

    this.modalDismiss();
  };

  modalConfirmDeactivate = revisor => {
    //console.log('desativou ' + revisor);

    this.alterReviewer(revisor, 'deactivate', () => {
      let profiles = this.state.profiles.slice();
      let i = profiles.map(value => value.id).indexOf(revisor.id);
      profiles[i].ativo = false;
      this.setState({ profiles: profiles });
    });

    this.modalDismiss();
  };

  modalConfirmPublish = revisor => {
    //console.log('publicou ' + revisor);

    this.alterReviewer(revisor, 'publish', () => {
      let profiles = this.state.profiles.slice();
      let i = profiles.map(value => value.id).indexOf(revisor.id);
      profiles[i].published_at = Date().toString();
      this.setState({ profiles: profiles });
    });

    this.modalDismiss();
  };

  modalConfirmUnpublish = revisor => {
    //console.log('despublicou', revisor);
    this.alterReviewer(revisor, 'unpublish', () => {
      let profiles = this.state.profiles.slice();
      let i = profiles.map(value => value.id).indexOf(revisor.id);
      profiles[i].published_at = null;
      this.setState({ profiles: profiles });
    });
    this.modalDismiss();
  };

  toUpperFirst = str => {
    if (str) {
      let restOfWord = str.slice(1);
      return str[0].toUpperCase() + restOfWord;
    }
  };

  render() {
    let getOkClick = reviewer => {
      switch (this.state.modalIntention) {
        case 'ativar':
          return () => this.modalConfirmActivate(reviewer);

        case 'publicar':
          return () => this.modalConfirmPublish(reviewer);

        case 'despublicar':
          return () => this.modalConfirmUnpublish(reviewer);

        default:
          return () => console.log('Não entendi');
      }
    };

    return (
      <div>
        <ModalContexto
          visible={this.state.modalVisible}
          title={this.toUpperFirst(this.state.modalIntention) + ' revisor'}
          message={
            'Deseja ' +
            this.toUpperFirst(this.state.modalIntention) +
            ' o perfil de ' +
            this.state.clickedReviewer?.name +
            '?'
          }
          onDismiss={() => this.modalDismiss(this.state.clickedReviewer)}
          okText={
            this.state.modalIntention != 'desativar' &&
            this.toUpperFirst(this.state.modalIntention)
          }
          onOkClick={getOkClick(this.state.clickedReviewer)}
          dangerText={this.state.modalIntention == 'desativar' && 'Desativar'}
          onDangerClick={() =>
            this.modalConfirmDeactivate(this.state.clickedReviewer)
          }
        />

        <Modal
          show={this.state.modalProfile}
          onHide={() => this.setState({ modalProfile: false })}
          aria-labelledby="example-custom-modal-styling-title"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.clickedReviewer?.name}
            </Modal.Title>
            {/*<div className="modal-star">
                  <StarEvaluation starCallback={this.handleAdminRating} />
        </div>*/}
          </Modal.Header>
          <Modal.Body>
            <h4>Sobre:</h4>
            <p>
              {this.state.clickedReviewer?.sobre
                ? this.state.clickedReviewer.sobre
                : 'Não informado'}
            </p>
            <h4>Valor da Lauda:</h4>
            <p>
              {this.state.clickedReviewer?.page_price_cents
                ? `R$ ${parseFloat(
                    this.state.clickedReviewer?.page_price_cents / 100
                  )
                    .toFixed(2)
                    .replace('.', ',')}`
                : 'Não informado'}
            </p>
            <h4>Documentos:</h4>
            <ul>
              <li>
                <a href={this.state.clickedReviewer?.curriculum}>Currículo</a>
              </li>
              {this.state.clickedReviewer?.attachments &&
                this.state.clickedReviewer?.attachments.map((attachment, i) => (
                  <li>
                    <a href={attachment.file}>{attachment.title}</a>
                  </li>
                ))}
            </ul>
            <h4>Experiências</h4>
            {this.state.clickedReviewer?.experiences &&
              this.state.clickedReviewer?.experiences.map((experience, i) => (
                <li>
                  {experience.title}
                  <p>{experience.description}</p>
                </li>
              ))}
            {!this.state.clickedReviewer?.experiences ||
              (this.state.clickedReviewer?.experiences.length === 0 && (
                <p>Não informado</p>
              ))}
          </Modal.Body>
        </Modal>

        <GeneralMenu selected={painel} {...this.props} />
        <div className="light-padding section-dark">
          {/* Caixa de filtros */}
          <div className="filter-form w-form">
            {/* Parte de cima do filtro */}
            <div className="filter-top">
              {/* Searchbar */}
              <input
                type="text"
                className="filter-input w-input"
                maxLength="256"
                name="filtro-nome"
                data-name="filtro-nome"
                placeholder="Digite sua palavra chave"
                id="filtro-nome"
                value={this.state.form.searchBar}
                onChange={this.handleSearchbarChange}
              />
              {/* Limpa a searchbar */}
              {this.state.form.searchBar && (
                <button
                  className="filter-btn close-btn w-button"
                  type="button"
                  onClick={this.handleClear}
                >
                  x
                </button>
              )}

              {/* Pesquisa */}
              <button
                className="filter-btn w-button"
                onClick={this.handleApply}
              />

              {/* Ativa/Desativa os filtros */}
              <button
                onClick={this.handleShowFilters}
                type="button"
                className="open-filter w-button"
              />
            </div>

            {/* Filtros */}
            {this.state.form.showFilters && (
              <ul className="filter-dropdown-app w-list-unstyled">
                <li className="filter-item">
                  <StarEvaluation starCallback={this.handleRating} />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <Range
                    title="Valor da lauda"
                    infText="R$5"
                    infValue={5}
                    supText="R$100"
                    supValue={100}
                    value={this.state.form.filters.pagePrice}
                    change={this.handlePagePrice}
                  />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <Range
                    title="Nº de Revisões"
                    infText="0"
                    infValue={0}
                    supText="300"
                    supValue={300}
                    value={this.state.form.filters.revisions}
                    change={this.handleRevisions}
                  />
                </li>
                <li className="filter-sep" />
                <li className="filter-item">
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.form.filters.checkbox.published}
                        name="published"
                        onChange={() => this.handleCheck('published')}
                      />
                      <p>Publicados ({this.state.publishedReviewers}) </p>
                    </label>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={this.state.form.filters.checkbox.unpublished}
                          name="unpublished"
                          onChange={() => this.handleCheck('unpublished')}
                        />
                        <p>
                          Não Publicados ({this.state.unpublishedReviewers}){' '}
                        </p>
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          checked={this.state.form.filters.checkbox.approved}
                          name="approved"
                          onChange={() => this.handleCheck('approved')}
                        />
                        <p>Aprovados ({this.state.approvedReviewers})</p>
                      </label>
                    </div>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.form.filters.checkbox.pending}
                        name="pending"
                        onChange={() => this.handleCheck('pending')}
                      />
                      <p>
                        Aguardando Aprovação ({this.state.pendingReviewers})
                      </p>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.form.filters.checkbox.unapproved}
                        name="unapproved"
                        onChange={() => this.handleCheck('unapproved')}
                      />
                      <p>Reprovados ({this.state.unapprovedReviewers})</p>
                    </label>
                  </div>
                </li>
                <li className="filter-sep" />
                <li className="filter-item last-item apply-btn">
                  <button
                    className="button w-button filter-button"
                    type="submit"
                    onClick={this.handleApply}
                  >
                    APLICAR
                  </button>
                  <button
                    className="button w-button filter-button"
                    onClick={this.handleClear}
                  >
                    LIMPAR
                  </button>
                </li>
              </ul>
            )}
          </div>

          {this.state.totalReviewers && this.state.totalReviewers !== 0 && (
            <p className="bold pre-title">
              Exibindo
              <span className="result-number">
                {` ${this.state.filteredProfiles.length} `}
              </span>
              de
              <span className="result-number">
                {` ${this.state.totalReviewers} `}
              </span>
              revisores encontrados
            </p>
          )}
          {this.state.totalReviewers === 0 && (
            <span className="result-number">
              Nenhum revisor encontrado com estes filtros.
            </span>
          )}

          <div className="admin-profile-container">
            {this.state.filteredProfiles.map((profile, i) => (
              <div key={`profile-${profile.id}`} className="admin-profile-card">
                <div
                  className={
                    profile.ativo
                      ? profile.published_at
                        ? 'published-top'
                        : 'unpublished-top'
                      : 'deactivated-top'
                  }
                ></div>
                <div className="top-row">
                  <div className="two-fifties">
                    {profile.roles && profile.roles[0].id === 4 ? (
                      <div alt="avatar" className="card-avatar">
                        <ParceiroAvatar
                          id={profile.id}
                          src={
                            profile.imagem
                              ? profile.imagem
                              : profile.sexo === 'M'
                              ? avatares.masculino
                              : avatares.feminino
                          }
                          onError={evt => {
                            evt.target.src =
                              profile.sexo === 'M'
                                ? avatares.masculino
                                : avatares.feminino;
                          }}
                          alt="avatar"
                          className="card-avatar"
                        />
                      </div>
                    ) : (
                      <img
                        id={profile.id}
                        src={
                          profile.imagem
                            ? profile.imagem
                            : profile.sexo === 'M'
                            ? avatares.masculino
                            : avatares.feminino
                        }
                        onError={evt => {
                          evt.target.src =
                            profile.sexo === 'M'
                              ? avatares.masculino
                              : avatares.feminino;
                        }}
                        alt="avatar"
                        className="card-avatar"
                      />
                    )}
                  </div>
                  <div className="three-fifties">
                    <div
                      className={`name ${
                        profile.ativo
                          ? profile.published_at
                            ? 'name-published'
                            : 'name-unpublished'
                          : 'name-deactivated'
                      }`}
                    >
                      {profile.name}
                    </div>
                    {profile.ativo ? (
                      <div className="published">
                        {!profile.published_at && (
                          <strong>⚠️ Não publicado</strong>
                        )}
                      </div>
                    ) : (
                      <div className="published">
                        {
                          <strong className="name-deactivated">
                            ❌ Desativado
                          </strong>
                        }
                      </div>
                    )}
                  </div>
                </div>
                <div className="content-row">
                  <div className="tag">Email:</div>
                  <div className="email">
                    <a href={`mailto:${profile.email}`}>{profile.email}</a>
                  </div>
                  <div className="tag">Whatsapp:</div>
                  {profile.whatsapp && (
                    <div>
                      <a
                        href={`https://api.whatsapp.com/send?phone=+55${profile.whatsapp}`}
                      >
                        {profile.whatsapp}
                      </a>
                    </div>
                  )}
                  {!profile.whatsapp && <div>Não informado</div>}
                  <div className="tag">Data de cadastro:</div>
                  <div className="date_created">{profile.created_at}</div>
                </div>
                <div className="action-row">
                  <div style={{ height: '40%' }}>
                    <button
                      className="card-btn w-button"
                      style={{
                        width: 'calc( 100% - 32px )',
                        marginLeft: '16px'
                      }}
                      onClick={() => this.handleSeeProfile(profile)}
                    >
                      Ver perfil
                    </button>
                  </div>
                  {profile.ativo ? (
                    <div style={{ height: '40%' }}>
                      <button
                        className={`btn ${
                          profile.ativo ? 'btn-danger' : 'btn-bg-success'
                        } erase`}
                        onClick={() => this.handleButtonDeactivate(profile)}
                      >
                        <span
                          className="glyphicon glyphicon-remove"
                          aria-hidden="true"
                          style={{ marginRight: '8px' }}
                        ></span>{' '}
                        Desativar
                      </button>
                      <button
                        className={`btn ${
                          profile.published_at
                            ? 'btn-warning btn-bg-warn'
                            : 'btn-success btn-bg-success'
                        } publish-unpublish`}
                        onClick={
                          profile.published_at
                            ? () => this.handleButtonUnpublish(profile)
                            : () => this.handleButtonPublish(profile)
                        }
                      >
                        {profile.published_at ? 'Despublicar' : 'Publicar'}
                      </button>
                    </div>
                  ) : (
                    <div style={{ height: '40%' }}>
                      <button
                        className="btn btn-success btn-bg-success publish-unpublish"
                        style={{
                          width: 'calc( 100% - 32px )',
                          marginLeft: '16px'
                        }}
                        onClick={() => this.handleButtonActivate(profile)}
                      >
                        Reativar
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ReactPaginate
          previousLabel={'← Anterior'}
          nextLabel={'Próxima →'}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.totalPages}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePageChange}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
        />
        <Footer />
      </div>
    );
  }
}

export default withRouter(AdminPanel);
