import React from 'react';
import form from './form';

export const FormValidator = form(props => {
  const {
    getValues,
    validate,
    validateAll,
    showError,
    hideError,
    ...filteredProps
  } = props;

  return <form {...filteredProps}>{props.children}</form>;
});
