import { connect } from 'react-redux';
import SearchReviewer from './SearchReviewer';
import { logoff } from '../../../state-management/user/actions';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchReviewer);
