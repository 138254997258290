import { connect } from 'react-redux';
import StarEvaluation from './StarEvaluation';
import {
  changePagePrice,
  changeNumberRevisions,
  changeRating
} from '../../../state-management/reviewers/actions';

const mapStateToProps = ({ reviewersStore }) => ({
  rating: reviewersStore.rating,
  pagePrice: reviewersStore.pagePrice,
  numberRevisions: reviewersStore.numberRevisions
});
const mapDispatchToProps = dispatch => ({
  changePagePrice: price => dispatch(changePagePrice(price)),
  changeNumberRevisions: number => dispatch(changeNumberRevisions(number)),
  changeRating: rating => dispatch(changeRating(rating))
});

export default connect(mapStateToProps, mapDispatchToProps)(StarEvaluation);
