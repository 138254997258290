import { combineReducers } from 'redux';
import reviewersStore from './reviewers/reducers';
import userStore from './user/reducers';
import notificationStore from './notification/reducers';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

const reducers = combineReducers({
  reviewersStore,
  userStore,
  notificationStore,
  route: routerReducer,
  form: formReducer
});

export { reducers };
