import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes';

const { criarContaRedator, criarContaRevisor, login } = routes;

const ChooseRegisterProfileSection = () => (
  <div className="halfbox login-box">
    <div className="row w-row">
      <div className="gray-list w-col w-col-1 w-col-small-6 w-col-tiny-1">
        <div>
          <Link className="no-style" to="/login">
            
          </Link>
        </div>
      </div>
      <div className="gray-list w-col w-col-11 w-col-small-6 w-col-tiny-11">
        <div>Criar conta</div>
      </div>
    </div>
    <div className="account-box">
      <div className="account-halfleft">
        <div className="redator-img">
          <FontAwesomeIcon icon="pencil-alt" />
        </div>
        <div>Cliente</div>
        <p className="pre-title">
          Você não paga nada para criar sua conta e escolher o revisor ideal
          para seu texto.
        </p>
        <Link to={criarContaRedator} className="button create-redator w-button">
          Criar conta
        </Link>
      </div>
      <div className="account-halfright">
        <div className="redator-img">
          <FontAwesomeIcon icon="eye" />
        </div>
        <div>Revisor</div>
        <p className="pre-title">
          Conquiste clientes, utilize ferramentas exclusivas e se torne um
          revisor-empreendedor.
        </p>
        <Link to={criarContaRevisor} className="button create-revisor w-button">
          Criar conta
        </Link>
      </div>
    </div>
    <div className="toggle-login">
      <div>Já tem uma conta?</div>
      <Link to={login} className="toggle-link">
        Efetuar login
      </Link>
    </div>
  </div>
);
export default ChooseRegisterProfileSection;
