import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { userPreviewState } from '../../../../state-management';

class AnexosComponent extends Component {
  status = 'clean';
  state = {
    formInvalid: true,
    formData: {
      title: '',
      file: null
    },
    anexosAntigos: null,
    anexos: [
      /*
       {
       title: "title",
       file: "http://adress.to/path/file.*"
       }
       */
    ]
  };
  fileRef = null;

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    if (props.value) {
      this.state.anexos = props.value;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ anexos: nextProps.value });
    }
  }

  addAnexo = () => {
    let state = { ...this.state };
    state.anexosAntigos = state.anexos;
    state.anexos.push({ ...this.state.formData, upload: true });
    state.formData = { title: '', file: null };
    state.formInvalid = true;
    this.fileRef.current.value = '';

    this.setState(state);
    this.props.onChange({ name: 'attachments', value: state.anexos });

    this.status = 'clean';
  };

  removeAnexo = index => {
    let state = { ...this.state };
    state.anexos.splice(index, 1);
    this.setState(state);
    this.status = 'clean';
  };

  handleInputChange = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.formData[name] = value;
    this.setState(state);
    this.validate();
  };

  handleFileChange = e => {
    let state = { ...this.state };
    let aux = e.target.files[0];
    if (aux) {
      if (aux.size <= 66060288) {
        state.formData.file = aux;
        this.status = 'selected';
      } else {
        state.formData.file = null;
        this.status = 'size_error';
      }
    } else {
      state.formData.file = null;
      this.status = 'error';
    }
    this.setState(state);
    this.validate();
  };

  validate() {
    this.setState({
      formInvalid: !(this.state.formData.title && this.state.formData.file)
    });
  }

  maxTextRender = (n, str) => {
    if (str.length > n) return str.substring(0, n) + '...';
    else return str;
  };

  render() {
    return (
      <div className="flex-column add-item-inverse-container">
        <h5 className="config-subtitle">Anexos</h5>

        <div className="anexo-container">
          {this.state.anexos.map((anexo, index) => (
            <div key={index} className="item-exp">
              <div style={{ flex: 4, display: 'flex' }}>
                <h5 style={{ alignSelf: 'center' }}>
                  {this.maxTextRender(50, anexo.title)}
                </h5>
              </div>
              <div className="icon-exp">
                <span
                  className="icon-anexo"
                  onClick={this.removeAnexo.bind(this, index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </span>
              </div>
            </div>
          ))}
          {this.status !== 'clean' && (
            <div className="item-exp">
              {this.status === 'selected' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Você selecionou o anexo com sucesso. Agora é só clicar em{' '}
                  <strong>'Adicionar anexo'</strong>!
                </h5>
              )}
              {this.status === 'size_error' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Por favor, envie um arquivo com <strong>tamanho menor</strong>{' '}
                  que <strong>64MB</strong>.
                </h5>
              )}
              {this.status === 'error' && (
                <h5 style={{ alignSelf: 'center' }}>
                  Ocorreu um <strong>erro</strong>. Por favor, envie novamente o
                  arquivo ou tente enviar um outro arquivo.
                </h5>
              )}
            </div>
          )}
        </div>

        <input
          type="text"
          placeholder="Título do anexo"
          className="accont-input w-input"
          name="title"
          value={this.state.formData.title}
          onChange={this.handleInputChange}
        />

        <div class="flexbox">
          <label for="files" className="btn button w-button link-btn">
            Selecionar anexo
          </label>
          <button
            className="btn button w-button small-btn inverse-btn add-btn"
            onClick={this.addAnexo}
            disabled={this.state.formInvalid}
          >
            Adicionar anexo
          </button>
        </div>

        <input
          id="files"
          type="file"
          placeholder="Anexo"
          name="file"
          accept=".pdf"
          style={{ visibility: 'hidden' }}
          onChange={this.handleFileChange}
          ref={this.fileRef}
        />
      </div>
    );
  }
}

const mapToStateProps = ({ userStore }) => {
  let { previewState } = userStore;
  return previewState;
};

const mapDispatchToProps = dispatch => ({
  userPreviewState: data => dispatch(userPreviewState(data))
});

const Anexos = connect(mapToStateProps, mapDispatchToProps)(AnexosComponent);

export { Anexos };
