import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfileMenu from '../ProfileMenu';
import routes from '../../../routes';
import marcaContexto from '../../assetsHolder/images/logo-horizontal-white.png';
import './MainMenu.css';
import Sidebar from './SidebarMenu';
import ReactDOM from 'react-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { ApiService } from '../../../state-management/service/api';
import { bool, func, object } from 'prop-types';

const {
  buscarRevisor,
  sejaRevisor,
  login,
  contato,
  sobre,
  profile,
  parcerias,
  painel,
  painelClients
} = routes;

export const MainMenu = ({ authenticated, logoff, history }) => {
  const [show, setShow] = useState(false);

  let viewer = ApiService.getUser()?.user;
  const [isAdmin] = useState(
    viewer?.role && viewer.role.map(role => role.name)?.indexOf('admin') != -1
  );

  function handleState() {
    setShow(!show);
  }

  return (
    <div className="container-2 w-container">
      <nav className="hero-nav w-nav-menu">
        <Link to="/" className="brand w-nav-brand">
          <img src={marcaContexto} alt="marca" />
        </Link>

        {authenticated && isAdmin && (
          <DropdownButton
            className="hero-navlink w-nav-link"
            id="dropdown-painel-admin"
            title="Painel de Admin"
          >
            <Dropdown.Item href={painel}>Visualizar Revisores</Dropdown.Item>
            <Dropdown.Item href={painelClients}>
              Visualizar Clientes
            </Dropdown.Item>
          </DropdownButton>
        )}

        <Link to={buscarRevisor} className="hero-navlink w-nav-link">
          Buscar Revisor
        </Link>

        <Link to={sejaRevisor} className="hero-navlink w-nav-link">
          Seja Revisor
        </Link>

        {/*
        <Link to={parcerias} className="hero-navlink w-nav-link">
          Seja Parceiro
        </Link>
        */}
        <Link to={sobre} className="hero-navlink w-nav-link">
          Sobre nós
        </Link>

        <Link to={contato} className="hero-navlink w-nav-link">
          Contato
        </Link>

        {authenticated ? (
          <ProfileMenu
            history={history}
            logoff={logoff}
            profileRoute={profile}
          />
        ) : (
          <Link to={login} className="hero-navbtn w-nav-link">
            Entrar
          </Link>
        )}
      </nav>
      <div className="home-menu w-nav-button">
        <div id="menu-btn" onClick={handleState} className="w-icon-nav-menu" />
        {ReactDOM.createPortal(<Sidebar show={show} />, document.body)}
      </div>
    </div>
  );
};

MainMenu.propTypes = {
  authenticated: bool,
  logoff: func,
  history: object
};

export default MainMenu;
