import React, { Component } from 'react';
import Button from 'react-validation/build/button';

class FormButton extends Component {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>;
  }
}

export { FormButton };
