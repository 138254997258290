import React from 'react';
import imageAvatarFem from '../../../../assetsHolder/images/avatar-1.svg';
import imageAvatarMasc from '../../../../assetsHolder/images/avatar-2.svg';
import ParceiroAvatar from './../../../../siteComponents/ParceiroAvatar';
const ProfileHeaderSection = ({ reviewer, states, roles }) => {
  let uf = '';

  if (
    reviewer.attributes &&
    reviewer.attributes.uf_id &&
    Object.keys(states).length > 0
  ) {
    uf = states[reviewer.attributes.uf_id].descricao;
  }

  let handleStandardImage = gender =>
    gender === 'F' ? imageAvatarFem : imageAvatarMasc;

  return (
    <div id="perfil" className="large-container">
      <div className="perfil-info">
        <div className="flex-container">
          {roles === 'parceiro' ? (
            <div className="perfil-avatar">
              <ParceiroAvatar
                src={
                  (reviewer.attributes && reviewer.attributes.imagem) ||
                  handleStandardImage(reviewer.sexo)
                }
                alt="avatar"
              />
            </div>
          ) : (
            <img
              src={
                (reviewer && reviewer.imagem) ||
                handleStandardImage(reviewer.sexo)
              }
              className="perfil-avatar"
              alt="avatar"
            />
          )}

          <div className="three-fifth">
            <div className="w-row">
              <div className="w-col w-col-6">
                <p className="perfil-name">{reviewer && reviewer.name}</p>
                <p className="perfil-function">{reviewer && reviewer.job}</p>
                <p className="small-paragraph">{uf}</p>
                <div className="giant-text-turquoise">
                  <span className="star-icon"></span>{' '}
                  {reviewer && reviewer.score}
                </div>
              </div>
            </div>
          </div>
          <div className="two-fifth">
            <ul className="quanti-numbers w-list-unstyled">
              <li className="quant">
                <div className="giant-text-turquoise">
                  {reviewer && reviewer.qtdRevisions}
                </div>
                <p className="small-paragraph">revisões</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeaderSection;
