import { func, string, ReactComponentLike } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export const Box = ({
  className,
  superTitle,
  title,
  content,
  linkText,
  footer,
  boxType,
  to,
  onFooterClick
}) => (
  <div className={'hero-box ' + className}>
    <div className={'texto-' + boxType}>{superTitle}</div>
    <div className={boxType + '-boxtitle'}>{title}</div>
    <p className="box-text">{content}</p>
    <Link
      to={to}
      className={
        boxType === 'revisor'
          ? 'hero-btn turquesa w-button'
          : 'hero-btn w-button'
      }
    >
      {linkText}
    </Link>
    <div className="stripe" />
    <div
      className={'darkgray-text ' + (onFooterClick ? 'pointer' : '')}
      onClick={onFooterClick}
    >
      {footer}
    </div>
  </div>
);

Box.propTypes = {
  className: string,
  superTitle: string,
  title: string,
  content: ReactComponentLike,
  linkText: string,
  footer: string,
  boxType: string,
  to: string,
  onFooterClick: func
};
