import React from 'react';
import { string } from 'prop-types';

export const Blurb = ({ content, title, description, color = 'false' }) => (
  <div className="progress-item">
    <div className={`progress-number ${color === 'true' ? 'color' : ''}`}>
      <div>{content}</div>
    </div>
    <div className="darkgray-text">{title}</div>
    <p>{description}</p>
  </div>
);

Blurb.propTypes = {
  content: string.isRequired,
  title: string.isRequired,
  description: string,
  color: string
};

Blurb.defaultProps = {
  content: '',
  title: '',
  description: '',
  color: 'false'
};
