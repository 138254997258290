import { connect } from 'react-redux';
import OrderByReviewer from './OrderByReviewer';
import { changeOrder } from '../../../state-management/reviewers/actions';

const mapStateToProps = ({ reviewersStore, route }) => ({
  orderType: reviewersStore.orderType
});
const mapDispatchToProps = dispatch => ({
  changeOrder: type => dispatch(changeOrder(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderByReviewer);
