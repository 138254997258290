import { fork } from 'redux-saga/effects';
import { requests } from './user/sagas';
import { requestsReviewer } from './reviewers/sagas';

function* sagas() {
  yield fork(requests);
  yield fork(requestsReviewer);
}

export { sagas };
