import React from 'react';
import { connect } from 'react-redux';
import '../../assetsHolder';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import MainSection from './sections/MainSection';
import PriceAndResourcesSection from './sections/PriceAndResourcesSection';
import { logoff } from '../../../state-management/user/actions';
import Footer from '../../siteComponents/Footer';
import routes from '../../../routes';
import { Redirect } from 'react-router-dom';

const { sejaRevisor } = routes;

const BecomeReviewerComponent = props =>
  props.authenticated ? (
    <Redirect to="/profile" />
  ) : (
    <div>
      <GeneralMenu selected={sejaRevisor} {...props} />
      <MainSection />
      <PriceAndResourcesSection />
      <Footer />
    </div>
  );

const mapStateToProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});
const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});

const BecomeReviewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomeReviewerComponent);
export default BecomeReviewer;
