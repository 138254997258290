import { makeActionCreator } from '../util';

export const REVIEWER_CREATE_COUNTER_PROPOSAL =
  'REVIEWER_CREATE_COUNTER_PROPOSAL';
export const reviewerCreateCounterProposal = makeActionCreator(
  REVIEWER_CREATE_COUNTER_PROPOSAL,
  'data',
  'id'
);

export const REVIEWER_CREATE_PROPOSAL = 'REVIEWER_CREATE_PROPOSAL';
export const reviewerCreateProposal = makeActionCreator(
  REVIEWER_CREATE_PROPOSAL,
  'data'
);

export const REVIEWER_CREATE_PROPOSAL_SUCCESS =
  'REVIEWER_CREATE_PROPOSAL_SUCCESS';
export const reviewerCreateProposalSuccess = makeActionCreator(
  REVIEWER_CREATE_PROPOSAL_SUCCESS,
  'data'
);

export const REVIEWER_CREATE_PROPOSAL_ERROR = 'REVIEWER_CREATE_PROPOSAL_ERROR';
export const reviewerCreateProposalError = makeActionCreator(
  REVIEWER_CREATE_PROPOSAL_ERROR
);

export const REVIEWER_CREATE_COUNTER_PROPOSAL_SUCCESS =
  'REVIEWER_CREATE_COUNTER_PROPOSAL_SUCCESS';
export const reviewerCreateCounterProposalSuccess = makeActionCreator(
  REVIEWER_CREATE_COUNTER_PROPOSAL_SUCCESS,
  'data'
);

export const REVIEWER_DISAPPROVE_PROPOSAL = 'REVIEWER_DISAPPROVE_PROPOSAL';
export const reviewerDisapproveProposal = makeActionCreator(
  REVIEWER_DISAPPROVE_PROPOSAL,
  'id'
);

export const REVIEWER_DISAPPROVE_PROPOSAL_SUCCESS =
  'REVIEWER_DISAPPROVE_PROPOSAL_SUCCESS';
export const reviewerDisapproveProposalSuccess = makeActionCreator(
  REVIEWER_DISAPPROVE_PROPOSAL_SUCCESS
);

export const REVIEWER_APPROVE_PROPOSAL = 'REVIEWER_APPROVE_PROPOSAL';
export const reviewerApproveProposal = makeActionCreator(
  REVIEWER_APPROVE_PROPOSAL,
  'id'
);

export const REVIEWER_APPROVE_PROPOSAL_SUCCESS =
  'REVIEWER_APPROVE_PROPOSAL_SUCCESS';
export const reviewerApproveProposalSuccess = makeActionCreator(
  REVIEWER_APPROVE_PROPOSAL_SUCCESS
);

export const FETCH_TRAININGS = 'FETCH_TRAININGS';
export const fetchTrainings = makeActionCreator(FETCH_TRAININGS);

export const FETCHED_TRAININGS = 'FETCHED_TRAININGS';
export const fetchedTrainings = makeActionCreator(FETCHED_TRAININGS, 'data');

export const FETCH_STATES = 'FETCH_STATES';
export const fetchStates = makeActionCreator(FETCH_STATES);

export const FETCHED_STATES = 'FETCHED_STATES';
export const fetchedStates = makeActionCreator(FETCHED_STATES, 'data');

export const FETCH_DIVULGACAO = 'FETCH_DIVULGACAO';
export const fetchDivulgacao = makeActionCreator(FETCH_DIVULGACAO);

export const FETCHED_DIVULGACAO = 'FETCHED_DIVULGACAO';
export const fetchedDivulgacao = makeActionCreator(FETCHED_DIVULGACAO, 'data');

export const FETCH_REVIEWERS = 'FETCH_REVIEWERS';
export const fetchReviewers = makeActionCreator(FETCH_REVIEWERS, 'data');

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const fetchPartners = makeActionCreator(FETCH_PARTNERS, 'data');

export const FETCHED_REVIEWERS = 'FETCHED_REVIEWERS';
export const fetchedReviewers = makeActionCreator(
  FETCHED_REVIEWERS,
  'data',
  'options'
);

export const FETCHED_PARTNERS = 'FETCHED_PARTNERS';
export const fetchedPartners = makeActionCreator(FETCHED_PARTNERS, 'data');

export const FETCH_RANDOM_REVIEWERS = 'FETCH_RANDOM_REVIEWERS';
export const fetchRandomReviewers = makeActionCreator(FETCH_RANDOM_REVIEWERS);

export const FETCHED_RANDOM_REVIEWERS = 'FETCHED_RANDOM_REVIEWERS';
export const fetchedRandomReviewers = makeActionCreator(
  FETCHED_RANDOM_REVIEWERS,
  'data'
);

export const FETCH_REVIEWER = 'FETCH_REVIEWER';
export const fetchReviewer = makeActionCreator(FETCH_REVIEWER, 'apelido');

export const FETCHED_REVIEWER = 'FETCHED_REVIEWER';
export const fetchedReviewer = makeActionCreator(FETCHED_REVIEWER, 'data');

export const CLEAR_QUERY_FILTER = 'CLEAR_QUERY_FILTER';
export const clearQueryFilter = makeActionCreator(CLEAR_QUERY_FILTER);

export const APPLY_QUERY_FILTER = 'APPLY_QUERY_FILTER';
export const applyQueryFilter = makeActionCreator(APPLY_QUERY_FILTER, 'data');

export const CHANGE_PAGE_PRICE = 'CHANGE_PAGE_PRICE';
export const changePagePrice = makeActionCreator(
  CHANGE_PAGE_PRICE,
  'pagePrice'
);

export const CHANGE_NUMBER_REVISIONS = 'CHANGE_NUMBER_REVISIONS';
export const changeNumberRevisions = makeActionCreator(
  CHANGE_NUMBER_REVISIONS,
  'numberRevisions'
);

export const CHANGE_RATING = 'CHANGE_RATING';
export const changeRating = makeActionCreator(CHANGE_RATING, 'rating');

export const CHANGE_ORDER = 'CHANGE_ORDER';
export const changeOrder = makeActionCreator(CHANGE_ORDER, 'orderType');

export const CHANGE_SEARCH_QUERY = 'CHANGE_SEARCH_QUERY';
export const changeSearchQuery = makeActionCreator(
  CHANGE_SEARCH_QUERY,
  'searchQuery'
);

export const USE_SEARCH_FILTER = 'USE_SEARCH_FILTER';
export const useSearchFilter = makeActionCreator(USE_SEARCH_FILTER);
