import React, { useState, useEffect } from 'react';
import GeneralMenu from '../../../../siteComponents/GeneralMenu/GeneralMenu';
import Footer from '../../../../siteComponents/Footer';
import Modal from '../../../../siteComponents/modal';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import routes from '../../../../../routes';
import { userCreatePartnerInstituition } from '../../../../../state-management';
import AgreementPDF from './AgreementPDF.js';
import { embeddedSigning } from '../../../../../state-management/user/actions';
import { pdf } from '@react-pdf/renderer';
import eversign from '../../eversign';

const { parcerias, criarContaInstituicaoParceira } = routes;

const InstitutionAgreement = ({ user, dispatch, history, signingURL }) => {
  const [showAccept, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (signingURL) {
      onScriptLoad(signingURL);
    }
  }, [signingURL]);

  const handleAccept = async () => {
    dispatch(userCreatePartnerInstituition(user));
    const doc = await pdf(<AgreementPDF user={user} />).toBlob();
    localStorage.setItem('email', user.email);
    dispatch(embeddedSigning(doc));
  };

  const onScriptLoad = signingURL => {
    eversign.open({
      url: signingURL,
      containerID: 'eversign',
      width: 600,
      height: 600,
      events: {
        loaded: () => {
          setShowButton(true);
        },
        signed: () => {
          setShow(true);
        },
        declined: function() {
          alert('Contrato recusado');
        },
        error: function() {
          alert('error callback');
        }
      }
    });
  };

  const handleClose = () => {
    history.push('/');
  };

  return user ? (
    <div className="about">
      <Modal
        message="Obrigado por participar! Sua proposta para tornar-se Instituição Parceira já está sob análise. Para mais informações, acabamos de lhe enviar um e-mail especial."
        okText="Okay"
        visible={showAccept}
        onlyOkButton={true}
        onOkClick={handleClose}
      />
      <GeneralMenu />
      <div className="section-dark light-padding">
        <div className="flexcourners large-container">
          <div className="terms-container" style={{ padding: '3%' }}>
            <h1 className="turquoise-text bold">
              CONTRATO DE PARCERIA COMERCIAL
            </h1>
            <p>
              PRECEPTOR EDUCAÇÃO A DIST NCIA LTDA ME, pessoa jurídica
              constituída na forma de Sociedade Limitada, inscrita no CNPJ sob o
              nº 07.633.844/0001-19, com sede na Rua Cel. Alves Teixeira, 1290,
              sala 5, bairro Dionísio Torres, CEP 60135-208, na cidade de
              Fortaleza, Estado do Ceará representada na forma de seus atos
              constitutivos, doravante denominada simplesmente{' '}
              <b>CONTEXTO REVISOR</b>, e, do outro lado,
            </p>
            <p>
              {user.name}, inscrita no CNPJ sob o nº {user.document}, com sede
              em {user.address}, {user.number}
              {user.complement && `, ${user.complement}`}, {user.district},{' '}
              {user.postal_code}, na cidade de {user.city} - {user.state} neste
              ato representada por seu(sua) representante{' '}
              {user.nome_responsavel_instituicao},{' '}
              {user.cargo_responsavel_instituicao}, inscrito no CPF sob o nº{' '}
              {user.cpf_responsavel}, doravante denominada simplesmente como{' '}
              <b>INSTITUIÇÃO PARCEIRA</b>.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA PRIMEIRA - DO OBJETO E NATUREZA JURÍDICA
            </h1>
            <p>
              1.1. O objeto contratual da parceria é firmar convênio entre as
              instituições, de forma que a <b>INSTITUIÇÃO PARCEIRA</b>{' '}
              possibilite a apresentação do sistema “Contexto Revisor” ao
              público.
            </p>
            <p>
              1.2. A <b>INSTITUIÇÃO PARCEIRA</b> disponibilizará seu espaço
              físico para livre trânsito nas dependências da instituição, bem
              como espaço em seu site, para prospecção de clientes demandantes
              de revisão de textos para a plataforma <b>CONTEXTO REVISOR</b>.
            </p>
            <p>
              1.3. A presente parceria é firmada sem caráter de exclusividade,
              podendo a <b>CONTEXTO REVISOR</b> realizar contratações com demais
              parceiros com objeto semelhante ao do presente instrumento. No
              entanto, a <b>INSTITUIÇÃO PARCEIRA</b> se compromete, no decorrer
              da presente parceria, a não firmar vínculos cujos objetos sejam
              conflitantes com o da presente parceria ou com empresas
              concorrentes da <b>CONTEXTO REVISOR</b>.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SEGUNDA - REMUNERAÇÃO E PAGAMENTO
            </h1>
            <p>
              2.1. Em razão desta parceria, a <b>CONTEXTO REVISOR</b> pagará à
              <b>INSTITUIÇÃO PARCEIRA</b> os seguintes valores:
            </p>
            <p>
              2.1.1. 8% (oito por cento) do valor total, retido o valor
              correspondente à tributação devida, proveniente de compra de
              serviços da <b>CONTEXTO REVISOR</b> por público da{' '}
              <b>INSTITUIÇÃO PARCEIRA</b>, aferíveis pela utilização do código
              da <b>INSTITUIÇÃO PARCEIRA</b>, a ela atribuído pela{' '}
              <b>CONTEXTO REVISOR</b>, serão repassados mensalmente a esta,
              enquanto perdurar este Contrato.
            </p>
            <p>
              2.1.2. Os pagamentos serão realizados todo dia 5 (cinco) do mês
              subsequente ao recebimento pela <b>CONTEXTO REVISOR</b>.
            </p>
            <p>
              2.2. O pagamento será justificado por meio de relatório específico
              disponibilizado pela <b>CONTEXTO REVISOR</b>, no qual estarão
              descritos os valores recebidos e a data de finalização de cada
              texto revisado.
            </p>
            <p>
              2.3. Todos os pagamentos feitos pela <b>CONTEXTO REVISOR</b> serão
              realizados por meio da plataforma on-line de pagamentos, em conta
              determinada pela <b>INSTITUIÇÃO PARCEIRA</b> no momento da
              assinatura do presente instrumento.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA
            </h1>
            <p>
              3.1. A <b>INSTITUIÇÃO PARCEIRA</b> se obriga a realizar as suas
              atividades, cabendo-lhe total e exclusiva responsabilidade pelo
              integral atendimento de toda a legislação que rege os negócios
              jurídicos e que lhe atribua responsabilidades. Igualmente, a
              <b>INSTITUIÇÃO PARCEIRA</b> se obriga também a reembolsar a
              CONTEXTO REVISOR por todas as despesas decorrentes de:
            </p>
            <p>
              a) reconhecimento judicial de vínculo empregatício seu ou de seus
              empregados com a <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              b) reconhecimento judicial de solidariedade da{' '}
              <b>CONTEXTO REVISOR</b> no cumprimento de suas obrigações
              trabalhistas e/ou previdenciárias;
            </p>
            <p>
              c) indenização, inclusive a terceiros, como consequência de
              eventuais danos causados pela <b>INSTITUIÇÃO PARCEIRA</b>, ou seus
              prepostos, decorrentes de negligência ou imperícia, na execução
              das suas atividades.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA QUARTA – OBRIGAÇÕES DA <b>CONTEXTO REVISOR</b>
            </h1>
            <p>
              4.1. São obrigações da <b>CONTEXTO REVISOR</b>:
            </p>
            <p>
              4.1.1. Dar assistência e orientação à <b>INSTITUIÇÃO PARCEIRA</b>{' '}
              para correta execução da parceria aqui estabelecida.
            </p>
            <p>
              4.1.2. Fornecer informações, desde que requisitadas, por meio de
              planilhas e relatórios, a fim de auxiliar e esclarecer dúvidas da
              <b>INSTITUIÇÃO PARCEIRA</b> a respeito da parceria exercida e
              valores recebidos.
            </p>
            <p>
              4.1.3. Realizar os repasses dos valores e das comissões, conforme
              definido na Cláusula Segunda;
            </p>
            <p>
              4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, cuidando para a observância
              das obrigações assumidas e visando prevenir erros de expectativas
              e interpretação.
            </p>
            <p>
              4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              da <b>INSTITUIÇÃO PARCEIRA</b> sem expressa autorização em
              documento específico.
            </p>
            <p>
              4.1.6. Identificar, no site, os revisores que se dispõem a ser
              parceiros e conceder 10% (dez por cento) de desconto sobre o valor
              da lauda.
            </p>
            <p>
              4.1.7. Fornecer arquivos com imagens de cartaz e folder/flyer,
              contendo código promocional específico, para impressão de material
              de divulgação por parte da <b>INSTITUIÇÃO PARCEIRA</b>, no
              objetivo de promover a parceria aqui estabelecida.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA QUINTA – OBRIGAÇÕES DA <b>INSTITUIÇÃO PARCEIRA</b>
            </h1>
            <p>
              5.1. São obrigações da <b>INSTITUIÇÃO PARCEIRA</b>:
            </p>
            <p>
              5.1.1. Utilizar seu espaço físico e na Internet para, junto da{' '}
              <b>CONTEXTO REVISOR</b>, divulgar e prospectar o público da
              instituição interessado em ter seus textos revisados.
            </p>
            <p>
              5.1.2. Recolher correta e pontualmente todas as obrigações e
              encargos trabalhistas, previdenciários, fiscais, parafiscais e
              outros que sejam ou venham a ser devidos em razão do presente
              contrato.
            </p>
            <p>
              5.1.3. Esclarecer plenamente seus empregados, caso possua, sobre
              as condições do contrato ora pactuado, cuidando para a observância
              das obrigações assumidas e visando prevenir erros de expectativas
              e interpretação.
            </p>
            <p>
              5.1.4. Responder integralmente pelos atos que vier a praticar
              pessoalmente ou através de prepostos ou funcionários, caso possua,
              que acarretem prejuízo à <b>CONTEXTO REVISOR</b> ou terceiro.
            </p>
            <p>
              5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome
              da <b>CONTEXTO REVISOR</b> sem expressa autorização em documento
              específico.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL
            </h1>
            <p>
              6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade
              e todos os direitos relativos ao software desenvolvido, como o
              código-fonte, especificações funcionais, fluxogramas, logomarca,
              dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
              REVISOR, podendo somente esta utilizá-los, cedê-los e
              transferi-los a terceiros.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO
            </h1>
            <p>
              7.1. A <b>INSTITUIÇÃO PARCEIRA</b> compromete-se a manter o mais
              absoluto sigilo de todas as informações de conteúdo confidencial,
              sejam elas comerciais, técnicas, pessoais e outras, cujo
              conhecimento tiver, em decorrência do exercício de suas
              atividades; além de não exercer qualquer forma de concorrência
              direta com a CONTEXTO REVISOR, podendo ser responsabilizada civil
              ou criminalmente por eventuais danos materiais ou morais
              decorrentes da não obediência a esta regra.
            </p>
            <p>
              7.2. Por definição, “Informações Confidenciais” significam todos
              as informações técnicas e gerais, know-how, documentos, programas
              de computador e documentação, códigos fonte, relatórios, dados
              financeiros ou outros dados, registros, formulários, ferramentas,
              produtos, serviços, pesquisa presente e futura, conhecimento
              técnico, planos de marketing, segredos comerciais e outros
              materiais tangíveis ou intangíveis, armazenados ou não, compilados
              ou reduzidos a termo, seja física, eletrônica ou graficamente, por
              escrito, ou por qualquer meio, que esteja em poder da{' '}
              <b>CONTEXTO REVISOR</b>, e que seja revelado à{' '}
              <b>INSTITUIÇÃO PARCEIRA</b>.
            </p>
            <p>
              7.3. As Informações Confidenciais incluem, sem limitação,
              materiais, arquivos e informações, bem como as informações de
              propriedade da <b>CONTEXTO REVISOR</b> ou de terceiros que: a)
              contenham a especificação de propriedade exclusiva ou
              confidencial; b) cuja natureza confidencial tenha sido informada à{' '}
              <b>INSTITUIÇÃO PARCEIRA</b>; c) consistam na solução, nas
              inovações e aperfeiçoamentos que sejam de criação e
              desenvolvimento individual ou em conjunto entre as PARTES; e d) em
              virtude de suas características e natureza seriam consideradas
              confidenciais em circunstâncias semelhantes.
            </p>
            <p>
              7.4. A <b>INSTITUIÇÃO PARCEIRA</b> concorda que a violação desta
              Cláusula, pelo uso de qualquer informação confidencial pertencente
              à <b>CONTEXTO REVISOR</b>, sem sua expressa autorização,
              causar-lhe-á danos e prejuízos irreparáveis.
            </p>
            <p>
              7.5. A <b>INSTITUIÇÃO PARCEIRA</b> concorda que as obrigações de
              Confidencialidade e Sigilo se iniciam a partir da assinatura do
              presente instrumento, perdurando na sua vigência e por 2 (dois)
              anos do término de qualquer que seja o vínculo existente entre as
              Partes. Desta forma e na hipótese de violação, a{' '}
              <b>CONTEXTO REVISOR</b>
              será imediatamente considerada legítima detentora do direito a
              tomar todas as medidas extrajudiciais e judiciais, nas esferas
              cíveis e criminais, inclusive de caráter cautelar ou de
              antecipação de tutela jurisdicional, que julgar cabíveis à defesa
              de seus direitos, inclusive autorais, independentemente da tomada
              das medidas judiciais tendentes a obter a reparação pelo dano
              causado.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
            </h1>
            <p>
              8.1. Toda e qualquer despesa que a <b>INSTITUIÇÃO PARCEIRA</b>{' '}
              tiver em virtude da execução desta parceria serão de exclusiva
              responsabilidade desta, cabendo apenas à <b>CONTEXTO REVISOR</b>{' '}
              realizar os repasses de valores e comissões, conforme acordado na
              Cláusula Segunda.
            </p>
            <p>
              8.2. Ainda, cada parte responderá, de forma individual e
              exclusiva, pelos tributos e encargos que sejam de sua titularidade
              passiva.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA OITAVA – DESPESAS E TRIBUTOS
            </h1>
            <p>
              9.1. O presente contrato de parceria comercial tem prazo de
              vigência de 365 (trezentos e sessenta e cinco) dias, renovável por
              iguais períodos caso as Partes assim decidam, a contar da data de
              sua assinatura, sendo passível de rescisão por qualquer das partes
              por meio de notificação expressa no prazo de 30 (trinta) dias.
            </p>
            <p>
              9.2. No caso de rescisão imotivada por qualquer das partes, caso a
              <b>CONTEXTO REVISOR</b> tenha pagamentos pendentes, deverá
              depositá-los em conta de titularidade da{' '}
              <b>INSTITUIÇÃO PARCEIRA</b>, enviando o respectivo comprovante, no
              prazo de 30 (trinta) dias da notificação da rescisão.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA NONA – RESCISÃO MOTIVADA
            </h1>
            <p>
              10.1. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se a <b>INSTITUIÇÃO PARCEIRA</b>:
            </p>
            <p>
              10.1.1. ceder ou transferir o presente contrato, por qualquer
              forma, a terceiros em quaisquer condições, sem o consentimento da
              <b>CONTEXTO REVISOR</b>;
            </p>
            <p>
              10.1.2. descumprir qualquer cláusula ou condição deste contrato,
              especialmente no que diz respeito ao estabelecido na Cláusula
              Primeira deste documento;
            </p>
            <p>
              10.2. O presente contrato reputar-se-á rescindido, de pleno
              direito, independentemente de qualquer formalidade judicial ou
              extrajudicial, se a <b>CONTEXTO REVISOR</b>:
            </p>

            <p>
              10.2.1. descumprir qualquer cláusula ou condição deste contrato,
              especialmente no que tange a realizar os pagamentos nas formas,
              prazos e condições pactuadas na Cláusula Segunda deste documento.
            </p>

            <p>
              10.3. Nos casos de rescisão motivada, não será necessária qualquer
              notificação prévia para rescisão do presente contrato, dando-se
              por encerrada a contratação no dia em que a parte justificadamente
              se manifestar.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
            </h1>
            <p>
              11.1. O descumprimento de qualquer das obrigações contratuais
              acarretará, após prévia notificação ou interpelação, facultada à
              parte inocente, de forma sucessiva:
            </p>
            <p>
              a) A suspensão da execução do presente contrato, o que faculta à
              <b>CONTEXTO REVISOR</b> a retenção de pagamentos e à{' '}
              <b>INSTITUIÇÃO PARCEIRA</b> a suspensão do acesso e relacionamento
              com o público concedido à <b>CONTEXTO REVISOR</b>.
            </p>
            <p>
              b) A exigibilidade direta e específica da obrigação descumprida,
              acrescida de multa moratória à razão de 1% (um por cento) sobre o
              total dos valores pagos até a data do inadimplemento, e de juros
              moratórios no montante de 1% (um por cento) ao mês.
            </p>
            <p>c) Em última instância, a resolução do contrato.</p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA SEGUNDA – TOLER NCIA
            </h1>

            <p>
              12.1. A tolerância de uma das partes para com a outra,
              relativamente ao descumprimento de qualquer uma das obrigações
              assumidas neste contrato, será mera liberalidade, não implicando
              novação ou renúncia a qualquer direito. A parte tolerante poderá,
              a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento
              de suas obrigações.
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS
            </h1>
            <p>
              13.1. A <b>INSTITUIÇÃO PARCEIRA</b> declara ter lido e estar
              ciente e de pleno acordo com todos os termos e condições deste
              Contrato, o qual foi feito em estrita observância aos limites do
              respectivo fim econômico ou social, pela boa-fé e/ou pelos bons
              costumes, levando em consideração inclusive que não estão em
              situação de premente necessidade e têm plenas condições de cumprir
              todas as cláusulas e condições que constituem seus direitos e
              obrigações constantes no presente instrumento.
            </p>

            <p>
              13.2. As disposições deste instrumento somente poderão ser
              alteradas mediante acordo escrito entre as partes, através de
              termos aditivos que farão parte integrante do contrato, ficando,
              desde já, consignado que atos de tolerância não configurarão
              novação contratual.
            </p>
            <p>
              13.3. Este Contrato não implica qualquer tipo de associação ou
              sociedade entre as Partes, ou em qualquer vínculo empregatício
              entre os sócios, diretores, empregados e prepostos de uma Parte
              para com a outra.
            </p>
            <p>
              13.4. As notificações de assuntos referentes a este instrumento
              poderão ser realizadas por correspondência destinada aos endereços
              físicos descritos na qualificação das Partes e eletrônicos, abaixo
              indicados, com a devida confirmação de recebimento:
            </p>
            <p>
              a) E-mail da <b>CONTEXTO REVISOR</b>:
              contato@contextorevisor.com.br
            </p>
            <p>
              b) E-mail da <b>INSTITUIÇÃO PARCEIRA</b>: {user.email}
            </p>
            <h1 className="turquoise-text bold">
              CLÁUSULA DÉCIMA QUARTA – FORO
            </h1>
            <p>
              14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
              Federal, para dirimir quaisquer controvérsias que surgirem do
              presente contrato, renunciando, expressamente, a qualquer outro,
              por mais privilegiado que seja.
            </p>
            <div id="eversign" align="center"></div>
            <div className="flex-container">
              <button
                className="hero-btn turquesa w-button"
                hidden={showButton}
                onClick={handleAccept}
              >
                Aceito os termos do contrato
              </button>
              <Link
                className="hero-btn turquesa w-button"
                to={criarContaInstituicaoParceira}
              >
                Voltar e Alterar os Dados
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <Redirect to={parcerias} />
  );
};

const mapStateToProps = ({ userStore }) => {
  const { user, signingURL } = userStore;
  return { user, signingURL };
};

export default connect(mapStateToProps)(InstitutionAgreement);
