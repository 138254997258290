import { func, oneOf } from 'prop-types';
import React from 'react';

export const OrderByReviewer = ({ changeOrder, orderType, orderCallback }) => (
  <div>
    <div className="filter-title">Ordenar por</div>
    <select
      id="field"
      name="field"
      className="filter-select w-select"
      onChange={e => {
        changeOrder(e.target.value);
        orderCallback(e.target.value);
      }}
      value={orderType}
    >
      <option value="">Selecione</option>
      <option value="cheap">Mais barato</option>
      <option value="expensive">Mais caro</option>
    </select>
  </div>
);

OrderByReviewer.propTypes = {
  changeOrder: func,
  orderType: oneOf(['cheap', 'expensive', '']),
  orderCallback: func
};

export default OrderByReviewer;
