import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PriceBox from '../../../../siteComponents/PriceBox';
import {
  FormMask,
  FormInput,
  FormButton,
  FormValidator,
  FormSelect,
  FormDate
} from '../../../../siteComponents/form';
import routes from '../../../../../routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import fileImage from '../../../../assetsHolder/images/document.png';
import {
  userCreateReviewer,
  showNotification
} from '../../../../../state-management';
import './ReviewerRegisterSection.css';
import { ApiService } from '../../../../../state-management/service/api';

const { criarConta } = routes;

class ReviewerRegisterComponent extends Component {
  sectionOne = null;
  sectionTwo = null;
  sectionThree = null;
  sectionFour = null;
  sectionFive = null;

  // completeSectionOne = false;
  // completeSectionTwo = false;
  // completeSectionThree = false;
  // completeSectionFour = false;
  // completeSectionFive = false;

  status = 'clean';

  // sectionButtons = {};

  state = {
    curriculumInvalid: true,
    paymentStepEnable: false,
    activeStep: 1,
    form: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      sexo: '',
      outro_sexo: '',
      uf_id: '',
      dt_nascimento: '',
      tipo_formacao_id: '',
      document: '',
      plan: 'free',
      terms: false,
      card: {
        number: '',
        verification_value: '',
        first_name: '',
        last_name: '',
        month: '',
        year: ''
      },
      curriculum: null
    },
    error: {
      name: false,
      email: false,
      password: false,
      uf_id: false,
      dt_nascimento: false,
      tipo_formacao_id: false
    }
  };

  timeOut = null;

  constructor(props) {
    super(props);
    this.sectionOne = React.createRef();
    this.sectionTwo = React.createRef();
    this.sectionThree = React.createRef();
    this.sectionFour = React.createRef();
    this.sectionFive = React.createRef();

    // this.sectionButtons = {
    //   1: this.sectionOne,
    //   2: this.sectionTwo,
    //   3: this.sectionThree,
    //   4: this.sectionFour,
    //   5: this.sectionFive
    // };
  }

  // handleCurriculoChange = e => {
  //   this.state.form.curriculum = e.target.files[0];
  //   this.setState(this.state);
  // };

  // validateStepOne = () => {
  //   return (
  //     this.state.form.name !== "" &&
  //     this.state.form.email !== "" &&
  //     this.state.form.password !== "" &&
  //     this.state.form.password_confirmation !== ""
  //   );
  // };

  // validateStepTwo = () => {
  //   return (
  //     this.state.form.uf_id !== "" && this.state.form.tipo_formacao_id !== ""
  //   );
  // };

  // validateStepThree = () => {
  //   return (
  //     this.state.form.dt_nascimento !== "" &&
  //     (this.state.form.sexo === "F" ||
  //       this.state.form.sexo === "M" ||
  //       (this.state.form.sexo === "O" && this.state.form.outro_sexo !== ""))
  //   );
  // };

  // validateStepFour = () => {
  //   return this.state.form.curriculum !== null && !this.state.curriculumInvalid;
  // };

  // validateStepFive = () => {
  //   return this.state.form.terms;
  // };

  // validateAllSteps = () => {
  //   return (
  //     this.validateStepOne &&
  //     this.validateStepTwo &&
  //     this.validateStepThree &&
  //     this.validateStepFour &&
  //     this.validateStepFive
  //   );
  // };

  handleCurriculoChange = e => {
    // this.setState({
    //   form: { ...this.state.form, curriculum: null }
    // });
    // this.setState({
    //   curriculumInvalid: {
    //     ...this.curriculumInvalid,
    //     curriculumInvalid: true
    //   }
    // });
    let state = { ...this.state };
    let aux = e.target.files[0];
    var extension = aux.type.split('/').pop();
    if (aux) {
      if (/^(pdf)$/.test(extension)) {
        if (aux.size <= 66060288) {
          state.form.curriculum = aux;
          this.status = 'success';
          state.curriculumInvalid = false;
        } else {
          state.form.curriculum = null;
          this.status = 'size_error';
          state.curriculumInvalid = true;
        }
      } else {
        state.form.curriculum = null;
        this.status = 'format_error';
        state.curriculumInvalid = true;
      }
    } else {
      state.form.curriculum = null;
      this.status = 'error';
      state.curriculumInvalid = true;
    }
    // console.log(this.state.curriculumInvalid, this.state.form.curriculum);
    this.setState(state);
  };

  handlePartnershipDenied = () => {
    this.props.history.replace('/login');
  };

  handlePartnershipAccept = () => {
    this.props.history.replace('/contrato-parceiro-revisor');
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated && !this.loggedHere) {
      this.props.history.replace('/profile');
    }

    if (nextProps.loginSuccess) {
      this.props.dispatch(
        showNotification('success', 'Usuário logado com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.replace(this.state.nextUrl);
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (nextProps.loginFailed) {
      this.props.dispatch(
        showNotification(
          'error',
          'Acesso não autorizado, confira seu email e senha!',
          true
        )
      );
    }

    if (nextProps.accountCreated) {
      const newState = { ...this.state };
      newState.form.card['number'] = '';
      this.setState(newState);

      this.props.dispatch(
        showNotification(
          'success',
          'Pronto! Você já preencheu o cadastro e pode logar no sistema. Mas lembre-se: você só poderá publicar seu perfil (minissite) após a autorização bancária, que ocorrerá em no máximo 48h.!',
          true
        )
      );

      // Se o login for completo com sucesso
      // Redirecionar para a página 6
      this.changeStep(7);

      // this.timeOut = setTimeout(() => {
      //   this.props.history.replace('/login');
      //   clearTimeout(this.timeOut);
      //   this.timeOut = null;
      // }, 2000);
    }

    if (nextProps.accountError) {
      if (nextProps.accountErrorMsg && nextProps.accountErrorMsg.message) {
        let message;

        if (
          nextProps.accountErrorMsg.message ===
          'The email has already been taken.'
        ) {
          message =
            'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.';
        } else if (
          nextProps.accountErrorMsg.message === 'invalid number card'
        ) {
          message = 'Número do cartão inválido.';
        } else if (
          nextProps.accountErrorMsg.message === 'error on created card token'
        ) {
          message = 'Erro ao criar o token do cartão.';
        } else if (
          nextProps.accountErrorMsg.message === 'data credit card invalid'
        ) {
          message = 'Dados do cartão de crédito inválidos.';
        } else if (nextProps.accountErrorMsg.message === 'charging failed') {
          message =
            'Erro ao criar usuário: Não foi possível efetivar a cobrança.';
        } else {
          message = 'Error ao criar revisor.';
        }

        this.props.dispatch(showNotification('error', message, true));

        const newState = { ...this.state };
        newState.form.card['number'] = '';
        this.setState(newState);
      } else {
        this.props.dispatch(
          showNotification('error', 'Verique os dados e tente novamente', true)
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  changeStep = (number, ref) => {
    if (number === 2) {
      if (this.state.form.password !== this.state.form.password_confirmation) {
        this.props.dispatch(
          showNotification(
            'error',
            'ATENÇÃO: As senhas devem ser iguais.',
            true
          )
        );
        return;
      }

      ApiService.existsEmail({ email: this.state.form.email })
        .then(() => {
          this._changeStep(number, ref);
        })
        .catch(e => {
          if (e && e.response && e.response.status) {
            if (e.response.status === 302) {
              this.props.dispatch(
                showNotification(
                  'error',
                  'ATENÇÃO: Você está tentando criar uma conta com e-mail já cadastrado.',
                  true
                )
              );
              return;
            }
          }
          throw e;
        });
    } else if (number === 4) {
      if (
        this.state.form.dt_nascimento.match(
          '^[0-9][0-9]/[0-9][0-9]/[0-9][0-9][0-9][0-9]'
        ) === null
      ) {
        this.props.dispatch(
          showNotification(
            'error',
            'ATENÇÃO: A data deve conter 8 digitos e obedecer o formato DD/MM/AAAA.',
            true
          )
        );
        return;
      } else this._changeStep(number, ref);
    } else this._changeStep(number, ref);
  };

  _changeStep = (number, ref) => {
    if (
      ref &&
      ReactDOM.findDOMNode(ref.current) &&
      ReactDOM.findDOMNode(ref.current).disabled
    ) {
      return;
    }

    const newState = { ...this.state };
    newState.activeStep = number;
    this.setState(newState);
  };

  handleChangeDate = (date, inputName) => {
    const newState = { ...this.state };
    newState.form[inputName] = date;
    this.setState(newState);
  };

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
    localStorage.setItem('nome', this.state.form.name);
    localStorage.setItem('email', this.state.form.email);
  };

  handleChangeGender = e => {
    const { value } = e.target;
    this.setState({
      form: { ...this.state.form, sexo: value, outro_sexo: '' }
    });
  };

  handleChangeOther = e => {
    const { value } = e.target;
    this.setState({
      form: { ...this.state.form, sexo: 'O', outro_sexo: value }
    });
  };

  handleAccountChange = accountType => {
    const newState = { ...this.state };
    newState.form.plan = accountType;
    newState.paymentStepEnable = accountType === 'premium';
    this.setState(newState);
  };

  onCheck = e => {
    let { name, checked } = e.target;
    let state = { ...this.state };
    state.form[name] = checked;
    this.setState(state);
  };

  handleChangeCardInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form.card[name] = value;
    this.setState(newState);
  };

  lastName = str => {
    var arr = str.split(' ');
    return arr[arr.length - 1];
  };

  firstName = str => {
    var arr = str.split(' ');
    return arr.slice(0, arr.length - 1).join(' ');
  };

  handlePartneshipProposal = event => {
    if (!this.state.form.terms) {
      this.props.dispatch(
        showNotification(
          'error',
          'Para efetivar o cadastro, você precisa concordar com os Termos e Condições de Uso e Política de Privacidade do Contexto Revisor.',
          true
        )
      );
      return;
    }

    const { form } = this.state;
    const name = form.card['first_name'];
    form.card['last_name'] = this.lastName(name);
    form.card['first_name'] = this.firstName(name);

    if (form.sexo !== 'O') {
      form.outro_sexo = '';
    }

    this.props.dispatch(userCreateReviewer(this.state.form));
    this.setState({ form });
    this.props.history.replace('/login');
  };

  renderStepIndicators(stepActive) {
    // let sectionButtonRef = this.sectionButtons[stepActive];

    return (
      <div className="turquoise-list w-col w-col-5 w-col-small-5 w-col-tiny-5">
        <ul className="unordered-list w-list-unstyled">
          <li
            className={'step-revisor ' + (stepActive === 1 ? 'active' : '')}
            // onClick={() => this.changeStep(1, sectionButtonRef)}
          >
            <div>1</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 2 ? 'active' : '')}
            // onClick={() => this.changeStep(2, sectionButtonRef)}
          >
            <div className="text-block">2</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 3 ? 'active' : '')}
            // onClick={() => this.changeStep(3, sectionButtonRef)}
          >
            <div className="text-block">3</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 4 ? 'active' : '')}
            // onClick={() => this.changeStep(4, sectionButtonRef)}
          >
            <div className="text-block">4</div>
          </li>

          <li
            className={'step-revisor ' + (stepActive === 5 ? 'active' : '')}
            // onClick={() => this.changeStep(5, sectionButtonRef)}
          >
            <div className="text-block">5</div>
          </li>

          {this.state.paymentStepEnable && (
            <li
              className={'step-revisor ' + (stepActive === 6 ? 'active' : '')}
              // onClick={() => this.changeStep(6, sectionButtonRef)}
            >
              <div className="text-block">6</div>
            </li>
          )}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="accont-box halfbox">
        <div className="w-form">
          {this.state.activeStep === 1 && (
            <FormValidator id="email-form" name="email-form" className="form">
              <div className="revisor-step1">
                <div className="row w-row">
                  <div className="turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1">
                    <Link className="no-style -turquoise" to={criarConta}>
                      
                    </Link>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Criar conta de revisor</div>
                  </div>
                  {this.renderStepIndicators(1)}
                </div>
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  maxLength="256"
                  name="name"
                  placeholder="Nome"
                  id="name"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.name}
                />
                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="email"
                  placeholder="E-mail"
                  validators={['email', 'required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.email}
                />
                <FormInput
                  type="password"
                  className="accont-input w-input"
                  name="password"
                  placeholder="Senha"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.password}
                />
                <FormInput
                  type="password"
                  className="accont-input w-input"
                  name="password_confirmation"
                  placeholder="Confirmação de senha"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.password_confirmation}
                />
                <FormButton
                  type="button"
                  ref={this.sectionOne}
                  // disabled={!this.validateStepOne}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(2)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 2 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back1"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor2-1"
                    onClick={() => this.changeStep(1)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Só mais alguns dados...</div>
                  </div>
                  {this.renderStepIndicators(2)}
                </div>
                <FormSelect
                  name="uf_id"
                  validators={['required']}
                  className="accont-input w-select"
                  value={this.state.form.uf_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Estado</option>

                  {this.props.states.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.uf} - {item.descricao}
                      </option>
                    );
                  })}
                </FormSelect>
                <FormSelect
                  name="tipo_formacao_id"
                  validators={['required']}
                  className="accont-input w-select"
                  value={this.state.form.tipo_formacao_id}
                  onChange={this.handleChangeInputText}
                >
                  <option value="">Nível de formação</option>

                  {this.props.trainings.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.formacao}
                      </option>
                    );
                  })}
                </FormSelect>

                <FormButton
                  type="button"
                  ref={this.sectionTwo}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(3)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 3 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back2"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor-3-2"
                    onClick={() => this.changeStep(2)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Complete seu perfil</div>
                  </div>
                  {this.renderStepIndicators(3)}
                </div>
                <div className="gender">
                  <div className="gender-box">
                    <label>Data de Nascimento</label>
                    <FormDate
                      alwaysShowMask={true}
                      className="w-input accont-input"
                      name="dt_nascimento"
                      minDate={new Date('01/01/1900')}
                      maxDate={new Date()}
                      validators={['date', 'required']}
                      value={this.state.form.dt_nascimento}
                      onChange={this.handleChangeInputText}
                    />
                  </div>
                </div>
                <div className="gender">
                  <div className="gender-box">
                    <label>Gênero *</label>
                    <div className="gender-radio w-radio">
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios1"
                          value="M"
                          checked={this.state.form.sexo === 'M'}
                          onChange={this.handleChangeGender}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Masculino
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios2"
                          value="F"
                          checked={this.state.form.sexo === 'F'}
                          onChange={this.handleChangeGender}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Feminino
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gender radio-input">
                  <div className="gender-box">
                    <div className="gender-radio w-radio">
                      <div class="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sexo"
                          id="exampleRadios3"
                          value="O"
                          checked={this.state.form.sexo === 'O'}
                          onChange={this.handleChangeGender}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios3"
                        >
                          Outro
                        </label>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="accont-input w-input"
                      maxLength="256"
                      name="outro"
                      data-name="outro"
                      placeholder="Outro"
                      id="outro"
                      value={this.state.form.outro_sexo || ''}
                      onChange={this.handleChangeOther}
                    />
                  </div>
                </div>

                <FormButton
                  type="button"
                  ref={this.sectionThree}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(4)}
                >
                  Próximo <strong>passo</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 4 && (
            <FormValidator className="form">
              <div>
                <div className="row w-row">
                  <div
                    id="revisor-back2"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor-3-2"
                    onClick={() => this.changeStep(3)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Currículo</div>
                  </div>
                  {this.renderStepIndicators(4)}
                </div>
                <div className="accont-box">
                  <div>
                    <p className="paragraph-3">
                      Agora precisamos que você inclua o seu currículo.
                    </p>
                    <p className="paragraph-3">
                      Ele estará disponível para consulta pelos Clientes e
                      também para nossa Curadoria.
                    </p>
                    <br />
                  </div>
                </div>

                <label
                  htmlFor="files"
                  className="accont-btn--revisor-nocolor button w-button nomargin margin-left-auto"
                >
                  Selecionar <stong>anexo</stong>
                </label>
                <FormInput
                  id="files"
                  type="file"
                  placeholder="Currículo"
                  className="accont-input w-input"
                  name="curriculo"
                  accept=".pdf"
                  validators={['required']}
                  style={{ visibility: 'hidden' }}
                  onChange={this.handleCurriculoChange}
                />

                {this.status === 'success' &&
                  this.state.form.curriculum !== null &&
                  this.state.form.curriculum.name !== null && (
                    <div>
                      <img className="center" src={fileImage} />
                      <p>
                        Arquivo{' '}
                        <strong>{this.state.form.curriculum.name}</strong>{' '}
                        selecionado com sucesso!
                      </p>
                    </div>
                  )}
                {this.status === 'size_error' && (
                  <div>
                    <img className="center" src={fileImage} />
                    <p>
                      Por favor, envie um arquivo com tamanho menor que{' '}
                      <strong> 64MB</strong>.
                    </p>
                  </div>
                )}
                {this.status === 'format_error' && (
                  <div>
                    <img className="center" src={fileImage} />
                    <p>
                      Atenção! Nós só aceitamos arquivos do formato
                      <strong> PDF</strong>. Por favor, envie outro arquivo.
                    </p>
                  </div>
                )}
                {this.status === 'error' && (
                  <div>
                    <img className="center" src={fileImage} />
                    <p>
                      Ocorreu algum <strong> erro</strong>. Por favor, tente
                      anexar o arquivo novamente ou tente outro arquivo.
                    </p>
                  </div>
                )}

                {/* <FileBase64
                  multiple={false}
                  placeholder="Currículo"
                  className="accont-input w-input"
                  name="curriculo"
                  accept=".pdf"
                  validators={['required']}
                  // onChange={this.handleCurriculoChange}
                  // value={this.state.form.curriculoData.name}
                  onDone={this.getFiles.bind(this)}
                /> */}

                <button
                  type="button"
                  ref={this.sectionFour}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(5)}
                  disabled={this.state.curriculumInvalid}
                >
                  Próximo <strong>passo</strong>
                </button>
              </div>
            </FormValidator>
          )}

          {this.state.activeStep === 5 && (
            <div>
              <div className="row w-row">
                <div
                  id="revisor-back2"
                  className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                  data-ix="revisor-3-2"
                  onClick={() => {
                    this.changeStep(4);
                  }}
                >
                  <div></div>
                </div>
                <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                  <div>Escolha o plano</div>
                </div>
                {this.renderStepIndicators(5)}
              </div>

              <div style={{ display: 'flex' }}>
                <PriceBox
                  title="Flex"
                  price="R$ 0,00"
                  period="/MÊS"
                  description="Pacote básico de ferramentas e marketing e 16% sobre receita."
                  register={true}
                  selected={this.state.form.plan === 'free'}
                  onClick={this.handleAccountChange.bind(this, 'free')}
                />

                <PriceBox
                  title="Premium"
                  price="R$ 39,90"
                  period="/MÊS"
                  description="Pacote completo de ferramentas e marketing e 16% sobre receita."
                  register={true}
                  selected={this.state.form.plan === 'premium'}
                  onClick={this.handleAccountChange.bind(this, 'premium')}
                />
              </div>

              {this.state.paymentStepEnable && (
                <button
                  type="button"
                  ref={this.sectionFive}
                  className="accont-btn--revisor button w-button"
                  onClick={() => this.changeStep(6)}
                >
                  Próximo <strong>passo</strong>
                </button>
              )}

              {!this.state.paymentStepEnable && (
                <FormValidator className="form">
                  <div className="w-row">
                    <div className="w-col w-col-12">
                      <div className="gender-checkbox w-checkbox terms-margin">
                        <FormInput
                          type="checkbox"
                          name="terms"
                          id="terms"
                          onClick={this.onCheck}
                          checked={this.state.form.terms ? true : false}
                          className="w-checkbox-input"
                        />
                        <label
                          htmlFor="terms"
                          className="w-form-label label-gray"
                        >
                          <span>Concordo com os </span>
                          <Link to="/termos" target="_blank">
                            Termos e Condições de Uso e Política de Privacidade
                            do Contexto Revisor.
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>
                </FormValidator>
              )}

              {!this.state.paymentStepEnable && (
                <button
                  type="button"
                  className="accont-btn--revisor button w-button"
                  onClick={this.handlePartneshipProposal}
                  disabled={!this.state.form.terms}
                >
                  Finalizar criação de <strong>conta</strong>
                </button>
              )}
            </div>
          )}

          {this.state.activeStep === 6 && (
            <FormValidator>
              <div className="payment-step">
                <div className="row w-row">
                  <div
                    id="revisor-back2"
                    className="back-button turquoise-list w-col w-col-1 w-col-small-1 w-col-tiny-1"
                    data-ix="revisor-3-2"
                    onClick={() => this.changeStep(5)}
                  >
                    <div></div>
                  </div>
                  <div className="turquoise-list w-col w-col-6 w-col-small-6 w-col-tiny-6">
                    <div>Dados para recebimento/pagamento</div>
                  </div>
                  {this.renderStepIndicators(6)}
                </div>

                <p>
                  As informações seguintes serão utilizadas para que você possa
                  receber pagamentos de clientes direto em sua conta, descontar
                  o percentual de administração e pagar sua assinatura do plano
                  Premium.
                </p>

                <FormMask
                  mask="999.999.999-99"
                  maskChar=""
                  className="accont-input w-input"
                  name="document"
                  placeholder="CPF"
                  validators={['required']}
                  onChange={this.handleChangeInputText}
                  value={this.state.form.document}
                />

                <FormMask
                  mask="9999 9999 9999 9999"
                  maskChar=""
                  className="accont-input w-input"
                  placeholder="Número do cartão"
                  validators={['required']}
                  name="number"
                  onChange={this.handleChangeCardInputText}
                  value={this.state.form.card.number}
                />

                <FormInput
                  type="text"
                  className="accont-input w-input"
                  name="first_name"
                  validators={['required']}
                  placeholder="Nome conforme o cartão"
                  onChange={this.handleChangeCardInputText}
                  value={this.state.form.card.first_name}
                />

                <div className="payment-card">
                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    maxLength="2"
                    name="month"
                    validators={['max', 'required']}
                    placeholder="Mês (Validade Cartão)"
                    onChange={this.handleChangeCardInputText}
                    value={this.state.form.card.month}
                  />

                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    maxLength="4"
                    name="year"
                    validators={['max']}
                    placeholder="Ano (Validade Cartão)"
                    onChange={this.handleChangeCardInputText}
                    value={this.state.form.card.year}
                  />

                  <FormInput
                    type="text"
                    className="accont-input w-input"
                    maxLength="3"
                    name="verification_value"
                    placeholder="Código (CVV)"
                    validators={['max', 'required']}
                    onChange={this.handleChangeCardInputText}
                    value={this.state.form.card.verification_value}
                  />
                </div>

                <div className="w-row">
                  <div className="w-col w-col-12">
                    <div className="gender-checkbox w-checkbox terms-margin">
                      <FormInput
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onClick={this.onCheck}
                        checked={this.state.form.terms ? true : false}
                        className="w-checkbox-input"
                      />
                      <label
                        htmlFor="terms"
                        className="w-form-label label-gray"
                      >
                        <span>Concordo com os </span>
                        <Link to="/termos" target="_blank">
                          Termos e Condições de Uso e Política de Privacidade do
                          Contexto Revisor.
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>

                <FormButton
                  type="button"
                  className="accont-btn--revisor button w-button"
                  onClick={this.handlePartneshipProposal}
                  disabled={!this.state.form.terms}
                >
                  Confirmar <strong>dados</strong>
                </FormButton>
              </div>
            </FormValidator>
          )}
        </div>
        {/*{this.state.activeStep === 6 && (
          <FormValidator>
            <div>
              <h1 className="section-header2">
                Quero ser um revisor <br />
                parceiro!
              </h1>
              <div className="accont-box">
                <div>
                  <p className="paragraph-3">
                    Habilite-se a receber clientes de instituições parceiras,
                    mediante desconto via código promocional.
                  </p>
                </div>
                <br />
                <p className="paragraph-3">
                  Você concede desconto de 10% e recebe clientes especiais.
                </p>
                <br />
                <p className="paragraph-3">
                  * Sujeito a análise de currículo, trabalhos de revisão
                  realizados, teste de habilitação e entrevista pela equipe do
                  Contexto Revisor.
                </p>
              </div>
              <FormButton
                type="button"
                // ref={this.sectionThree}
                className="accont-btn--revisor button w-button"
                onClick={this.handlePartnershipAccept}
              >
                Quero ser parceiro revisor
              </FormButton>
              <FormButton
                type="button"
                // ref={this.sectionThree}
                className="accont-btn--revisor button w-button"
                onClick={this.handlePartnershipDenied}
              >
                Não tenho interesse
              </FormButton>
            </div>
          </FormValidator>
        )}
        */}
      </div>
    );
  }
}

const mapPropsToState = ({ reviewersStore, userStore }) => {
  let { states, trainings } = reviewersStore;
  let { accountCreated, accountError, accountErrorMsg } = userStore;
  let { loginSuccess, loginFailed, authenticated } = userStore;

  return {
    states,
    trainings,
    accountCreated,
    accountError,
    accountErrorMsg,
    loginSuccess,
    loginFailed,
    authenticated
  };
};

const ReviewerRegisterSection = connect(mapPropsToState)(
  ReviewerRegisterComponent
);
export default ReviewerRegisterSection;
