import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOFF,
  USER_CREATE_ERROR,
  USER_CREATE_SUCCESS,
  EMBEDDED_SIGNING_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_RECOVER_PASSWORD_SUCCESS,
  USER_RECOVER_PASSWORD_ERROR,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  USER_REVIEWER_PUBLISH_PROFILE_SUCCESS,
  USER_REVIEWER_PUBLISH_PROFILE_ERROR,
  USER_REVIEWER_VERIFY_APELIDO_SUCCESS,
  USER_REVIEWER_VERIFY_APELIDO_ERROR,
  FETCHED_PROPOSAL,
  FETCH_PROPOSAL,
  FETCHED_COUNTERPROPOSAL,
  FETCH_COUNTERPROPOSAL,
  FETCHED_ONE_PROPOSAL,
  FETCHED_INVOICES,
  FETCH_INVOICES,
  BECOME_PARTNER_SUCCESS,
  USER_PREVIEW_STATE
} from './actions';

const initialState = {
  loading: false,
  authenticated: false,
  accountCreated: false,
  accountError: false,
  checkin: false,
  proposals: [],
  proposalsPagination: {},
  proposal: null,
  invoices: [],
  counterProposals: [],
  counterProposalsPagination: {},
  roles: {
    isAdmin: false,
    isRedator: false,
    isRevisor: false,
    isParceiro: false,
    isRepresentante: false,
    isInstituicao: false,
    isDivulgador: false
  }
};

const user = (state = initialState, action) => {
  let updateState = { ...state };
  updateState.accountCreated = false;
  updateState.accountError = false;
  updateState.loginFailed = false;
  updateState.httpStatus = null;
  updateState.loginSuccess = false;
  updateState.updateSuccess = false;
  updateState.previewState = {};
  updateState.becomePartnerSuccess = false;
  updateState.updateError = false;
  updateState.updateErrorMessage = '';
  updateState.proposal = null;
  updateState.recoverPasswordSuccess = false;
  updateState.recoverPasswordError = false;
  updateState.resetPasswordError = false;
  updateState.resetPasswordSuccess = false;
  updateState.updatePasswordError = false;
  updateState.updatePasswordSuccess = false;
  updateState.accountErrorMsg = null;
  updateState.publishProfileSuccess = false;
  updateState.publishProfileError = null;
  updateState.verifyApelidoSuccess = null;
  updateState.verifyApelidoError = null;

  switch (action.type) {
    case LOGIN_SUCCESS:
      updateState.authenticated = true;
      updateState.loginSuccess = true;
      updateState = { ...updateState, ...action.data };
      break;

    case LOGIN_ERROR:
      updateState.loginFailed = true;
      updateState.httpStatus = action.data;
      updateState = { ...updateState, ...action.data };
      break;

    case LOGOFF:
      updateState.authenticated = false;
      break;

    case USER_CREATE_SUCCESS:
      updateState.accountCreated = true;
      break;

    case EMBEDDED_SIGNING_SUCCESS:
      updateState.signingURL = action.data;
      break;

    case BECOME_PARTNER_SUCCESS:
      updateState.becomePartnerSuccess = true;
      updateState = { ...updateState, ...action.data };
      break;

    case USER_CREATE_ERROR:
      updateState.accountError = true;
      updateState.accountErrorMsg = action.data;
      break;

    case USER_UPDATE_SUCCESS:
      updateState.updateSuccess = true;
      updateState = { ...updateState, ...action.data };
      break;

    case USER_UPDATE_ERROR:
      updateState.updateError = true;
      updateState.updateErrorMessage = action.data;
      updateState = { ...updateState, ...action.data };
      break;

    case USER_PREVIEW_STATE:
      updateState.previewState = { ...action.data };
      break;

    case FETCHED_PROPOSAL:
      updateState.proposals = action.data.data;
      updateState.proposalsPagination = action.data.pagination;
      updateState.loading = false;
      break;

    case FETCH_PROPOSAL:
      updateState.loading = true;
      break;

    case FETCHED_COUNTERPROPOSAL:
      updateState.counterProposals = action.data.data;
      updateState.counterProposalsPagination = action.data.pagination;
      updateState.loading = false;
      break;

    case FETCH_COUNTERPROPOSAL:
      updateState.loading = true;
      break;

    case FETCH_INVOICES:
      updateState.loading = true;
      break;

    case FETCHED_INVOICES:
      updateState.invoices = action.data;
      updateState.loading = false;
      break;

    case FETCHED_ONE_PROPOSAL:
      updateState.proposal = action.data;
      break;

    case USER_RECOVER_PASSWORD_SUCCESS:
      updateState.recoverPasswordSuccess = true;
      break;

    case USER_RECOVER_PASSWORD_ERROR:
      updateState.recoverPasswordError = true;
      break;

    case USER_RESET_PASSWORD_ERROR:
      updateState.resetPasswordError = true;
      break;

    case USER_RESET_PASSWORD_SUCCESS:
      updateState.resetPasswordSuccess = true;
      break;

    case UPDATE_PASSWORD_ERROR:
      updateState.updatePasswordError = true;
      break;

    case UPDATE_PASSWORD_SUCCESS:
      updateState.updatePasswordSuccess = true;
      break;

    case USER_REVIEWER_PUBLISH_PROFILE_SUCCESS:
      updateState.publishProfileSuccess = true;
      break;

    case USER_REVIEWER_PUBLISH_PROFILE_ERROR:
      updateState.publishProfileError = action.data;
      break;

    //Reducer da verficação do apelido (nome minisite)
    case USER_REVIEWER_VERIFY_APELIDO_SUCCESS:
      updateState.verifyApelidoSuccess = true;
      break;

    case USER_REVIEWER_VERIFY_APELIDO_ERROR:
      updateState.verifyApelidoError = action.data;
      break;

    default:
      break;
  }

  return updateState;
};

export default user;
