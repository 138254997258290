import React from 'react';
import { control } from 'react-validation';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { required } from './validators';

const validators = {
  required: required
};

const FormCurrencyInput = props => (
  <NumberFormat
    {...props}
    decimalSeparator=","
    thousandSeparator="."
    prefix="R$"
    decimalScale={2}
    fixedDecimalScale
    inputMode="decimal"
  />
);

FormCurrencyInput.propTypes = {
  validators: PropTypes.array
};

const FormCurrencyHOC = control(FormCurrencyInput);

const FormCurrency = props => {
  let validations = [];
  if (props.validators) {
    for (let validator of props.validators) {
      validations.push(validators[validator]);
    }
  }

  return <FormCurrencyHOC {...props} validations={validations} />;
};

export { FormCurrency };
