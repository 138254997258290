import { string } from 'prop-types';
import React from 'react';
import logoContexto2 from './images/logo-contexto2.png';
import scroll from './images/scroll.svg';

export const ViralContainer = ({ color, text, subText }) => (
  <div className="flexcourners large-container">
    <div className={'halfbox viral ' + color}>
      <img src={logoContexto2} alt="logo" className="contexto-img" />
      <div className="scroll">
        <img src={scroll} alt="scroll" className="image" />
      </div>
      <h3 className="block-mainheader">{text}</h3>
      {subText && <h3 className="block-mainheader">{subText}</h3>}
      <ul className="bullet-ul w-list-unstyled">
        <li className="bullet" />
        <li className="bullet" />
        <li className="bullet" />
        <li className="bullet" />
      </ul>
    </div>
    <div className="halfbox revisor-img viral" />
  </div>
);

ViralContainer.propTypes = {
  color: string,
  text: string,
  subText: string
};

export default ViralContainer;
