import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showNotification,
  userResetPassword
  // login
} from '../../../state-management';
import { FormValidator, FormInput } from '../../siteComponents/form';
import './ResetPassword.css';
import marcaContextoAzul from '../../assetsHolder/images/marca-contexto-azul.svg';

class ResetPasswordComponent extends Component {
  state = {
    form: {
      token: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
  };

  componentDidMount() {
    if (this.props.location.search) {
      let newState = { ...this.state };
      newState.form.token = this.props.location.search.split('=')[1];
      this.setState(newState);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated) {
      this.props.history.replace('/profile');
    }

    if (nextProps.resetPasswordSuccess) {
      this.props.dispatch(
        showNotification('success', 'Senha atualizada', true)
      );
      this.props.history.replace('/');
      // this.props.dispatch(login(this.state.form));
    }

    if (nextProps.resetPasswordError) {
      this.props.dispatch(
        showNotification(
          'error',
          'ATENÇÃO: Houve um erro tente novamente.',
          true
        )
      );
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    if (
      this.state.form.email &&
      this.state.form.password &&
      this.state.form.password_confirmation
    ) {
      this.props.dispatch(userResetPassword(this.state.form));
    } else {
      this.props.dispatch(
        showNotification('error', 'ATENÇÃO: Informe os dados', true)
      );
    }
  };

  handleChange = e => {
    let { name, value } = e.target;
    let newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  render() {
    return (
      <div className="recover-container">
        <img src={marcaContextoAzul} alt="Contexto" className="marca" />

        <div className="box">
          <div className="title-padding title-container">
            <div className="title">Recuperação de senha</div>
          </div>

          <p className="title-padding">
            Informe seus dados para definir uma nova senha
          </p>

          <FormValidator className="form">
            <FormInput
              type="text"
              placeholder="E-mail"
              className="accont-input w-input"
              value={this.state.form.email}
              validators={['email']}
              name="email"
              onChange={this.handleChange}
            />

            <FormInput
              type="password"
              placeholder="Defina uma senha"
              className="accont-input w-input"
              value={this.state.form.password}
              name="password"
              onChange={this.handleChange}
            />

            <FormInput
              type="password"
              placeholder="Repita sua senha"
              className="accont-input w-input"
              value={this.state.form.password_confirmation}
              name="password_confirmation"
              onChange={this.handleChange}
            />

            <button
              type="submit"
              className="button w-button"
              onClick={this.handleSubmit}
            >
              Enviar
            </button>
          </FormValidator>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  let { resetPasswordSuccess, resetPasswordError, authenticated } = userStore;
  return { resetPasswordSuccess, resetPasswordError, authenticated };
};

const ResetPasswordPage = connect(mapStateToProps)(ResetPasswordComponent);
export { ResetPasswordPage };
