import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReviewerCard from '../ReviewerCard/index';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { fetchReviewers } from '../../../state-management/reviewers/actions';
import avatar1 from './images/avatar-1.svg';
import avatar2 from './images/avatar-2.svg';
import { arrayOf, func, object } from 'prop-types';

export class ListReviewersComponent extends PureComponent {
  componentDidMount() {
    if (this.props.reviewers.length === 0) {
      const { page } = this.props.reviewersPagination;
      this.props.dispatch(fetchReviewers({ page }));
    }
  }

  loadMore = () => {
    const { page } = this.props.reviewersPagination;
    const {
      searchQuery,
      orderType,
      rating,
      pagePrice,
      numberRevisions
    } = this.props;
    this.props.dispatch(
      fetchReviewers({
        page,
        searchQuery,
        rating,
        pagePrice,
        numberRevisions,
        orderType
      })
    );
  };

  chooseImage(user) {
    if (user.imagem) {
      return user.imagem;
    } else if (user.facebook_picture) {
      return user.facebook_picture;
    } else if (user.sexo === 'F') {
      return avatar1;
    } else {
      return avatar2;
    }
  }

  render() {
    let reviewers = Object.values(this.props.reviewers);
    let pagination = this.props.reviewersPagination;
    console.log(pagination);
    let loading = false;
    if (pagination.users === undefined) {
      loading = true;
    }
    console.log({ reviewers, pagination });
    return (
      <SkeletonTheme color="#ffffff">
        <div className="light-padding section-dark">
          <div className="header">
            {loading && <Skeleton height="40px" />}
            {!loading && (
              <p className="bold pre-title">
                <span className="result-number">
                  {`${reviewers.length} de ${pagination.users.total} `}
                </span>
                revisores encontrados
              </p>
            )}
          </div>
          {loading && (
            <div className="grid large-container">
              <Skeleton
                height={300}
                width={300}
                count={10}
                style={{
                  marginBottom: '10px',
                  marginRight: '10px',
                  marginLeft: '60px'
                }}
              />
            </div>
          )}
          {!loading && (
            <div className="grid large-container">
              {reviewers.map((reviewer, index) => (
                <ReviewerCard
                  key={index}
                  avatar={this.chooseImage(reviewer)}
                  {...reviewer}
                />
              ))}
            </div>
          )}
          {pagination.next_page_url && (
            <button
              className="button w-button white-btn"
              onClick={this.loadMore}
            >
              Carregar mais
            </button>
          )}
        </div>
      </SkeletonTheme>
    );
  }
}

ListReviewersComponent.propTypes = {
  reviewers: arrayOf(object),
  reviewersPagination: object,
  dispatch: func
};

const mapStateToProps = ({ reviewersStore }) => {
  const { reviewers, reviewersPagination } = reviewersStore;
  return {
    reviewers,
    reviewersPagination
  };
};

const ListReviewers = connect(mapStateToProps)(ListReviewersComponent);
export { ListReviewers };
