import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Modal from '../../siteComponents/modal';
import ParceiroAvatar from '../../siteComponents/ParceiroAvatar';

import avatar1 from '../../assetsHolder/images/avatar-1.svg';
import avatar2 from '../../assetsHolder/images/avatar-2.svg';

import { DadosComponent } from './DadosComponent';
import { AlterarSenhaComponent } from './AlterarSenhaComponent';
import { ConfiguracaoRevisorComponent } from './ConfiguracaoRevisorComponent';
import { RevisoesComponent } from './RevisoesComponent';
import { FinanceiroComponent } from './FinanceiroComponent';
import { PromocionalComponent } from './PromocionalComponent';
// import { InformacoesPlanoComponent } from './InformacoesPlanoComponent';
import { ComprasComponent } from './redator';
import { ProposalsComponent, DetalhePropostaComponent } from './proposta';
import { ProfilePreview } from '../ProfileReviewer/ProfilePreview';
import { PromotionalCodeClient } from './PromotionalCodeClient';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {
  URL_VIEW_MOTOR,
  TOKEN_KEY
} from '../../../state-management/service/api';

import {
  showNotification,
  userUpdate,
  userUpdateAvatar,
  userReviewerPublishProfile,
  fetchOneProposal
} from '../../../state-management';
import './ProfilePage.css';
import { Redirect } from 'react-router-dom';

//const URL_VIEW_MOTOR = 'https://redacao.contextorevisor.com.br/#/';

class ProfilePageComponent extends Component {
  cards = {
    dados: DadosComponent,
    configuracao: ConfiguracaoRevisorComponent,
    revisoes: RevisoesComponent,
    financeiro: FinanceiroComponent,
    propostas: ProposalsComponent,
    compras: ComprasComponent,
    detalheProposta: DetalhePropostaComponent,
    alterarSenha: AlterarSenhaComponent,
    promocional: PromocionalComponent,
    codeclient: PromotionalCodeClient
    // plano: InformacoesPlanoComponent
  };

  state = {
    seeProfile: false,
    modalPublishedSuccess: false,
    modalPublish: false,
    modalPublishMessage: '',
    currentCard: 'dados',
    paramsCard: {},
    userFilled: false,
    shouldRedirect: !localStorage.getItem(TOKEN_KEY),
    user: {
      name: '',
      email: '',
      tipo_formacao_id: '',
      uf_id: '',
      city: '',
      celular: '',
      sexo: '',
      dt_nascimento: '',
      partner_id: ''
    }
  };

  constructor(props) {
    super(props);
    let screen =
      props.location && props.location.state && props.location.state.screen;

    if (screen) {
      let component = this.cards[screen];
      if (component) {
        this.state.currentCard = screen;
      }
    }
  }

  onBackButtonEvent = e => {
    if (this.state.seeProfile === true) {
      e.preventDefault();
      //console.log('zap');
      this.props.history.push('/profile');
    }
  };

  componentDidMount() {
    window.onpopstate = this.onBackButtonEvent;
    this.setup(this.props);

    let { match } = this.props;

    if (match.params.id !== null && match.params.id !== undefined) {
      this.handleSeeProposta();
      this.props.dispatch(fetchOneProposal(match.params.id));
    }
  }

  componentDidUpdate() {
    if (!this.state.shouldRedirect && this.props.loginFailed) {
      this.setState({ shouldRedirect: true });
    }
  }

  componentWillReceiveProps(props) {
    this.setup(props);
    this.showNotification(props);

    let { match } = props;

    if (match.params.id != null && match.params.id !== undefined) {
      let proposal = props.proposals.filter(proposal => {
        return proposal.id === match.params.id;
      })[0];

      if (proposal !== null && proposal !== undefined) {
        this.handleSeeDetalheProposta(proposal);
      }
    }
  }

  updateCard = currentCard => {
    //console.log('zuzuzuzuzu');
    this.props.history.push('/profile');
    if (currentCard === this.state.currentCard) {
      return;
    }
    this.setState({ currentCard });
  };

  setup(props) {
    if (!this.state.userFilled && typeof props.user !== 'undefined') {
      //console.log("user", props.user)
      this.setState({ user: props.user, userFilled: true });
    }
  }

  showNotification(props) {
    if (props.updateSuccess) {
      this.props.dispatch(
        showNotification('success', 'Perfil alterado com sucesso!', true)
      );
    }

    if (props.verifyApelidoError) {
      let messages = props.verifyApelidoError.data.errors;
      this.props.dispatch(showNotification('error', messages, true));
    }

    if (props.publishProfileError) {
      let messages = Object.keys(
        props.publishProfileError.data.errors
      ).map(key => props.publishProfileError.data.errors[key].join(', '));

      if (messages && messages[0] === 'Provider account not verified') {
        messages[0] =
          'ATENÇÃO: Para publicar o perfil e divulgar o minissite, aguarde a análise de seus dados bancários pela instituição financeira';
        messages[1] = 'Duração máxima: 48h.';

        this.props.dispatch(
          showNotification('error', messages.join('. '), true)
        );
      } else {
        this.props.dispatch(
          showNotification('error', messages.join(', '), true)
        );
      }
    }

    if (props.publishProfileSuccess) {
      this.setState({ modalPublishedSuccess: true });
    }
  }

  hasFinanceiro() {
    let fields = [
      'document',
      'email',
      'bank',
      'bank_agency',
      'bank_account',
      'bank_account_type',
      'postal_code',
      'address',
      'number',
      'district',
      'city',
      'uf_id'
    ];
    return this.validateRequiredFields(fields);
  }

  hasPerfilRevisor() {
    let fields = ['apelido', 'ocupacao', 'sobre', 'page_price_cents'];
    return this.validateRequiredFields(fields);
  }

  validateRequiredFields(fields) {
    let { user } = this.props;
    let has = true;

    for (let field of fields) {
      if (!user[field]) {
        has = false;
        break;
      }
    }

    return has;
  }

  handleSave = (user, file) => {
    //console.log(user)
    if (file) {
      this.props.dispatch(userUpdateAvatar(user, file));
      return;
    }
    this.props.dispatch(userUpdate(user));
  };

  handleError = msg => {
    this.props.dispatch(showNotification('error', msg, true));
  };

  handleProfileMenuClick = screen => {
    if (screen) {
      let component = this.cards[screen];
      if (component) {
        this.setState({ currentCard: screen, paramsCard: {} });
      }
    }
  };

  handleDismiss = () => {
    this.setState({ modalPublish: false, modalPublishedSuccess: false });
  };

  handlePublishProfile = () => {
    let erros = [];

    if (!this.hasFinanceiro()) {
      erros.push('Dados Financeiros');
    }

    if (!this.hasPerfilRevisor()) {
      erros.push('Configuração do Perfil');
    }

    if (erros.length) {
      erros = erros.join(' e ');
      this.setState({ modalPublishMessage: erros, modalPublish: true });
    } else {
      this.props.dispatch(userReviewerPublishProfile());
    }
  };

  handleSeeProfile = () => {
    this.setState({ seeProfile: true });
  };

  handleDismissProfile = () => {
    this.setState({ seeProfile: false });
  };

  handleSeeRevisoes = () => {
    let screen = 'revisoes';
    let component = this.cards[screen];
    if (component) {
      this.setState({ currentCard: screen });
    }
  };

  handleSeeMinhasCompras = () => {
    let screen = 'compras';
    let component = this.cards[screen];
    if (component) {
      this.setState({ currentCard: screen });
    }
  };

  handleSeeDetalheProposta = proposal => {
    //console.log('popopopopo');
    let screen = 'detalheProposta';
    let component = this.cards[screen];
    if (component) {
      this.setState({ currentCard: screen, paramsCard: { proposal } });
    }
  };

  handleSeeProposta = () => {
    //console.log('pipipipipip');
    let screen = 'propostas';
    let component = this.cards[screen];
    if (component) {
      this.setState({ currentCard: screen });
    }
  };

  render() {
    let CardComponent = this.cards[this.state.currentCard];
    let { user, roles, authenticated } = this.props;
    let socialUser = false;
    if (user) {
      if (user.fb_id !== null) {
        socialUser = true;
      }
      if (user.google_id !== null) {
        socialUser = true;
      }
    }
    const { shouldRedirect } = this.state;

    return shouldRedirect && !authenticated ? (
      <Redirect to={`/login?msg=true&id=${this.props.match.params.id || ''}`} />
    ) : (
      <div>
        <div className="profile">
          <GeneralMenu
            {...this.props}
            onProfileMenuClick={this.handleProfileMenuClick}
          />
          <div className="profile-container">
            <div className="hero-box profile-box">
              <div className="avatar-container">
                {roles.isParceiro ? (
                  <div style={{ width: '80px', height: '80px' }}>
                    <ParceiroAvatar
                      src={
                        (user && user.imagem) ||
                        (user && user.sexo === 'F' ? avatar1 : avatar2)
                      }
                      className="avatar"
                      alt="Avatar"
                    />
                  </div>
                ) : (
                  <img
                    src={
                      (user && user.imagem) ||
                      (user && user.sexo === 'F' ? avatar1 : avatar2)
                    }
                    className="avatar"
                    alt="Avatar"
                  />
                )}

                <div>
                  <div className="name">{user && user.name}</div>
                  {/*roles && roles.isParceiro && <div className="email">Revisor Parceiro</div>*/}
                  <div className="email">{user && user.email}</div>
                  {this.state.user.role &&
                  this.state.user.role.length > 0 &&
                  this.state.user.role[0].name == 'admin' ? (
                    <div>{this.state.user.role[0].name.toUpperCase()}</div>
                  ) : null}
                </div>
              </div>

              <div className="divider" />
              <div>
                <ul className="menu">
                  {((roles && roles.isRevisor) ||
                    (roles && roles.isParceiro) ||
                    (roles && roles.isRedator)) && (
                    <li
                      onClick={() => this.updateCard('propostas')}
                      className={
                        this.state.currentCard === 'propostas' ? 'selected' : ''
                      }
                      ref="teste"
                    >
                      Propostas e Contrapropostas
                    </li>
                  )}
                  <div>
                    {((roles && roles.isRevisor) ||
                      (roles && roles.isParceiro)) && (
                      <li
                        onClick={() => this.updateCard('revisoes')}
                        className={
                          this.state.currentCard === 'revisoes'
                            ? 'selected'
                            : ''
                        }
                      >
                        Minhas revisões
                      </li>
                    )}
                  </div>

                  {((roles && roles.isRevisor) ||
                    (roles && roles.isParceiro)) && (
                    <div>
                      <li
                        className={
                          this.state.currentCard === 'configuracao'
                            ? 'selected'
                            : ''
                        }
                      >
                        <span onClick={() => this.updateCard('configuracao')}>
                          Meu perfil (minissite)
                        </span>
                        {!this.hasPerfilRevisor() && (
                          <span
                            className="item-icon red"
                            data-tip
                            data-for="info"
                          >
                            <FontAwesomeIcon icon="exclamation" />
                          </span>
                        )}
                      </li>
                    </div>
                  )}

                  <li
                    onClick={() => this.updateCard('dados')}
                    className={
                      this.state.currentCard === 'dados' ? 'selected' : ''
                    }
                  >
                    Dados conta Contexto
                  </li>

                  {/*((roles && roles.isRepresentante) ||
                    (roles && roles.isInstituicao) ||
                    (roles && roles.isDivulgador)) && (
                    <div>
                      <li
                        onClick={() => this.updateCard('promocional')}
                        className={
                          this.state.currentCard === 'promocional'
                            ? 'selected'
                            : ''
                        }
                      >
                        Código Promocional
                      </li>
                    </div>
                      )*/}

                  {/*roles && roles.isRedator && !this.state.user.partner_id && (
                    <div>
                      <li
                        onClick={() => this.updateCard('codeclient')}
                        className={
                          this.state.currentCard === 'codeclient'
                            ? 'selected'
                            : ''
                        }
                      >
                        Inserir Código Promocional
                      </li>
                    </div>
                      )*/}

                  {/* código comentado porque ainda não há as roles Representante Instituição e Divulgador */}

                  {((roles && roles.isRepresentante) ||
                    (roles && roles.isInstituicao) ||
                    (roles && roles.isDivulgador) ||
                    (roles && roles.isRevisor) ||
                    (roles && roles.isParceiro)) && (
                    <div>
                      <li
                        className={
                          this.state.currentCard === 'financeiro'
                            ? 'selected'
                            : ''
                        }
                      >
                        <span onClick={() => this.updateCard('financeiro')}>
                          Dados financeiros
                        </span>
                        {!this.hasFinanceiro() && (
                          <span
                            className="item-icon red"
                            data-tip
                            data-for="info"
                          >
                            <FontAwesomeIcon icon="exclamation" />
                          </span>
                        )}
                      </li>
                    </div>
                  )}

                  {roles && roles.isRedator && (
                    <li
                      onClick={() => this.updateCard('compras')}
                      className={
                        this.state.currentCard === 'compras' ? 'selected' : ''
                      }
                    >
                      Meus pedidos
                    </li>
                  )}

                  {!socialUser && (
                    <li
                      onClick={() => this.updateCard('alterarSenha')}
                      className={
                        this.state.currentCard === 'alterarSenha'
                          ? 'selected'
                          : ''
                      }
                    >
                      Alterar senha
                    </li>
                  )}

                  {((roles && roles.isRevisor) ||
                    (roles && roles.isParceiro)) && (
                    <div>
                      <li>
                        <span>
                          <a
                            className="revisoes-detalhes"
                            href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/`}
                          >
                            Área de trabalho
                          </a>
                        </span>
                      </li>
                    </div>
                  )}
                </ul>
              </div>

              {(roles.isRevisor || roles.isParceiro) &&
                user &&
                !user.published_at && (
                  <button
                    className="button w-button turquoise"
                    onClick={this.handlePublishProfile}
                  >
                    Publicar seu Perfil
                  </button>
                )}

              {(roles.isRevisor || roles.isParceiro) && (
                <button
                  className="button w-button"
                  onClick={this.handleSeeProfile}
                >
                  Ver Perfil
                </button>
              )}
            </div>

            <CardComponent
              onSave={this.handleSave}
              onError={this.handleError}
              onDetalheProposta={this.handleSeeDetalheProposta}
              onBackProposta={this.handleSeeProposta}
              onBackRevisoes={this.handleSeeRevisoes}
              onBackMinhasCompras={this.handleSeeMinhasCompras}
              {...this.props}
              {...this.state.paramsCard}
            />
          </div>
          <Footer />
          <ReactTooltip place="top" type="dark" effect="float" />

          <ReactTooltip id="info" aria-haspopup="true">
            <span>
              Complete as informações para <br />
              publicar seu perfil
            </span>
          </ReactTooltip>

          <ReactTooltip id="financeiro" aria-haspopup="true">
            <span>
              Analisando seus dados <br /> financeiros
            </span>
          </ReactTooltip>

          <Modal
            message={
              'Para publicar seu perfil você precisa completar os dados dos seguintes menus: ' +
              this.state.modalPublishMessage
            }
            okText={'Ok, entendi'}
            onOkClick={this.handleDismiss}
            visible={this.state.modalPublish}
            onlyOkButton={true}
          />

          <Modal
            message={'Perfil publicado com sucesso'}
            okText={'Ok'}
            onOkClick={this.handleDismiss}
            visible={this.state.modalPublishedSuccess}
            onlyOkButton={true}
          />
        </div>
        <ProfilePreview
          {...this.props}
          visible={this.state.seeProfile}
          onDismiss={this.handleDismissProfile}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ reviewersStore, userStore }) => {
  let {
    states,
    trainings,
    reviewerCreateCounterProposalSuccess
  } = reviewersStore;
  return {
    states,
    trainings,
    ...userStore,
    reviewerCreateCounterProposalSuccess
  };
};

const ProfilePage = connect(mapStateToProps)(ProfilePageComponent);
export { ProfilePage };
