import { makeActionCreator } from '../util';

export const LOGIN = 'LOGIN';
export const login = makeActionCreator(LOGIN, 'data');

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const updatePassword = makeActionCreator(UPDATE_PASSWORD, 'data');

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const updatePasswordSuccess = makeActionCreator(UPDATE_PASSWORD_SUCCESS);

export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const updatePasswordError = makeActionCreator(UPDATE_PASSWORD_ERROR);

export const LOGIN_MOTOR = 'LOGIN_MOTOR';
export const loginMotor = makeActionCreator(LOGIN_MOTOR, 'data');

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const loginFacebook = makeActionCreator(LOGIN_FACEBOOK, 'data');

export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const loginGoogle = makeActionCreator(LOGIN_GOOGLE, 'data');

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = makeActionCreator(LOGIN_SUCCESS, 'data');

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = makeActionCreator(LOGIN_ERROR, 'data');

export const LOGIN_MOTOR_SUCCESS = 'LOGIN_MOTOR_SUCCESS';
export const loginMotorSuccess = makeActionCreator(LOGIN_MOTOR_SUCCESS, 'data');

export const LOGIN_MOTOR_ERROR = 'LOGIN_MOTOR_ERROR';
export const loginMotorError = makeActionCreator(LOGIN_MOTOR_ERROR);

export const LOGOFF = 'LOGOFF';
export const logoff = makeActionCreator(LOGOFF);

export const CHECK_TOKEN = 'CHECK_TOKEN';
export const checkToken = makeActionCreator(CHECK_TOKEN);

export const USER_RECOVER_PASSWORD = 'USER_RECOVER_PASSWORD';
export const userRecoverPassword = makeActionCreator(
  USER_RECOVER_PASSWORD,
  'data'
);

export const USER_RECOVER_PASSWORD_SUCCESS = 'USER_RECOVER_PASSWORD_SUCCESS';
export const userRecoverPasswordSuccess = makeActionCreator(
  USER_RECOVER_PASSWORD_SUCCESS
);

export const USER_RECOVER_PASSWORD_ERROR = 'USER_RECOVER_PASSWORD_ERROR';
export const userRecoverPasswordError = makeActionCreator(
  USER_RECOVER_PASSWORD_ERROR
);

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const userResetPassword = makeActionCreator(USER_RESET_PASSWORD, 'data');

export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const userResetPasswordSuccess = makeActionCreator(
  USER_RESET_PASSWORD_SUCCESS
);

export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const userResetPasswordError = makeActionCreator(
  USER_RESET_PASSWORD_ERROR
);

export const USER_CREATE_WRITER = 'USER_CREATE_WRITER';
export const userCreateWriter = makeActionCreator(USER_CREATE_WRITER, 'data');

export const USER_CREATE_REVIEWER = 'USER_CREATE_REVIEWER';
export const userCreateReviewer = makeActionCreator(
  USER_CREATE_REVIEWER,
  'data'
);
//**-* */
export const USER_CREATE_PARTNER_INSTITUITION =
  'USER_CREATE_PARTNER_INSTITUITION';
export const userCreatePartnerInstituition = makeActionCreator(
  USER_CREATE_PARTNER_INSTITUITION,
  'data'
);

export const USER_CREATE_PROMOTER = 'USER_CREATE_PROMOTER';
export const userCreatePromoter = makeActionCreator(
  USER_CREATE_PROMOTER,
  'data'
);
//**-* */
export const USER_UPDATE = 'USER_UPDATE';
export const userUpdate = makeActionCreator(USER_UPDATE, 'data');

export const USER_UPDATE_AVATAR = 'USER_UPDATE_AVATAR';
export const userUpdateAvatar = makeActionCreator(
  USER_UPDATE_AVATAR,
  'data',
  'file'
);

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const userUpdateSuccess = makeActionCreator(USER_UPDATE_SUCCESS, 'data');

export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const userUpdateError = makeActionCreator(USER_UPDATE_ERROR, 'data');

export const USER_PREVIEW_STATE = 'USER_PREVIEW_STATE';
export const userPreviewState = makeActionCreator(USER_PREVIEW_STATE, 'data');

export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const userCreateSuccess = makeActionCreator(USER_CREATE_SUCCESS);

export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const userCreateError = makeActionCreator(USER_CREATE_ERROR, 'data');

export const USER_CONTACT = 'USER_CONTACT';
export const userContact = makeActionCreator(USER_CONTACT, 'data');

export const USER_REVIEWER_PUBLISH_PROFILE = 'USER_REVIEWER_PUBLISH_PROFILE';
export const userReviewerPublishProfile = makeActionCreator(
  USER_REVIEWER_PUBLISH_PROFILE,
  'data'
);

export const USER_REVIEWER_PUBLISH_PROFILE_SUCCESS =
  'USER_REVIEWER_PUBLISH_PROFILE_SUCCESS';
export const userReviewerPublishProfileSuccess = makeActionCreator(
  USER_REVIEWER_PUBLISH_PROFILE_SUCCESS
);

export const USER_REVIEWER_PUBLISH_PROFILE_ERROR =
  'USER_REVIEWER_PUBLISH_PROFILE_ERROR';
export const userReviewerPublishProfileError = makeActionCreator(
  USER_REVIEWER_PUBLISH_PROFILE_ERROR,
  'data'
);

// Actions da verificação do apelido (nome minisite) do revisor

export const VERIFY_APELIDO = 'VERIFY_APELIDO';
export const verifyApelido = makeActionCreator(VERIFY_APELIDO, 'apelido');

export const USER_REVIEWER_VERIFY_APELIDO_SUCCESS =
  'USER_REVIEWER_VERIFY_APELIDO_SUCCESS';
export const userReviewerVerifyApelidoSuccess = makeActionCreator(
  USER_REVIEWER_VERIFY_APELIDO_SUCCESS,
  'data'
);

export const USER_REVIEWER_VERIFY_APELIDO_ERROR =
  'USER_REVIEWER_VERIFY_APELIDO_ERROR';
export const userReviewerVerifyApelidoError = makeActionCreator(
  USER_REVIEWER_VERIFY_APELIDO_ERROR,
  'data'
);

export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export const fetchProposal = makeActionCreator(FETCH_PROPOSAL, 'data');

export const FETCH_COUNTERPROPOSAL = 'FETCH_COUNTERPROPOSAL';
export const fetchCounterProposal = makeActionCreator(
  FETCH_COUNTERPROPOSAL,
  'data'
);

export const FETCH_ONE_PROPOSAL = 'FETCH_ONE_PROPOSAL';
export const fetchOneProposal = makeActionCreator(FETCH_ONE_PROPOSAL, 'id');

export const FETCHED_ONE_PROPOSAL = 'FETCHED_ONE_PROPOSAL';
export const fetchedOneProposal = makeActionCreator(
  FETCHED_ONE_PROPOSAL,
  'data'
);

export const FETCHED_PROPOSAL = 'FETCHED_PROPOSAL';
export const fetchedProposal = makeActionCreator(FETCHED_PROPOSAL, 'data');

export const FETCHED_COUNTERPROPOSAL = 'FETCHED_COUNTERPROPOSAL';
export const fetchedCounterProposal = makeActionCreator(
  FETCHED_COUNTERPROPOSAL,
  'data'
);

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const fetchInvoices = makeActionCreator(FETCH_INVOICES);

export const FETCHED_INVOICES = 'FETCHED_INVOICES';
export const fetchedInvoices = makeActionCreator(FETCHED_INVOICES, 'data');

export const USER_CREATE_REPRESENTATIVE = 'USER_CREATE_REPRESENTATIVE';
export const userCreateRepresentative = makeActionCreator(
  USER_CREATE_REPRESENTATIVE,
  'data'
);

export const EMBEDDED_SIGNING = 'EMBEDDED_SIGNING';
export const embeddedSigning = makeActionCreator(EMBEDDED_SIGNING, 'data');

export const EMBEDDED_SIGNING_SUCCESS = 'EMBEDDED_SIGNING_SUCCESS';
export const embeddedSigningSuccess = makeActionCreator(
  EMBEDDED_SIGNING_SUCCESS,
  'data'
);

export const BECOME_PARTNER = 'BECOME_PARTNER';
export const becomePartner = makeActionCreator(BECOME_PARTNER, 'data');

export const BECOME_PARTNER_SUCCESS = 'BECOME_PARTNER_SUCCESS';
export const becomePartnerSuccess = makeActionCreator(
  BECOME_PARTNER_SUCCESS,
  'data'
);
