import React, { Component } from 'react';
import { NotificationComponent } from '../../siteComponents/Notification';
import { connect } from 'react-redux';

class NotificationContainer extends Component {
  render() {
    let { notificationType, notificationMsg, auto } = this.props;

    return (
      <NotificationComponent
        msg={notificationMsg}
        type={notificationType}
        auto={auto}
      />
    );
  }
}

const mapStateToProps = ({ notificationStore }) => {
  return { ...notificationStore };
};

const Notification = connect(mapStateToProps)(NotificationContainer);
export { Notification };
