export default (() => {
  const includeRoutesOnKey = (key, newRoutes) =>
    newRoutes.forEach(
      route =>
        (routes[Object.keys(route)[0]] =
          routes[key] + route[Object.keys(route)[0]])
    );

  const routes = {
    buscarRevisor: '/buscar-revisor',
    sejaRevisor: '/seja-revisor',
    contato: '/contato',
    parcerias: '/parcerias',
    sobre: '/sobre',
    blog: '/blog',
    perfilRevisor: '/perfil-revisor/:apelido',
    perfilRevisorSite: '/perfil-revisor/:apelido',
    login: '/login',
    logout: '/logout',
    profile: '/profile',
    contratoRevisor: '/contrato-parceiro-revisor',
    contratoRepresentante: '/contrato-parceiro-representante',
    contratoDivulgador: '/contrato-parceiro-divulgador',
    contratoInstituicao: '/contrato-instituicao-parceira',
    carrinhoCompras: '/carrinho',
    termos: '/termos',
    recuperarSenha: '/recuperar-senha',
    redefinirSenha: '/redefinir-senha',
    perfilPropostaDetalhe: '/profile-proposta-detalhe/:id',
    painel: '/painel',
    painelClients: '/painel-clientes'
  };

  const loginRoutes = [
    { criarConta: '/criar-conta' },
    { criarContaRevisor: '/criar-conta-revisor' },
    { criarContaRedator: '/criar-conta-redator' },
    { criarContaInstituicaoParceira: '/criar-conta-instituicao' },
    { parceiroRepresentante: '/criar-conta-representante' },
    { criarContaParceiroDivulgador: '/criar-conta-divulgador' }
  ];

  includeRoutesOnKey('login', loginRoutes);
  return routes;
})();
