import React from 'react';
import recebaImg from './images/receba-img.svg';
import reviseImg from './images/revise-img.svg';
import classifiqueImg from './images/classifique-img.svg';
import contaBancariaImg from './images/cbancaria-img.svg';
import InfoBox from '../../../../siteComponents/InfoBox';
import ViralContainer from '../../../../siteComponents/ViralContainer';

const MainSection = () => (
  <div className="light-padding section-dark">
    <ViralContainer
      color="turquoise-box"
      text={[
        'Trabalhe em qualquer lugar e a qualquer hora.',
        <br />,
        'Seja um revisor-empreendedor!'
      ]}
    />
    <div className="info-boxes">
      <InfoBox
        img={recebaImg}
        title="Escritório de revisão virtual com suporte tecnológico"
        content="Utilize ferramentas inovadoras de revisão de textos e comunicação, atualizadas automaticamente e que lhe garantem otimização e escalabilidade em seu trabalho."
      />
      <InfoBox
        img={contaBancariaImg}
        title="Você recebe seu pagamento on-line, com rapidez e segurança"
        content="O sistema Iugu de transações bancárias mantém sob depósito os valores combinados até a conclusão do trabalho. Entregou? Recebeu!"
      />
      <InfoBox
        img={reviseImg}
        title="Clientes do Brasil e do mundo ao seu alcance"
        content="Nós divulgamos seu trabalho e você também pode fazer marketing do minissite nas redes sociais. É sinergia gerando renda e relacionamentos."
      />
      <InfoBox
        img={classifiqueImg}
        title="Conquiste autonomia e qualidade de vida, e ganhe destaque profissional"
        content="A internet ampliou muito o mercado para o revisor de textos. Você já está aparelhado para ampliar sua carteira de clientes? Explore essa oportunidade e inaugure um novo e melhor estilo de vida."
      />
    </div>
  </div>
);

export default MainSection;
