import React, { Component } from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApiService } from '../../../state-management/service/api.js';
import { showNotification, userUpdate } from '../../../state-management';
class PromocionalComponent extends Component {
  state = {
    user: {
      code: '',
      due_date_code: ''
    }
  };

  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
    let newState = { ...this.state };
    newState.avatar = '';
    this.setState({ ...newState });
  }

  componentDidMount() {
    this.setup(this.props);
  }

  componentWillReceiveProps(props) {
    this.setup(props);
  }

  setup(props) {
    if (!this.state.userFilled && typeof props.user !== 'undefined') {
      let { user } = props;
      let newState = { ...this.state };
      if (user && user.dt_nascimento && !user.dt_nascimento.includes('/')) {
        user.dt_nascimento = moment(user.dt_nascimento, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        );
      }

      if (props.user && props.user.imagem) {
        newState.avatar = props.user.imagem;
        newState.code = props.user.code;
        newState.due_date_code = props.user.due_date_code;
      }
      this.setState({ ...newState, user, userFilled: true });
    }
  }
  createCode = event => {
    const { user } = this.state;
    const { dispatch } = this.props;
    let user_atributtes;
    ApiService.createCode()
      .then(response => {
        user_atributtes = response.data[0];
        this.setState({
          user: {
            code: user_atributtes.code,
            due_date_code: user_atributtes.due_date_code
          }
        });
        dispatch(userUpdate(user));
      })
      .catch(function(response) {
        dispatch(showNotification('error', 'Código já existente.', true));
        return;
      });
  };

  render() {
    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">Códigos Promocionais</h4>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Código Promocional</th>
              <th scope="col">Data de Expiração</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.user.code}</td>
              <td>{this.state.user.due_date_code}</td>
            </tr>
          </tbody>
        </table>
        <button className="button w-button turquoise" onClick={this.createCode}>
          Gerar novo código
        </button>
      </div>
    );
  }
}

export { PromocionalComponent };
