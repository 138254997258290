import React, { Component } from 'react';
import {
  FormInput,
  FormValidator,
  FormCurrency
} from '../../siteComponents/form';

import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { ExperienciasComponent, Skills, Anexos, Curriculos } from './revisor';

import {
  verifyApelido,
  userPreviewState,
  showNotification
} from '../../../state-management';

import { ProfilePreview } from '../ProfileReviewer/ProfilePreview';

const map = {
  â: 'a',
  Â: 'A',
  à: 'a',
  À: 'A',
  á: 'a',
  Á: 'A',
  ã: 'a',
  Ã: 'A',
  ê: 'e',
  Ê: 'E',
  è: 'e',
  È: 'E',
  é: 'e',
  É: 'E',
  î: 'i',
  Î: 'I',
  ì: 'i',
  Ì: 'I',
  í: 'i',
  Í: 'I',
  õ: 'o',
  Õ: 'O',
  ô: 'o',
  Ô: 'O',
  ò: 'o',
  Ò: 'O',
  ó: 'o',
  Ó: 'O',
  ü: 'u',
  Ü: 'U',
  û: 'u',
  Û: 'U',
  ú: 'u',
  Ú: 'U',
  ù: 'u',
  Ù: 'U',
  ç: 'c',
  Ç: 'C'
};

const laudaMinimalValue = 500; //Valor mínimo da lauda é de R$5 por conta do processamento mínimo da IUGU

const adviceStyle = {
  color: '#95989a',
  fontStyle: 'italic',
  fontSize: '0.9em'
};

class ConfiguracaoRevisorComponent extends Component {
  state = {
    seeProfile: false,
    userFilled: false,
    apelidoValidate: false,
    user: {
      apelido: '',
      ocupacao: '',
      sobre: '',
      video_url: '',
      page_price_cents: '0.00',
      experiences: [],
      attachments: [],
      curriculum: {},
      // skills
      redacao_estudantil: 0,
      redacao_concursos: 0,
      tcc: 0,
      monografia: 0,
      dissertacao: 0,
      tese: 0,
      trabalhos_tecnicos: 0,
      livros: 0,
      periodicos: 0,
      blogs: 0,
      sites_aplicativos: 0,
      textos_em_imagens: 0,
      transcricoes_audio_video: 0,
      recursos_concurso: 0,
      traducoes: 0,
      tag_skill: ''
    }
  };

  componentDidMount() {
    //console.log(this.props)
    this.setup(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.verifyApelidoError) {
      let user = { ...props.user };
      this.setState({ user });
    }

    this.setup(props);
  }

  setup(props) {
    if (
      (!this.state.userFilled && typeof props.user !== 'undefined') ||
      props.updateSuccess
    ) {
      let user = { ...props.user };

      for (let i in this.state.user) {
        user[i] = props.user[i] || this.state.user[i];
      }

      this.setState({ user, userFilled: true });
    }
  }

  handleChangeInput = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    if (name === 'video_url') {
      this.checkVideo(value);
    }
    state.user[name] = value;
    this.setState(state);
    this.props.dispatch(
      userPreviewState({ ...this.props.user, ...this.state.user })
    );
  };

  handleOnBlurInputApelido = e => {
    let { value } = e.target;
    if (value !== '' && this.props.user.apelido !== value) {
      this.setState({ apelidoValidate: false });
      this.props.dispatch(verifyApelido(value));
    }
    return;
  };

  handleChange = ({ name, value }) => {
    let state = { ...this.state };
    state.user[name] = value;
    //console.log(state)
    this.state = state;
    //this.props.dispatch(
    //  userPreviewState({ ...this.props.user, ...this.state.user })
    //);
  };

  handleCentsChange = values => {
    let { user } = this.state;
    user.page_price_cents = values.floatValue * 100;
    this.setState({ user });
    this.props.dispatch(userPreviewState({ ...this.state.user }));
  };

  checkVideo = url => {
    //O back também verifica isso, mas por questões de UX, é interessante avisar logo ao usuário que seu link não será aceito
    //antes de fazer a requisição.
    if (url.length !== 0 && url.indexOf('embed') === -1) {
      if (url.indexOf('youtube') === -1) {
        this.props.dispatch(
          showNotification(
            'error',
            'Favor inserir um vídeo da plataforma Youtube.',
            false
          )
        );
      } else if (url.indexOf('v=') === -1) {
        this.props.dispatch(
          showNotification(
            'error',
            'Por favor, insira um vídeo ao invés de canais ou playlists.',
            false
          )
        );
      }
    }
  };

  replaceAll(str, de, para) {
    var pos = str.indexOf(de);
    while (pos > -1) {
      str = str.replace(de, para);
      pos = str.indexOf(de);
    }
    return str;
  }

  /**
   * Remove acentos de strings
   * @param  {String} string acentuada
   * @return {String} string sem acento
   */
  removerAcentos = s => {
    return s.replace(/[\W[\] ]/g, function(a) {
      return map[a] || a;
    });
  };

  handleSave = e => {
    //console.log(this.state);
    e.preventDefault();
    if (this.validate()) {
      let { user } = this.state;
      //console.log(user);

      if (this.validatePrice(user.page_price_cents)) {
        user.apelido = this.replaceAll(user.apelido, ' ', '-').toLowerCase();

        //remover acentos
        user.apelido = this.removerAcentos(user.apelido);

        this.props.onSave(user);
      }
    }
  };

  handleSeeProfile = () => {
    this.setState({ seeProfile: true });
  };

  handleDismissProfile = () => {
    this.setState({ seeProfile: false });
  };

  validate() {
    if (
      !this.state.user.apelido ||
      !this.state.user.ocupacao ||
      !this.state.user.sobre ||
      !this.state.user.page_price_cents
    ) {
      this.props.onError(
        'ATENÇÃO: Você precisar preencher todos os dados de configuração de seu perfil'
      );
      return false;
    }

    return true;
  }

  validatePrice(price) {
    if (price < laudaMinimalValue) {
      this.props.onError('O valor mínimo por lauda é de R$ 5,00.');
      return false;
    }

    return true;
  }

  render() {
    let salvarLabel =
      this.state.user && this.state.user.published_at
        ? 'Salvar e Publicar'
        : 'Salvar';

    let previewProps = { ...this.props };

    previewProps.user = { ...this.state.user };

    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">
          Configuração perfil revisor (minissite)
        </h4>

        <p>
          Aqui você insere e, quando necessário, modifica os dados que serão
          mostrados aos clientes, quer no marketplace, quer no seu minissite,
          que, com endereço próprio, você poderá divulgar onde quiser. Portanto,
          capriche nas informações.
        </p>

        <p>
          (No entanto, visando à segurança das informações e ao bom fluxo das
          atividades, é vedado incluir dados para contato direto, como telefone,
          WhatsApp, e-mail, endereço e similares.)
        </p>

        <h5 className="config-subtitle">Nome do Minissite *</h5>

        <FormValidator className="w-form" onSubmit={this.handleSave}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              Complete segundo o modelo:
              https://www.contextorevisor.com.br/revisor/nomedominissite
            </InputGroupText>
          </InputGroupAddon>

          <div class="inner-form">
            <div class="first-wrap">
              <label className="w-label w-input">
                contextorevisor.com.br/revisor/
              </label>
            </div>
            <div class="second-wrap">
              <input
                type="text"
                placeholder="Nome do minissite"
                className="w-input"
                name="apelido"
                validators={['required']}
                value={this.state.user.apelido}
                onChange={this.handleChangeInput}
                onBlur={this.handleOnBlurInputApelido}
                autoFocus={true}
              />
            </div>
          </div>

          <InputGroupAddon>
            <h5 className="config-subtitle margintop">Nível de formação *</h5>
            <FormInput
              type="text"
              placeholder="Inclua seu nível de formação *"
              className="accont-input w-input"
              name="ocupacao"
              validators={['required']}
              value={this.state.user.ocupacao}
              onChange={this.handleChangeInput}
            />
          </InputGroupAddon>

          <h5 className="config-subtitle margintop">Apresentação *</h5>

          <textarea
            type="text"
            placeholder="Inclua texto com breve apresentação como revisor"
            className="accont-input w-input"
            name="sobre"
            validators={['required']}
            value={this.state.user.sobre}
            onChange={this.handleChangeInput}
          />

          <h5 className="config-subtitle margintop">Vídeo</h5>

          <span style={adviceStyle}>
            Somente links para <strong>vídeos</strong> da plataforma{' '}
            <strong>Youtube</strong>, e não de canais ou playlists.
          </span>

          <FormInput
            type="text"
            placeholder="Insira a url (link) do seu vídeo (se tiver)"
            className="accont-input w-input"
            name="video_url"
            value={this.state.user.video_url}
            onChange={this.handleChangeInput}
          />

          <ExperienciasComponent
            value={this.state.user.experiences}
            onChange={this.handleChange}
          />

          {/* {console.log(this.state.user.curriculum)} */}

          <Curriculos
            value={this.state.user.curriculum}
            onChange={this.handleChange}
          />

          <Anexos
            value={this.state.user.attachments}
            onChange={this.handleChange}
          />

          <Skills value={this.state.user} onChange={this.handleChange} />

          <h5 className="config-subtitle">Valor médio por lauda *</h5>

          <FormCurrency
            placeholder="Valor por lauda R$ 0,00"
            className="accont-input w-input"
            name="page_price"
            onValueChange={this.handleCentsChange}
            value={this.state.user.page_price_cents / 100 || undefined}
            validators={['required']}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <button
              type="button"
              className="button inverse-btn w-button"
              onClick={this.handleSeeProfile}
            >
              Ver perfil
            </button>

            <button
              type="button"
              className="button turquoise w-button"
              onClick={this.handleSave}
            >
              {salvarLabel}
            </button>
          </div>
        </FormValidator>

        <ProfilePreview
          {...previewProps}
          visible={this.state.seeProfile}
          onDismiss={this.handleDismissProfile}
        />
      </div>
    );
  }
}

export { ConfiguracaoRevisorComponent };
