import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormValidator,
  FormInput,
  FormButton
} from '../../../../siteComponents/form';
import routes from '../../../../../routes';
import {
  login,
  loginMotor,
  showNotification
} from '../../../../../state-management';
import LoginSocialSection from '../LoginSocialSection/LoginSocialSection';

const { criarConta, recuperarSenha } = routes;

class LoginComponent extends Component {
  state = {
    nextUrl: '/profile',
    form: {
      email: '',
      password: ''
    },
    error: {
      email: false,
      password: false
    }
  };
  timeOut = null;
  loggedHere = false;

  constructor(props) {
    super(props);

    if (props.location.search) {
      var msgStr = this.props.location.search.split('=')[1];
      if (msgStr !== undefined) {
        var msg = msgStr.split('&')[0];
      }
      var id = this.props.location.search.split('=')[2];

      if (msgStr !== undefined && msg !== 'true') {
        this.state.nextUrl = props.location.search.split('=')[1];
      } else {
        this.state.nextUrl = '/profile-proposta-detalhe/' + id;
      }
    }
  }

  componentDidMount() {
    var msgStr = this.props.location.search.split('=')[1];
    if (msgStr !== undefined) {
      var msg = msgStr.split('&')[0];
    }

    if (msgStr !== 'undefined' && msg === 'true') {
      this.props.dispatch(
        showNotification(
          'error',
          'Faça o login e vá até a página "Propostas".',
          true
        )
      );
      this.timeOut = setTimeout(() => {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 3000);
    }
  }

  componentWillReceiveProps(props) {
    if (props.authenticated && !this.loggedHere) {
      this.props.history.replace('/profile');
    }

    if (props.loginSuccess) {
      this.props.dispatch(
        showNotification('success', 'Usuário logado com sucesso!', true)
      );
      this.timeOut = setTimeout(() => {
        this.props.history.replace(this.state.nextUrl);
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 2000);
    }

    if (props.loginFailed) {
      if (props.httpStatus == 403) {
        this.props.dispatch(
          showNotification(
            'error',
            'Acesso não autorizado, confira seu contrato',
            true
          )
        );
      } else {
        this.props.dispatch(
          showNotification(
            'error',
            'Acesso não autorizado, confira seu email e senha!',
            true
          )
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  handleChangeInputText = e => {
    let { name, value } = e.target;
    const newState = { ...this.state };
    newState.form[name] = value;
    this.setState(newState);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.loggedHere = true;

    try {
      this.props.dispatch(loginMotor(this.state.form));
    } catch (error) {
      // TODO adicionar em alerta
      console.error(error);
    }
    this.props.dispatch(login(this.state.form));
  };

  render() {
    return (
      <div className="halfbox login-box">
        <div className="w-form">
          <FormValidator className="form" onSubmit={this.handleSubmit}>
            <div className="redator-step1">
              <div className="row w-row">
                <div className="gray-list w-col w-col-1">
                  <div />
                </div>
                <div className="gray-list w-col w-col-11">
                  <div>Efetuar login</div>
                </div>
              </div>
              <FormInput
                type="text"
                placeholder="E-mail"
                className={
                  'accont-input w-input ' + (this.state.error.email && 'error')
                }
                validators={['required', 'email']}
                name="email"
                onChange={this.handleChangeInputText}
              />
              <FormInput
                type="password"
                name="password"
                className={
                  'accont-input w-input ' + (this.state.error.email && 'error')
                }
                validators={['required']}
                placeholder="Senha"
                onChange={this.handleChangeInputText}
              />
              <FormButton
                type="submit"
                className="accont-btn--redator button w-button"
                onClick={this.handleSubmit}
              >
                Entrar
              </FormButton>
              <div className="separator">
                <div className="account-stripe" />
                <div>ou entrar com</div>
                <div className="account-stripe" />
              </div>
            </div>
          </FormValidator>

          <LoginSocialSection />
        </div>
        <div className="toggle-login">
          <div>Ainda não tem uma conta?</div>
          <Link to={criarConta} className="toggle-link">
            Criar conta
          </Link>
          <br />
          <Link to={recuperarSenha} className="toggle-esqueci-senha">
            esqueci minha senha
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  let { loginSuccess, loginFailed, authenticated, httpStatus } = userStore;
  return { loginSuccess, loginFailed, authenticated, httpStatus };
};
const LoginSection = connect(mapStateToProps)(LoginComponent);
export { LoginSection };
