import { bool, func, string, ReactElementLike } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export const PriceBox = ({
  title,
  price,
  description,
  period,
  buttonText,
  register,
  selected,
  onClick,
  to,
  children,
  customClass
}) => (
  <div
    className={
      'price-box ' +
      (selected ? 'selected ' : ' ') +
      (customClass ? customClass : '')
    }
    onClick={() => {
      onClick && onClick();
    }}
  >
    <p className="bold pre-title turquoise-text">{title}</p>
    <div className="stripe-turquoise" />
    <div className="giant-text">
      {price}
      <span className="price-billied">{period}</span>
    </div>

    <p className="price-p">{description}</p>

    {children && (
      <div style={{ display: 'flex', flexDirection: 'column', height: '85%' }}>
        <div style={{ flex: 1 }}>{children && children[0]}</div>

        <div>
          {children && children[1]}

          {!register && (
            <div>
              <div className="stripe-turquoise" />
              <Link to={to || '/'} className="button price-btn w-button">
                {buttonText}
              </Link>
            </div>
          )}
        </div>
      </div>
    )}

    {!children && !register && (
      <div>
        <div className="stripe-turquoise" />
        <Link to={to || '/'} className="button price-btn w-button">
          {buttonText}
        </Link>
      </div>
    )}
  </div>
);

PriceBox.propTypes = {
  title: string,
  price: string,
  description: string,
  period: string,
  buttonText: string,
  register: bool,
  selected: bool,
  onClick: func,
  to: string,
  children: ReactElementLike,
  customClass: string
};

export default PriceBox;
