import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInvoices } from '../../../state-management';
import emptyList from '../../assetsHolder/images/img-empty-list.png';
import { URL_VIEW_MOTOR } from '../../../state-management/service/api';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const labelTipo = {
  '1': 'Redação estudantil/concurso',
  '2': 'TCC/monografia/dissertação/tese',
  '3': 'Outros tipos de texto'
};

// TODO usar variavel do .gitlab-ci.yml
//const URL_VIEW_MOTOR = 'https://redacao.contextorevisor.com.br/#/';

class RevisoesContainer extends Component {
  componentDidMount() {
    this.props.dispatch(fetchInvoices());
  }

  render() {
    let loading = this.props.loading;
    let { invoices } = this.props;

    if (invoices === undefined) {
      invoices = [];
    }

    return (
      <div className="hero-box img-box minhas-compras">
        <h4 className="turquoise-text bold">Minhas revisões</h4>

        {invoices.length > 0 && !loading && (
          <ul className="perfil-list w-list-unstyled">
            {this.props.invoices
              .sort((a, b) => (a['proposal_id'] < b['proposal_id'] ? 1 : -1))
              .map((invoice, index) => (
                <li className="perfil-item" key={index}>
                  <div className="list-style" />
                  <div className="revisao-item">
                    <div className="compras-title">
                      <strong>{labelTipo[invoice.proposal.type]}</strong>
                    </div>
                    <div className="compras-title">
                      <strong>Cliente: </strong>
                      {invoice.writer.name}
                    </div>
                    {invoice.status === 'in_analysis' && (
                      <div className="compras-title">
                        <strong>
                          Esperando a debitação do valor (Ao iniciar a revisão,
                          você se declara ciente dos riscos)
                        </strong>
                      </div>
                    )}
                    {invoice.status !== 'in_analysis' && (
                      <div className="compras-title">
                        <strong>Proposta feita em: </strong>
                        {invoice.created_at}
                      </div>
                    )}
                    {invoice.paid_at !== 'Invalid date' && (
                      <div className="compras-title">
                        <strong>Pago em: </strong>
                        {invoice.paid_at}
                      </div>
                    )}
                    <div className="revisao-paginas">
                      {invoice.proposal.total_pages} laudas
                    </div>
                    <div className="revisao-preco">
                      R${' '}
                      {(
                        (invoice.proposal.total_proposal_cents ||
                          invoice.proposal.total_original_cents) / 100
                      )
                        .toFixed(2)
                        .toLocaleString('pt-BR')}
                    </div>

                    {invoice.wrp &&
                      invoice.wrp.redacao &&
                      invoice.status == ('archive' || 'archive-done') && (
                        <a
                          className="button turquoise w-button nomargin margin-left-auto"
                          href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                        >
                          Link para revisar
                        </a>
                      )}

                    {invoice.wrp &&
                      invoice.wrp.redacao &&
                      invoice.status == ('used' || 'done') && (
                        <a
                          className="button turquoise w-button nomargin margin-left-auto"
                          //href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                          //em vez de "1", o ideal seria o id da revisão, mas qualquer valor serve nessa url
                          href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                        >
                          Link para revisar
                        </a>
                      )}

                    {invoice.wrp &&
                      invoice.wrp.redacao &&
                      invoice.status == 'paid || in_analysis' && (
                        <p className="revisao-paginas">
                          Esperando a entrega do texto
                        </p>
                      )}

                    {invoice.wrp &&
                      invoice.wrp.redacao &&
                      invoice.status == 'pending' && (
                        <p className="revisao-paginas">Esperando o pagamento</p>
                      )}
                  </div>
                </li>
              ))}
          </ul>
        )}

        {invoices.length === 0 && !loading && (
          <div>
            <img src={emptyList} alt="Lista Vazia" className="no-proposta" />
            <span className="no-proposta">Você ainda não tem revisões</span>
          </div>
        )}

        {loading && (
          <SkeletonTheme>
            <ul className="perfil-list w-list-unstyled">
              <li className="perfil-item" key={2}>
                <div className="list-style" />
                <div className="revisao-item">
                  <Skeleton
                    count={6}
                    width={200}
                    style={{ marginRight: '50%', marginBottom: '2%' }}
                  />
                </div>
              </li>
            </ul>
          </SkeletonTheme>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  return { ...userStore };
};

const RevisoesComponent = connect(mapStateToProps)(RevisoesContainer);
export { RevisoesComponent };
