import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import './SidebarMenu.css';
import { connect } from 'react-redux';

const {
  buscarRevisor,
  logout,
  login,
  contato,
  sobre,
  profile,
  parcerias
} = routes;

const mapStatetoProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});

const Sidebar = ({ authenticated, revisor, open, onClose, show }) => {
  function handleLogout() {
    window.location.replace('/');
  }

  return (
    <Fragment style={{ position: 'relative', float: 'left' }}>
      <div id="container" className={!show ? 'hidden' : 'showed'}>
        <div
          id="sidebar"
          className={`column-basics ${
            !show ? 'closed-sidebar' : 'opened-sidebar'
          }`}
        >
          {authenticated ? (
            <Link to={profile} className="hero-navlink2 w-nav-link">
              Perfil
            </Link>
          ) : (
            <Link to={login} className="hero-navlink2 w-nav-link">
              Entrar
            </Link>
          )}

          {revisor ? (
            <Fragment></Fragment>
          ) : (
            <Link to={buscarRevisor} className="hero-navlink2 w-nav-link">
              Buscar Revisor
            </Link>
          )}

          <Link to={contato} className="hero-navlink2 w-nav-link">
            Contato
          </Link>

          <Link to={sobre} className="hero-navlink2 w-nav-link">
            Sobre nós
          </Link>

          {/*
           <Link
           to={parcerias}
           id={authenticated ? 'penultimate' : 'last'}
           className="hero-navlink2 w-nav-link"
         >
           Seja Parceiro
         </Link>
         */}

          {authenticated ? (
            <Link
              to={logout}
              onClick={handleLogout}
              id={authenticated ? 'last' : 'hidden'}
              className="hero-navlink2 w-nav-link"
            >
              Sair
            </Link>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStatetoProps)(Sidebar);
