import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import {
  changePagePrice,
  changeNumberRevisions,
  changeSearchQuery,
  clearQueryFilter,
  applyQueryFilter
} from '../../../state-management/reviewers/actions';

const mapStateToProps = ({ reviewersStore }) => ({ ...reviewersStore });
const mapDispatchToProps = dispatch => ({
  changePagePrice: price => dispatch(changePagePrice(price)),
  changeNumberRevisions: number => dispatch(changeNumberRevisions(number)),
  changeSearchQuery: value => dispatch(changeSearchQuery(value)),
  clearQueryFilter: () => dispatch(clearQueryFilter()),
  applyFilter: params => dispatch(applyQueryFilter(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
