import React, { Component } from 'react';
import SocialButton from '../LoginSection/SocialButton';

import { loginFacebook, loginGoogle } from '../../../../../state-management';

import { connect } from 'react-redux';

class LoginSocialSection extends Component {
  handleSocialLogin = user => {
    const profile = user._profile || {};
    const token = user._token || {};
    this.props.dispatch(
      loginFacebook({
        email: profile.email,
        fb_id: profile.id,
        name: profile.name,
        token: token.accessToken
      })
    );
  };

  handleSocialLoginFailure = err => {
    console.error(err);
  };

  handleGoogleLogin = user => {
    const profile = user._profile || {};
    const token = user._token || {};

    this.props.dispatch(
      loginGoogle({
        name: profile.name,
        email: profile.email,
        google_id: profile.id,
        token: token.accessToken
      })
    );
  };

  handleGoogleLoginFailure = err => {
    console.error(err);
  };

  render() {
    return (
      <div className="account-linkbox w-row">
        <div className="w-col w-col-6">
          <SocialButton
            provider="facebook"
            appId="159812762135717"
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleSocialLoginFailure}
            className="button facebook-btn w-button"
          >
            Facebook
          </SocialButton>
        </div>

        <div className="w-col w-col-6">
          <SocialButton
            provider="google"
            appId="731987052214-b2c84jut90j10la0olbsesp7umjhnq5i.apps.googleusercontent.com"
            onLoginSuccess={this.handleGoogleLogin}
            onLoginFailure={this.handleGoogleLoginFailure}
            className="button google-btn w-button"
          >
            Google
          </SocialButton>
        </div>
      </div>
    );
  }
}

export default connect()(LoginSocialSection);
