import React from 'react';
import { withRouter } from 'react-router-dom';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Footer from '../../siteComponents/Footer/index';
import Card from 'react-bootstrap/Card';
import avatar1 from './images/avatar-1.svg';
import avatar2 from './images/avatar-2.svg';
import routes from '../../../routes';
import { ApiService } from '../../../state-management/service/api';

import './AdminPanelClients.css';

const avatares = {
  feminino: avatar1,
  masculino: avatar2
};

const { painelClients } = routes;

class AdminPanelClients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profiles: [],
      total: null
    };

    this.findClients();
  }

  findClients() {
    ApiService.getClients().then(res => {
      console.log(res);
      this.setState({ profiles: res.data.users.data, total: res.data.users.total });
    });
  }

  render() {
    return (
      <div>
        <GeneralMenu selected={painelClients} {...this.props} />
        {this.state.total && this.state.total !== 0 && (
          <p className="bold pre-title">
            Exibindo
            <span className="result-number">
              {` ${this.state.profiles.length} `}
            </span>
            de
            <span className="result-number">{` ${this.state.total} `}</span>
            redatores encontrados
          </p>
        )}
        <div className="admin-clients-container">
          {this.state.profiles.map((profile, i) => (
            <div className="card-container">
              <div className="top-card"></div>
              <div className="top-row">
                <div className="two-fifties">
                  <div alt="avatar" className="card-avatar">
                    <img
                      id={profile.id}
                      src={
                        profile.imagem
                          ? profile.imagem
                          : profile.sexo === 'M'
                          ? avatares.masculino
                          : avatares.feminino
                      }
                      onError={evt => {
                        evt.target.src =
                          profile.sexo === 'M'
                            ? avatares.masculino
                            : avatares.feminino;
                      }}
                      alt="avatar"
                      className="card-avatar"
                    />
                  </div>
                </div>
                <div className="three-fifties">
                  <div className="name">{profile.name}</div>
                </div>
              </div>
              <div className="tag">Email:</div>
              <div className="email">
                <a href={`mailto:${profile.email}`}>{profile.email}</a>
              </div>
              <div className="tag">Whatsapp:</div>
              {profile.whatsapp && (
                <div className="whatsapp">
                  <a
                    href={`https://api.whatsapp.com/send?phone=+55${profile.whatsapp}`}
                  >
                    {profile.whatsapp}
                  </a>
                </div>
              )}
              {!profile.whatsapp && (
                <div className="whatsapp">Não informado</div>
              )}
              <div className="tag">Data de cadastro:</div>
              <div className="date_created">{profile.created_at}</div>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AdminPanelClients);
