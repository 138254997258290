import axios from 'axios';
import moment from 'moment';
import { API_URL, TOKEN_KEY, USER_ROLES_KEY } from './api';

class PropostaService {
  static createProposal(attributes) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    attributes = { ...attributes };

    let params = {
      data: { attributes }
    };

    return axios
      .post(`${API_URL}proposals`, params, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static createCounterProposal(attributes, id) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    attributes = { ...attributes };

    let params = {
      data: { attributes }
    };

    return axios
      .post(`${API_URL}proposals/${id}/counterproposal`, params, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static getProposal(proposal) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .get(`${API_URL}proposals/${proposal}?include=writer,reviewer,invoice`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => PropostaService.transformOneProposal(response.data));
  }

  static listProposal(page = { size: 10000, number: 1 }) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    let params = {
      'page[size]': page.size,
      'page[number]': page.number,
      sort: JSON.stringify({ created_at: 'desc' })
    };
    return axios
      .get(
        `${API_URL}proposals?include=writer,reviewer,counter_proposal,invoice`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(response => {
        const { data } = response;
        if (data.data.length === 0) {
          const unformattedData = {
            data: data.data,
            pagination: data.meta.pagination
          };

          return unformattedData;
        }
        const formattedData = {
          data: PropostaService.transformProposal(data),
          pagination: data.meta.pagination
        };
        return formattedData;
      });
  }

  static listCounterProposal(page = { size: 10000, number: 1 }) {
    let token = window.localStorage.getItem(TOKEN_KEY);
    let params = {
      'page[size]': page.size,
      'page[number]': page.number,
      sort: JSON.stringify({ created_at: 'desc' })
    };

    return axios
      .get(
        `${API_URL}counterproposals?include=writer,reviewer,counter_proposal,invoice`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(response => {
        const { data } = response;
        if (data.data.length === 0) {
          const unformattedData = {
            data: data.data,
            pagination: data.meta.pagination
          };

          return unformattedData;
        }
        const formattedData = {
          data: PropostaService.transformProposal(data),
          pagination: data.meta.pagination
        };
        return formattedData;
      });
  }

  static approve(id) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .post(`${API_URL}proposals/${id}/approve`, null, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static disapprove(id) {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return axios
      .post(`${API_URL}proposals/${id}/disapprove`, null, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => response.data);
  }

  static invoices() {
    let token = window.localStorage.getItem(TOKEN_KEY);
    let userRole = JSON.parse(window.localStorage.getItem(USER_ROLES_KEY));
    let role;
    if (userRole.isRedator === false && userRole.isRevisor === true) {
      role = 'writer';
    } else if (userRole.isRedator === true && userRole.isRevisor === false) {
      role = 'reviewer';
    }
    return axios
      .get(`${API_URL}invoices?include=${role},proposal`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        const { data } = response;
        if (data.data.length === 0) {
          const unformattedData = [];

          return unformattedData;
        }

        return PropostaService.transformInvoice(response.data);
      });
  }

  static transformInvoice(response) {
    let includes = PropostaService.getIncludes(response);
    return response.data.map(item => {
      let { id, attributes, relationships } = item;

      if (relationships.reviewer) {
        let { type, id } = relationships.reviewer.data;
        attributes.reviewer = includes[type][id];
      }

      if (relationships.writer) {
        let { type, id } = relationships.writer.data;
        attributes.writer = includes[type][id];
      }

      if (relationships.proposal) {
        let { type, id } = relationships.proposal.data;
        attributes.proposal = includes[type][id];
      }

      attributes.paid_at = moment(
        attributes.paid_at,
        'YYYY-MM-DD HH:mm:ss'
      ).format('DD/MM/YYYY');

      attributes.created_at = moment(
        attributes.created_at,
        'YYYY-MM-DD HH:mm:ss'
      ).format('DD/MM/YYYY');
      return { id, ...attributes };
    });
  }

  static mapProposal(item, includes) {
    let { id, attributes, relationships } = item;

    if (relationships.reviewer) {
      let { type, id } = relationships.reviewer.data;
      attributes.reviewer = includes[type][id];
    }

    if (relationships.counter_proposal) {
      let { type, id } = relationships.counter_proposal.data;
      attributes.counter_proposal = includes[type][id];
      attributes.counter_proposal.reviewer =
        includes['users'][attributes.counter_proposal.reviewer_id];
      attributes.counter_proposal.created_at = moment(
        attributes.counter_proposal.created_at,
        'YYYY-MM-DD HH:mm:ss'
      ).format('DD/MM/YYYY');
      attributes.counter_proposal.writer =
        includes['users'][attributes.counter_proposal.writer_id];
    }

    if (relationships.writer) {
      let { type, id } = relationships.writer.data;
      attributes.writer = includes[type][id];
    }

    if (relationships.invoice) {
      let { type, id } = relationships.invoice.data;
      attributes.invoice = includes[type][id];
      attributes.invoice.paid_at = moment(
        attributes.invoice.paid_at,
        'YYYY-MM-DD HH:mm:ss'
      ).format('DD/MM/YYYY');
    }

    attributes.created_at = moment(
      attributes.created_at,
      'YYYY-MM-DD HH:mm:ss'
    ).format('DD/MM/YYYY');
    attributes.status_update_at = moment(
      attributes.status_update_at,
      'YYYY-MM-DD HH:mm:ss'
    ).format('DD/MM/YYYY');

    return { id, ...attributes };
  }

  static transformProposal(response) {
    let includes = PropostaService.getIncludes(response);
    return response.data.map(item =>
      PropostaService.mapProposal(item, includes)
    );
  }

  static transformOneProposal(response) {
    let includes = PropostaService.getIncludes(response);
    return PropostaService.mapProposal(response.data, includes);
  }

  static getIncludes(response) {
    let includes = {};

    for (let e of response.included) {
      if (!includes[e.type]) {
        includes[e.type] = {};
      }

      includes[e.type][e.id] = { id: e.id, ...e.attributes };
    }

    return includes;
  }
}

export { PropostaService };
