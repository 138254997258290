import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class ExperienciasComponent extends Component {
  state = {
    formInvalid: true,
    helpBlock: '',
    formData: {
      title: '',
      description: ''
    },
    experiences: [
      /*
        {
          title: "Titulo",
          description: "some text"
        }
       */
    ]
  };

  constructor(props) {
    super(props);

    if (props.value) {
      this.state.experiences = props.value;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ experiences: nextProps.value });
    }
  }

  addExperiencia = () => {
    let state = { ...this.state };
    state.experiences.push(this.state.formData);
    state.formData = { title: '', description: '' };
    state.formInvalid = true;
    state.helpBlock = '';
    this.setState(state);

    this.props.onChange({ name: 'experiences', value: state.experiences });
  };

  removeExperiencia = index => {
    let state = { ...this.state };
    state.experiences.splice(index, 1);
    this.setState(state);

    this.props.onChange({ name: 'experiences', value: state.experiences });
  };

  handleInputChange = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.formData[name] = value;
    this.setState(state);
    this.validate();
  };

  handleInputBlur = e => {
    let state = { ...this.state };
    if (
      this.state.formData.title !== '' &&
      this.state.formData.description !== ''
    ) {
      state.helpBlock =
        'Você informou uma experiência mas falta adicioná-la. Clique no botão Adicionar item.';
    } else {
      state.helpBlock = '';
    }
    this.setState(state);
  };

  validate() {
    this.setState({
      formInvalid: !(
        this.state.formData.title && this.state.formData.description
      )
    });
  }

  maxTextRender = (n, str) => {
    if (str.length > n) return str.substring(0, n) + '...';
    else return str;
  };

  render() {
    return (
      <div className="flex-column add-item-inverse-container">
        <h5 className="config-subtitle">Experiências</h5>

        <span style={{ color: '#95989a', fontSize: '0.9em' }}>
          Liste suas atividades principais como revisor, professor, redator e
          outras áreas afins ou inclua um minicurrículo.
        </span>

        <div className="anexo-container">
          {this.state.experiences.map((exp, index) => (
            <div key={index} className="item-exp">
              <div style={{ flex: 4, display: 'flex' }}>
                <div>
                  <h5>{this.maxTextRender(45, exp.title)}</h5>
                  <p className="item-paragraph">
                    {this.maxTextRender(50, exp.description)}
                  </p>
                </div>
              </div>
              <div className="icon-exp">
                <span
                  className="icon-anexo"
                  onClick={this.removeExperiencia.bind(this, index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </span>
              </div>
            </div>
          ))}
        </div>

        <input
          type="text"
          placeholder="Título / empresa / experiência"
          className="accont-input w-input"
          name="title"
          value={this.state.formData.title}
          onChange={this.handleInputChange}
        />

        <div className="form-group">
          <input
            type="textarea"
            placeholder="Texto complementar"
            className="accont-input w-input"
            name="description"
            value={this.state.formData.description}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
            style={{ marginBottom: '0px' }}
          />
          <span className="error">{this.state.helpBlock}</span>
        </div>

        <button
          className="btn button w-button inverse-btn small-btn add-btn"
          disabled={this.state.formInvalid}
          onClick={this.addExperiencia}
          style={{ width: '250px' }}
        >
          Incluir experiência
        </button>
      </div>
    );
  }
}

export { ExperienciasComponent };
