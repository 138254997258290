import React, { Component } from 'react';
import { Blurb } from '../../../../siteComponents/blurb/index';

class TenReasonsToBeReviewr extends Component {
  sectionRef = null;

  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollTo && nextProps.scrollTo !== this.props.scrollTo) {
      this.sectionRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  render() {
    return (
      <div className="img-bg section-dark">
        <div className="header" ref={this.sectionRef}>
          <div className="revisor-header">Revisor</div>
          <h1 className="section-header">
            10 razões para usar o Contexto Revisor
          </h1>
        </div>
        <div className="process-content">
          <Blurb
            content="1"
            color="true"
            title="Revisor-empreendedor"
            description="Você terá um verdadeiro escritório virtual de revisão de textos, com ferramentas de trabalho e
            comunicação, sistema de pagamento eletrônico e muito mais."
          />
          <Blurb
            content="2"
            color="true"
            title="Minissite só seu"
            description="Você o customiza com sua foto, currículo, textos, vídeos, preços e prazos, tem um endereço www
            exclusivo e já pode trabalhar de imediato."
          />
          <Blurb
            content="3"
            color="true"
            title="O cliente busca você..."
            description="ao entrar no Contexto Revisor e procurar por um revisor de textos. Nós faremos o marketing, você
            terá visibilidade em nosso market place, e o cliente o escolherá para executar o trabalho."
          />
          <Blurb
            content="4"
            color="true"
            title="… e você busca o cliente"
            description="pois pode divulgar o endereço de internet específico do seu minissite em redes sociais e onde
            mais quiser."
          />
          <Blurb
            content="5"
            color="true"
            title="Trato feito"
            description="Você e o cliente combinam e confirmam prazo de entrega e pagamento, e a revisão pode se iniciar."
          />
          <Blurb
            content="6"
            color="true"
            title="Pagamento seguro"
            description="Utilizamos o sistema Iugu de transações bancárias on-line, um dos mais confiáveis do País."
          />
          <Blurb
            content="7"
            color="true"
            title="Adeus, calote"
            description="O sistema de pagamento on-line mantém sob depósito os valores combinados, até a conclusão do
            trabalho. Entregou? Recebe o pagamento de imediato."
          />
          <Blurb
            color="true"
            content="8"
            title="Dois planos"
            description="Você pode optar pelos planos Flex ou Premium, cada qual com participação e vantagens específicas, mas ambos ajustados a você, revisor-empreendedor."
          />
          <Blurb
            color="true"
            content="9"
            title="Suporte tecnológico"
            description="Fornecemos os sistemas de revisão e de pagamento, a hospedagem, o suporte técnico e o know-how para empreender. Você entra com seu talento."
          />
          <Blurb
            color="true"
            content="10"
            title="Não à escravidão"
            description="Você estabelece o preço e o prazo em livre negociação com o cliente. Trabalha quando e onde quiser, e pode dizer não ao frila sub-remunerado e ao subemprego."
          />
        </div>
      </div>
    );
  }
}

export default TenReasonsToBeReviewr;
