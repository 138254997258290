import React, { Component } from 'react';
import Input from 'react-validation/build/input';
import PropTypes from 'prop-types';
import * as validators from './validators';

class FormInput extends Component {
  render() {
    let validations = [];

    if (this.props.validators) {
      for (let validator of this.props.validators) {
        validations.push(validators[validator]);
      }
    }

    return <Input {...this.props} validations={validations} />;
  }
}

FormInput.propTypes = {
  validators: PropTypes.array
};

export { FormInput };
