import React, { Component } from 'react';
import {
  FormValidator,
  FormMask,
  FormInput,
  FormButton
} from '../../siteComponents/form';
import Modal from '../../siteComponents/modal';
import { ApiService } from '../../../state-management/service/api.js';
import cep from 'cep-promise';
import { showNotification, userUpdate } from '../../../state-management';

const masks = {
  '001': { agency: '9999-*', account: '99999999-*' },
  '033': { agency: '9999', account: '99999999-*' },
  '104': { agency: '9999', account: '99999999-*' },
  '237': { agency: '9999-*', account: '9999999-*' },
  '341': { agency: '9999', account: '99999-*' },
  '041': { agency: '9999', account: '999999999-*' },
  '748': { agency: '9999', account: '999999' },
  '756': { agency: '9999', account: '999999999-*' },
  '077': { agency: '9999', account: '999999999-*' },
  '070': { agency: '9999', account: '999999999-*' },
  '260': { agency: '9999', account: '9999999999-*' }
};

class FinanceiroComponent extends Component {
  state = {
    modalSuccess: false,
    modal2Success: false,
    published: false,
    userFilled: false,
    user: {
      document: '',
      email: '',
      bank: '001',
      bank_agency: '',
      bank_account: '',
      bank_account_type: '',
      postal_code: '',
      address: '',
      complement: '',
      number: '',
      district: '',
      city: '',
      uf_id: ''
    }
  };

  componentDidMount() {
    console.log(this.props);
    this.setup(this.props);
  }

  componentWillReceiveProps(props) {
    this.setup(props);

    if (
      props.updateError &&
      props.updateErrorMessage &&
      props.updateErrorMessage.message
    ) {
      this.props.dispatch(
        showNotification(
          'error',
          this.formatarErrorMensagem(props.updateErrorMessage.message),
          true
        )
      );
    }
  }

  formatarErrorMensagem(message) {
    return message.substr(0, 1).toUpperCase() + message.substr(1) + '.';
  }

  setup(props) {
    if (!this.state.userFilled && typeof props.user !== 'undefined') {
      let user = { ...props.user };
      console.log(user);
      user.bank = user.bank || '001';
      let published = user.published_at !== null;
      this.setState({ user, userFilled: true, published });
    }
  }

  handleChangeInput = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.user[name] = value;
    this.setState(state);
  };

  handleValidateAccount = () => {
    if (this.state.user.bank_account) {
      let size = this.state.user.bank_account
        .replace(/_/g, '')
        .replace(/-/g, '').length;

      if (masks[this.state.user.bank].account.length !== size) {
        let total = masks[this.state.user.bank].account.length - size - 1;
        let zeros = new Array(total).fill('0').join('');
        let newState = { ...this.state };
        let bank_account = this.state.user.bank_account;
        bank_account = bank_account.replace(/[^0-9a-z]/gi, '');
        newState.user.bank_account =
          zeros + bank_account.slice(0, -1) + '-' + bank_account.slice(-1);
        this.setState(newState);
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.user.bank_account_type === null) {
      this.props.dispatch(
        showNotification('error', 'Por favor, preencha todos os dados.', true)
      );
    } else {
      let { user } = this.state;
      const { dispatch } = this.props;
      let s;
      delete user.page_price_cents;
      ApiService.putUser(this.state.user)
        .then(res => {
          if (!this.state.published) {
            this.setState({ modalSuccess: true });
          }
          this.props.onSave(this.state.user);
        })
        .catch(res => {
          dispatch(
            showNotification(
              'error',
              'Não foi possível enviar os dados, tente novamente.',
              true
            )
          );
          return;
        });
    }
  };

  handleDismiss = () => {
    this.setState({ modalSuccess: false });
  };

  handleShowModal2 = () => {
    this.setState({ modal2Success: true });
  };

  handleCep(e) {
    let cep_str = e.target.value.replace('-', '');
    cep(cep_str)
      .then(data => {
        let newState = { ...this.state };
        let uf_id = 0;

        newState.user.address = data.street;
        newState.user.district = data.neighborhood;
        newState.user.city = data.city;
        newState.ufCep = data.state;
        newState.user.uf_id = this.state.user.uf_id;

        this.props.states.forEach(item => {
          if (item.uf === data.state) {
            uf_id = item.id;
          }
        });
        this.refs.selectUf.value = uf_id;
        newState.user.uf_id = uf_id;
        this.setState({ ...newState });
      })
      .catch(err => {
        this.props.dispatch(
          showNotification('error', `ATENÇÃO: CEP inválido.`, true)
        );
      });
  }

  render() {
    let { roles } = this.props;

    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">Dados financeiros</h4>
        {((roles && roles.isRevisor) || (roles && roles.isParceiro)) && (
          <p>
            Você agora inserirá seus dados para tornar-se um
            Revisor-Empreendedor e utilizar o sistema de recebimento on-line da
            IUGU, nossa parceira e empresa de referência em gestão de pagamentos
            on-line. Por questões de segurança, ela solicita o prazo de até 48
            horas para análise do cadastro, após o qual, aprovado, você já
            poderá negociar com seus clientes e, por meio da plataforma e direto
            em sua conta bancária, receber deles o pagamento por seu trabalho.
          </p>
        )}
        {((roles && roles.isRepresentante) ||
          (roles && roles.isInstituicao) ||
          (roles && roles.isDivulgador)) && (
          <p>
            Você agora inserirá os dados da sua conta para utilizar o sistema de
            recebimento on-line da IUGU, nossa parceira e empresa de referência
            em gestão de pagamentos on-line. Por questões de segurança, ela
            solicita o prazo de até 48 horas para análise do cadastro, após o
            qual, aprovado, por meio da plataforma e direto em sua conta
            bancária, receber deles o pagamento por seu trabalho. Se você for
            Pessoa Jurídica insira seu CNPJ e se você for Pessoa Física insira
            seu CPF.
          </p>
        )}
        <p>
          * Ao usar o recurso de preenchimento automático do seu navegador,
          confira se as informações estão corretas.
        </p>

        <FormValidator onSubmit={this.handleSubmit}>
          {((roles && roles.isRevisor) ||
            (roles && roles.isParceiro) ||
            this.state.user.person_type === 'Pessoa Física') && (
            <FormMask
              mask="999.999.999-99"
              maskChar=""
              placeholder="CPF *"
              className="accont-input w-input form-margin"
              name="document"
              validators={['required']}
              value={this.state.user.document}
              onChange={this.handleChangeInput}
            />
          )}
          {((roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica') && (
            <FormMask
              mask="99.999.999/9999-99"
              maskChar=""
              placeholder="CNPJ *"
              className="accont-input w-input form-margin"
              name="document"
              validators={['required']}
              value={this.state.user.document}
              onChange={this.handleChangeInput}
            />
          )}

          <select
            className="accont-input w-select form-margin"
            name="bank"
            value={this.state.user.bank}
            onChange={this.handleChangeInput}
            ref="selectUf"
          >
            <option value="001">Banco do Brasil</option>
            <option value="033">Banco Santander</option>
            <option value="104">Caixa Econômica Federal</option>
            <option value="237">Bradesco</option>
            <option value="341">Itaú Unibanco</option>
            <option value="260">Nubank</option>
            <option value="041">Banrisul</option>
            <option value="748">Sicredi</option>
            <option value="756">Sicoob (Bancoob)</option>
            <option value="077">Inter</option>
            <option value="070">BRB</option>
          </select>

          <div className="flex-row form-margin">
            <FormMask
              mask={masks[this.state.user.bank].agency}
              placeholder="Agência *"
              className="accont-input w-input"
              name="bank_agency"
              validators={['required']}
              value={this.state.user.bank_agency}
              onChange={this.handleChangeInput}
            />

            <div className="space" />

            <FormMask
              mask={masks[this.state.user.bank].account}
              validators={['required']}
              placeholder="Conta (com dígito) *"
              className="accont-input w-input"
              name="bank_account"
              value={this.state.user.bank_account}
              onChange={this.handleChangeInput}
              onBlur={this.handleValidateAccount}
            />
          </div>

          <div className="gender form-margin">
            <div className="gender-box">
              <label>Tipo de conta *</label>
              <div className="gender-radio w-radio">
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="bank_account_type"
                    id="exampleRadios1"
                    value="Corrente"
                    checked={this.state.user.bank_account_type === 'Corrente'}
                    onChange={this.handleChangeInput}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Conta corrente
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="bank_account_type"
                    id="exampleRadios2"
                    value="Poupança"
                    checked={this.state.user.bank_account_type === 'Poupança'}
                    onChange={this.handleChangeInput}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Conta poupança
                  </label>
                </div>
              </div>
            </div>
          </div>

          <FormMask
            mask="99999-999"
            placeholder="CEP *"
            className="accont-input w-input form-margin"
            name="postal_code"
            validators={['required']}
            value={this.state.user.postal_code}
            onChange={this.handleChangeInput}
            onBlur={this.handleCep.bind(this)}
          />

          <FormInput
            type="text"
            placeholder="Endereço *"
            className="accont-input w-input form-margin"
            name="address"
            validators={['required']}
            value={this.state.user.address}
            onChange={this.handleChangeInput}
          />

          <div className="flex-row form-margin">
            <input
              type="text"
              placeholder="Complemento"
              className="accont-input w-input"
              name="complement"
              value={this.state.user.complement || ''}
              onChange={this.handleChangeInput}
            />

            <div className="space" />

            <FormInput
              type="number"
              validators={['required']}
              placeholder="Número *"
              className="accont-input w-input"
              name="number"
              value={this.state.user.number}
              onChange={this.handleChangeInput}
            />
          </div>

          <input
            type="text"
            placeholder="Bairro *"
            className="accont-input w-input form-margin"
            name="district"
            value={this.state.user.district || ''}
            onChange={this.handleChangeInput}
          />

          <div className="flex-row form-margin">
            <select
              className="accont-input w-select"
              name="uf_id"
              value={this.state.user.uf_id}
              onChange={this.handleChangeInput}
            >
              <option value="">Estado *</option>

              {this.props.states.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.uf} - {item.descricao}
                  </option>
                );
              })}
            </select>

            <div className="space" />

            <input
              type="text"
              placeholder="Cidade *"
              className="accont-input w-input"
              name="city"
              value={this.state.user.city}
              onChange={this.handleChangeInput}
            />
          </div>

          <button
            type="button"
            className="button turquoise w-button submit-profile"
            onClick={this.handleSubmit}
          >
            Salvar
          </button>
        </FormValidator>

        <Modal
          title="TUDO CERTO!"
          message={
            'Seus dados foram enviados com sucesso! Pedimos que aguarde até 48h para análise pela instituição financeira. Após essa confirmação, você será liberado para publicar seu perfil, aparecer no marketplace e poder divulgar seu minissite.'
          }
          okText={'Ok, entendi'}
          onDismiss={this.handleDismiss}
          onlyOkButton={true}
          visible={this.state.modalSuccess}
        />
      </div>
    );
  }
}

export { FinanceiroComponent };
