import React, { Component } from 'react';
import { connect, useStore } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Loading from '../../siteComponents/loading';
import ProfileHeaderSection from './section/ProfileHeaderSection';
import OrderSection from './section/OrderSection';
import { fetchReviewer } from '../../../state-management';
import { ApiService } from '../../../state-management/service/api';
import Modal from '../../siteComponents/modal';

import { WithContext as ReactTags } from 'react-tag-input';
import user from '../../../state-management/user/reducers';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ProfileReviewerComponent extends Component {
  state = {
    seeMore: false,
    modalIntention: null,
    modalVisible: false,
    modal2Visible: false,
    isEditing: false,
    form: {
      about: '',
      interests: '',
      occupation: '',
      experiences: '',
      attachments: '',
      skills: ''
    }
  };

  editedFields = null;

  componentDidMount() {
    let { dispatch, match } = this.props;
    dispatch(fetchReviewer(match.params.apelido));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reviewerCreateProposalError) {
      let { dispatch, match } = this.props;

      let timer = setTimeout(() => {
        dispatch(fetchReviewer(match.params.apelido));
        clearTimeout(timer);
      }, 3000);
    }
  }

  scrollTop = () => {
    document.querySelector('.nav-container').scrollIntoView({
      behavior: 'smooth'
    });
  };

  editFields = () => {
    if (!this.state.isEditing) {
      document.querySelector('.load-more').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      if (this.getChangedFields() != '') {
        this.editedFields = this.updateEditedFields();
        let newState = { ...this.state };
        newState.modalIntention = 'edit';
        newState.modal2Visible = true;
        this.setState(newState);
      }
    }

    this.setState({ isEditing: !this.state.isEditing });
  };

  updateEditedFields = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'flex-start'
        }}
      >
        {this.state.form.about && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Sobre</h4>
            <p>{this.state.form.about}</p>
          </div>
        )}
        {this.state.form.attachments && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Anexos</h4>
            <p>{this.state.form.attachments}</p>
          </div>
        )}
        {this.state.form.experiences && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Experiências</h4>
            <p>{this.state.form.experiences}</p>
          </div>
        )}
        {this.state.form.interests && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Interesses</h4>
            <p>{this.state.form.interests}</p>
          </div>
        )}
        {this.state.form.occupation && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Ocupação</h4>
            <p>{this.state.form.occupation}</p>
          </div>
        )}
        {this.state.form.skills && (
          <div style={{ textAlign: 'start' }}>
            <h4 style={{ color: '#55ddd2' }}>Skills/Habilidades</h4>
            <p>{this.state.form.skills}</p>
          </div>
        )}
      </div>
    );
  };

  getChangedFields = () => {
    let str = '';
    for (let fieldName of Object.keys(this.state.form))
      if (this.state.form[fieldName])
        str += `${fieldName}: ${this.state.form[fieldName]}\n`;
    return str;
  };

  handleToggleSeeMore = () => {
    this.setState({ seeMore: !this.state.seeMore });
  };

  getAbout() {
    let { reviewer } = this.props;
    let more = (reviewer.attributes && reviewer.attributes.sobre) || '';
    let less = more.substring(0, 100);
    let enableMore = more.length > 100;

    return { less, more, enableMore };
  }

  getTraining(id) {
    const training = this.props.trainings.filter(
      training => training.id === id
    )[0];
    return training ? training.formacao : '';
  }

  getTags(tagSkill) {
    if (tagSkill && typeof tagSkill === 'string') {
      return tagSkill.split(',').map(tag => {
        return { id: tag, text: tag };
      });
    }
    return [];
  }

  handleApproveClick = () => {
    let newState = { ...this.state };
    newState.modalIntention = 'approve';
    newState.modalVisible = true;
    this.setState(newState);
  };

  handleDeleteClick = () => {
    let newState = { ...this.state };
    newState.modalIntention = 'delete';
    newState.modalVisible = true;
    this.setState(newState);
  };

  modalShow = () => {
    this.setState({ modalVisible: true });
  };

  modalDismiss = () => {
    this.setState({ modalVisible: false });
  };

  modal2Dismiss = () => {
    this.setState({ modal2Visible: false });
  };

  modalConfirmApprove = () => {
    //console.log('aprovou esse porra')
    this.modalDismiss();
  };

  modalConfirmDelete = () => {
    //console.log('deletou da face da terra')
    this.modalDismiss();
  };

  handleEdit = evt => {
    let newState = { ...this.state };
    newState.form[evt.target.name] = evt.target.value;
    this.setState(newState);
  };

  render() {
    let { reviewer, statesMap, roles } = this.props;

    let experiences =
      reviewer.attributes && reviewer.attributes.experiences
        ? reviewer.attributes.experiences
        : [];
    let attachments =
      reviewer.attributes && reviewer.attributes.attachments
        ? reviewer.attributes.attachments
        : [];

    let curriculum =
      reviewer.attributes && reviewer.attributes.curriculum
        ? reviewer.attributes.curriculum
        : null;

    let about = this.getAbout();

    let viewer = ApiService.getUser()?.user;
    let isAdmin =
      viewer?.role &&
      viewer.role.map(role => role.name)?.indexOf('admin') != -1;

    return (
      reviewer.attributes !== undefined && (
        <div>
          <GeneralMenu {...this.props} />
          <div className="light-padding section-dark">
            <ProfileHeaderSection
              reviewer={reviewer}
              states={statesMap}
              roles={reviewer.role}
            />

            <div className="info-boxes">
              <div className="two-thirds">
                <Modal
                  visible={this.state.modalVisible}
                  title={
                    (this.state.modalIntention == 'approve'
                      ? 'Aprovar'
                      : 'Deletar') + ' revisor'
                  }
                  message={
                    'Deseja ' +
                    (this.state.modalIntention == 'approve'
                      ? 'aprovar'
                      : 'deletar') +
                    ' o perfil de ' +
                    reviewer?.name +
                    '?'
                  }
                  okText={
                    this.state.modalIntention == 'approve'
                      ? 'Aprovar'
                      : undefined
                  }
                  danger={this.state.modalIntention != 'approve'}
                  dangerText={
                    this.state.modalIntention == 'approve'
                      ? undefined
                      : 'Deletar'
                  }
                  onDismiss={this.modalDismiss}
                  onOkClick={this.modalConfirmApprove}
                  onDangerClick={this.modalConfirmDelete}
                />

                <Modal
                  visible={this.state.modal2Visible}
                  title="Sugerir alteração dos seguintes campos?"
                  message={this.editedFields}
                  okText="Enviar"
                  onOkClick={this.modal2Dismiss}
                  onDismiss={this.modal2Dismiss}
                />

                {isAdmin && (
                  <div className="perfil-box">
                    <h2 className="perfil-titles">Ações de Administrador</h2>

                    <ul className="filter-dropdown-app w-list-unstyled">
                      <li className="filter-item" style={{ width: 'auto' }}>
                        <button
                          className="button btn-success"
                          onClick={this.handleApproveClick}
                        >
                          <span
                            className="glyphicon glyphicon-ok"
                            aria-hidden="true"
                          ></span>{' '}
                          Aprovar perfil
                        </button>

                        <button
                          className="button btn-danger"
                          onClick={this.handleDeleteClick}
                        >
                          <span
                            className="glyphicon glyphicon-remove"
                            aria-hidden="true"
                          ></span>{' '}
                          Deletar perfil
                        </button>
                      </li>

                      <li className="filter-sep"></li>

                      <li className="filter-item" style={{ width: 'auto' }}>
                        <button
                          className={`button ${
                            this.state.isEditing ? 'btn-primary' : ''
                          }`}
                          onClick={this.editFields}
                          //disabled="disabled"
                        >
                          <span
                            className="glyphicon glyphicon-pencil"
                            aria-hidden="true"
                          ></span>{' '}
                          {this.state.isEditing
                            ? this.getChangedFields()
                              ? 'Enviar sugestões'
                              : 'Fechar sugestões'
                            : 'Sugerir alteração'}
                        </button>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="perfil-box load-more">
                  <p className="perfil-titles">Sobre</p>
                  <p className="turquoise-text">{reviewer.sobre}</p>
                  {this.state.isEditing && (
                    <textarea
                      name="about"
                      value={this.state.form.about}
                      onChange={this.handleEdit}
                      style={{
                        resize: 'none',
                        width: '100%',
                        borderStyle: 'none',
                        boxShadow: 'inset 0 0 8px #D9D9D9',
                        padding: '8px 8px'
                      }}
                      placeholder="Sugestão: Fale mais sobre você!"
                    ></textarea>
                  )}

                  <div style={{ marginTop: 20 }}>
                    {reviewer.interesse && (
                      <div style={{ marginTop: 20 }}>
                        <p className="perfil-titles">Interesse</p>
                        <p className="turquoise-text">{reviewer.interesse}</p>
                        {this.state.isEditing && (
                          <textarea
                            name="interests"
                            value={this.state.form.interests}
                            onChange={this.handleEdit}
                            style={{
                              resize: 'none',
                              width: '100%',
                              borderStyle: 'none',
                              boxShadow: 'inset 0 0 8px #D9D9D9',
                              padding: '8px 8px'
                            }}
                            placeholder="Sugestão: Detalhe mais seus interesses!"
                          ></textarea>
                        )}
                      </div>
                    )}

                    {reviewer.ocupacao && (
                      <div style={{ marginTop: 20 }}>
                        <p className="perfil-titles">Ocupação</p>
                        <p className="turquoise-text">{reviewer.ocupacao}</p>
                        {this.state.isEditing && (
                          <textarea
                            name="occupation"
                            value={this.state.form.occupation}
                            onChange={this.handleEdit}
                            style={{
                              resize: 'none',
                              width: '100%',
                              borderStyle: 'none',
                              boxShadow: 'inset 0 0 8px #D9D9D9',
                              padding: '8px 8px'
                            }}
                            placeholder="Sugestão: Placeholder"
                          ></textarea>
                        )}
                      </div>
                    )}

                    {/* {reviewer.tipo_formacao_id && (
                      <div style={{ marginTop: 20 }}>
                        <p className="perfil-titles">Titulação</p>
                        <p className="turquoise-text">
                          {this.getTraining(reviewer.tipo_formacao_id)}
                        </p>
                      </div>
                    )} */}
                  </div>

                  {reviewer.video_url && (
                    <iframe
                      type="text/html"
                      width="320"
                      height="180"
                      src={reviewer.video_url}
                      frameBorder="0"
                      title="Video"
                    />
                  )}

                  {/* {about.enableMore && !this.state.seeMore && (
                    <div style={{ marginTop: 20 }}>
                      <p className="show-more">{about.less}</p>
                    </div>
                  )} */}

                  {/* {!about.enableMore && (
                    <div style={{ marginTop: 20 }}>
                      <p className="show-more">{about.more}</p>
                    </div>
                  )} */}

                  {/*   {this.state.seeMore && (
                    <div style={{ marginTop: 20 }}>
                      <p>{about.more}</p>
                    </div>
                  )}
 */}
                  {about.enableMore && (
                    <div className="loadmore">
                      <button
                        className="button small-btn w-button"
                        onClick={this.handleToggleSeeMore}
                      >
                        {this.state.seeMore ? 'VER MENOS' : 'VER MAIS'}
                      </button>
                    </div>
                  )}
                </div>

                <div className="perfil-box">
                  <p className="perfil-titles">Experiências</p>

                  {experiences.length === 0 && (
                    <p className="turquoise-text">Nenhuma experiência</p>
                  )}

                  {experiences.length > 0 && (
                    <ul className="perfil-list w-list-unstyled">
                      {experiences.map((exp, index) => (
                        <li className="perfil-item" key={index}>
                          <div className="list-style" />
                          <div>
                            <p className="perfil-especification">{exp.title}</p>
                            <p>{exp.description}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.isEditing && (
                    <textarea
                      name="experiences"
                      value={this.state.form.experiences}
                      onChange={this.handleEdit}
                      style={{
                        resize: 'none',
                        width: '100%',
                        borderStyle: 'none',
                        boxShadow: 'inset 0 0 8px #D9D9D9',
                        padding: '8px 8px'
                      }}
                      placeholder="Sugestão: Placeholder"
                    ></textarea>
                  )}
                </div>

                <div className="perfil-box">
                  <p className="perfil-titles bold">Currículo</p>

                  {!curriculum && (
                    <p className="turquoise-text">Nenhum anexo</p>
                  )}

                  {curriculum && (
                    <ul className="perfil-list w-list-unstyled">
                      <li className="perfil-item">
                        <div className="list-style" />
                        <div>
                          <a
                            className="listonly perfil-especification"
                            href={curriculum}
                            target="blank"
                          >
                            Currículo de {reviewer.name}
                          </a>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>

                <div className="perfil-box">
                  <p className="perfil-titles bold">Anexos</p>

                  {attachments.length === 0 && (
                    <p className="turquoise-text">Nenhum anexo</p>
                  )}

                  {attachments.length > 0 && (
                    <ul className="perfil-list w-list-unstyled">
                      {attachments.map((attch, index) => (
                        <li className="perfil-item" key={index}>
                          <div className="list-style" />
                          <div>
                            <a
                              className="listonly perfil-especification"
                              href={attch.file}
                              target="blank"
                            >
                              {attch.title}
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                  {this.state.isEditing && (
                    <textarea
                      name="attachments"
                      value={this.state.form.attachments}
                      onChange={this.handleEdit}
                      style={{
                        resize: 'none',
                        width: '100%',
                        borderStyle: 'none',
                        boxShadow: 'inset 0 0 8px #D9D9D9',
                        padding: '8px 8px'
                      }}
                      placeholder="Sugestão: Placeholder"
                    ></textarea>
                  )}
                </div>

                {/* skills */}
                <div className="perfil-box">
                  <p className="perfil-titles">Skills/Habilidades</p>
                  {reviewer.redacao_estudantil === 0 &&
                    reviewer.redacao_concursos === 0 &&
                    reviewer.tcc === 0 &&
                    reviewer.monografia === 0 &&
                    reviewer.dissertacao === 0 &&
                    reviewer.tese === 0 &&
                    reviewer.trabalhos_tecnicos === 0 &&
                    reviewer.livros === 0 &&
                    reviewer.periodicos === 0 &&
                    reviewer.blogs === 0 &&
                    reviewer.sites_aplicativos === 0 &&
                    reviewer.textos_em_imagens === 0 &&
                    reviewer.transcricoes_audio_video === 0 &&
                    reviewer.recursos_concurso === 0 &&
                    reviewer.traducoes === 0 && (
                      <p className="turquoise-text">Nenhuma Skill/Habilidade</p>
                    )}
                  <ul className="turquoise-text">
                    <div className="w-row">
                      <div className="w-col w-col-4">
                        {reviewer.redacao_estudantil === 1 && (
                          <li>Redação estudantil</li>
                        )}
                        {reviewer.redacao_concursos === 1 && (
                          <li>Redação para concursos</li>
                        )}
                        {reviewer.tcc === 1 && <li>TCC</li>}
                        {reviewer.monografia === 1 && <li>Monografia</li>}
                        {reviewer.dissertacao === 1 && <li>Dissertação</li>}
                      </div>
                      <div className="w-col w-col-4">
                        {reviewer.tese === 1 && <li>Tese</li>}
                        {reviewer.trabalhos_tecnicos === 1 && (
                          <li>Trabalhos técnicos</li>
                        )}
                        {reviewer.livros === 1 && <li>Livros</li>}
                        {reviewer.periodicos === 1 && <li>Periódicos</li>}
                        {reviewer.blogs === 1 && <li>Blogs</li>}
                      </div>
                      <div className="w-col w-col-4">
                        {reviewer.sites_aplicativos === 1 && (
                          <li>Sites e aplicativos</li>
                        )}
                        {reviewer.textos_em_imagens === 1 && (
                          <li>Textos em imagens</li>
                        )}
                        {reviewer.transcricoes_audio_video === 1 && (
                          <li>Transcrições de áudio/vídeo</li>
                        )}
                        {reviewer.recursos_concurso === 1 && (
                          <li>Recursos para concurso</li>
                        )}
                        {reviewer.traducoes === 1 && <li>Traduções</li>}
                      </div>
                    </div>
                  </ul>

                  {reviewer.tag_skill && (
                    <label className="w-form-label">
                      <p>Outros</p>
                    </label>
                  )}
                  <div>
                    <ReactTags
                      tags={this.getTags(reviewer.tag_skill)}
                      delimiters={delimiters}
                      readOnly={true}
                    />
                  </div>
                  {this.state.isEditing && (
                    <textarea
                      name="skills"
                      value={this.state.form.skills}
                      onChange={this.handleEdit}
                      style={{
                        resize: 'none',
                        width: '100%',
                        borderStyle: 'none',
                        boxShadow: 'inset 0 0 8px #D9D9D9',
                        padding: '8px 8px'
                      }}
                      placeholder="Sugestão: Placeholder"
                    ></textarea>
                  )}
                </div>
                {/* skills */}

                {
                  // Essa parte, apesar de ter conteúdo no front, não está integrada e não possui
                  // função empregada.
                  /* <div className="perfil-box load-more">
                  <p className="perfil-titles">Avaliações</p>

                  <div className="loadmore">
                    <button className="button small-btn w-button">
                      VER MAIS
                    </button>
                  </div>
                </div> */
                }

                <button
                  onClick={this.scrollTop}
                  className="button w-button white-btn"
                >
                  Voltar ao topo
                </button>
              </div>
              <div className="one-third">
                <div>
                  <OrderSection {...this.props} />

                  <div className="publicidade">Publicidade</div>
                </div>
              </div>
            </div>
          </div>
          <Footer />

          <Loading visible={this.props.loading} />
        </div>
      )
    );
  }
}

const mapStateToProps = ({ reviewersStore, userStore }, props) => {
  let {
    reviewer,
    loading,
    reviewerCreateProposalSuccess,
    reviewerCreateProposalError,
    statesMap,
    trainings
  } = reviewersStore;
  return {
    reviewer,
    loading,
    userStore,
    ...userStore,
    reviewerCreateProposalSuccess,
    reviewerCreateProposalError,
    statesMap,
    trainings
  };
};
const ProfileReviewer = connect(mapStateToProps)(ProfileReviewerComponent);
export { ProfileReviewer };
