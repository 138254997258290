import { connect } from 'react-redux';
import GeneralMenu from './GeneralMenu';
import { logoff } from '../../../state-management/user/actions';
const mapStateToProps = ({ userStore }) => ({
  authenticated: userStore.authenticated
});
const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff())
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneralMenu);
