import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN,
  UPDATE_PASSWORD,
  LOGIN_MOTOR,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  LOGOFF,
  USER_RECOVER_PASSWORD,
  USER_RESET_PASSWORD,
  USER_REVIEWER_PUBLISH_PROFILE,
  USER_CREATE_REVIEWER,
  USER_CREATE_WRITER,
  USER_CREATE_PARTNER_INSTITUITION,
  CHECK_TOKEN,
  USER_UPDATE,
  USER_CONTACT,
  USER_UPDATE_AVATAR,
  FETCH_PROPOSAL,
  FETCH_COUNTERPROPOSAL,
  FETCH_ONE_PROPOSAL,
  FETCH_INVOICES,
  VERIFY_APELIDO,
  USER_CREATE_REPRESENTATIVE,
  USER_CREATE_PROMOTER,
  EMBEDDED_SIGNING,
  BECOME_PARTNER,
  loginSuccess,
  loginError,
  loginMotorSuccess,
  loginMotorError,
  userCreateSuccess,
  userCreateError,
  userUpdateSuccess,
  userUpdateError,
  fetchedProposal,
  fetchedCounterProposal,
  fetchedInvoices,
  fetchedOneProposal,
  userRecoverPasswordSuccess,
  userRecoverPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
  userReviewerPublishProfileSuccess,
  userReviewerPublishProfileError,
  userReviewerVerifyApelidoSuccess,
  userReviewerVerifyApelidoError,
  updatePasswordSuccess,
  updatePasswordError,
  userCreateRepresentative,
  embeddedSigning,
  embeddedSigningSuccess,
  becomePartnerSuccess,
  becomePartner
} from './actions';
import { showNotification } from '../notification/actions.js';
import { ApiService, PropostaService } from '../service';

function* loginFacebook(action) {
  try {
    yield call(ApiService.loginFacebook, action.data);
    yield put(loginSuccess(ApiService.getUser()));
  } catch (e) {
    yield put(loginError());
  }
}

function* loginGoogle(action) {
  try {
    yield call(ApiService.loginGoogle, action.data);
    yield put(loginSuccess(ApiService.getUser()));
  } catch (e) {
    yield put(loginError());
  }
}

function* login(action) {
  try {
    yield call(ApiService.loginNew, action.data);
    yield put(loginSuccess(ApiService.getUser()));
  } catch (e) {
    yield put(loginError(e.response.status));
  }
}

function* loginMotor(action) {
  try {
    yield call(ApiService.loginMotor, action.data);
    yield put(loginMotorSuccess(ApiService.getUser()));
  } catch (e) {
    yield put(loginMotorError());
  }
}

function* logoff() {
  try {
    yield call(ApiService.logoff);
  } catch (e) {}
}

function* checkToken() {
  try {
    const data = yield call(ApiService.checkToken);
    if (data) {
      yield put(loginSuccess(ApiService.getUser()));
    }
  } catch (e) {
    yield put(loginError(e.response.status));
  }
}

function* createWriter(action) {
  try {
    const user = yield call(ApiService.registerWriter, action.data);
    yield put(userCreateSuccess(user));
  } catch (e) {
    yield put(userCreateError(e.response.data));
  }
}

function* createReviewer(action) {
  try {
    // console.log(action);
    const user = yield call(ApiService.registerReviewer, action.data);
    // console.log(user);
    yield put(userCreateSuccess(user));
  } catch (e) {
    yield put(userCreateError(e.response.data));
  }
}

function* createPromoter(action) {
  try {
    const user = yield call(ApiService.registerPromoter, action.data);

    yield put(userCreateSuccess(user));
  } catch (e) {
    yield put(userCreateError(e.response.data));
  }
}

function* createRepresentativePartner(action) {
  try {
    const user = yield call(
      ApiService.createRepresentativePartner,
      action.data
    );
    yield put(userCreateSuccess(user));
  } catch (e) {
    yield put(userCreateError(e.response.data));
  }
}

function* createPartnerInstituition(action) {
  try {
    const user = yield call(ApiService.createPartnerInstituition, action.data);

    yield put(userCreateSuccess(user));
  } catch (e) {
    yield put(userCreateError(e.response.data));
  }
}

function* updateUser(action) {
  try {
    const user = yield call(ApiService.updateUser, action.data);
    //console.log(user)
    yield put(userUpdateSuccess(user));
  } catch (e) {
    yield put(
      showNotification('error', 'Não foi possível atualizar os dados.', true)
    );
    yield put(userUpdateError(e.response.data));
    return false;
  }
}

function* updateUserAvatar(action) {
  try {
    const user = yield call(
      ApiService.updateUserAvatar,
      action.data,
      action.file
    );
    yield put(userUpdateSuccess(user));
  } catch (e) {}
}

function* userContact(action) {
  try {
    yield call(ApiService.contact, action.data);
  } catch (e) {}
}

function* getProposals(action) {
  try {
    const data = yield call(PropostaService.listProposal, action.data.page);
    yield put(fetchedProposal(data));
  } catch (e) {}
}

function* getCounterProposals(action) {
  try {
    const data = yield call(
      PropostaService.listCounterProposal,
      action.data.page
    );
    yield put(fetchedCounterProposal(data));
  } catch (e) {}
}

function* getProposal(action) {
  try {
    const data = yield call(PropostaService.getProposal, action.id);
    yield put(fetchedOneProposal(data));
  } catch (e) {}
}

function* verifyApelido(action) {
  try {
    const data = yield call(ApiService.verifyApelido, action.apelido);
    yield put(userReviewerVerifyApelidoSuccess(data));
  } catch (e) {
    yield put(userReviewerVerifyApelidoError(e.response));
  }
}

function* getInvoices() {
  try {
    const data = yield call(PropostaService.invoices);
    //console.log(data);
    yield put(fetchedInvoices(data));
  } catch (e) {}
}

function* publishProfile(action) {
  try {
    yield call(ApiService.publishProfile, action.data);
    yield put(userReviewerPublishProfileSuccess());
  } catch (e) {
    yield put(userReviewerPublishProfileError(e.response));
  }
}

function* recoverPassword(action) {
  try {
    yield call(ApiService.recoverPassword, action.data);
    yield put(userRecoverPasswordSuccess());
  } catch (e) {
    yield put(userRecoverPasswordError());
  }
}

function* resetPassword(action) {
  try {
    yield call(ApiService.resetPassword, action.data);
    yield put(userResetPasswordSuccess());
  } catch (e) {
    yield put(userResetPasswordError());
  }
}

function* updatePassword(action) {
  try {
    yield call(ApiService.updatePassword, action.data);
    yield put(updatePasswordSuccess());
  } catch (e) {
    yield put(updatePasswordError());
  }
}

function* userEmbeddedSigning(action) {
  try {
    const signingURL = yield call(ApiService.embeddedSigning, action.data);
    yield put(embeddedSigningSuccess(signingURL));
  } catch (e) {}
}

function* userBecomePartner(action) {
  try {
    const user = yield call(ApiService.becomePartner, action.data);
    yield put(becomePartnerSuccess(user));
  } catch (e) {}
}

export function* requests() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
  yield takeLatest(LOGIN_MOTOR, loginMotor);
  yield takeLatest(LOGIN_FACEBOOK, loginFacebook);
  yield takeLatest(LOGIN_GOOGLE, loginGoogle);
  yield takeLatest(LOGOFF, logoff);
  yield takeLatest(CHECK_TOKEN, checkToken);
  yield takeLatest(USER_CREATE_REVIEWER, createReviewer);
  yield takeLatest(USER_CREATE_WRITER, createWriter);
  //**- */
  yield takeLatest(USER_CREATE_PARTNER_INSTITUITION, createPartnerInstituition);
  yield takeLatest(USER_CREATE_PROMOTER, createPromoter);
  //**- */
  yield takeLatest(USER_UPDATE, updateUser);
  yield takeLatest(USER_CONTACT, userContact);
  yield takeLatest(USER_UPDATE_AVATAR, updateUserAvatar);
  yield takeLatest(FETCH_PROPOSAL, getProposals);
  yield takeLatest(FETCH_ONE_PROPOSAL, getProposal);
  yield takeLatest(FETCH_COUNTERPROPOSAL, getCounterProposals);
  yield takeLatest(FETCH_INVOICES, getInvoices);
  yield takeLatest(USER_REVIEWER_PUBLISH_PROFILE, publishProfile);
  yield takeLatest(USER_RECOVER_PASSWORD, recoverPassword);
  yield takeLatest(USER_RESET_PASSWORD, resetPassword);
  yield takeLatest(VERIFY_APELIDO, verifyApelido);
  yield takeLatest(USER_CREATE_REPRESENTATIVE, createRepresentativePartner);

  yield takeLatest(EMBEDDED_SIGNING, userEmbeddedSigning);
  yield takeLatest(BECOME_PARTNER, userBecomePartner);
}
