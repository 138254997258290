import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const Heading = styled.Text`
  margin: 12px;
  font-size: 12px;
  font-weight: bold;
`;

const Bold = styled.Text`
  font-weight: bold;
`;

const Section = styled.View`
  margin: 30px;
  font-size: 8px;
`;

const Paragraph = styled.Text`
  margin: 8px;
`;

const AgreementPDF = ({ user }) => (
  <Document>
    <Page>
      <Section>
        <Heading>CONTRATO DE PARCERIA COMERCIAL</Heading>
        <Paragraph>
          <Bold>
            <Bold>PRECEPTOR EDUCAÇÃO A DISTÂNCIA LTDA ME,</Bold>
          </Bold>
          pessoa jurídica constituída na forma de Sociedade Limitada, inscrita
          no CNPJ sob o nº 07.633.844/0001-19, com sede em St SRTVS, Quadra 701,
          Bloco O, 110, Asa Sul, CEP 70.340-000, Brasília-DF, representada na
          forma de seus atos constitutivos, doravante denominada simplesmente{' '}
          <Bold>CONTEXTO REVISOR</Bold>,do outro lado,
          {user.pf ? (
            <Paragraph>
              <Bold> {user && localStorage.getItem('nome')}</Bold>,{' '}
              {user.nacionalidade},{user.estado_civil}, portador do RG nº{' '}
              {user.rg}, inscrito no CPF sob o nº {user.document}, residente e
              domiciliado na {user.address}, nº {user.number}{' '}
              {user.complement || ''},{user.city}, {user.uf_id} ,{' '}
              {user.postal_code} ,neste ato representada na forma de seus atos
              constitutivos, doravante denominado simplesmente como{' '}
              <Bold>REVISOR PARCEIRO</Bold>.
            </Paragraph>
          ) : (
            <Paragraph>
              {user && localStorage.getItem('nome')} pessoa jurídica constituída
              na forma de (Sociedade Limitada/Empresário Individual) no CNPJ sob
              o nº {user.document}, com sede no {user.address}, neste ato
              representada na forma de seus atos constitutivos, doravante
              denominado simplesmente como REVISOR PARCEIRO.
            </Paragraph>
          )}
        </Paragraph>

        <Paragraph>
          As partes ora identificadas comprometem-se a, respectivamente, unir
          esforços, para firmar parceria comercial no intuito de acelerar os
          resultados esperados na utilização do software da seguinte forma:
        </Paragraph>

        <Heading>CLÁUSULA PRIMEIRA - DO OBJETO E NATUREZA JURÍDICA</Heading>

        <Paragraph>
          1.1. O objeto contratual da parceria é firmar parceria comercial entre
          CONTEXTO REVISOR e REVISOR PARCEIRO, de forma que o REVISOR PARCEIRO
          permita descontos maiores em seus serviços no intuito de aumentar o
          número de serviços solicitados na plataforma da CONTEXTO REVISOR e
          fomentar o crescimento geral de interesse pelos serviços do sistema
          “Contexto Revisor”.
        </Paragraph>

        <Paragraph>
          1.2. Para tal, o REVISOR PARCEIRO autorizará à CONTEXTO REVISOR
          aplicar desconto fixo de 10% (dez por cento) em todos os serviços de
          revisão feitos pelo REVISOR PARCEIRO a clientes da plataforma da
          CONTEXTO REVISOR.
        </Paragraph>

        <Paragraph>
          A presente parceria é firmada sem caráter de exclusividade, podendo as
          Partes realizarem contratações com demais parceiros, desde que não
          afete as questões de confidencialidade previstas neste.
        </Paragraph>

        <Heading>CLÁUSULA SEGUNDA - REMUNERAÇÃO E PAGAMENTO</Heading>

        <Paragraph>
          2.1. Em razão desta parceria, a <Bold>CONTEXTO REVISOR</Bold> pagará
          ao <Bold>REVISOR PARCEIRO</Bold> os seguintes valores:
        </Paragraph>

        <Paragraph>
          2.1.1. 2% (setenta e dois por cento) sobre o valor contratado para
          cada revisão na plataforma, após descontados o valor correspondente à
          tributação devida e o percentual de 10% (dez por cento) para o
          cliente, conforme definido como objeto deste Contrato. Estes valores
          serão repassados mensalmente, enquanto perdurar este Contrato.
        </Paragraph>

        <Paragraph>
          2.1.2. Os pagamentos serão realizados todo dia 5 (cinco) do mês
          subsequente.
        </Paragraph>

        <Paragraph>
          2.2. O pagamento será justificado por meio de relatório específico
          disponibilizado pela CONTEXTO REVISOR, no qual estarão descritos os
          valores recebidos e a data de finalização de cada redação.
        </Paragraph>

        <Paragraph>
          2.3. Todos os pagamentos feitos pela CONTEXTO REVISOR serão realizados
          por meio da plataforma online de pagamentos, em conta determinada pelo
          REVISOR PARCEIRO no momento da assinatura do presente instrumento.
        </Paragraph>

        <Heading>CLÁUSULA TERCEIRA – DAS CONDIÇÕES DA PARCERIA</Heading>

        <Paragraph>
          3.1. OO REVISOR PARCEIRO se obriga a realizar as suas atividades,
          cabendo-lhe total e exclusiva responsabilidade pelo integral
          atendimento de toda a legislação que rege os negócios jurídicos e que
          lhe atribua responsabilidades. Igualmente, o CAPTADOR se obriga também
          a reembolsar a REVISOR PARCEIRO por todas as despesas decorrentes de:
        </Paragraph>

        <Paragraph>
          a) reconhecimento judicial de vínculo empregatício seu ou de seus
          empregados com a REVISOR PARCEIRO;
        </Paragraph>

        <Paragraph>
          b) reconhecimento judicial de solidariedade da CONTEXTO REVISOR no
          cumprimento de suas obrigações trabalhistas e/ou previdenciárias;
        </Paragraph>

        <Paragraph>
          c) indenização, inclusive a terceiros, como consequência de eventuais
          danos causados pelo REVISOR PARCEIRO, ou seus prepostos, decorrentes
          de negligência ou imperícia, na execução das suas atividades.
        </Paragraph>

        <Heading>CLÁUSULA QUARTA – OBRIGAÇÕES DA CONTEXTO REVISOR</Heading>

        <Paragraph>
          4.1. São obrigações da <Bold>CONTEXTO REVISOR</Bold>:
        </Paragraph>

        <Paragraph>
          4.1.1. Dar assistência e orientação ao
          <Bold>REVISOR PARCEIRO</Bold> para correta execução da parceria aqui
          estabelecida.
        </Paragraph>

        <Paragraph>
          4.1.2. Fornecer informações, desde que requisitadas, por meio de
          planilhas e relatórios, a fim de auxiliar e esclarecer dúvidas do
          REVISOR PARCEIRO a respeito da parceria exercida e valores recebidos.
        </Paragraph>

        <Paragraph>
          4.1.3. Realizar os repasses dos valores e das comissões, conforme
          definido na Cláusula Segunda;
        </Paragraph>

        <Paragraph>
          4.1.4. Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, cuidando para a observância das
          obrigações assumidas e visando prevenir erros de expectativas e
          interpretação
        </Paragraph>

        <Paragraph>
          4.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome do{' '}
          <Bold>REVISOR PARCEIRO</Bold> sem expressa autorização em documento
          específico.
        </Paragraph>

        <Paragraph>
          4.1.6. Identificar no site o REVISOR PARCEIRO e sua oferta de desconto
          de 10% (dez por cento) para os alunos de Instituições Parceiras e
          outros clientes que por ventura utilizem a plataforma da CONTEXTO
          REVISOR para revisões.
        </Paragraph>

        <Heading>CLÁUSULA QUINTA – OBRIGAÇÕES DO REVISOR PARCEIRO</Heading>

        <Paragraph>
          5.1. São obrigações do <Bold>REVISOR PARCEIRO</Bold>:
        </Paragraph>
        <Paragraph>
          5.1.1. Realizar seus serviços de revisão com responsabilidade,
          atenção, zelo e afinco, assumindo toda e qualquer responsabilidade por
          frustrações, prejuízos e eventuais dúvidas que clientes contratantes
          venham a ter após o serviço prestado a partir da conexão feita pela
          CONTEXTO REVISOR.
        </Paragraph>
        <Paragraph>
          5.1.2. Recolher correta e pontualmente todas as obrigações e encargos
          trabalhistas, previdenciários, fiscais, parafiscais e outros que sejam
          ou venham a ser devidos em razão do presente contrato.
        </Paragraph>
        <Paragraph>
          5.1.3. Responder integralmente pelos atos praticados por si, seus
          prepostos ou funcionários, caso possua, que acarretarem prejuízo a
          CONTEXTO REVISOR ou terceiro.
        </Paragraph>

        <Paragraph>
          5.1.4.Esclarecer plenamente seus empregados, caso possua, sobre as
          condições do contrato ora pactuado, cuidando para a observância das
          obrigações assumidas e visando prevenir erros de expectativas e
          interpretação.
        </Paragraph>

        <Paragraph>
          5.1.5. Jamais formalizar qualquer tipo de negócio jurídico em nome da{' '}
          <Bold>CONTEXTO REVISOR</Bold> sem expressa autorização, em documento
          específico.
        </Paragraph>

        <Heading>CLÁUSULA SEXTA – DA PROPRIEDADE INTELECTUAL</Heading>

        <Paragraph>
          6.1. Conforme determina o art. 4º da Lei 9.609/98, a titularidade e
          todos os direitos relativos ao software desenvolvido, como o
          código-fonte, especificações funcionais, fluxogramas, logomarca,
          dentre outros dados técnicos, pertencem exclusivamente à CONTEXTO
          REVISOR, podendo somente esta utilizá-los, cedê-los e transferi-los a
          terceiros.
        </Paragraph>

        <Heading>CLÁUSULA SÉTIMA – CONFIDENCIALIDADE E SIGILO</Heading>

        <Paragraph>
          7.1. O REVISOR PARCEIRO compromete-se a manter o mais absoluto sigilo
          de todas as informações de conteúdo confidencial, sejam elas
          comerciais, técnicas, pessoais e outras, cujo conhecimento tiver, em
          decorrência do exercício de suas atividades; além de não exercer
          qualquer forma de concorrência direta com a CONTEXTO REVISOR, podendo
          ser responsabilizado civil ou criminalmente por eventuais danos
          materiais ou morais decorrentes da não obediência a esta regra.
        </Paragraph>

        <Paragraph>
          7.2. Por definição, “Informações Confidenciais” significam todos as
          informações técnicas e gerais, know-how, documentos, programas de
          computador e documentação, códigos fonte, relatórios, dados
          financeiros ou outros dados, registros, formulários, ferramentas,
          produtos, serviços, pesquisa presente e futura, conhecimento técnico,
          planos de marketing, segredos comerciais e outros materiais tangíveis
          ou intangíveis, armazenados ou não, compilados ou reduzidos a termo,
          seja física, eletrônica ou graficamente, por escrito, ou por qualquer
          meio, que esteja em poder da CONTEXTO REVISOR, e que seja revelado ao
          REVISOR PARCEIRO.
        </Paragraph>

        <Paragraph>
          7.3. As Informações Confidenciais incluem, sem limitação, materiais,
          arquivos e informações, bem como as informações de propriedade da{' '}
          <Bold>CONTEXTO REVISOR</Bold> ou de terceiros que:
        </Paragraph>

        <Paragraph>
          a) contenham a especificação de propriedade exclusiva ou confidencial;
        </Paragraph>

        <Paragraph>
          b) cuja natureza confidencial tenha sido informada ao{' '}
          <Bold>REVISOR PARCEIRO</Bold>;
        </Paragraph>

        <Paragraph>
          c) consistam na solução, nas inovações e nos aperfeiçoamentos que
          sejam de criação e de desenvolvimento individual ou em conjunto entre
          as <Bold>PARTES</Bold>;
        </Paragraph>

        <Paragraph>
          d) em virtude de suas características e de sua natureza seriam
          consideradas confidenciais em circunstâncias semelhantes.
        </Paragraph>

        <Paragraph>
          7.4. O <Bold>REVISOR PARCEIRO</Bold> concorda que a violação desta
          Cláusula, pelo uso de qualquer informação confidencial pertencente à{' '}
          <Bold>CONTEXTO REVISOR</Bold>, sem sua expressa autorização,
          causar-lhe-á danos e prejuízos irreparáveis, não podendo este ser
          inferior ao valor de pelo menos, R$30.000,00 (trinta mil reais).
        </Paragraph>

        <Paragraph>
          7.5. O REVISOR PARCEIRO concorda que as obrigações de
          Confidencialidade e Sigilo se iniciam a partir da assinatura do
          presente instrumento, perdurando na sua vigência e por 2 (dois) anos
          do término de qualquer que seja o vínculo existente entre as Partes.
          Desta forma e na hipótese de violação, a CONTEXTO REVISOR será
          imediatamente considerada legítima detentora do direito a tomar todas
          as medidas extrajudiciais e judiciais, nas esferas cíveis e criminais,
          inclusive de caráter cautelar ou de antecipação de tutela
          jurisdicional, que julgar cabíveis à defesa de seus direitos,
          inclusive autorais, independentemente da tomada das medidas judiciais
          tendentes a obter a reparação pelo dano causado.
        </Paragraph>

        <Heading>CLÁUSULA OITAVA – DESPESAS E TRIBUTOS</Heading>

        <Paragraph>
          8.1. Toda e qualquer despesa que o REVISOR PARCEIRO tiver em virtude
          da execução desta parceria serão de exclusiva responsabilidade deste,
          cabendo apenas à CONTEXTO REVISOR realizar os repasses de valores e
          comissões, conforme acordado na Cláusula Segunda.
        </Paragraph>

        <Paragraph>
          8.2. Ainda, cada parte responderá, de forma individual e exclusiva,
          pelos tributos e encargos que sejam de sua titularidade passiva.
        </Paragraph>

        <Heading>CLÁUSULA NONA – VIGÊNCIA E RESCISÃO IMOTIVADA</Heading>

        <Paragraph>
          9.1. O presente contrato de parceria comercial tem prazo de vigência
          de 365 (trezentos e sessenta e cinco) dias, renovável por iguais
          períodos caso as Partes assim decidam, a contar da data de sua
          assinatura, sendo passível de rescisão por qualquer das partes por
          meio de notificação expressa no prazo de 30 (trinta) dias.
        </Paragraph>

        <Paragraph>
          9.2. No caso de rescisão imotivada por qualquer das partes, caso a
          CONTEXTO REVISOR tenha pagamentos pendentes, deverá depositá-los em
          conta de titularidade do REVISOR PARCEIRO, enviando o respectivo
          comprovante, no prazo de 30 (trinta) dias da notificação da rescisão.
        </Paragraph>

        <Heading>CLÁUSULA DÉCIMA – RESCISÃO MOTIVADA</Heading>

        <Paragraph>
          10.1. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se o REVISOR PARCEIRO:
        </Paragraph>

        <Paragraph>
          10.1.1. ceder ou transferir o presente contrato, por qualquer forma, a
          terceiros em quaisquer condições, sem o consentimento da CONTEXTO
          REVISOR;
        </Paragraph>

        <Paragraph>
          10.1.2. descumprir qualquer cláusula ou condição deste contrato,
          especialmente no que diz respeito ao estabelecido na Cláusula Primeira
          deste documento;
        </Paragraph>

        <Paragraph>
          10.2. O presente contrato reputar-se-á rescindido, de pleno direito,
          independentemente de qualquer formalidade judicial ou extrajudicial,
          se a CONTEXTO REVISOR:
        </Paragraph>

        <Paragraph>
          10.2.1. Descumprir qualquer cláusula ou condição deste contrato
          especialmente no que tange a realizar os pagamentos nas formas, prazos
          e condições pactuadas na Cláusula Segunda deste documento.
        </Paragraph>

        <Paragraph>
          10.3. Nos casos de rescisão motivada, não será necessária qualquer
          notificação prévia para rescisão do presente contrato, dando-se por
          encerrada a contratação no dia em que a parte justificadamente se
          manifestar.
        </Paragraph>

        <Heading>
          CLÁUSULA DÉCIMA PRIMEIRA – PENALIDADES PELO INADIMPLEMENTO
        </Heading>

        <Paragraph>
          11.1. O descumprimento de qualquer das obrigações contratuais
          acarretará, após prévia notificação ou interpelação, facultada à parte
          inocente, de forma sucessiva:
        </Paragraph>

        <Paragraph>
          a) A suspensão da execução do presente contrato, o que faculta à
          CONTEXTO REVISOR a retenção de pagamentos e ao REVISOR PARCEIRO a
          suspensão dos serviços de revisão imediatamente.
        </Paragraph>

        <Paragraph>
          b) A exigibilidade direta e específica da obrigação descumprida,
          acrescida de multa moratória à razão de 1% (um por cento) sobre o
          total dos valores pagos até a data do inadimplemento, e de juros
          moratórios no montante de 1% (um por cento) ao mês.
        </Paragraph>

        <Paragraph>c) Em última instância, a resolução do contrato.</Paragraph>

        <Heading>CLÁUSULA DÉCIMA SEGUNDA – TOLERÂNCIA</Heading>

        <Paragraph>
          12.1. A tolerância de uma das partes para com a outra, relativamente
          ao descumprimento de qualquer uma das obrigações assumidas neste
          contrato, será mera liberalidade, não implicando novação ou renúncia a
          qualquer direito. A parte tolerante poderá, a qualquer tempo, exigir
          da outra parte o fiel e cabal cumprimento de suas obrigações.
        </Paragraph>

        <Heading>CLÁUSULA DÉCIMA TERCEIRA – DISPOSIÇÕES GERAIS</Heading>

        <Paragraph>
          13.1. O REVISOR PARCEIRO declara ter lido e estar ciente e de pleno
          acordo com todos os termos e condições deste Contrato, o qual foi
          feito em estrita observância aos limites do respectivo fim econômico
          ou social, pela boa-fé e/ou pelos bons costumes, levando em
          consideração inclusive que não estão em situação de premente
          necessidade e têm plenas condições de cumprir todas as cláusulas e
          condições que constituem seus direitos e obrigações constantes no
          presente instrumento.
        </Paragraph>

        <Paragraph>
          13.2. As disposições deste instrumento somente poderão ser alteradas
          mediante acordo escrito entre as partes, através de termos aditivos
          que farão parte integrante do contrato, ficando, desde já, consignado
          que atos de tolerância não configurarão novação contratual.
        </Paragraph>

        <Paragraph>
          13.3. Este Contrato não implica qualquer tipo de associação ou
          sociedade entre as Partes, ou em qualquer vínculo empregatício entre
          os sócios, diretores, empregados e prepostos de uma Parte para com a
          outra.
        </Paragraph>

        <Paragraph>
          13.4. As notificações de assuntos referentes a este instrumento
          poderão ser realizadas por correspondência destinada aos endereços
          físicos descritos na qualificação das Partes e eletrônicos, abaixo
          indicados, com a devida confirmação de recebimento:
        </Paragraph>

        <Paragraph>
          a) E-mail da <Bold>CONTEXTO REVISOR</Bold>:
          contato@contextorevisor.com.br
        </Paragraph>

        <Paragraph>
          b) E-mail do <Bold>REVISOR PARCEIRO</Bold>:{' '}
          {user && localStorage.getItem('email')}
        </Paragraph>

        <Heading>CLÁUSULA DÉCIMA QUARTA – FORO</Heading>

        <Paragraph>
          14.1. As Partes elegem o foro da Comarca de Brasília, no Distrito
          Federal, para dirimir quaisquer controvérsias que surgirem do presente
          contrato, renunciando, expressamente, a qualquer outro por mais
          privilegiado que seja.
        </Paragraph>

        <Paragraph>
          E, por estarem, assim justos e contratados, assinam o presente
          instrumento em duas vias de igual teor e forma, juntamente com 02
          (duas) testemunhas.
        </Paragraph>

        <Paragraph>
          Por estarem assim justos e contratados, firmam o presente instrumento
          em duas vias de igual teor, juntamente com 02 (duas) testemunhas.
        </Paragraph>
      </Section>
    </Page>
  </Document>
);

export default AgreementPDF;
