import React, { Component } from 'react';
import moment from 'moment';
import AvatarImageCropper from 'react-avatar-image-cropper';

import avatar1 from '../../assetsHolder/images/avatar-1.svg';
import avatar2 from '../../assetsHolder/images/avatar-2.svg';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import {
  FormInput,
  FormValidator,
  FormDate,
  FormMask
} from '../../siteComponents/form';
import { showNotification } from '../../../state-management';

class DadosComponent extends Component {
  state = {
    ufCep: null,
    avatar: null,
    avatarFile: null,
    userFilled: false,
    formError: false,
    user: {
      name: '',
      email: '',
      tipo_formacao_id: '',
      uf_id: '',
      city: '',
      celular: '',
      sexo: '',
      outro_sexo: '',
      dt_nascimento: '',
      whatsapp: ''
    }
  };

  avatarRef = null;

  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
    this.state = { ...this.state, avatar: '' };
  }

  componentDidMount() {
    this.setup(this.props);
  }

  componentWillReceiveProps(props) {
    this.setup(props);
  }

  setup(props) {
    if (!this.state.userFilled && typeof props.user !== 'undefined') {
      let { user } = props;
      let newState = { ...this.state };
      if (user && user.dt_nascimento && !user.dt_nascimento.includes('/')) {
        user.dt_nascimento = moment(user.dt_nascimento, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        );
      }

      if (props.user && props.user.imagem) {
        newState.avatar = props.user.imagem;
      }
      this.setState({ ...newState, user, userFilled: true });
    }
  }

  readFile(file) {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ avatar: reader.result, avatarFile: file });
      };
    }
  }

  validate(user) {
    let dt_nascimento;
    if (user.dt_nascimento) {
      dt_nascimento = user.dt_nascimento.replace(/[^0-9]/g, '');
    } else {
      dt_nascimento = '00000000';
    }

    return user.name && user.celular && dt_nascimento.length === 8;
  }

  handleChangeInput = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.formError = false;
    state.user[name] = value;
    this.setState(state);
  };

  handleChangeOther = e => {
    const { value } = e.target;
    this.setState({
      user: { ...this.state.user, sexo: 'O', outro_sexo: value }
    });
  };

  handleFileChoose = e => {
    let { files } = e.target;
    this.readFile(files[0]);
  };

  handleImageClick(ref) {
    ref.current.click();
  }

  apply(file) {
    this.readFile(file);
    var src = window.URL.createObjectURL(file);
    this.refs.divImage.style = `backgroundImage: url(${src})`;
    let newState = { ...this.state };
    newState.avatar = '';
    this.setState({ ...newState });
    this.refs.avatarImageCropper.state.relX = 0;
    this.refs.avatarImageCropper.state.relY = 0;
  }

  handleSubmit = () => {
    if (!this.validate(this.state.user)) {
      this.setState({ formError: true });
      this.props.dispatch(
        showNotification(
          'error',
          'ATENÇÃO: Você precisa preencher todos os dados obrigatórios.',
          true
        )
      );
      return;
    }

    let { user } = this.state;

    user.celular = user.celular.replace(/\s+/g, '');

    if (this.state.user.whatsapp) {
      user.whatsapp = user.whatsapp.replace(/\s+/g, '');
    }
    delete user.bank;
    delete user.page_price_cents;

    this.props.onSave(user, this.state.avatarFile);
  };

  renderFoto() {
    const actions = [
      <button key={0} className="hero-btn danger w-button">
        <FontAwesomeIcon icon="times" />
      </button>,
      <button key={1} className="hero-btn turquesa w-button">
        <FontAwesomeIcon icon="crop" />
      </button>
    ];
    return (
      <div className="img-container flex-row">
        <div
          ref="divImage"
          className="avatar"
          style={{
            backgroundImage: `url(${this.state.avatar ||
              (this.state.user && this.state.user.sexo === 'F'
                ? avatar1
                : avatar2)})`
          }}
        >
          <AvatarImageCropper
            apply={this.apply.bind(this)}
            isBack={false}
            text=" "
            icon={() => null}
            ref="avatarImageCropper"
            actions={actions}
            id="elementAvatarOnForm"
          />
        </div>

        <div className="profile-photo">
          <div>Defina uma foto para seu perfil.</div>
          <div>Tamanho máximo recomendado: 300 x 300 px.</div>
        </div>
      </div>
    );
  }

  render() {
    let { roles } = this.props;
    return (
      <div className="hero-box img-box">
        <h4 className="turquoise-text bold">Dados conta Contexto</h4>

        {this.renderFoto()}

        <FormValidator className="flex-column w-form">
          <FormInput
            type="text"
            placeholder="Nome *"
            className="accont-input w-input"
            name="name"
            value={this.state.user.name}
            onChange={this.handleChangeInput}
            error={this.state.formError}
          />
          {((roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica') && (
            <FormInput
              type="text"
              placeholder="Nome Fantasia*"
              className="accont-input w-input"
              name="nome_fantasia"
              value={this.state.user.nome_fantasia}
              onChange={this.handleChangeInput}
            />
          )}
          <select
            className="accont-input w-select"
            name="uf_id"
            value={this.state.user.uf_id || ''}
            onChange={this.handleChangeInput}
          >
            <option value="">Estado *</option>

            {this.props.states.map(item => {
              return (
                <option key={item.id} value={item.id}>
                  {item.uf} - {item.descricao}
                </option>
              );
            })}
          </select>

          <FormInput
            type="text"
            placeholder="Cidade"
            className="accont-input w-input"
            name="city"
            value={this.state.user.city || ''}
            onChange={this.handleChangeInput}
            error={this.state.formError}
          />

          <FormMask
            mask="99 99999 9999"
            maskChar=""
            placeholder="Telefone *"
            className="accont-input w-input"
            name="celular"
            value={this.state.user.celular || ''}
            onChange={this.handleChangeInput}
          />

          <FormMask
            mask="99 99999 9999"
            maskChar=""
            placeholder="Whatsapp"
            className="accont-input w-input"
            name="whatsapp"
            value={this.state.user.whatsapp || ''}
            onChange={this.handleChangeInput}
          />
          {!(
            (roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica'
          ) && (
            <select
              id="formacao"
              name="tipo_formacao_id"
              className="accont-input w-select"
              value={this.state.user.tipo_formacao_id || ''}
              onChange={this.handleChangeInput}
            >
              <option value="">Nível de formação</option>

              {this.props.trainings.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.formacao}
                  </option>
                );
              })}
            </select>
          )}
          {!(
            (roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica'
          ) && (
            <div className="gender date-input">
              <div className="gender-box">
                <label>Data de Nascimento *</label>
                <FormDate
                  className="w-input accont-input"
                  name="dt_nascimento"
                  minDate={'01/01/1900'}
                  maxDate={new Date()}
                  validators={['date']}
                  value={this.state.user.dt_nascimento}
                  onChange={this.handleChangeInput}
                />
              </div>
            </div>
          )}
          {!(
            (roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica'
          ) && (
            <div className="gender">
              <div className="gender-box">
                <label>Gênero *</label>
                <div className="gender-radio w-radio">
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sexo"
                      id="exampleRadios1"
                      value="M"
                      checked={this.state.user.sexo === 'M'}
                      onChange={this.handleChangeInput}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Masculino
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sexo"
                      id="exampleRadios2"
                      value="F"
                      checked={this.state.user.sexo === 'F'}
                      onChange={this.handleChangeInput}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Feminino
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!(
            (roles && roles.isInstituicao) ||
            this.state.user.person_type === 'Pessoa Jurídica'
          ) && (
            <div className="gender radio-input">
              <div className="gender-box">
                <div className="gender-radio w-radio">
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sexo"
                      id="exampleRadios3"
                      value="O"
                      checked={this.state.user.sexo === 'O'}
                      onChange={this.handleChangeInput}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios3"
                    >
                      Outro
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  className="accont-input w-input"
                  maxLength="256"
                  name="outro"
                  data-name="outro"
                  placeholder="Outro"
                  id="outro"
                  value={this.state.user.outro_sexo || ''}
                  onChange={this.handleChangeOther}
                />
              </div>
            </div>
          )}
          <button
            type="button"
            className="button turquoise w-button submit-profile"
            onClick={this.handleSubmit}
          >
            Confirmar alterações
          </button>
        </FormValidator>
      </div>
    );
  }
}

export { DadosComponent };
