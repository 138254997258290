import { string, ReactComponentLike } from 'prop-types';
import React from 'react';

export const InfoBox = ({ img, title, content }) => (
  <div className="white-halfbox">
    <img src={img} alt="blurb" className="centered-img" />
    <div className="bold revisor-header">{title}</div>
    <p>{content}</p>
  </div>
);

InfoBox.propTypes = {
  img: string,
  title: string,
  content: ReactComponentLike
};

export default InfoBox;
