import { func, number, string } from 'prop-types';
import React from 'react';
import Slider from 'react-rangeslider';
import './style.css';

export const Range = ({
  title,
  infValue,
  infText,
  supValue,
  supText,
  value,
  change
}) => {
  const labels = {
    [infValue]: infText,
    [supValue]: supText
  };
  return (
    <div>
      <div className="filter-title">{title}</div>
      <Slider
        min={infValue}
        max={supValue}
        value={value}
        labels={labels}
        onChange={change}
        tooltip={false}
      />
      <h4 style={{ textAlign: 'center', color: '#34aeff' }}>{value}</h4>
    </div>
  );
};

Range.propTypes = {
  title: string,
  infValue: number,
  infText: string,
  supValue: number,
  supText: string,
  value: number,
  change: func
};

export default Range;
