import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import './Notification.css';

class NotificationComponent extends Component {
  state = {
    visible: false
  };
  delayTime = null;
  DELAY = 4000;

  componentWillReceiveProps() {
    this.setState({ visible: true });
  }

  componentWillUnmount() {
    if (this.delayTime) {
      clearTimeout(this.delayTime);
    }
  }

  handleClose = () => {
    this.setState({ visible: false });
  };

  render() {
    let { type, msg, auto } = this.props;

    if (auto) {
      this.delayTime = setTimeout(() => {
        this.handleClose();
        clearTimeout(this.delayTime);
        this.delayTime = null;
      }, this.DELAY);
    }

    return (
      this.state.visible && (
        <div className={'notification ' + type}>
          <span>{msg}</span>

          {!auto && (
            <span className="close" onClick={this.handleClose}>
              <FontAwesomeIcon icon="times" />
            </span>
          )}
        </div>
      )
    );
  }
}

NotificationComponent.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'success']),
  auto: PropTypes.bool
};

export { NotificationComponent };
