import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../siteComponents/Footer';
import GeneralMenu from '../../siteComponents/GeneralMenu';
import Logo from '../../siteComponents/Logo';
import routes from '../../../routes';
import {
  logoff,
  userContact,
  showNotification
} from '../../../state-management/';
import './Contact.css';

const { contato } = routes;

class ContactComponent extends Component {
  state = {
    form: {
      name: '',
      email: '',
      message: ''
    },
    error: {
      name: false,
      email: false,
      message: false
    }
  };

  delayTimer = null;

  componentWillUnmount() {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
  }

  handleInputChange = e => {
    let { name, value } = e.target;
    let state = { ...this.state };
    state.form[name] = value;
    state.error[name] = !value;
    this.setState(state);
  };

  handleSendContact = () => {
    if (
      this.state.form.name &&
      this.state.form.email &&
      this.state.form.message
    ) {
      this.props.contact(this.state.form);
      this.props.showNotification(
        'success',
        'Mensagem enviada! Em breve entraremos em contato',
        true
      );

      this.delayTimer = setTimeout(() => {
        this.props.history.replace('/');
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }, 1000);
    } else {
      this.props.showNotification('error', 'ATENÇÃO: Preencha os dados', true);
    }
  };

  render() {
    return (
      <div className="contact">
        <GeneralMenu selected={contato} {...this.props} />
        <div className="section-dark light-padding">
          <div className="flexcourners large-container">
            <div className="halfbox overlay-login">
              <Logo />
              <h4 className="overlay-text">
                Plataforma de conexão entre clientes <br />e revisores.
              </h4>
            </div>

            <div className="halfbox login-box" style={{ padding: '3%' }}>
              <div className="w-form">
                <h1 className="turquoise-text bold">Fale Conosco</h1>

                <input
                  type="text"
                  placeholder="Seu nome"
                  className={
                    'accont-input w-input ' + (this.state.error.name && 'error')
                  }
                  name="name"
                  value={this.state.form.name}
                  onChange={this.handleInputChange}
                />

                <input
                  type="text"
                  placeholder="E-mail"
                  className={
                    'accont-input w-input ' +
                    (this.state.error.email && 'error')
                  }
                  name="email"
                  value={this.state.form.email}
                  onChange={this.handleInputChange}
                />

                <textarea
                  name="message"
                  className={
                    'accont-input w-input ' +
                    (this.state.error.message && 'error')
                  }
                  placeholder="Deixe sua mensagem"
                  value={this.state.form.message}
                  onChange={this.handleInputChange}
                />

                <button
                  type="button"
                  className="accont-btn--redator button w-button"
                  onClick={this.handleSendContact}
                >
                  Enviar
                </button>

                <div className="divider" />

                <p className="text-center bold">
                  <b>Fale com a gente agora!</b>
                </p>

                <p className="text-center phone">
                  <p>
                    <img
                      src="/images/whatsapp.png"
                      width="20"
                      height="20"
                      alt="Whatsapp"
                    />{' '}
                    <a
                      href="http://api.whatsapp.com/send?1=pt_BR&phone=5561999494321"
                      Style="color:#34aeff"
                      target="_blanck"
                    >
                      61 99949-4321
                    </a>{' '}
                  </p>
                  <p>(Clique no número)</p>
                  <p className="text-center">contato@contextorevisor.com.br</p>
                </p>
                <p className="text-center address">
                  <div>Preceptor Educação a Distância.</div>
                  <div>
                    Rua Cel. Alves Teixeira, 1290, sala 5, bairro Dionísio
                    Torres, CEP 60135-208, Fortaleza-CE
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  logoff: () => dispatch(logoff()),
  contact: data => dispatch(userContact(data)),
  showNotification: (type, msg, auto) =>
    dispatch(showNotification(type, msg, auto))
});

const Contact = connect(mapStateToProps, mapDispatchToProps)(ContactComponent);
export { Contact };
