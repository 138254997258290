import {
  CHANGE_PAGE_PRICE,
  CHANGE_NUMBER_REVISIONS,
  CHANGE_RATING,
  CHANGE_ORDER,
  CHANGE_SEARCH_QUERY,
  USE_SEARCH_FILTER,
  FETCHED_STATES,
  FETCHED_TRAININGS,
  FETCHED_REVIEWERS,
  FETCHED_PARTNERS,
  FETCHED_RANDOM_REVIEWERS,
  FETCHED_REVIEWER,
  CLEAR_QUERY_FILTER,
  REVIEWER_CREATE_PROPOSAL,
  REVIEWER_CREATE_PROPOSAL_SUCCESS,
  REVIEWER_CREATE_COUNTER_PROPOSAL_SUCCESS,
  REVIEWER_DISAPPROVE_PROPOSAL_SUCCESS,
  REVIEWER_APPROVE_PROPOSAL_SUCCESS,
  REVIEWER_CREATE_PROPOSAL_ERROR,
  FETCHED_DIVULGACAO
} from './actions';

const initialState = {
  loading: false,
  searchQuery: '',
  searchFilter: false,
  orderType: '',
  rating: null,
  pagePrice: 0,
  numberRevisions: 0,
  trainings: [],
  divulgacao: [],
  states: [],
  statesMap: {},
  reviewerProfile: {
    name: 'Amanda Reis',
    job: 'Professora',
    location: 'São Luís - MA',
    price: 12.9,
    qtdRevisions: '32',
    score: '3.5',
    userPackage: 'premium'
  },
  reviewers: [],
  reviewersPagination: {
    page: 1
  },
  randomReviewers: {},
  reviewer: {},
  reviewerCreateProposalSuccess: false,
  reviewerCreateProposalError: false,
  reviewerCreateCounterProposalSuccess: false,
  reviewerDisapproveProposalSuccess: false,
  reviewerApproveProposalSuccess: false
};

const reviewers = (state = initialState, action) => {
  state.loading = false;
  state.reviewerCreateProposalSuccess = false;
  state.reviewerCreateProposalError = false;
  state.reviewerCreateCounterProposalSuccess = false;
  state.reviewerDisapproveProposalSuccess = false;
  state.reviewerApproveProposalSuccess = false;

  switch (action.type) {
    case CHANGE_PAGE_PRICE:
      return {
        ...state,
        pagePrice: action.pagePrice
      };
    case CHANGE_NUMBER_REVISIONS:
      return {
        ...state,
        numberRevisions: action.numberRevisions
      };
    case CHANGE_RATING:
      let rating = action.rating !== state.rating ? action.rating : null;

      return {
        ...state,
        rating
      };
    case CHANGE_ORDER:
      return {
        ...state,
        orderType: action.orderType
      };
    case CHANGE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    case USE_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: !state.searchFilter
      };

    case FETCHED_STATES:
      let statesMap = action.data.reduce((m, state) => {
        m[state.id] = state;
        return m;
      }, {});

      return {
        ...state,
        states: action.data,
        statesMap
      };

    case FETCHED_TRAININGS:
      return {
        ...state,
        trainings: action.data
      };

    case FETCHED_DIVULGACAO:
      return {
        ...state,
        divulgacao: action.data
      };

    case FETCHED_REVIEWERS:
      const { reset } = action.options || {};
      const { data, ...pagination } = action.data;

      const reviewers = reset ? data : [...state.reviewers, ...data];
      const reviewersPagination = {
        ...pagination,
        page: reset ? 2 : state.reviewersPagination.page + 1
      };

      return {
        ...state,
        reviewers,
        reviewersPagination
      };

    case FETCHED_RANDOM_REVIEWERS:
      return {
        ...state,
        randomReviewers: action.data.data
      };

    case FETCHED_REVIEWER:
      return {
        ...state,
        reviewer: action.data
      };

    case CLEAR_QUERY_FILTER:
      return {
        ...state,
        searchQuery: '',
        searchFilter: false,
        orderType: '',
        rating: null,
        pagePrice: 0,
        numberRevisions: 0
      };

    case REVIEWER_CREATE_PROPOSAL:
      return {
        ...state,
        loading: true
      };

    case REVIEWER_CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewerCreateProposalSuccess: true
      };

    case REVIEWER_CREATE_COUNTER_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewerCreateCounterProposalSuccess: true
      };

    case REVIEWER_DISAPPROVE_PROPOSAL_SUCCESS:
      return {
        ...state,
        reviewerDisapproveProposalSuccess: true
      };

    case REVIEWER_APPROVE_PROPOSAL_SUCCESS:
      return {
        ...state,
        reviewerApproveProposalSuccess: true
      };

    case REVIEWER_CREATE_PROPOSAL_ERROR:
      return {
        ...state,
        loading: false,
        reviewerCreateProposalError: true
      };

    default:
      return state;
  }
};

export default reviewers;
