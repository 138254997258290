import React, { Component } from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApiService } from '../../../state-management/service/api.js';
import { showNotification, userUpdate } from '../../../state-management';

class PromotionalCodeClient extends Component {
  state = {
    code: '',
    user: {
      due_date_code: ''
    }
  };

  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
    let newState = { ...this.state };
    newState.avatar = '';
    this.setState({ ...newState });
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setup(this.props);
  }

  componentWillReceiveProps(props) {
    this.setup(props);
  }

  setup(props) {
    if (!this.state.userFilled && typeof props.user !== 'undefined') {
      let { user } = props;
      let newState = { ...this.state };
      if (user && user.dt_nascimento && !user.dt_nascimento.includes('/')) {
        user.dt_nascimento = moment(user.dt_nascimento, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        );
      }

      this.setState((state, props) => {
        return {
          user: user
        };
      });
    }
  }

  sendCode = event => {
    const { dispatch } = this.props;
    const { user } = this.state;
    ApiService.verifyCode({ code: this.state.code })
      .then(res => {
        ApiService.sendCode(this.state.code)
          .then(response => {
            dispatch(
              showNotification(
                'success',
                'Parábens agora você já pode receber 10% de desconto com revisores parceiros.',
                true
              )
            );
            dispatch(userUpdate(user));
            this.props.history.push('/buscar-revisor');
          })
          .catch(function(response) {
            dispatch(showNotification('error', 'Código Inválido.', true));
            return;
          });
      })
      .catch(function(response) {
        dispatch(showNotification('error', 'Código Inválido.', true));
        return;
      });
  };

  handleChange(event) {
    this.setState({ code: event.target.value });
  }

  render() {
    return (
      <div
        style={{
          display: 'flex'
        }}
        className="hero-box img-box"
      >
        <h1
          style={{
            paddingBottom: '10vh',
            fontSize: '2.5em'
          }}
          className="turquoise-text bold"
        >
          Insira seu Código Promocional
        </h1>
        <input
          value={this.state.code}
          maxlength="30"
          className="accont-input w-input"
          placeholder="Código Promocional"
          style={{
            fontSize: '1.5em',
            paddingTop: '2vh',
            paddingBottom: '2vh',
            width: '20vw',
            paddingLeft: '-50vw',
            display: 'inline-flex',
            marginBottom: '6vh'
          }}
          type="text"
          onChange={this.handleChange}
        ></input>
        <div style={{ 'text-align': 'right' }}>
          <button
            style={{ display: 'inline-block', marginBottom: '5vh' }}
            className="button w-button turquoise"
            onClick={this.sendCode}
          >
            {' '}
            Enviar Código{' '}
          </button>
        </div>
      </div>
    );
  }
}

export { PromotionalCodeClient };
