import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInvoices, showNotification } from '../../../../state-management';
import emptySales from '../../../assetsHolder/images/img-empty-sales.png';
import {
  URL_VIEW_MOTOR,
  ApiService
} from '../../../../state-management/service/api';
import './SendOptions.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// TODO usar variavel do .gitlab-ci.yml
//const URL_VIEW_MOTOR = 'https://redacao.contextorevisor.com.br/#/';

const labelTipo = {
  '1': 'Redação estudantil/concurso',
  '2': 'TCC/monografia/dissertação/tese',
  '3': 'Outros tipos de texto'
};

class ComprasContainer extends Component {
  componentDidMount() {
    this.props.dispatch(fetchInvoices());
  }

  onChangeImage(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    var extension = file.type.split('/').pop();
    var wrpID = event.target.dataset.wrp;
    var props = this.props;

    if (/^(jpg|jpeg|png|gif|svg)$/.test(extension)) {
      if (file.size < 300000000) {
        var fileReader = new FileReader();
        fileReader.onload = function(result) {
          var fileName = file.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          var data = {
            filename: fileName,
            wrpID: wrpID,
            arquivo: result.target.result
          };

          ApiService.uploadRedacaoImagem(data).then(res => {
            props.dispatch(
              showNotification(
                'success',
                'Arquivo de imagem enviado com sucesso.',
                true
              )
            );
          });
        };
        fileReader.readAsDataURL(file);
      } else {
        props.dispatch(
          showNotification(
            'error',
            'No momento aceitamos apenas upload de imagens de até 300MB, tente enviar um arquivo menor.',
            true
          )
        );
      }
    } else {
      props.dispatch(
        showNotification('error', 'Tipo de arquivo não suportado.', true)
      );
    }
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var redatorID = this.props.user.id;
    var revisorID = event.target.dataset.reviewer;
    var wrpID = event.target.dataset.wrp;
    var file = event.target.files[0];
    var extension = file.type.split('/').pop();
    if (
      /^(pdf|octet-stream|msword|odt|vnd.ms-fontobject|vnd.oasis.opendocument.text|plain|vnd.openxmlformats-officedocument.wordprocessingml.document)$/.test(
        extension
      )
    ) {
      if (file.size < 300000000) {
        var fileReader = new FileReader();
        fileReader.onload = result => {
          var fileName = file.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          ApiService.uploadRedacaoFile(
            result.target.result,
            fileName,
            revisorID,
            redatorID,
            wrpID
          ).then(res => {
            this.props.dispatch(
              showNotification('success', 'Arquivo enviado com sucesso.', true)
            );
            this.componentDidMount();
          });
        };
        fileReader.readAsDataURL(file);
      } else {
        this.props.dispatch(
          showNotification(
            'error',
            'No momento somente aceitamos upload de arquivos de até 300MB, tente enviar um arquivo menor.',
            true
          )
        );
      }
    } else {
      this.props.dispatch(
        showNotification('error', 'Tipo de arquivo não suportado.', true)
      );
    }
  }

  renderStatus(invoice) {
    switch (invoice.status) {
      case 'pending':
        return (
          <div>
            <div className="compras-pagamento">
              Aguardando pagamento.
              <span>
                A instituição bancária pode levar até 48h para efetivá-lo
              </span>
            </div>
            <div className="visible">
              <a
                // className="compras-detalhes"
                className="button turquoise w-button nomargin margin-left-auto"
                target="_blank"
                rel="noopener noreferrer"
                href={invoice.provider_url}
              >
                Link para <strong>pagamento</strong>
              </a>
            </div>
          </div>
        );

      case 'in_analysis':
        return (
          <div>
            <div className="compras-finalizada">
              Em espera de debitação do pagamento
              <span>
                (O Revisor possui o direito de não iniciar o processo de revisão
                ainda)
              </span>
            </div>
            <div
              className="flexbox"
              style={{ maxWidth: '510px', marginTop: '10px' }}
            >
              <button className="button w-button nomargin margin-left-auto">
                Redigir texto
              </button>
              {/* <button
                className="button w-button nomargin margin-left-auto"
                onClick={() => {
                  this.uploadImage.click();
                }}
              >
                Enviar imagem
                <input
                  id="image"
                  type="file"
                  ref={ref => (this.uploadImage = ref)}
                  style={{ display: 'none' }}
                  onChange={this.onChangeImage.bind(this)}
                />
              </button> */}
              <button
                className="button w-button nomargin margin-left-auto"
                onClick={() => {
                  this.uploadFile.click();
                }}
              >
                Enviar arquivo
                <input
                  id="arquivo"
                  type="file"
                  ref={ref => (this.uploadFile = ref)}
                  style={{ display: 'none' }}
                  onChange={(invoice.status = this.onChangeFile.bind(this))}
                />
              </button>
            </div>
          </div>
        );

      case 'paid':
        return (
          <div>
            <div className="compras-finalizada">Compra finalizada</div>
            <div
              className="flexbox"
              style={{ maxWidth: '510px', marginTop: '10px' }}
            >
              <a
                //href={`${URL_VIEW_MOTOR}redacao-list/?id=${invoice.wrp.redacao.id}/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                //href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                href={`${URL_VIEW_MOTOR}redacao/create/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                className="button w-button nomargin margin-left-auto"
              >
                Redigir texto
              </a>
              {/* <button
                className="button w-button nomargin margin-left-auto"
                onClick={() => {
                  this.uploadImage.click();
                }}
              >
                Enviar imagem
                <input
                  id="image"
                  type="file"
                  accept="image/*"
                  ref={ref => (this.uploadImage = ref)}
                  data-wrp={invoice.wrp_id}
                  style={{ display: 'none' }}
                  onChange={this.onChangeImage.bind(this)}
                />
              </button> */}
              <button
                className="button w-button nomargin margin-left-auto"
                onClick={() => {
                  this.uploadFile.click();
                }}
              >
                Enviar arquivo
                <input
                  id="arquivo"
                  type="file"
                  ref={ref => (this.uploadFile = ref)}
                  style={{ display: 'none' }}
                  data-reviewer={invoice.reviewer.id}
                  data-wrp={invoice.wrp_id}
                  onChange={this.onChangeFile.bind(this)}
                />
              </button>
            </div>
          </div>
        );

      case 'used':
        return (
          <div>
            <div className="compras-finalizada">Compra finalizada</div>
            <div className="visible">
              <a
                //href={`${URL_VIEW_MOTOR}redacao-list/?id=${invoice.wrp.redacao.id}/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                //href={`${URL_VIEW_MOTOR}redacao/edit/${invoice.wrp.redacao.id}/${localStorage.contexto_token_motor_key}/`}
                className="button w-button nomargin margin-left-auto"
              >
                Acompanhar Correção
              </a>
            </div>
          </div>
        );

      case 'done':
        return (
          <div>
            <div className="compras-finalizada">Revisão Encerrada</div>
            <div className="visible">
              <a
                //href={`${URL_VIEW_MOTOR}redacao-list/?id=${invoice.wrp.redacao.id}/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                href={`${URL_VIEW_MOTOR}login/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                className="button w-button nomargin margin-left-auto"
              >
                Revisão
              </a>
            </div>
          </div>
        );

      case 'archive':
        return (
          <div>
            <div className="compras-finalizada">Compra finalizada</div>
            <div className="visible">
              <a
                //href={`${URL_VIEW_MOTOR}redacao-list/?id=${invoice.wrp.redacao.id}/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                href={`${URL_VIEW_MOTOR}upload-arquivo/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                className="button w-button nomargin margin-left-auto"
              >
                Acompanhar Correção
              </a>
            </div>
          </div>
        );
      case 'archive-done':
        return (
          <div>
            <div className="compras-finalizada">Revisão Encerrada</div>
            <div className="visible">
              <a
                //href={`${URL_VIEW_MOTOR}redacao-list/?id=${invoice.wrp.redacao.id}/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/`}
                href={`${URL_VIEW_MOTOR}upload-arquivo/${invoice.wrp_id}/${localStorage.contexto_token_motor_key}/?id=${invoice.wrp.redacao.id}`}
                className="button w-button nomargin margin-left-auto"
              >
                Ver revisão
              </a>
            </div>
          </div>
        );

      default:
        return <div className="compras-finalizada">Compra cancelada</div>;
    }
  }
  render() {
    let loading = this.props.loading;

    return (
      <div className="hero-box img-box minhas-compras">
        <h4 className="turquoise-text bold">Meus pedidos</h4>

        {this.props.invoices !== undefined &&
          this.props.invoices.length > 0 &&
          !loading && (
            <ul className="perfil-list w-list-unstyled">
              {[]
                .concat(this.props.invoices)
                .sort((a, b) => (a['proposal_id'] < b['proposal_id'] ? 1 : -1))
                .map((invoice, index) => (
                  <li className="perfil-item" key={index}>
                    <div className="list-style" />
                    <div className="revisao-item">
                      <div className="listonly perfil-especification compras-title">
                        <strong>{labelTipo[invoice.proposal.type]}</strong>
                      </div>
                      <div className="listonly perfil-especification compras-title">
                        <strong>Revisor: </strong>
                        {invoice.reviewer.name}
                      </div>
                      {!(invoice.status !== 'paid') && (
                        <div className="revisao-data">
                          <strong>Aprovado em: </strong>
                          {invoice.created_at}
                        </div>
                      )}
                      {this.renderStatus(invoice)}
                    </div>
                  </li>
                ))}
            </ul>
          )}
        {(this.props.invoices === undefined ||
          this.props.invoices.length === 0) &&
          !loading && (
            <div>
              <img src={emptySales} alt="Lista Vazia" className="no-proposta" />
              <span className="no-proposta">
                Você ainda não fez nenhum pedido
              </span>
            </div>
          )}

        {loading && (
          <SkeletonTheme>
            <ul className="perfil-list w-list-unstyled">
              <li className="perfil-item" key={2}>
                <div className="list-style" />
                <div className="revisao-item">
                  <Skeleton
                    count={6}
                    width={300}
                    style={{ marginRight: '50%', marginBottom: '2%' }}
                  />
                </div>
              </li>
            </ul>
          </SkeletonTheme>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userStore }) => {
  return { ...userStore };
};

const ComprasComponent = connect(mapStateToProps)(ComprasContainer);
export { ComprasComponent };
