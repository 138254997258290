import React, { Component } from 'react';
import { control } from 'react-validation';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { required } from './validators';

const validators = {
  required: required
};

class FormMaskInput extends Component {
  state = {
    error: null
  };

  handleChange = e => {
    this.props.onChange && this.props.onChange(e);
  };

  handleValidate = e => {
    let error;

    if (this.props.validators) {
      for (let key of this.props.validators) {
        error = validators[key](e.target.value, this.props);
        if (error) {
          this.setState({ error });
          break;
        }
      }

      if (!error) {
        this.setState({ error: null });
      }
    }

    this.props.onBlur && this.props.onBlur(e);
  };

  render() {
    let newProps = { ...this.props };
    delete newProps.onChange;
    delete newProps.isChanged;
    delete newProps.isUsed;
    delete newProps.className;
    delete newProps.onBlur;

    let { className } = this.props;
    className = className || '';
    className += this.state.error ? ' is-invalid-input' : '';

    return (
      <div>
        <InputMask
          {...newProps}
          className={className}
          onChange={this.handleChange}
          onBlur={this.handleValidate}
        />
        {this.state.error}
      </div>
    );
  }
}

FormMaskInput.propTypes = {
  mask: PropTypes.string.isRequired,
  validators: PropTypes.array
};

const FormMaskHOC = control(FormMaskInput);

const FormMask = props => {
  let validations = [];
  if (props.validators) {
    for (let validator of props.validators) {
      validations.push(validators[validator]);
    }
  }

  return <FormMaskHOC {...props} validations={validations} />;
};

export { FormMask };
